<template>
  <div class="content-section implementation">
    <div class="card">
      <b-row class="">
        <b-col md="8" sm=""></b-col>
        <b-col md="4" sm="12" class="text-right">
          <Button
            :label="$t('reset')"
            icon="pi pi-refresh"
            @click="resetValues"
            class="p-button-secondary"
          ></Button>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="4" sm="12 " class="mt-2">
          <label>{{ $t("condition") }}</label
          ><br />

          <SelectButton
            v-model="status"
            :options="statusOptions"
            optionLabel="text"
            @input="
              resetValues();
              loadingYears();
              findRates();
              calculateResidualValue();
            "
          />
        </b-col>
        <b-col md="4" sm="12" class="mt-2">
          <label>{{ $t("type") }}</label
          ><br />
          <Dropdown
            id="type"
            v-model="type"
            style="width: 100%"
            :options="typeOptions"
            optionLabel="text"
            :placeholder="$t('select_type')"
            :disabled="status == null"
            @change="
              eraseSelectionPrev();
              getCarRates($event);
              calculateResidualValue();
            "
          />
        </b-col>
        <b-col md="4" sm="12" class="mt-2">
          <label>{{ $t("code") }}</label
          ><br />
          <InputNumber
              :min="0"
              :max="3"
              :step="1"
              style="width: 100%"
              :disabled="permit != 1"
              class="p-inputtext-md"
              v-model="incrementValue"
              showButtons
              id="increasedInterest"
              :inputStyle="{ width: '100%' }"
              @input="
              findRates();
              calculateResidualValue();
            "
          />
        </b-col>
      </b-row>
<!--      <b-row>
        <div
          class="field col-4 md:col-6 sm:col-12 mt-2"
          v-if="permit != 1"
        ></div>
        <div
          class="field col-8 md:col-6 sm:col-12 mt-2"
          v-if="status != null && status.value == 'used'"
        ></div>
        <div class="field col-12 md:col-6 sm:col-12 mt-2" v-else></div>
      </b-row>-->
      <b-row>
        <b-col md="4" sm="12" class="mt-2">
          <label for="vin">{{ $t("VIN_T") }}</label>
          <InputText
            class="p-inputtext-md"
            style="width: 100%"
            :disabled="!vinEnabled"
            id="vin"
            v-model="vin"
            :placeholder="this.$t('VIN_T')"
            @input="checkVin()"
          />
        </b-col>
        <b-col md="4" sm="12" class="mt-2">
          <label for="year">{{ $t("year") }}</label>
          <Dropdown
            id="year"
            style="width: 100%"
            v-model="year"
            :options="yearOptions"
            optionLabel="text"
            :placeholder="$t('select_year')"
            @change="loadingMarke()"
            :disabled="!type"
          />
        </b-col>
        <b-col md="4" sm="12" class="mt-2">
          <label for="make">{{ $t("brand") }}</label> <br />
          <Dropdown
            id="make"
            v-model="make"
            style="width: 100%"
            :options="markesOptions"
            optionLabel="text"
            :placeholder="$t('select_mark')"
            @change="loadingModels()"
            :disabled="!year"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col md="4" sm="12" class="mt-2">
          <label for="model">{{ $t("model") }}</label> <br />
          <Dropdown
            id="model"
            v-model="model"
            style="width: 100%"
            :options="modelOptions"
            optionLabel="text"
            :placeholder="$t('select_model')"
            :disabled="!make"
            @change="loadingSerie()"
          />
        </b-col>

        <b-col md="4" sm="12" class="mt-2">
          <label for="serie">{{ $t("serie") }}</label> <br />
          <Dropdown
            id="serie"
            v-model="serie"
            style="width: 100%"
            :options="serieOptions"
            optionLabel="text"
            :placeholder="$t('select_serie')"
            @change="loadingStyles()"
            :disabled="!model"
          />
        </b-col>

        <b-col md="4" sm="12" class="mt-2">
          <label for="style">{{ $t("style") }}</label>
          <Dropdown
            id="stylecar"
            style="width: 100%"
            v-model="style"
            :options="styleOptions"
            optionLabel="text"
            :placeholder="$t('select_style')"
            @change="loadPricesNormal()"
            :disabled="!serie"
          />
        </b-col>
      </b-row>
      <b-row>
<!--        <b-col md="4" sm="12" class="mt-2" v-if="status == null"></b-col>-->
<!--        <b-col
          md="4"
          sm="12"
          class="mt-2"
          v-if="
            status != null &&
            status.value == 'new' &&
            type != null &&
            type.value != 'Light truck' &&
            type.value != 'Heavy truck'
          "
        ></b-col>-->
        <b-col
          md="4"
          sm="12"
          class="mt-2"
        >
          <label>{{ $t("kilometers") }}</label
          ><br />
          <InputNumber
            class="p-inputtext-md"
            style="width: 100%"
            :inputStyle="{ width: '100%' }"
            id="kilometers"
            v-model="kilometers"
            suffix=" km"
            :disabled="type == null || (status == null || status.value != 'used')"
            @input="checkVin(), loadPricesNormal()"
          />
        </b-col>
        <b-col
          md="4"
          sm="12"
          class="mt-2"
        >
          <label for="equipment">{{ $t("equipment") }}</label>
          <InputText
            id="equipment"
            v-model="equipment"
            style="width: 100%"
            :inputStyle="{ width: '100%' }"
            :disabled="type == null || (type.value != 'Light truck' && type.value != 'Heavy truck')"
            :placeholder="$t('equipment')"
          />
        </b-col>
        <b-col md="4" sm="12" class="mt-2"></b-col>
<!--        <b-col
          lg="8"
          md="8"
          sm="12"
          class="mt-2"
          v-if="
            type != null &&
            status != null &&
            status.value == 'used' &&
            (type.value == 'Light truck' || type.value == 'Heavy truck')
          "
        ></b-col>-->
      </b-row>
      <b-row>
        <b-col md="4" sm="12" class="field col-4 md:col-4 sm:col-12 mt-2">
          <label for="capitalCost"
            >{{ $t("capitalCost") }}
            <i
              class="pi pi-info-circle"
              v-tooltip="$t('seeReferral')"
              @click="checkPricesDialog()"
              style="cursor: pointer"
            ></i>
          </label>
          <InputNumber
            id="capitalCost"
            v-model="capitalCost"
            style="width: 100%"
            :inputStyle="{ width: '100%' }"
            mode="currency"
            :currency="$t('currency')"
            :locale="$t('currency_locale')"
            class="p-inputtext-md"
            @input="calculateResidualValue()"
            @focus="clearInputValue('capitalCost')"
            @blur="setDefaultValue('capitalCost')"
          />
        </b-col>
        <b-col md="4" sm="12" class="field col-4 md:col-4 sm:col-12 mt-2">
          <label for="downpayment">{{ $t("downpayment") }}</label>
          <InputNumber
              id="downpayment"
              v-model="downpayment"
              style="width: 100%"
              :inputStyle="{ width: '100%' }"
              mode="currency"
              :currency="$t('currency')"
              :locale="$t('currency_locale')"
              class="p-inputtext-md"
              @input="calculateResidualValue()"
              @focus="clearInputValue('downpayment')"
              @blur="setDefaultValue('downpayment')"
          />
        </b-col>
        <b-col md="4" sm="12" class="mt-2">
          <label for="residualValue">{{ $t("residualValue") }}</label> <br />
          <InputNumber
            id="residualValue"
            v-model="residualValue"
            mode="currency"
            :currency="$t('currency')"
            :locale="$t('currency_locale')"
            class="p-inputtext-md"
            style="width: 100%"
            :inputStyle="{ width: '100%' }"
            :max="maxResidualValue"
            @focus="clearInputValue('residualValue')"
            @blur="setDefaultValue('residualValue')"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col md="4" sm="12" class="mt-2">
          <label for="amountAmorticed">{{ $t("amountAmorticed") }}</label>
          <br />
          <InputNumber
            id="amountAmorticed"
            v-model="amountAmorticed"
            mode="currency"
            :currency="$t('currency')"
            :locale="$t('currency_locale')"
            cl
            style="width: 100%"
            :inputStyle="{ width: '100%' }"
          />
        </b-col>
        <b-col md="8" sm="12" class="mt-2"></b-col>
      </b-row>
      <b-row>
        <b-col md="8" sm="12" class="field mt-2">
          <br />
          <b-form-group>
            <label for="term"
              >{{ $t("term") }} ({{ term }} {{ $t("months_c") }})</label
            >
            <b-form-radio-group
              id="btn-radios-2"
              v-model="term"
              :options="termsOptions"
              size="md"
              name="radio-btn-outline"
              buttons
              primary
              button-variant="outline-primary"
              style="padding: 0; width: 100%"
              @input="
                findRates();
                calculateResidualValue();
              "
            ></b-form-radio-group>
          </b-form-group>
          <!--
            TODO: Fix select button review with abner
              <SelectButton
            id="term"
            v-model="term"
            :options="termsOptions"
            optionValue="value"
            optionLabel="text"
            :placeholder="$t('select_terms')"
            style="padding: 0; width: 100% !important"
          /> -->
        </b-col>
        <b-col md="4" sm="12" class="mt-2"></b-col>
        <b-col md="3" sm="12" class="mt-4 mb-4"></b-col>
        <b-col
          md="6"
          sm="12"
          class="mt-4 mb-4"
          v-if="capitalCost > 0 && interestRate > 0"
        >
          <span class="total">{{ data[0].Versement | currency }} </span>
        </b-col>
        <b-col md="6" sm="12" class="mt-4 mb-4" v-else></b-col>
        <b-col md="12" sm="12" class="mt-2 mb-2 text-right">
          <Button
            :label="$t('createDeal')"
            icon="pi pi-check-circle"
            @click="openCustomerModal"
            :disabled="!deal"
            v-if="buttonSend && deal"
          ></Button>
        </b-col>
      </b-row>
    </div>
    <Dialog :header="$t('referralTitle')" :visible.sync="showReferralPrice">
      <TableRef :financialReferralPriceInfo="pricesInfo" :refShow="refShow" />
    </Dialog>
    <CustomerForm :deal="deal" ref="customerModal" />
  </div>
</template>
<script>
import CarServices from "@/services/CarServices";
import CustomerForm from "@/modules/shared/calculator/CustomerForm.vue";
import { mapState, mapActions } from "vuex";
import { integer } from "vuelidate/lib/validators";
import TableRef from "@/modules/shared/calculator/TableRef.vue";
import { ifError } from "assert";

export default {
  components: { CustomerForm, TableRef },
  props: {
    permit: {
      type: integer,
      default: 0,
    },
    buttonSend: {
      type: Boolean,
      default: false,
    },
    number: {
      type: integer,
      default: 0,
    },
  },
  data() {
    return {
      type: null,
      code: 0,
      carComission: 0,
      vin: null,
      year: null,
      make: null,
      model: null,
      status: null,
      kilometers: 0,
      capitalCost: 0,
      interestRate: 0,
      residualRate: 0,
      increaseRate: 0,
      increaseActive: true,
      incrementValue: 0,
      residualValue: 0,
      downpayment: 0,
      maxResidualValue: 0,
      limit_code: 0,
      term: 24,
      equipment: "",
      serie: null,
      style: null,
      rates: [],
      refShow: false,
      showReferralPrice: false,
      referralPrices: [],
      pricesInfo: [],
      item_cal: {},
      markesOptions: [],
      modelOptions: [],
      dealString: {},
      yearOptions: [],
      serieOptions: [],
      styleOptions: [],
      typeOptions: [
        { value: null, text: this.$t("select_type") },
        { value: "Pickup", text: this.$t("type_vehicule_pickup") },
        { value: "Light truck", text: this.$t("type_vehicule_lighttruck") },
        { value: "Heavy truck", text: this.$t("type_vehicule_heavytruck") },
        { value: "Car", text: this.$t("type_vehicule_car") },
        { value: "SUV", text: this.$t("type_vehicule_suv") },
      ],
      termsOptions: [
        { value: 24, text: 24 },
        { value: 36, text: 36 },
        { value: 48, text: 48 },
        { value: 60, text: 60 },
      ],
    };
  },
  created() {},
  mounted() {},
  computed: {
    ...mapState(["carRates"]),
    numericType() {
      switch (this.type.value) {
        case "Pickup":
          return 1;
        case "Light truck":
          return 2;
        case "Heavy truck":
          return 3;
        case "Car":
          return 4;
        case "SUV":
          return 5;
      }
    },
    deal() {
      return this.data[0].Versement > 0
        ? {
            condition: this.status.value,
            type: this.numericType,
            typeValue: this.type.value,
            year: this.year.value,
            make: this.make.value,
            model: this.model.value,
            style: this.style.value,
            serie: this.serie.value,
            equipment: this.equipment,
            capitalCost: this.capitalCost,
            residual: this.residualRate,
            residualValue: this.residualValue,
            downpayment: this.downpayment,
            interestRate: this.increaseActive
              ? this.interestRate - this.increaseRate
              : this.interestRate,
            customerInterestRate: this.interestRate,
            amortizeAmount: this.amountAmorticed,
            monthlyPayment: parseFloat(this.data[0].Versement),
            terms: this.term,
            refShow: false,
          }
        : null;
    },
    statusOptions() {
      return [
        { value: "new", text: this.$t("new") },
        { value: "used", text: this.$t("used") },
      ];
    },
    terms() {
      if (this.rates.length) {
        const terms = this.carRates.map((v) => {
          return v.months;
        });
        return { min: Math.min(...terms), max: Math.max(...terms) };
      } else {
        return { min: 24, max: 60 };
      }
    },
    isDisabled() {
      if (this.kilometers == 0 && this.status.value == "used") {
        return true;
      } else {
        return false;
      }
    },
    vinEnabled() {
      return (
        (this.type != null && this.type.value == "Car") ||
        (this.type != null && this.type.value == "Pickup")
      );
    },
    amountAmorticed() {
      var aux = this.capitalCost - this.downpayment
      return aux - this.residualValue;
    },
    firstInterest() {
      return (((this.interestRate / 100) * this.residualValue) / 12).toFixed(2);
    },
    interestPeriod() {
      return this.interestRate / 12 / 100;
    },
    interestPercent() {
      return this.interestRate / 100;
    },
    PMT() {
      return -(
        (parseFloat(this.capitalCost) +
          (parseFloat(this.capitalCost) + -this.residualValue) /
            ((1 + this.interestPeriod) ** this.term - 1)) *
        (-this.interestPeriod / (1 + this.interestPeriod))
      ).toFixed(2);
    },
    sumInterest() {
      let capital = 0;
      let interest = 0;
      let sum = 0;
      let solde = 0;
      for (let i = 1; i <= this.term; i++) {
        if (i == 1) {
          capital = this.PMT;
          interest = parseFloat(this.firstInterest);
          sum = sum + interest;
          solde = this.capitalCost - capital;
        } else {
          interest = ((solde * this.interestPercent) / 12).toFixed(2) * 1;
          capital = this.PMT - interest;
          solde = solde - capital;
          sum = sum + interest;
        }
      }
      return sum;
    },
    payment() {
      return ((this.amountAmorticed + this.sumInterest) / this.term).toFixed(2);
    },
    sumTerm() {
      let sum = 0;
      for (let i = 1; i <= this.term; i++) {
        sum += i;
      }
      return sum;
    },
    data() {
      let rows = [];
      let capital = 0;
      let interest = 0;
      let interestPercent = 0;
      let counter = 0;
      for (let i = this.term; i > 0; i--) {
        interestPercent = i / this.sumTerm;
        interest = interestPercent * this.sumInterest;
        capital = this.payment - interest;
        counter += 1;
        let total = (capital + interest).toFixed(2);
        rows.push({
          Termes: counter,
          Versement: total,
          Capital: capital.toFixed(2),
          Intérêt: interest.toFixed(2),
        });
      }
      return rows;
    },
  },
  methods: {
    ...mapActions([
      "fetchCarRates",
      "loadMarke",
      "loadModels",
      "loadSeries",
      "loadStyles",
      "loadPricesDrilldown",
      "loadIncrease",
    ]),
    async getCarRates(carType) {
      if (this.type.value != null) {
        await this.fetchCarRates(carType.value.value);
        this.rates = this.carRates;
        this.findRates();
        this.loadIncrease(carType.value.value).then((result) => {
          this.incrementValue = result.increase;
        });
      }
    },
    resetValues() {
      this.kilometers = 0;
      this.vin = "";
      this.year = null;
      this.make = null;
      this.model = null;
      this.code = 0;
      this.type = null;
      this.serie = null;
      this.style = null;
      this.pricesInfo = [];
      this.term = 24;
      this.capitalCost = 0;
      this.residualValue = 0;
      this.downpayment = 0;
      this.incrementValue = 0;
      this.maxResidualValue = 0;
      this.findRates();
      this.calculateResidualValue();
    },
    checkPricesDialog() {
      this.showReferralPrice = true;
    },
    loadingYears() {
      this.yearOptions = [];
      this.yearOptions = [{ value: null, text: this.$t("select_year") }];
      if (this.status.value == "used") {
        const currentYear = new Date().getFullYear();
        const oldYear = currentYear - 5;
        for (let i = currentYear; i >= oldYear; i--) {
          this.yearOptions.push({ value: i, text: i });
        }
      } else {
        const currentYear = new Date().getFullYear();
        const oldYear = currentYear + 1;
        for (let i = currentYear; i <= oldYear; i++) {
          this.yearOptions.push({ value: i, text: i });
        }
      }
    },
    loadingMarke() {
      if (this.year.value != null) {
        this.markesOptions = [];
        this.modelOptions = [];
        this.serieOptions = [];
        this.styleOptions = [];
        this.make = null;
        this.model = null;
        this.serie = null;
        this.style = null;
        this.term = 24;
        this.capitalCost = 0;
        this.residualValue = 0;

        const payload = {
          type: this.type.value,
          year: this.year.value,
        };
        this.loadMarke(payload)
          .then((response) => {
            this.markesOptions = response;
          })
          .catch((error) => {
            this.$toast.add({
              summary: this.$t("toast_title_error"),
              detail: this.$t("toast_message_error"),
              severity: "error",
              life: 5000,
            });
          });
      } else {
        this.markesOptions = [];
        this.modelOptions = [];
        this.make = null;
        this.model = null;
        this.year = null;
      }
    },
    loadingModels() {
      this.pricesInfo = [];
      this.vin = "";
      this.modelOptions = [];
      this.serieOptions = [];
      this.styleOptions = [];
      this.model = null;
      this.serie = null;
      this.style = null;
      this.term = 24;
      this.capitalCost = 0;
      this.residualValue = 0;
      this.kilometers = 0;
      this.equipment = "";

      const payload = {
        year: this.year.value,
        make: this.make.value,
        type: this.type.value,
      };
      this.loadModels(payload)
        .then((response) => {
          if (response.length == 0) {
            this.$toast.add({
              severity: "warn",
              summary: this.$t("toast_title_error"),
              detail: this.$t("toast_message_notfound_results"),
              life: 4000,
            });
          } else {
            this.modelOptions = response;
          }
        })
        .catch((error) => {
          this.$toast.add({
            severity: "error",
            summary: this.$t("toast_title_error"),
            detail: this.$t("toast_message_error"),
            life: 4000,
          });
        });
    },
    loadingSerie() {
      this.pricesInfo = [];
      this.styleOptions = [];
      this.serieOptions = [];
      this.serie = [];
      this.style = null;
      this.vin = "";
      this.term = 24;
      this.capitalCost = 0;
      this.residualValue = 0;
      this.kilometers = 0;
      this.equipment = "";
      const payload = {
        year: this.year.value,
        make: this.make.value,
        type: this.type.value,
        model: this.model.value,
      };

      this.loadSeries(payload)
        .then((response) => {
          if (response.length == 0) {
            this.$toast.add({
              severity: "warn",
              summary: this.$t("toast_title_error"),
              detail: this.$t("toast_message_notfound_results"),
              life: 4000,
            });
          } else {
            this.serieOptions = response;
          }
        })
        .catch((error) => {
          this.$toast.add({
            severity: "error",
            summary: this.$t("toast_title_error"),
            detail: this.$t("toast_message_error"),
            life: 4000,
          });
        });
    },
    loadingStyles() {
      if (this.model != null) {
        this.pricesInfo = [];
        this.vin = "";
        this.styleOptions = [];
        this.style = null;
        this.term = 24;
        this.capitalCost = 0;
        this.residualValue = 0;
        this.kilometers = 0;
        this.equipment = "";
        const payload = {
          year: this.year.value,
          make: this.make.value,
          type: this.type.value,
          model: this.model.value,
          serie: this.serie.value,
        };

        this.loadStyles(payload)
          .then((response) => {
            if (response.length == 0) {
              this.$toast.add({
                severity: "warn",
                summary: this.$t("toast_title_error"),
                detail: this.$t("toast_message_notfound_results"),
                life: 4000,
              });
            } else {
              this.styleOptions = response;
            }
          })
          .catch((error) => {
            this.$toast.add({
              severity: "error",
              summary: this.$t("toast_title_error"),
              detail: this.$t("toast_message_error"),
              life: 4000,
            });
          });
      }
    },
    loadPricesNormal() {
      if (this.style != null && this.vin == "") {
        this.pricesInfo = [];
        this.vin = "";
        const payload = {
          year: this.year.value,
          make: this.make.value,
          type: this.type.value,
          model: this.model.value,
          serie: this.serie.value,
          style: this.style.value,
          kilometers: this.kilometers,
        };
        if (this.status != null && this.status.value == "new") {
          this.loadPricesDrilldown(payload)
            .then((response) => {
              this.pricesInfo = response.data[0].priceInfo;
              this.refShow = true;
            })
            .catch((error) => {
              this.$toast.add({
                severity: "error",
                summary: this.$t("toast_title_error"),
                detail: this.$t("toast_message_error"),
                life: 4000,
              });
            });
        } else if (this.kilometers != null && this.kilometers != 0) {
          this.loadPricesDrilldown(payload)
            .then((response) => {
              this.pricesInfo = response.data[0].priceInfo;
              this.refShow = true;
            })
            .catch((error) => {
              this.$toast.add({
                severity: "error",
                summary: this.$t("toast_title_error"),
                detail: this.$t("toast_message_error"),
                life: 4000,
              });
            });
        } else {
          console.log("INPUT KILOMETERS");
        }
      }
    },
    findRates() {
      if (this.type != null) {
        const rate = this.rates.find((rate) => rate.months == this.term);
        if (this.status.value == "new") {
          this.interestRate = parseFloat(rate.rateNew);
          this.residualRate = parseFloat(rate.new);
          this.increaseRate = parseFloat(rate.increaseNew);
          this.limit_code = parseFloat(rate.increaseNew);
          if (parseFloat(this.incrementValue) != 0) {
            this.interestRate += parseFloat(this.incrementValue);
          } else {
            //this.interestRate += parseFloat(rate.increaseNew);
          }
        } else if (this.status.value == "used") {
          this.interestRate = parseFloat(rate.rateUsed);
          this.residualRate = parseFloat(rate.used);
          this.increaseRate = parseFloat(rate.increaseUsed);
          this.limit_code = parseFloat(rate.increaseUsed);
          if (parseFloat(this.incrementValue) != 0) {
            this.interestRate += parseFloat(this.incrementValue);
          } else {
            //this.interestRate += parseFloat(rate.increaseUsed);
          }
        }
      }
    },
    checkVin() {
      if (this.vin.length >= 17 && this.status.value == "new") {
        const payload = {
          vinNumber: this.vin,
          condition: this.status.value,
          km: this.kilometers,
        };
        CarServices.getCarVinType(payload).then((response) => {
          const carDetails = response.data[0];

          switch (carDetails.vehicleType) {
            case "Pickup":
              this.type = { value: "Pickup", text: this.$t("type_vehicule_pickup") };

              break;
            case "Light truck":
              this.type = { value: "Light truck", text: this.$t("type_vehicule_lighttruck") };

              break;
            case "Heavy truck":
              this.type = { value: "Heavy truck", text: this.$t("type_vehicule_heavytruck") };

              break;
            case "Car":
              this.type = {value: "Car", "text": this.$t("type_vehicule_car")};
              break;
            case "SUV":
              this.type = {value: "SUV", "text": this.$t("type_vehicule_suv")};
              break;
          }

          const currentYear = new Date().getFullYear();
          if (carDetails.year < currentYear) {
            this.status = { value: "used", text: this.$t("used") };
          }
          if (carDetails.year > currentYear) {
            this.status = { value: "new", text: this.$t("new") };
          }

          this.year = {
            value: parseInt(carDetails.year),
            text: parseInt(carDetails.year),
          };
          this.make = { value: carDetails.make, text: carDetails.make };
          this.model = { value: carDetails.model, text: carDetails.model };
          this.serie = {
            value: carDetails.vehicleType,
            text: carDetails.vehicleType,
          };
          this.style = { value: carDetails.style, text: carDetails.style };
          this.yearOptions = [];
          this.yearOptions.push(this.year);

          this.markesOptions = [];
          this.markesOptions.push(this.make);

          this.modelOptions = [];
          this.modelOptions.push(this.model);

          this.serieOptions = [];
          this.serieOptions.push(this.serie);

          this.styleOptions = [];
          this.styleOptions.push(this.style);

          this.pricesInfo = carDetails.priceInfo;
          this.refShow = true;
          this.calculateResidualValue();
        });
      } else if (this.vin.length >= 17 && this.status.value == "used") {
        const payload = {
          vinNumber: this.vin,
          condition: this.status.value,
          km: this.kilometers,
        };
        CarServices.getCarVinType(payload).then((response) => {
          const carDetails = response.data[0];

          switch (carDetails.vehicleType) {
            case "Pickup":
              this.type = { value: "Pickup", text: this.$t("type_vehicule_pickup") };
              break;

            case "Light truck":
              this.type = { value: "Light truck", text: this.$t("type_vehicule_lighttruck") };
              break;

            case "Heavy truck":
              this.type = { value: "Heavy truck", text: this.$t("type_vehicule_heavytruck") };
              break;
            case "Car":
              this.type = {value: "Car", "text": this.$t("type_vehicule_car")};
              break;
            case "SUV":
              this.type = {value: "SUV", "text": this.$t("type_vehicule_suv")};
              break;
          }

          const currentYear = new Date().getFullYear();
          if (carDetails.year < currentYear) {
            this.status = { value: "used", text: this.$t("used") };
          }
          if (carDetails.year > currentYear) {
            this.status = { value: "new", text: this.$t("new") };
          }

          this.year = {
            value: parseInt(carDetails.year),
            text: parseInt(carDetails.year),
          };
          this.make = { value: carDetails.make, text: carDetails.make };
          this.model = { value: carDetails.model, text: carDetails.model };
          this.serie = {
            value: carDetails.vehicleType,
            text: carDetails.vehicleType,
          };
          this.style = { value: carDetails.style, text: carDetails.style };

          this.yearOptions = [];
          this.yearOptions.push(this.year);

          this.markesOptions = [];
          this.markesOptions.push(this.make);

          this.modelOptions = [];
          this.modelOptions.push(this.model);

          this.serieOptions = [];
          this.serieOptions.push(this.serie);

          this.styleOptions = [];
          this.styleOptions.push(this.style);

          this.pricesInfo = carDetails.priceInfo;
          this.refShow = true;
          this.calculateResidualValue();
        });
      }
    },
    loadingYearsVIN(year) {
      this.loadingYears();
    },
    loadingModelsVIN(model) {
      const payload = {
        year: this.year.value,
        make: this.make.value,
        type: this.type.value,
      };
      this.loadModels(payload)
        .then((response) => {
          this.modelOptions = response;
          let option = { value: model, text: model };
          this.modelOptions.push(option);
        })
        .catch((error) => {
          this.$toast.add({
            summary: this.$t("toast_title_error"),
            detail: this.$t("toast_message_error"),
            severity: "error",
            life: 5000,
          });
        });
    },
    eraseSelectionPrev() {
      this.year = null;
      this.vin = "";
      this.pricesInfo = [];
      this.make = null;
      this.model = null;
      this.style = null;
      this.serie = null;
      this.capitalCost = 0;
      this.residualValue = 0;
      this.term = 24;
      this.downpayment = 0;
    },
    calculateResidualValue() {
      if (this.type != null) {
        var aux = this.capitalCost - this.downpayment
        this.maxResidualValue = parseFloat(
          (
            aux -
            aux * (this.residualRate / 100)
          ).toFixed(2)
        );
        this.residualValue = this.maxResidualValue;
      }
      this.updateCalculatriceModel();
    },
    clearInputValue(inputName) {
      if (this[inputName] === 0) {
        this[inputName] = null;
      }
    },
    setDefaultValue(inputName) {
      if (!this[inputName]) {
        this[inputName] = 0;
      }
    },
    openCustomerModal() {
      this.$refs.customerModal.openModal();
    },
    updateCalculatriceModel() {
      this.dealString = this.deal;
      this.item_cal = {
        number: this.number,
        deal: this.deal,
      };
      this.$parent.updateCalculatriceModel(this.item_cal);
    },
    dowloadComparation(customer) {
      this.$parent.dowloadComparation(customer);
    },
  },
};
</script>
<style lang="scss" scoped>
.total {
  color: #ffffff;
  font-size: 2.3rem;
  padding: 14px 14px;
  display: inline-block;
  width: 100%;
  text-align: center;
  border-radius: 3px;
  background-color: #20d077;
}

#increasedInterest {
  width: 100%;
}
</style>