import * as constants from "./actions";
import axios from "@/plugins/axios.js";

function getDefaultState() {
  return {
    forgotPassword: {
      status: null,
      data: null,
      error: null,
    },
    setNewPassword: {
      status: null,
      data: null,
      error: null,
    },
  };
}

const actions = {
  [constants.POST_FORGOT_PASSWORD]: async ({ commit }, data) => {
    try {
      commit(constants.POST_FORGOT_PASSWORD);
      const response = await axios.post(`forgotPassword`, data);
      commit(constants.POST_FORGOT_PASSWORD_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.POST_FORGOT_PASSWORD_ERROR, error);
      throw error.response;
    }
  },
  [constants.POST_SET_NEW_PASSWORD]: async ({ commit }, data) => {
    try {
      commit(constants.POST_SET_NEW_PASSWORD);
      const token = data.token;
      const password = data.password;
      const response = await axios.put(`setPassword/${token}`, { password });
      commit(constants.POST_SET_NEW_PASSWORD_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.POST_SET_NEW_PASSWORD_ERROR, error);
      throw error.response;
    }
  },
};

const mutations = {
  [constants.POST_FORGOT_PASSWORD]: (state) => {
    state.forgotPassword.status = "fetching";
    state.forgotPassword.error = null;
  },
  [constants.POST_FORGOT_PASSWORD_SUCCESS]: (state, data) => {
    state.forgotPassword.status = "success";
    state.forgotPassword.data = data;
    state.forgotPassword.error = null;
  },
  [constants.POST_FORGOT_PASSWORD_ERROR]: (state, error) => {
    state.forgotPassword.status = "error";
    state.forgotPassword.error = error;
  },
  [constants.POST_SET_NEW_PASSWORD]: (state) => {
    state.setNewPassword.status = "fetching";
    state.setNewPassword.error = null;
  },
  [constants.POST_SET_NEW_PASSWORD_SUCCESS]: (state, data) => {
    state.setNewPassword.status = "success";
    state.setNewPassword.data = data;
    state.setNewPassword.error = null;
  },
  [constants.POST_SET_NEW_PASSWORD_ERROR]: (state, error) => {
    state.setNewPassword.status = "error";
    state.setNewPassword.error = error;
  },
};

export default {
  state: getDefaultState(),
  actions,
  mutations,
};
