<template>
  <Dialog
    :header="$t('upload_image')"
    :visible.sync="showModal"
    :containerStyle="{ width: '380px' }"
    :modal="true"
  >
    <div class="p-fluid">
      <span class="text-primary mt-2">{{ $t("logo_info") }}</span>
      <div
        class="imagePreviewWrapper mt-4"
        :style="{
          height: '85px',
          width: '350px',
          'background-image': `url(${previewImage})`,
        }"
      ></div>
      <!-- <b-form class="mb-3" action="" method="POST" enctype="multipart/form-data" ref="form" novalidate> -->
      <div class="alert alert-info">{{ $t("logo_size") }}</div>
      <b-form-file
        id="logo"
        name="logo"
        accept="image/*"
        @change="uploadImage($event)"
        :placeholder="$tc('chooseFile')"
        size="sm"
        required
        :browse-text="$tc('btnDownloadFile')" 
        class="mb-3 mt-3"
      >
      </b-form-file>
      <Button
        :label="$t('submit')"
        icon="pi pi-plus"
        :disabled="!previewImage"
        class="p-button-success p-mr-2"
        @click="onSubmit"
      ></Button>
      <!-- </b-form> -->
    </div>
  </Dialog>
</template>
<script>
import { mapActions } from "vuex";
export default {
  name: "imageUpload",
  data() {
    return {
      submitted: false,
      showModal: false,
      previewImage: null,
    };
  },
  methods: {
    ...mapActions(["uploadFileImage", "auth"]),
    openModal() {
      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;
    },
    fileUpload(event) {
      const reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = () => {
        this.file64 = reader.result;
      };
    },
    uploadImage(e) {
      const image = e.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onload = (e) => {
        this.previewImage = e.target.result;
      };
    },
    onSubmit(e) {
      let payload = { ...{ logo: this.previewImage } };
      this.uploadFileImage(payload)
        .then((response) => {
          this.$toast.add({
            severity: "success",
            summary: "Photo",
            detail: "Photo was updated",
            life: 4000,
          });
          this.closeModal();
          location.reload();
        })
        .catch((error) => {
          this.$toast.add({
            severity: "error",
            summary: error,
          });
        });
    },
  },
};
</script>
<style scoped lang="scss">
.imagePreviewWrapper {
  border: 1px solid gray;
  width: 250px;
  height: 250px;
  display: block;
  cursor: pointer;
  margin: 0 auto 30px;
  background-size: cover;
  background-position: center center;
}
</style>