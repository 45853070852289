<template>
  <b-card>
    <b-row>
      <!-- Last vehicle -->
      <b-col sm="12" md="6" lg="3" xl="3">
        <b-form-group label-size="sm">
          <template slot="label">
            <span>
              {{ $t("lastVehicle") }}
            </span>
          </template>
          <b-form-input v-model="form.lastVehicle" :maxlength="65">
          </b-form-input>
          <b-form-invalid-feedback id="input-companyName-live-feedback">
            {{ $t("requiredTwoCharacters") }}
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>

      <!-- Dealer -->
      <b-col sm="12" md="6" lg="3" xl="3">
        <b-form-group label-size="sm">
          <template slot="label">
            <span>
              {{ $t("dealer") }}
            </span>
          </template>
          <b-form-input v-model="form.dealer" :maxlength="65"> </b-form-input>
          <b-form-invalid-feedback id="input-companyName-live-feedback">
            {{ $t("requiredTwoCharacters") }}
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>

      <!--  Financing Or Leasing-->
      <b-col sm="12" md="6" lg="6" xl="6">
        <b-form-group label-size="sm">
          <template slot="label">
            <span>
              {{ $t("financingOrLeasing") }}
            </span>
          </template>
          <b-form-input v-model="form.financingOrLeasing" :maxlength="65">
          </b-form-input>
          <b-form-invalid-feedback id="input-companyName-live-feedback">
            {{ $t("requiredTwoCharacters") }}
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>

      <!-- Type of vehicle required -->
      <b-col sm="12" md="6" lg="3" xl="3">
        <b-form-group label-size="sm">
          <template slot="label">
            <span>
              {{ $t("typeOfVehicleRequired") }}
            </span>
          </template>
          <b-form-input v-model="form.typeOfVehicleRequired" :maxlength="65">
          </b-form-input>
          <b-form-invalid-feedback id="input-companyName-live-feedback">
            {{ $t("requiredTwoCharacters") }}
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>

      <!-- Usage -->
      <b-col sm="12" md="6" lg="3" xl="3">
        <b-form-group label-size="sm">
          <template slot="label">
            <span> Usage </span>
          </template>
          <b-form-input v-model="form.usage" :maxlength="65"> </b-form-input>
          <b-form-invalid-feedback id="input-companyName-live-feedback">
            {{ $t("requiredTwoCharacters") }}
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>

      <!-- Approximate Annual Mileage -->
      <b-col sm="12" md="6" lg="6" xl="6">
        <b-form-group label-size="sm">
          <template slot="label">
            <span> {{ $t("approximateAnnualMileage") }} </span>
          </template>
          <b-form-input v-model="form.approximateAnnualMileage" :maxlength="65">
          </b-form-input>
          <b-form-invalid-feedback id="input-companyName-live-feedback">
            {{ $t("requiredTwoCharacters") }}
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
export default {
  props: ["form"],
};
</script>

<style>
</style>