<template>
  <div>
    <!-- iterate dealerships in checks-->
    <div v-if="isLoading">
      <div class="text-center text-danger my-2">
        <b-spinner class="align-middle" variant="yellow" />
      </div>
    </div>
    <div v-else>
      <b-form-group>
        <b-form-checkbox
          v-for="dealearship in dealearships"
          v-model="dealershipFilter"
          :key="dealearship.value"
          :value="dealearship.id"
          name="flavour-3a"
          @change="onSelectedDealership"
        >
          {{ dealearship.name }}
        </b-form-checkbox>
      </b-form-group>
    </div>
  </div>
</template>

<script>
export default {
  name: "DealershipFilter",
  props: ["dealershipSelected"],
  data() {
    return {
      dealearships: [],
      dealershipFilter: [],
      isLoading: false,
    };
  },
  watch: {
    dealershipSelected: {
      handler: function (val) {
        this.dealershipFilter = val;
      },
      deep: true,
    },
  },
  methods: {
    getDealerships() {
      this.isLoading = true;
      this.$store
        .dispatch("GET_DEALERSHIPS")
        .then((response) => {
          this.dealearships = response;
          this.dealershipFilter = this.dealershipSelected;
          this.isLoading = false;
        })
        .catch(() => {
          this.isLoading = false;
        });
    },
    onSelectedDealership() {
      this.$emit("selectedDealership", this.dealershipFilter);
    },
  },

  mounted() {
    this.getDealerships();
  },
};
</script>

<style>
</style>
