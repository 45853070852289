<template>
  <b-card>
    <b-row class="justify-content-md-center">
      <b-col xs="12" sm="12" md="1" lg="1" xl="1"></b-col>

      <b-col xs="12" sm="12" md="10" lg="10" xl="10" class="">
        <b-row>
          <b-col xs="12" sm="12" md="10" lg="10" xl="10">
            <h2>{{ $t("company") }}</h2>
          </b-col>
          <br />

          <!-- legal name -->
          <b-col align-self="center" xs="12" sm="12" md="12" lg="12" xl="12">
            <b-form-group label-size="sm">
              <template slot="label">
                <span>
                  {{ $tc("legalName") }}
                </span>
              </template>
              <b-form-input
                @change="setValue('companyName', $event)"
                v-model="form.companyName"
                :maxlength="65"
                readonly
              >
              </b-form-input>
            </b-form-group>
          </b-col>

          <!-- Address -->
          <b-col align-self="center" xs="12" sm="12" md="12" lg="12" xl="12">
            <b-form-group label-size="sm">
              <template slot="label">
                <span>
                  {{ $tc("address") }}
                </span>
              </template>
              <b-form-input
                v-model="form.completeAddress"
                :maxlength="65"
                readonly
              >
              </b-form-input>
            </b-form-group>
          </b-col>
          <!-- company profile -->
          <b-col align-self="center" xs="12" sm="12" md="12" lg="12" xl="12">
            <b-form-group label-size="sm">
              <template slot="label">
                <span>
                  {{ $t("companyProfile") }} ({{
                    $t("registeredIncorporatedOther")
                  }})
                </span>
              </template>
              <b-form-input v-model="form.companyProfile" :maxlength="65">
              </b-form-input>
              <b-form-invalid-feedback id="input-companyName-live-feedback">
                {{ $t("requiredTwoCharacters") }}
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>

          <!-- Data of incorporation -->
          <b-col align-self="center" xs="12" sm="12" md="3" lg="3" xl="3">
            <b-form-group label-size="sm">
              <template slot="label">
                <span>
                  {{ $t("dateOfIncorporation") }}
                </span>
              </template>
              <b-form-input v-model="form.dateIncorporation" :maxlength="65">
              </b-form-input>
              <b-form-invalid-feedback id="input-companyName-live-feedback">
                {{ $t("requiredTwoCharacters") }}
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>

          <!-- date of fundation -->
          <b-col xs="12" sm="12" md="3" lg="3" xl="3">
            <b-form-group label-size="sm">
              <template slot="label">
                <span> date of fundation </span>
              </template>
              <b-form-input v-model="form.dateFoundation" :maxlength="65">
              </b-form-input>
              <b-form-invalid-feedback id="input-companyName-live-feedback">
                {{ $t("requiredTwoCharacters") }}
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>

          <!-- Business type -->
          <b-col xs="12" sm="12" md="6" lg="6" xl="6">
            <b-form-group label-size="sm">
              <template slot="label">
                <span>
                  {{ $t("bussinesType") }}
                </span>
              </template>
              <b-form-input v-model="form.businessType" :maxlength="65">
              </b-form-input>
              <b-form-invalid-feedback id="input-companyName-live-feedback">
                {{ $t("requiredTwoCharacters") }}
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>

          <!-- Telephone number -->
          <b-col align-self="center" xs="12" sm="12" md="3" lg="3" xl="3">
            <b-form-group label-size="sm">
              <template slot="label">
                <span>
                  {{ $t("telephone") }}
                </span>
              </template>
              <b-form-input v-model="form.telephoneNumber" :maxlength="65">
              </b-form-input>
              <b-form-invalid-feedback id="input-companyName-live-feedback">
                {{ $t("requiredTwoCharacters") }}
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>

          <!-- Fax number -->
          <b-col align-self="center" xs="12" sm="12" md="3" lg="3" xl="3">
            <b-form-group label-size="sm">
              <template slot="label">
                <span>
                  {{ $t("faxNumber") }}
                </span>
              </template>
              <b-form-input v-model="form.faxNumber" :maxlength="65">
              </b-form-input>
              <b-form-invalid-feedback id="input-companyName-live-feedback">
                {{ $t("requiredTwoCharacters") }}
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>

          <!-- Email -->
          <b-col xs="12" sm="12" md="6" lg="6" xl="6">
            <b-form-group label-size="sm">
              <template slot="label">
                <span> Email / Site web </span>
              </template>
              <b-form-input v-model="form.emailAddress" :maxlength="65">
              </b-form-input>
              <b-form-invalid-feedback id="input-companyName-live-feedback">
                {{ $t("requiredTwoCharacters") }}
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>

          <!-- signatory -->
          <b-col align-self="center" xs="12" sm="12" md="12" lg="12" xl="12">
            <b-form-group label-size="sm">
              <template slot="label">
                <span>
                  {{ $tc("authorizedSignatory") }}
                </span>
              </template>
              <b-form-input
                @change="setValue('companyName', $event)"
                v-model="form.companyName"
                :maxlength="65"
                readonly
              >
              </b-form-input>
            </b-form-group>
          </b-col>

          <b-col>
            <b-table striped hover :items="employees" :fields="fields">
              <template v-slot:cell()="{ item, field: { key } }">
                <b-form-input v-model="item[key]" />
              </template>
            </b-table>
          </b-col>
        </b-row>
      </b-col>
      <b-col xs="12" sm="12" md="1" lg="1" xl="1"></b-col>
    </b-row>
  </b-card>
</template>

<script>
export default {
  props: ["form"],
  data() {
    return {
      employees: [
        {
          id: 0,
          employeeName: "Jane",
          joinDate: "11-11-1111",
          selectedDepartment: "IT",
          jobDescription: "60",
        },
        {
          id: 1,
          employeeName: "Peter",
          joinDate: "12-12-1212",
          selectedDepartment: "Accounting",
          jobDescription: "30",
        },
      ],
      fields: [
        {
          key: "employeeName",
          label: "Name of Principal Shareholders",
        },
        {
          key: "joinDate",
          label: "Title",
        },
        {
          key: "selectedDepartment",
          label: "Selected Department",
        },
        {
          key: "jobDescription",
          label: "%",
        },
      ],
    };
  },

  methods: {},
};
</script>

<style>
</style>