<template>
  <div class="container mt-5">
    <b-progress
      :value="75"
      show-progress
      class="mb-3"
      variant="blue"
    ></b-progress>
    <b-form @submit.stop.prevent="onSubmit()">
      <h4>
        {{ $t("authorizedSignatory") }}
      </h4>
      <b-row>
        <!-- name -->
        <b-col sm="12" md="12" lg="6" xl="6">
          <b-form-group label-size="sm">
            <template slot="label">
              <span>
                {{ $t("firstName") }} <span class="text-danger"> *</span>
              </span>
            </template>
            <b-form-input
              size="sm"
              id="firstName-input"
              name="firstName-input"
              @change="setValue('signatoryFirstName', $event)"
              :value="form.signatoryFirstName"
              v-model.trim="$v.form.signatoryFirstName.$model"
              :state="validateState('signatoryFirstName')"
              maxlength="100"
            >
            </b-form-input>
            <b-form-invalid-feedback id="input-firstName-live-feedback">
              {{ $t("requiredTwoCharacters") }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <!-- Last name -->
        <b-col sm="12" md="12" lg="6" xl="6">
          <b-form-group label-size="sm">
            <template slot="label">
              <span>
                {{ $t("lastName") }} <span class="text-danger"> *</span>
              </span>
            </template>
            <b-form-input
              size="sm"
              name="lastName-input"
              @change="setValue('signatoryLastName', $event)"
              :value="form.signatoryLastName"
              v-model.trim="$v.form.signatoryLastName.$model"
              :state="validateState('signatoryLastName')"
              maxlength="100"
            >
            </b-form-input>
            <b-form-invalid-feedback id="input-lastName-live-feedback">
              {{ $t("requiredTwoCharacters") }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <!-- Telephone -->
        <b-col sm="12" md="12" lg="6" xl="6">
          <b-form-group label-size="sm">
            <template slot="label">
              <span>
                {{ $t("telephone") }} <span class="text-danger"> *</span>
              </span>
            </template>
            <b-form-input
              size="sm"
              @change="setValue('signatoryTelephone', $event)"
              :value="form.signatoryTelephone"
              v-model.trim="$v.form.signatoryTelephone.$model"
              :state="validateState('signatoryTelephone')"
              maxlength="255"
            >
            </b-form-input>
            <b-form-invalid-feedback>
              {{ $t("requiredField") }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>

        <b-col sm="12" md="12" lg="6" xl="6">
          <b-form-group label-size="sm">
            <template slot="label">
              {{ $t("identification") }}
            </template>
            <template slot="description" v-if="previewFiles.length == 0">
              {{ $t("uploadIDImages") }}
            </template>

            <b-input-group size="sm">
              <b-form-file
                multiple
                size="sm"
                min="1"
                max="2"
                :capture="true"
                v-model="form.files"
                :placeholder="$t('chooseFile')"
                :drop-placeholder="$t('dropFile')"
                @change="handleImages"
              >
                <template slot="file-name" slot-scope="{ names }">
                  <b-badge
                    class="mr-1"
                    v-for="(name, index) in names"
                    v-bind:key="index"
                    variant="blue"
                    >{{ name }}</b-badge
                  >
                </template>
              </b-form-file>

              <b-input-group-prepend
                v-if="!isMobileDevice"
                size="sm"
                is-text
                id="tooltip-camera"
                @click="
                  sendMessageToOpenCamera();
                  watchImages();
                  togleMessageModal(true);
                "
              >
                <b-tooltip show target="tooltip-camera" placement="topleft">
                  {{ $t("clickToOpenSmartphoneCamera") }}
                </b-tooltip>
                <font-awesome-icon :icon="['fa', 'mobile']" />
              </b-input-group-prepend>

              <b-form-invalid-feedback>
                <div>{{ $t("onlyTwoFiles") }}</div>
              </b-form-invalid-feedback>
            </b-input-group>
          </b-form-group>
          <b-row v-if="form.companyImage == null || form.files != null">
            <b-col
              cols="4"
              sm="4"
              md="4"
              lg="4"
              xl="4"
              v-for="(file, index) in previewFiles"
              v-bind:key="index"
            >
              <b-img class="my-auto" thumbnail fluid :src="file"></b-img>
            </b-col>
            <b-col
              cols="4"
              sm="4"
              md="4"
              lg="4"
              xl="4"
              v-if="(previewFiles.length > 0) & (previewFiles.length < 2)"
              class="text-center"
            >
              <button
                block
                class="btn my-auto v-100"
                type="button"
                @click="openFileBrowser"
              >
                <b-icon
                  icon="plus-circle"
                  font-scale="3.3"
                  class="m-1 align-bottom"
                >
                </b-icon>
              </button>
              <b-form-file
                id="addOneFile"
                size="sm"
                :capture="true"
                ref="addOneFile"
                v-model="form.secondFile"
                @change="addImageToPreview"
                v-show="false"
              >
              </b-form-file>
            </b-col>
          </b-row>

          <b-row v-else>
            <b-col
              cols="4"
              sm="4"
              md="4"
              lg="4"
              xl="4"
              v-for="(file, index) in form.companyImage"
              v-bind:key="index"
            >
              <b-img
                class="my-auto"
                thumbnail
                fluid
                :src="isImage(file.photo) ? file.photo : fileImage"
              ></b-img>
            </b-col>
          </b-row>
        </b-col>
        <b-col sm="12" md="12" lg="4" xl="4"> </b-col>
        <b-col sm="12" md="12" lg="4" xl="4">
          <b-btn
            block
            size="lg"
            class="btn btn-success mt-4"
            :disabled="$v.form.$invalid || isLoading"
            type="submit"
          >
            {{ $t("submit") }}
            <b-spinner v-if="isLoading" small variant="white"></b-spinner>
          </b-btn>
        </b-col>
        <b-col sm="12" md="12" lg="4" xl="4"> </b-col>
      </b-row>
    </b-form>

    <b-modal
      centered
      ref="messageModal"
      id="messageModal"
      size="md"
      hide-header-close
      hide-header
      hide-footer
      no-close-on-backdrop
      body-class="pl-0 pr-0"
    >
      <div class="text-center p-5">
        <p>
          <b-icon font-scale="5" icon="camera"></b-icon>
        </p>
        <h5>
          {{ $t("weSendMesssageToUpload") }}
        </h5>

        <div class="text-center">
          <b-icon icon="three-dots" animation="cylon" font-scale="4"></b-icon>
          <p>{{ $t("waitingForImages") }}...</p>
        </div>
        <b-btn
          size="lg"
          class="btn-main mt-2"
          @click="
            isWaitingForImages = false;
            $bvModal.hide('messageModal');
          "
        >
          {{ $t("cancel") }}
        </b-btn>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { isMobile } from "mobile-device-detect";
import { required } from "vuelidate/lib/validators";
import { GET_COMPANY, POST_UPLOAD_IMAGE, POST_SEND_MESSSAGE } from "./actions";
import { generalMixin } from "@/modules/mixin.js";
import { mapState } from "vuex";

export default {
  mixins: [generalMixin],
  data() {
    return {
      form: {
        id: null,
        signatoryFirstName: "",
        signatoryLastName: "",
        signatoryEmail: "",
        signatoryTelephone: "",
        files: [],
        companyFile: null,
        secondFile: null,
      },
      isWaitingForImages: false,
      previewFiles: [],
      fileImage: "http://134.122.35.220/storage/file.png",
    };
  },
  validations: {
    form: {
      signatoryFirstName: {
        required,
      },
      signatoryLastName: {
        required,
      },

      signatoryTelephone: {
        required,
      },
    },
  },
  computed: {
    isMobileDevice() {
      return isMobile;
    },
    ...mapState({
      files: (state) => state.dealership.document.uploadImage,
    }),
    isLoading() {
      return this.files.status == "fetching" ? true : false;
    },
  },

  methods: {
    setValue(key, value) {
      this.form[key] = value;
      this.$v.form[key].$touch();
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    getCompany() {
      this.$store
        .dispatch(GET_COMPANY, {
          companyToken: this.$route.params.token,
        })
        .then((response) => {
          this.form = { ...response.data[0] };
        })
        .catch(() => {
          this.$toast.add({
              summary: this.$t('toast_title_error'),
              detail: this.$t('toast_message_error'),
              severity: "error",
              life: 5000
          })
        });
    },
    togleMessageModal(otp) {
      otp
        ? this.$refs["messageModal"].show()
        : this.$refs["messageModal"].hide();
    },
    handleImages(e) {
      this.form.companyImage = [];
      this.previewFiles = [];
      let images = e.target.files;
      images.forEach((file) => {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (e) => {
          if (file.type.includes("image")) {
            this.previewFiles.push(e.target.result);
          } else {
            this.previewFiles.push(this.fileImage);
          }
        };
      });
    },
    addImageToPreview(e) {
      let image = e.target.files[0];
      this.form.files.push(image);

      let reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onload = (e) => {
        if (image.type.includes("image")) {
          this.previewFiles.push(e.target.result);
        } else {
          this.previewFiles.push(this.fileImage);
        }
      };
    },

    openFileBrowser() {
      document.getElementById("addOneFile").click();
    },

    onSubmit() {
      const payload = this.formatPayload();
      this.$store
        .dispatch(POST_UPLOAD_IMAGE, payload)
        .then((response) => {
          if (response.error == false) {
            this.makeToast(
              this.$t("success"),
              this.$t("identificationUploadSuccessfully"),
              "success"
            );

            this.goToDocument();
          }
        })
        .catch((error) => {
          this.makeToast(
            this.$t("warning"),
            this.$t("errorMessage"),
            "warning"
          );
        });
    },
    formatPayload() {
      const data = new FormData();
      data.set("token", this.$route.params.token);
      data.set("signatoryLastName", this.form.signatoryLastName);
      data.set("signatoryFirstName", this.form.signatoryFirstName);
      data.set("signatoryTelephone", this.form.signatoryTelephone);
      if (this.form.companyImage.length == 0) {
        data.set("photo1", this.form.files[0]);
        if (this.form.files[1]) {
          data.set("photo2", this.form.files[1]);
        }
      }
      return data;
    },
    goToDocument() {
      this.$router.push({
        path: `/dealership/document/${this.$route.params.token}`,
      });
    },

    watchImages() {
      if (this.isWaitingForImages) {
        setTimeout(() => {
          this.getCompany();
          if (this.form.companyImage.length > 0) {
            this.isWaitingForImages = false;
            this.togleMessageModal(false);
          } else {
            this.watchImages();
          }
        }, 5000);
      }
    },

    sendMessageToOpenCamera() {
      if (
        !this.validateState("signatoryTelephone") &&
        this.validateState("signatoryTelephone") != null
      ) {
        return;
      }
      this.isWaitingForImages = true;

      const payload = {
        id: this.form.id,
        signatoryTelephone: this.form.signatoryTelephone,
      };
      this.$store
        .dispatch(POST_SEND_MESSSAGE, payload)
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          console.log("error", error);
        });
    },

    isImage(file) {
      let extension = file.split(/[#?]/)[0].split(".").pop().trim();
      if (extension == "pdf" || extension == "doc" || extension == "docx") {
        return false;
      }
      return true;
    },
  },

  beforeMount() {
    this.getCompany();
    this.form.companyImage = [];
    this.form.files = [];
  },
};
</script>

<style>
</style>