<template>
  <b-dropdown
    ref="filter-dropdown"
    variant="none"
    toggle-class="p-0 text-decoration-none"
    menu-class="menu-min-width p-3 "
    no-caret
    boundary="window"
  >
    <template #button-content>
      <b-icon
        class="cursor-pointer"
        icon="filter"
        :variant="hasFilter() ? 'success' : ''"
      ></b-icon>
    </template>

    <b-dropdown-form style="min-width: 240px">
      <CustomersFilter
        v-if="type == 'customer'"
        @selectedCustomer="onSelectCustomer($event)"
        :customerSelected="filter"
      />
      <StartDateFilterVue v-if="type == 'startDate'" />
      <EndDateFilterVue v-if="type == 'endDate'" />
      <YearFilterVue v-if="type == 'year'" />

      <AgentFilter
        v-if="type == 'agent.fullName'"
        @selectedAgent="onSelectAgent($event)"
        :agentSelected="filter"
      />
      <DealershipFilter
        v-if="type == 'dealership.fullName'"
        @selectedDealership="onSelectDealership($event)"
        :dealershipSelected="filter"
      />
      <LeaseAgentFilter
        v-if="type == 'leaseAgent.fullName'"
        @selectedLeaseAgent="onSelectLeaseAgent($event)"
        :leaseAgentSelected="filter"
      />
      <MakeFilter
        v-if="type == 'make'"
        @selectedMake="onSelectMake($event)"
        :makeSelected="filter"
      />
      <ModelFilter
        v-if="type == 'model'"
        @selectedModel="onSelectModel($event)"
        :modelSelected="filter"
      />
      <div class="text-nowrap">
        <b-form-checkbox-group
          v-model="filter"
          :options="filterList"
          class="mb-3"
          stacked
          small
        />
      </div>
    </b-dropdown-form>
    <b-dropdown-divider></b-dropdown-divider>
    <div class="text-center">
      <div class="float-right">
        <b-button
          size="sm"
          variant="primary"
          class="mx-1"
          @click="applyFilters()"
          >{{ $t('apply') }}</b-button
        >

        <b-button
          size="sm"
          variant="danger"
          class="mx-1 text-white"
          @click="clearFilter()"
          >{{ $t('clear') }}</b-button
        >
      </div>
    </div>
  </b-dropdown>
</template>

<script>
import { filterList } from "./filterList";
import { mapState } from "vuex";
import { SET_DEALS_FILTERS } from "../actions";
import CustomersFilter from "./CustomerFilter.vue";
import StartDateFilterVue from "./StartDateFilter.vue";
import EndDateFilterVue from "./EndDateFilter.vue";
import YearFilterVue from "./YearFilter.vue";
import AgentFilter from "./AgentFilter.vue";
import MakeFilter from "./MakeFilter.vue";
import ModelFilter from "./ModelFilter.vue";
import LeaseAgentFilter from "./LeaseAgentFilter.vue";
import DealershipFilter from "./DealershipFilter.vue";
export default {
  name: "FilterColum",
  props: {
    type: {
      type: String,
      required: true,
    },
    refreshData: {
      type: Function,
      required: true,
    },
  },
  components: {
    CustomersFilter,
    StartDateFilterVue,
    EndDateFilterVue,
    YearFilterVue,
    AgentFilter,
    MakeFilter,
    ModelFilter,
    LeaseAgentFilter,
    DealershipFilter
},
  data() {
    return {
      filterList: filterList[this.type],
      filter: [],
    };
  },
  computed: {
    typeFormatted() {
      return filterList[this.type];
    },
    ...mapState({
      dealsFilters: (state) => state.dealership.dealStore.deals.filters,
    }),
    dealsStore() {
      return this.dealsFilters;
    },
  },
  mounted() {
    this.getFilterFromStore();
  },
  watch: {
    dealsStore() {
      // Our fancy notification (2).
      this.getFilterFromStore();
    },
  },

  methods: {
    formatTypeToFilter(type) {
      switch (type) {
        case "user":
          return "agentId";
        case "stockNumber":
          return "stock";
        case "conditionalReports":
          return "cr";
        case "files":
          return "export";
        case "leaseAgent.fullName":
          return "leaseAgent";
        case "agent.fullName":
          return "agent";
        default:
          return type;
      }
    },
    applyFilters() {
      let arrayToApply = this.convertArrayToString(this.filter);
      this.$store.commit(SET_DEALS_FILTERS, {
        fields: { [this.formatTypeToFilter(this.type)]: arrayToApply },
      });

      this.closeMenuFilter();
      this.refreshData();
    },
    clearFilter() {
      this.filter = [];

      this.$store.commit(SET_DEALS_FILTERS, {
        fields: { [this.formatTypeToFilter(this.type)]: [] },
      });

      if (this.getDateFieldByType(this.type)) {
        this.clearDateFilter();
      }
      this.closeMenuFilter();
      this.refreshData();
    },
    clearDateFilter() {
      this.$store.commit(SET_DEALS_FILTERS, {
        fields: {
          [this.getDateFieldByType(this.type).from]: null,
          [this.getDateFieldByType(this.type).to]: null,
        },
      });
    },
    getDateFieldByType(type) {
      switch (type) {
        case "startDate":
          return { to: "startDateFrom", from: "startDateTo" };
        case "endDate":
          return { to: "endDateFrom", from: "endDateTo" };

        default:
          return null;
      }
    },
    convertArrayToString(array) {
      return array.join(",");
    },
    getFilterFromStore() {
      let filterStore = this.dealsFilters[this.formatTypeToFilter(this.type)];
      if (filterStore && filterStore != "" && typeof filterStore == "string") {
        this.filter = filterStore.split(",");
      } else {
        this.filter = [];
      }
    },
    onSelectCustomer(agents) {
      this.filter = agents;
    },
    onSelectMarketplace(marketplaces) {
      this.filter = marketplaces;
    },
    onSelectModel(models) {
      this.filter = models;
    },
    onSelectMake(makes) {
      this.filter = makes;
    },
    onSelectAgent(agents) {
      this.filter = agents;
    },
    onSelectLeaseAgent(leaseAgents) {
      this.filter = leaseAgents;
    },
    onSelectDealership(dealearships) {
      this.filter = dealearships;
    },

    closeMenuFilter() {
      this.$refs["filter-dropdown"].hide();
    },
    hasFilter() {
      if (this.filter.length > 0) {
        return true;
      }
      if (this.getDateFieldByType(this.type) == null) {
        return false;
      }
      if (
        this.dealsStore[this.getDateFieldByType(this.type).to] != null ||
        this.dealsStore[this.getDateFieldByType(this.type).from] != null
      ) {
        return true;
      }
      return false;
    },
  },
};
</script>

<style scoped>
.menu-min-width {
  min-width: 5rem !important;
}
.hideOverflow {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
  display: block;
}
</style>
