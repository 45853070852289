/** @format */

import Base from './Base.vue'
import NavUsers from './users/Nav.vue'
import PreRegistryList from './users/PreRegistryList.vue'
import RegistryList from './users/RegistryList.vue'
import LeasingAgentList from './users/LeasingAgentList.vue'
import ActiveList from './users/ActiveList.vue'
import OnHoldList from './users/OnHoldList.vue'
import ValidateList from './users/ValidateList.vue'
import RejectedList from './users/RejectedList.vue'
import DealsTable from '../admin/DealsTable.vue'
import LeaseAgreement from './leaseAgreement/LeaseAgreement.vue'
import SignaturePreApproval from '@/modules/shared/signature/Signature.vue'
import InternalValidation from '@/modules/admin/internalValidation/InternalValidation.vue'
import { ifRoleAuthenticated } from '@/router/navigationGuards'
import Rates from '@/modules/dealership/Rates.vue'
import Calculator from '@/modules/shared/calculator/Calculator.vue'

const routes = [
  {
    path: '/admin',
    name: 'admin',
    component: Base,
    meta: { layout: 'admin' },

    beforeEnter: (to, from, next) => {
      ifRoleAuthenticated(to, from, next, 'ROLE_ADMIN')
    },
    children: [
      {
        path: 'users',
        name: 'admin.users',
        component: NavUsers,
        meta: { layout: 'admin' },

        children: [
          {
            path: 'pre-registry',
            name: 'admin.users.pre-registry',
            component: PreRegistryList,
            meta: { layout: 'admin' }
          },
          {
            path: 'registry',
            name: 'admin.users.registry',
            component: RegistryList,
            meta: { layout: 'admin' }
          },
          {
            path: 'active',
            name: 'admin.users.active',
            component: ActiveList,
            meta: { layout: 'admin' }
          },
          {
            path: 'on-hold',
            name: 'admin.users.on-hold',
            component: OnHoldList,
            meta: { layout: 'admin' }
          },
          {
            path: 'validate',
            name: 'admin.users.validate',
            component: ValidateList,
            meta: { layout: 'admin' }
          },
          {
            path: 'rejected',
            name: 'admin.users.rejected',
            component: RejectedList,
            meta: { layout: 'admin' }
          }
        ]
      },
      {
        path: 'agents-list',
        name: 'admin.agents-list',
        component: LeasingAgentList,
        meta: { layout: 'admin' }
      },
      {
        path: 'lease-agreement',
        name: 'admin.lease-agreement',
        component: LeaseAgreement,
        meta: { layout: 'admin' }
      },
      {
        path: 'lease-agreement/signature/:token',
        component: SignaturePreApproval,
        name: 'admin.lease-agreement.signature'
      },
      {
        path: 'internal-validation',
        component: InternalValidation,
        name: 'admin.internal-validation'
      },
      {
        path: 'rates',
        component: Rates,
        name: 'admin.rates',
        meta: { layout: 'admin' },
      },
      {
        path: 'calculator',
        component: Calculator,
        name: 'admin.calculator',
        meta: { layout: 'admin' },
      },
      {
        path: 'deals',
        component: DealsTable,
        name: 'admin.deals',
        meta: { layout: 'admin' },
      },
    ]
  }
]

export default routes
