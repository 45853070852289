<template>
  <div>
    <b-row class="mt-1 mb-2">
      <b-col class="ml-1">
        Style:
        <i v-if="vehicle.vinNumber.style">{{ vehicle.vinNumber.style }}</i>
        <i v-else>No Style</i>
      </b-col>
      <b-col class="ml-4">
        Doors:
        <i v-if="vehicle.vinNumber.doors">{{ vehicle.vinNumber.doors }}</i>
        <i v-else>No Doors</i>
      </b-col>
    </b-row>
    <b-row class="mt-2 mb-2">
      <b-col class="ml-1">
        Color:
        <i>{{ vehicle.vehicleInformation.color }}</i>
      </b-col>
    </b-row>
    <b-row class="mt-2 mb-2">
      <b-col class="ml-1">
        Vehicle Type:
        <i v-if="vehicle.vinNumber.vehicleType">
          {{ vehicle.vinNumber.vehicleType }}
        </i>
        <i v-else> No Vehicle Type </i>
      </b-col>
    </b-row>
    <b-row class="mt-2 mb-2">
      <b-col class="ml-1">
        Drive Type:
        <i v-if="vehicle.vinNumber.driveType">
          {{ vehicle.vinNumber.driveType }}
        </i>
        <i v-else>No Drive Type</i>
      </b-col>
    </b-row>
    <b-row class="mt-2 mb-2">
      <b-col class="ml-1">
        Engine:
        <i v-if="vehicle.vinNumber.driveType">
          {{ vehicle.vinNumber.driveType }}
        </i>
        <i v-else>No Engine</i>
      </b-col>
    </b-row>
    <b-row>
      <b-col class="ml-1">
        Conditional Report:
        <i v-if="vehicle.crUrl">
          {{ vehicle.crUrl }}
        </i>
        <i v-else>No Conditional Report</i>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  props: ["vehicle"],
};
</script>

<style></style>
