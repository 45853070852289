<template>
  <div>
    <!-- iterate agent in checks-->
    <div v-if="isLoading">
      <div class="text-center text-danger my-2">
        <b-spinner class="align-middle" variant="yellow" />
      </div>
    </div>
    <div v-else>
      <b-form-group>
        <b-form-checkbox
          v-for="agent in agents"
          v-model="agentFilter"
          :key="agent.value"
          :value="agent.id"
          name="flavour-3a"
          @change="onSelectedAgent"
        >
          {{ agent.name }}
        </b-form-checkbox>
      </b-form-group>
    </div>
  </div>
</template>

<script>
export default {
  name: "AgentFilter",
  props: ["agentSelected"],
  data() {
    return {
      agents: [],
      agentFilter: [],
      isLoading: false,
    };
  },
  watch: {
    agentSelected: {
      handler: function (val) {
        this.agentFilter = val;
      },
      deep: true,
    },
  },
  methods: {
    getAgents() {
      this.isLoading = true;
      this.$store
        .dispatch("GET_AGENTS")
        .then((response) => {
          this.agents = response;
          this.agentFilter = this.agentSelected;
          this.isLoading = false;
        })
        .catch(() => {
          this.isLoading = false;
        });
    },
    onSelectedAgent() {
      this.$emit("selectedAgent", this.agentFilter);
    },
  },

  mounted() {
    this.getAgents();
  },
};
</script>

<style>
</style>
