/** @format */

import Base from '@/modules/auth/Base.vue'
import User from '@/modules/user/profile/User'
import Company from '@/modules/user/profile/Company'
import NavProfile from '@/modules/user/profile/components/Nav'
const routes = [
  {
    path: '/user',
    component: Base,
    children: [
      {
        path: 'profile',
        component: NavProfile,
        meta: { layout: 'default' },
        name: 'profile',
        children: [
          {
            path: 'user',
            component: User,
            name: 'user.profile.user'
          },
          {
            path: 'company',
            component: Company,
            name: 'user.profile.company'
          }
        ]
      }
    ]
  }
]

export default routes
