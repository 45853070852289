/** @format */
const generalMixin = {
  data() {
    return {
      isBusy: false
    }
  },
  computed: {
    loginData() {
      return this.$store.state.auth.loginStore.profile.data
    },
    user() {
      return this.loginData ? this.loginData.user : null
    }
  },
  methods: {
    makeToast(title, text, type) {
      this.$root.$bvToast.toast(text, {
        title: title,
        autoHideDelay: 5000,
        variant: type,
        solid: true
      })
    },
    showModal(id) {
      this.$bvModal.show(id)
    },
    closeModal(modalId, callback = null) {
      this.$bvModal.hide(modalId)
      if (callback) callback()
    },
    showConfirmDeleteBox() {
      return this.$bvModal.msgBoxConfirm(
        'Please confirm that you want to delete the item.',
        {
          title: 'Please Confirm',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'danger',
          okTitle: 'YES',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true
        }
      )
    },
    userHasRole(userRole) {
      if (!this.user) {
        return false
      } else {
        const { roles } = this.user
        return roles.find(role => role.name === userRole)
      }
    }
  }
}

export { generalMixin }
