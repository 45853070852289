<template>
  <b-row>
    <b-col>
      <b-img
        fluid
        center
        class="mb-4 mb-md-5"
        :src="require('../../../assets/img/logos/cb_2.png')"
      >
      </b-img>
      <b-form @submit.stop.prevent="onSubmit()">
        <b-form-group
          id="emailGroup"
          :label="$t('c_email')"
          label-For="email"
          label-cols-md="4"
          content-cols-md="5"
          label-align-md="right"
          class="text-white"
        >
          <b-form-input
            id="email"
            size="md"
            :placeholder="$t('c_email')"
            class="bg-white text-dark"
            v-model.trim="$v.form.email.$model"
          ></b-form-input>
        </b-form-group>
        <b-form-group
          id="passwordGroup"
          :label="$t('password')"
          label-For="password"
          label-cols-md="4"
          content-cols-md="5"
          label-align-md="right"
          class="text-white"
        >
          <b-input-group>
            <b-form-input
              id="password"
              :type="showPassword ? 'text' : 'password'"
              :value="form.password"
              v-model.trim="$v.form.password.$model"
            >
            </b-form-input>
            <b-input-group-append>
              <b-button variant="success" style="border: none">
                <b-icon
                  v-if="showPassword == false"
                  icon="eye-fill"
                  class="mt-1 text-white"
                  @click="hanldePasswordVisibility(true)"
                />
                <b-icon
                  v-else
                  icon="eye-slash"
                  class="mt-1 text-white"
                  @click="hanldePasswordVisibility(false)"
                />
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
        <b-col md="12" class="mt-3 mb-3 text-center">
          <router-link to="/auth/dealership-create"
            ><span class="span-text mr-3">{{
              $t("sign_up")
            }}</span></router-link
          >
          <span
            @click="showDialogPassword"
            class="span-text"
            style="text-decoration: none"
            >{{ $t("forgot_password") }}</span
          >
          <Dialog
            :style="{ width: '380px' }"
            :visible.sync="forgotPassworDialog"
            :modal="true"
          >
            <ForgotPassword></ForgotPassword>
          </Dialog>
        </b-col>
        <b-col md="4" class="mx-auto">
          <b-button
            squared
            block
            :disabled="$v.form.$invalid"
            class="btn btn-success btn-custom"
            type="submit"
            @keydown.enter="onSubmit()"
          >
            <b-spinner
              v-if="loginStatus === 'fetching'"
              variant="white"
              label="Spinning"
            />
            <span v-else
              ><b>{{ $t("login") }}</b></span
            >
          </b-button>
        </b-col>
      </b-form>
    </b-col>
  </b-row>
</template>

<script>
import { required, email } from "vuelidate/lib/validators";
import { AUTH_LOGIN } from "./actions";
import appRoles from "../../../helpers/appRoles";
import { mapState } from "vuex";
import ForgotPassword from "../forgotPassword/ForgotPassword.vue";
export default {
  name: "LoginForm",
  components: { ForgotPassword },
  data() {
    return {
      modalId: "login-modal",
      form: { email: null, password: null },
      showPassword: false,
      forgotPassworDialog: false,
    };
  },
  computed: {
    ...mapState({
      profile: (state) => state.auth.loginStore.profile.data.user,
      loginStatus: (state) => state.auth.loginStore.profile.status,
    }),
  },
  validations: {
    form: {
      email: {
        required,
        email,
      },
      password: {
        required,
      },
    },
  },
  methods: {
    showDialogPassword() {
      this.forgotPassworDialog = true;
    },
    show() {},
    close() {
      this.$bvModal.hide(this.modalId);
    },
    setValue(key, value) {
      this.form[key] = value;
      this.$v.form[key].$touch();
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    onSubmit() {
      this.$store
        .dispatch(AUTH_LOGIN, this.form)
        .then((response) => {
          this.$toast.add({
            summary: this.$t("toast_title_success"),
            detail: this.$t("toast_message_welcome"),
            severity: "success",
            life: 5000,
          });
          this.handleRedirect(response.user.roles[0].name);
          this.$bvModal.hide(this.modalId);
        })
        .catch((error) => {
          this.$toast.add({
            summary: this.$t("toast_title_warning"),
            detail: this.$t("toast_message_login_invalid"),
            severity: "warn",
            life: 5000,
          });
        });
    },
    handleRedirect(authority) {
      const userRoles = this.profile.roles.find((r) => r.name === authority);
      const roleAuthority = appRoles.find(
        (role) => role.authority === userRoles.name
      );
      if (roleAuthority.authority == "ROLE_DEALERSHIP") {
        this.$router.push({
          name: roleAuthority.mainRoute,
        });
      } else {
        this.$router.push({ name: roleAuthority.mainRoute });
      }
    },
    hanldePasswordVisibility(isShow) {
      this.showPassword = isShow;
    },
  },
};
</script>

<style scoped>
.btn-custom {
  transform: skewX(-10deg);
  background-color: #3deabb !important;
  color: #4f4f4f !important;
}
.span-text {
  color: white;
}
.span-text:hover {
  color: #3deabb;
}

input[type="text"] {
  color: white;
  background-color: transparent;
  box-sizing: border-box;
  border: none;
  border-bottom: 2px solid white;
  outline: transparent !important;
}

input[type="text"]:focus {
  color: white;
  background-color: transparent;
  box-sizing: border-box;
  border: none;
  border-bottom: 2px solid white;
  outline: none !important;
}

input[type="password"] {
  color: white;
  background-color: transparent;
  box-sizing: border-box;
  border: none;
  border-bottom: 2px solid white;
  outline: none !important;
}

input[type="password"]:focus {
  color: white;
  background-color: transparent;
  box-sizing: border-box;
  border: none;
  border-bottom: 2px solid white;
  outline: none !important;
}
</style>
