<template>
  <div class="h-100">
    <div
      v-if="profile.userDetails.company.status != 'Validated'"
      class="container text-center"
    >
      <b-icon
        icon="exclamation-circle"
        class="mt-"
        variant="blue"
        font-scale="7.5"
      />
      <h3 class="mt-5">
        {{ $tc("weAreValidating") }}
      </h3>
    </div>
    <div v-else>
      <Navigation/>
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import FormUser from "@/modules/dealership/users/FormUser.vue";
import Table from "@/modules/shared/table.vue"
import Navigation from "../Nav.vue";
export default {
  components: { FormUser, Navigation, Table },
  computed: {
    ...mapState({
      profile: (state) => state.auth.loginStore.profile.data.user,
      loginStatus: (state) => state.auth.loginStore.profile.status,
      configuration: (state) =>
        state.dealership.siteConfiguration.configuration.data,
    }),
  },
  mounted() {
    
  },
};
</script>

<style>
</style>