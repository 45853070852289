<template>
  <div>
    <!-- iterate make in checks-->
    <div v-if="isLoading">
      <div class="text-center text-danger my-2">
        <b-spinner class="align-middle" variant="yellow" />
      </div>
    </div>
    <div v-else>
      <b-form-group>
        <b-form-checkbox
          v-for="make in makes"
          v-model="makeFilter"
          :key="make.value"
          :value="make.value"
          name="flavour-3a"
          @change="onSelectedAgent"
        >
          {{ make.text }}
          <span v-if="make.nickname">({{ make.nickname }})</span>
        </b-form-checkbox>
      </b-form-group>
    </div>
  </div>
</template>

<script>
export default {
  name: "MakeFilter",
  props: ["makeSelected"],
  data() {
    return {
      makes: [],
      makeFilter: [],
      isLoading: false,
    };
  },
  watch: {
    makeSelected: {
      handler: function (val) {
        this.makeFilter = val;
      },
      deep: true,
    },
  },
  methods: {
    getMakes() {
      this.isLoading = true;
      this.$store
        .dispatch("GET_MAKES")
        .then((response) => {
          this.makes = response;
          this.makeFilter = this.makeSelected;
          this.isLoading = false;
        })
        .catch(() => {
          this.isLoading = false;
        });
    },
    onSelectedAgent() {
      this.$emit("selectedMake", this.makeFilter);
    },
  },

  mounted() {
    this.getMakes();
  },
};
</script>

<style>
</style>
