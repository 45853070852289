/** @format */

import buyerStore from '@/modules/dealership/registrationForm/store.js'
import users from '@/modules/dealership/users/store.js'
import document from '@/modules/dealership/document/store.js'
import preApproval from '@/modules/dealership/preApproval/store.js'
import siteConfiguration from '@/modules/dealership/siteConfig/store.js'
import agentStore from '@/modules/agent/store.js'
import vehiclesStore from './vehicles/store'
import dealStore from './deals/store'

export default {
  state: {},
  mutations: {},
  actions: {},
  modules: {
    buyerStore,
    users,
    document,
    preApproval,
    siteConfiguration,
    vehiclesStore,
    agentStore,
    dealStore
  }
}
