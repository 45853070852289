<template>
  <div>
    <!-- iterate leaseAgent in checks-->
    <div v-if="isLoading">
      <div class="text-center text-danger my-2">
        <b-spinner class="align-middle" variant="yellow" />
      </div>
    </div>
    <div v-else>
      <b-form-group>
        <b-form-checkbox
          v-for="leaseAgent in leaseAgents"
          v-model="leaseAgentFilter"
          :key="leaseAgent.value"
          :value="leaseAgent.id"
          name="flavour-3a"
          @change="onSelectLeaseAgent"
        >
          {{ leaseAgent.name }}
        </b-form-checkbox>
      </b-form-group>
    </div>
  </div>
</template>

<script>
export default {
  name: "LeaseAgentFilter",
  props: ["leaseAgentSelected"],
  data() {
    return {
      leaseAgents: [],
      leaseAgentFilter: [],
      isLoading: false,
    };
  },
  watch: {
    leaseAgentSelected: {
      handler: function (val) {
        this.leaseAgentFilter = val;
      },
      deep: true,
    },
  },
  methods: {
    getLeaseAgents() {
      this.isLoading = true;
      this.$store
        .dispatch("GET_LEASE_AGENTS")
        .then((response) => {
          this.leaseAgents = response;
          this.leaseAgentFilter = this.leaseAgentSelected;
          this.isLoading = false;
        })
        .catch(() => {
          this.isLoading = false;
        });
    },
    onSelectLeaseAgent() {
      this.$emit("selectedLeaseAgent", this.leaseAgentFilter);
    },
  },

  mounted() {
    this.getLeaseAgents();
  },
};
</script>

<style>
</style>
