const makes = [
    'Acura',
    'Alfa Romeo',
    'Aston Martin',
    'Audi',
    'Bentley',
    'BMW',
    'Buick',
    'Cadillac',
    'Chevrolet',
    'Chrysler',
    'Daewoo',
    'Daihatsu',
    'Dodge',
    'Eagle',
    'Ferrari',
    'FIAT',
    'Fisker',
    'Ford',
    'Freightliner',
    'Genesis',
    'Geo',
    'GMC',
    'Honda',
    'HUMMER',
    'Hyundai',
    'INFINITI',
    'Isuzu',
    'Jaguar',
    'Jeep',
    'Kia',
    'Lamborghini',
    'Land Rover',
    'Lexus',
    'Lincoln',
    'Lotus',
    'Maserati',
    'Maybach',
    'MAZDA',
    'McLaren',
    'Mercedes - Benz',
    'Mercury',
    'MINI',
    'Mitsubishi',
    'Nissan',
    'Oldsmobile',
    'Panoz',
    'Plymouth',
    'Polestar',
    'Pontiac',
    'Porsche',
    'Ram',
    'Rivian',
    'Rolls - Royce',
    'Saab',
    'Saturn',
    'Scion',
    'smart',
    'SRT',
    'Subaru',
    'Suzuki',
    'Tesla',
    'Toyota',
    'Volkswagen',
    'Volvo',
]
export default makes