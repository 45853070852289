<template>
  <div class="mw mx-auto">
    <Company :form="form" class="mb-3" />
    <FinancialInformation :form="form" class="mb-3" />
    <SupliersCreditors :form="form" class="mb-3" />
    <VehicleInfo :form="form" class="mb-5" />
  </div>
</template>

<script>
import Company from "./components/Company.vue";
import FinancialInformation from "./components/FinalcialInformation.vue";
import SupliersCreditors from "./components/SupliersCreditors.vue";
import VehicleInfo from "./components/VehicleInfo.vue";

export default {
  components: { Company, FinancialInformation, SupliersCreditors, VehicleInfo },
  data() {
    return {
      form: {
        test: "test",
      },
    };
  },
};
</script>

<style>
</style>