<template>
  <div class="card">
    <div class="ml-2">
      <template>
        <h1 class="text-primary ml-4">{{ $t("deals-ending") }}</h1>
      </template>
    </div>
    <div class="col-12 mt-3">
      <div class="row">
        <div class="col-12 mt-3 ml-2">
          <h4 v-if="in_days != 0">
            {{ $t("message_fin_contract") }}
            <span class="text-primary">{{ in_days }} {{ $t("days") }} </span>
          </h4>
          <h4 v-if="in_days == 0">{{ $t("message_fin_contract_all") }}</h4>
        </div>
        <div class="col-12 mt-2 mb-4">
          <b-button
            variant="primary"
            class="ml-2"
            @click="filterClosingDealInDays(1, 30)"
            >30 {{ $t("days") }}</b-button
          >
          <b-button
            variant="primary"
            class="ml-2"
            @click="filterClosingDealInDays(2, 60)"
            >60 {{ $t("days") }}</b-button
          >
          <b-button
            variant="primary"
            class="ml-2"
            @click="filterClosingDealInDays(3, 90)"
            >90 {{ $t("days") }}</b-button
          >
          <b-button
            variant="primary"
            class="ml-2"
            @click="filterClosingDealInDays(4, 180)"
            >180 {{ $t("days") }}</b-button
          >
          <b-button
            variant="primary"
            class="ml-2"
            @click="filterClosingDealInDays(5, 0)"
            >{{ $t("all_contract") }}</b-button
          >
        </div>
        <div class="col-lg-12">
          <TableBase
            :items="deals_list"
            :fields="fields"
            :options="options"
            :busy="loading === 'fetching' ? true : false"
          />
          <Dialog
            :visible.sync="detailsDialog"
            :style="{ width: '380px' }"
            :header="$t('details_deal')"
            :modal="true"
            class="p-fluid"
          >
            <div class="row">
              <div class="col-12 mt-1">
                <strong>{{ $t("company") }}</strong
                ><br />
                {{ deal.company }}
              </div>
              <div class="col-8 mt-1">
                <strong>{{ $t("customer") }}</strong
                ><br />
                {{ deal.customer }}
              </div>
              <div class="col-4 mt-1">
                <strong>{{ $t("condition") }}</strong
                ><br />
                {{ deal.condition }}
              </div>
              <div class="col-4 mt-1">
                <strong>{{ $t("type") }}</strong
                ><br />
                {{ deal.type }}
              </div>
              <div class="col-4 mt-1">
                <strong>{{ $t("brand") }}</strong
                ><br />
                {{ deal.make }}
              </div>
              <div class="col-4 mt-1">
                <strong>{{ $t("model") }}</strong
                ><br />
                {{ deal.model }}
              </div>
              <div class="col-12 mt-1">
                <strong>{{ $t("capitalCost") }}</strong
                ><br />
                {{ deal.capitalCost }}
              </div>
              <div class="col-4 mt-1">
                <strong>{{ $t("term") }}</strong
                ><br />
                {{ deal.terms }}
              </div>
              <div class="col-8 mt-1">
                <strong>{{ $t("monthlyPayment") }}</strong
                ><br />
                {{ deal.monthlyPayment }}
              </div>
            </div>
            <template #footer>
              <div class="mt-2">
                <Button
                  :label="$t('close')"
                  icon="pi pi-times"
                  class="p-button-danger"
                  @click="hideDetailsDeal"
                />
              </div>
            </template>
          </Dialog>
          <Dialog
            :visible.sync="userValidationDocumentsDialog"
            :style="{ width: '380px' }"
            :header="$t('documents_deal')"
            :modal="true"
            class="p-fluid"
          >
            <div class="p-field">
              <div class="mt-3" v-for="(item, k) in deal.documents" :key="k">
                <div v-if="item.required">
                  <label
                    ><a :href="item.url" download target="_blank"
                      ><i class="pi pi-download"></i> {{ item.name }}
                      <span v-if="item.status == 0"
                        >({{ $t("document_pending_short") }})</span
                      >
                      <span v-if="item.status == 1"
                        >({{ $t("document_process_short") }})</span
                      >
                      <span v-if="item.status == 2"
                        >({{ $t("document_accepted_short") }})</span
                      >
                      <span v-if="item.status == 3"
                        >({{ $t("document_rejected_short") }})</span
                      ></a
                    ></label
                  >
                  <select
                    v-if="item.status == 1"
                    name="LeaveType"
                    @change="onChange($event, item)"
                    class="form-control"
                    v-model="key"
                  >
                    <option value="2">
                      {{ $t("document_accepted_short") }}
                    </option>
                    <option value="3">
                      {{ $t("document_rejected_short") }}
                    </option>
                  </select>
                  <div v-if="item.status == 0">
                    {{ $t("document_pending_short") }}
                  </div>
                  <div v-if="item.status == 2">
                    {{ $t("document_accepted") }}
                  </div>
                  <div v-if="item.status == 3">
                    {{ $t("document_rejected") }}
                  </div>
                </div>
              </div>
            </div>
            <template #footer>
              <div class="mt-2">
                <Button
                  :label="$t('close')"
                  icon="pi pi-times"
                  class="p-button-danger"
                  @click="hideValidationDocumentsDialog"
                />
              </div>
            </template>
          </Dialog>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import TableBase from "@/modules/shared/table.vue";
export default {
  components: { TableBase },
  data() {
    return {
      users: null,
      deal: {},
      rank: 1,
      loading: "fetching",
      in_days: "30",
      payload: {},
      deals_list: [],
      filters: {},
      submitted: false,
      totalRecords: 0,
      detailsDialog: false,
      userValidationDocumentsDialog: false,
      options: {
        assign: false,
        rolFilter: false,
        add: false,
      },
    };
  },
  mounted() {
    this.loadingDeals();
  },
  computed: {
    fields() {
      return [
        { key: "id", label: this.$t("id"), thClass: "bg-header text-dark" },
        {
          key: "condition",
          label: this.$t("condition"),
          thClass: "bg-header text-dark",
        },
        {
          key: "make",
          label: this.$t("brand"),
          thClass: "bg-header text-dark",
        },
        {
          key: "model",
          label: this.$t("model"),
          thClass: "bg-header text-dark",
        },
        { key: "type", label: this.$t("type"), thClass: "bg-header text-dark" },
        {
          key: "capitalCost",
          label: this.$t("prix"),
          thClass: "bg-header text-dark",
          formatter: (capitalCost) =>
            this.$options.filters.currency(capitalCost),
        },
        {
          key: "customer",
          label: this.$t("customer"),
          thClass: "bg-header text-dark",
        },
        {
          key: "terms",
          label: this.$t("term"),
          thClass: "bg-header text-dark",
        },
        {
          key: "monthlyPayment",
          label: this.$t("monthlyPayment"),
          thClass: "bg-header text-dark",
          formatter: (capitalCost) =>
            this.$options.filters.currency(capitalCost),
        },
        {
          key: "endDate",
          label: this.$t("end_date"),
          thClass: "bg-header text-dark",
        },
        {
          key: "actions",
          label: "Actions",
          detailsdeal: true,
          thClass: "bg-header text-dark",
          canceldeal: true,
          validationdocsdeal: true,
          selectdocsdeal: true,
          closeddeal: true,
          downloaddeal: true,
        },
      ];
    },
  },
  methods: {
    ...mapActions(["loadDealsClosing", "generatePDF"]),
    loadingDeals() {
      this.loading = "fetching";
      this.loadDealsClosing(this.rank)
        .then((response) => {
          this.deals_list = response;
          this.loading = "";
        })
        .catch((error) => {
          this.$toast.add({
            severity: "error",
            summary: error,
          });
          this.loading = "";
        });
    },
    filterClosingDealInDays(days, strg_days) {
      this.rank = days;
      (this.in_days = strg_days), this.loadingDeals();
    },
    detailsDeal(deal) {
      this.deal = { ...deal };
      this.detailsDialog = true;
    },
    hideDetailsDeal() {
      this.detailsDialog = false;
      this.submitted = false;
    },
    editValidationDocuments(deal) {
      this.deal = { ...deal };
      this.userValidationDocumentsDialog = true;
    },
    hideValidationDocumentsDialog() {
      this.userValidationDocumentsDialog = false;
      this.submitted = false;
      this.deal = {};
      this.validation_array = [];
    },
    exportDealPDF(deal) {
      this.generatePDF(deal.id)
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response]));
          const link = document.createElement("a");
          link.href = url;
          const dateToday = new Date();
          const day = dateToday.getDate();
          const month = dateToday.getMonth() + 1;
          const year = dateToday.getFullYear();
          const stringToday = day+""+month+""+year;
          const name = "Offer-"+ deal.type + "-term("+deal.terms+")-"+stringToday
          +".pdf";
          link.setAttribute("download", name);
          document.body.appendChild(link);
          link.click();
        })
        .catch((error) => {
          this.$toast.add({
            severity: "error",
            summary: error,
          });
        });
    },
  },
};
</script>