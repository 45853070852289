
<template>
  <div class="card">
    <div class="d-flex justify-content-md-between">
      <div>
        <h1 class="text-primary">{{ $t("deals_table") }}</h1>
      </div>
      <div>
        <Button class="p-button-success" @click="downloadExcelDeals">
          <div class="d-flex align-items-sm-center flex-column">
            {{ $t("download_excel") }}
          </div>
        </Button>
      </div>
    </div>
    <div class="card">
      <!-- n -->
      <Dialog
        :visible.sync="userDialog"
        :style="{ width: '380px' }"
        :header="$t('closing_deal')"
        :modal="true"
        class="p-fluid"
      >
        <div class="p-field">
          <div class="row">
            <div class="mt-3 col-6">
              <label>{{ $t("start_date") }}</label
              ><br />
              <input type="date" v-model="startDate" />
            </div>
            <div class="mt-3 col-6">
              <label>{{ $t("end_date") }}</label
              ><br />
              <input type="date" v-model="endDate" />
            </div>
          </div>
        </div>
        <template #footer>
          <Button
            :label="$t('cancel')"
            icon="pi pi-times"
            class="p-button-danger"
            @click="hideDialog"
          />
          <Button
            :label="$t('save')"
            icon="pi pi-check"
            class="p-button-success"
            @click="saveClosedDeal"
          />
        </template>
      </Dialog>

      <Dialog
        :visible.sync="detailsDialog"
        :style="{ width: '680px' }"
        :header="$t('details_deal')"
        :modal="true"
        class="p-fluid"
      >
        <div class="row">
          <div class="col-12 mt-1">
            <strong>{{ $t("company") }}</strong
            ><br />
            {{ deal.company }}
          </div>
          <div class="col-12 mt-2">
            <strong>{{ $t("customer") }}</strong
            ><br />
            {{ deal.customer }}
          </div>
          <div class="col-4 mt-2">
            <strong>{{ $t("condition") }}</strong
            ><br />
            {{ $t(deal.condition) }}
          </div>
          <div class="col-4 mt-2">
            <strong>{{ $t("type") }}</strong
            ><br />
            {{ $t(deal.type) }}
          </div>
          <div class="col-4 mt-2"></div>
          <div class="col-4 mt-2">
            <strong>{{ $t("year") }}</strong
            ><br />
            {{ deal.year }}
          </div>
          <div class="col-4 mt-2">
            <strong>{{ $t("brand") }}</strong
            ><br />
            {{ deal.make }}
          </div>
          <div class="col-4 mt-2">
            <strong>{{ $t("model") }}</strong
            ><br />
            {{ deal.model }}
          </div>
          <div class="col-8 mt-2">
            <strong>{{ $t("serie") }}</strong
            ><br />
            {{ deal.serie }}
          </div>
          <div class="col-4 mt-2">
            <strong>{{ $t("style") }}</strong
            ><br />
            {{ deal.style }}
          </div>
          <div class="col-4 mt-2">
            <strong>{{ $t("capitalCost") }}</strong
            ><br />
            {{  this.$options.filters.currency(deal.capitalCost) }}
          </div>
          <div class="col-4 mt-1">
            <strong>{{ $t("downpayment") }}</strong
            ><br />
            {{ this.$options.filters.currency(deal.downpayment) }}
          </div>
          <div class="col-4 mt-1">
            <strong>{{ $t("residual") }}</strong
            ><br />
            {{ this.$options.filters.currency(deal.residual) }}
          </div>
          <div class="col-4 mt-1">
            <strong>{{ $t("amountAmorticed") }}</strong
            ><br />
            {{ this.$options.filters.currency(deal.amortizeAmount) }}
          </div>
          <div class="col-4 mt-1">
            <strong>{{ $t("term") }}</strong
            ><br />
            {{ deal.terms }}
          </div>
          <div class="col-4 mt-1">
            <strong>{{ $t("monthlyPayment") }}</strong
            ><br />
            {{ this.$options.filters.currency(deal.monthlyPayment) }}
          </div>
        </div>
        <template #footer>
          <div class="mt-2">
            <Button
              :label="$t('close')"
              icon="pi pi-times"
              class="p-button-danger"
              @click="hideDetailsDeal"
            />
          </div>
        </template>
      </Dialog>

      <div class="">
        <b-row>
          <b-col md="8" sm="12"></b-col>
          <b-col md="4" sm="12"></b-col>
        </b-row>
        <b-table
          show-empty
          outlined
          striped
          hover
          responsive
          stacked="md"
          bordered
          small
          head-variant="light"
          :items="deals.data"
          :fields="fields"
          :busy="loading === 'fetching' ? true : false"
          sticky-header
        >
          <template #head()="scope">
            <div class="table-head-menu">
              <span class=""> {{ scope.label }} </span>
              <br />
              <div class="text-center">
                <DealsTableFilter
                  ref="filter-component"
                  :type="scope.field.key"
                  v-if="scope.field.hasFilter"
                  :refreshData="getData"
                />
                <div v-else>
                  <span><br /></span>
                </div>
              </div>
            </div>
          </template>

          <template #table-busy>
            <div class="text-center text-danger my-2">
              <b-spinner class="align-middle" variant="yellow" />
            </div>
          </template>
          <template v-slot:cell(condition)="{item}">
            <div>
              {{ $t(item.condition) }}
            </div>
          </template>
          <template v-slot:cell(interestRate)="{item}">
            <div>
              {{ item.interestRate }} %
            </div>
          </template>
          <template v-slot:cell(type)="{item}">
            <div>
              {{ $t(item.type) }}
            </div>
          </template>
          <template v-slot:cell(actions)="row">
            <div class="text-center d-flex justify-content-around">
              <b-form-group>
                <Button
                  v-if="row.field.selectdocsdeal && row.item.closedDeal == 0"
                  icon="pi pi-list"
                  class="p-button-primary p-mr-2"
                  @click="chooseDocumentsDeal(row.item)"
                />
                <font-awesome-icon
                  v-if="row.field.selectdocsdeal && row.item.closedDeal == 0"
                  class="mx-2 text-dark cursor-pointer"
                  :icon="['far', 'list']"
                  size="xl"
                  @click="chooseDocumentsDeal(row.item)"
                />
                <!--  <Button
                  v-if="row.field.validationdocsdeal"
                  icon="pi pi-paperclip"
                  class="p-button-help p-mr-2"
                  @click="editValidationDocuments(row.item)"
                /> -->
                <font-awesome-icon
                  v-if="row.field.validationdocsdeal"
                  class="mx-2 text-dark cursor-pointer"
                  :icon="['far', 'paperclip']"
                  size="xl"
                  @click="editValidationDocuments(row.item)"
                />
                <!--   <Button
                  v-if="row.field.closeddeal && row.item.closedDeal == 0"
                  icon="pi pi-check"
                  class="p-button-success p-mr-2"
                  @click="closeDeal(row.item)"
                /> -->
                <!-- font-awesome-icon
                  class="m-2 text-dark cursor-pointer"
                  :icon="['far', 'check']"
                  size="xl"
                  v-if="row.item.closedDeal == 0"
                  @click="closeDeal(row.item)"
                />
                <Button
                  v-if="row.field.canceldeal && row.item.closedDeal == 0"
                  icon="pi pi-times"
                  class="p-button-danger p-mr-2"
                  @click="cancelDeal(row.item)"
                /-->
                <!--     <Button
                  v-if="row.field.downloaddeal"
                  icon="pi pi-download"
                  class="p-button-info p-mr-2"
                  @click="exportDealPDF(row.item)"
                /> -->
                <font-awesome-icon
                  v-if="row.field.downloaddeal"
                  class="mx-2 my-1 text-dark cursor-pointer"
                  :icon="['far', 'download']"
                  size="xl"
                  @click="exportDealPDF(row.item)"
                />
                <!--    <Button
                  v-if="row.field.detailsdeal"
                  icon="pi pi-eye"
                  class="p-button-primary p-mr-2"
                  @click="detailsDeal(row.item)"
                /> -->

                <font-awesome-icon
                  v-if="row.field.detailsdeal"
                  class="mx-2 my-1 text-dark cursor-pointer"
                  :icon="['far', 'eye']"
                  size="xl"
                  @click="detailsDeal(row.item)"
                />
              </b-form-group>
            </div>
          </template>
        </b-table>
        <div v-if="deals.data">
          <b-pagination
            :value="deals.currentPage ? deals.currentPage : 1"
            :total-rows=" deals.total? deals.total : 0"
            :per-page="deals.perPage ? deals.perPage : 25"
            class="mb-2"
            align="end"
            @change="changePage($event)"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import TableBase from "@/modules/shared/table.vue";
import { mapActions, mapState } from "vuex";
import FormUser from "@/modules/dealership/users/FormUser.vue";
import { GET_DEALS_LIST } from "./actions";
import DealsTableFilter from "./filters/DealsTableFilter.vue";
import { generalMixin } from "../../mixin";
export default {
  name: "DealsTable",
  components: { FormUser, TableBase, DealsTableFilter },
  methods: {
    ...mapActions([GET_DEALS_LIST]),
  },
  mixins: [generalMixin],
  computed: {
    ...mapState({
      deals: (state) => state.dealership.dealStore.deals.data,
    }),
  },
  data() {
    return {
      users: null,
      startDate : null,
      endDate: null,
      selectedDeals: null,
      filters: {},
      loading: "fetching",
      detailsDialog: false,
      closeDialog: false,
      userDialog: false,
      deal: {},
      submitted: false,
      totalRecords: 0,
      fields: [
        {
          key: "agent.fullName",
          label: this.$t("agent"),
          thClass: "bg-header text-dark",
          hasFilter: true,
        },
        {
          key: "condition",
          label: this.$t("condition"),
          thClass: "bg-header text-dark",
          hasFilter: true,
        },
        {
          key: "type",
          label: this.$t("type"),
          thClass: "bg-header text-dark",
          hasFilter: true,
        },
        {
          key: "year",
          label: this.$t("year"),
          thClass: "bg-header text-dark",
          hasFilter: true,
        },
        {
          key: "make",
          label: this.$t("brand"),
          thClass: "bg-header text-dark",
          hasFilter: true,
        },
        {
          key: "model",
          label: this.$t("model"),
          thClass: "bg-header text-dark text-center",
          hasFilter: true,
        },
        {
          key: "capitalCost",
          label: this.$t("capitalCost"),
          thClass: "bg-header text-dark",
          class: "text-center",
          formatter: (capitalCost) =>
            this.$options.filters.currency(capitalCost),
        },
        {
          key: "residual",
          label: this.$t("residual"),
          thClass: "bg-header text-dark",
          class: "text-center",
          formatter: (residual) => this.$options.filters.currency(residual),
        },
        {
          key: "interestRate",
          label: this.$t("interestRateTable"),
          thClass: "bg-header text-dark",
        },
        {
          key: "amortizeAmount",
          label: this.$t("amountAmorticed"),
          thClass: "bg-header text-dark",
          formatter: (payment) => this.$options.filters.currency(payment),
        },

        {
          key: "monthlyPayment",
          label: this.$t("monthlyPayment"),
          thClass: "bg-header text-dark",
          class: "text-center",
          formatter: (payment) => this.$options.filters.currency(payment),
        },
        {
          key: "terms",
          label: this.$t("term"),
          thClass: "bg-header text-dark",
        },
        //{ key: "status", label:  this.$t("status") },
        // { key: "approvalDate", label:  this.$t("approvalDate") },
        {
          key: "customer",
          label: this.$t("customer"),
          thClass: "bg-header text-dark",
          hasFilter: true,
        },
        //{ key: "company", label:  this.$t("company") },
        //{ key: "customerId", label:  this.$t("customerId") },
        //{ key: "createdAt", label:  this.$t("createdAt") },
        {
          key: "leaseAgent.fullName",
          label: this.$t("leasing_agent"),
          thClass: "bg-header text-dark",
          hasFilter: true,
        },
        {
          key: "customer",
          label: this.$t("customer"),
          thClass: "bg-header text-dark",
          hasFilter: true,
        },
        {
          key: "startDate",
          label: this.$t("start_date"),
          thClass: "bg-header text-dark",
          class: "text-center",
          hasFilter: true,
        },
        {
          key: "endDate",
          label: this.$t("end_date"),
          thClass: "bg-header text-dark",
          class: "text-center",
          hasFilter: true,
        },
        {
          key: "actions",
          label: "Actions",
          thClass: "bg-header text-dark",
          downloaddeal: true,
          detailsdeal: true,
        },
      ],
      options: {
        assign: false,
        rolFilter: false,
        add: false,
      },
    };
  },
  created() {
    //this.GET_DEALS_LIST()
  },
  mounted() {
    this.getData();
  },
  methods: {
    ...mapActions(["GET_DEALS_LIST", "generateExcel","generatePDF","saveCloseDeal","SET_DEALS_FILTERS"]),

    getData(filter = "") {
      this.loading = "fetching";
      this.$store
        .dispatch("GET_DEALS_LIST")
        .then((response) => {
          this.loading = "";
        })
        .catch((error) => {
          this.$toast.add({
            summary: this.$t("toast_title_error"),
            detail: this.$t("toast_message_error"),
            severity: "error",
            life: 5000,
          });
          this.loading = "";
        });
    },
    changePage(page, filter = "") {
      console.log(page);
      /* const payload = {
        search: filter,
        role: "ROLE_LEASE_AGENT",
        page: page,
      };*/
      this.$store.commit("SET_DEALS_FILTERS", {
        fields: { page },
      });
      this.getData();
      this.loading = "";
    },
    exportDealPDF(deal) {
      this.generatePDF(deal.id)
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response]));
          const link = document.createElement("a");
          link.href = url;
          const dateToday = new Date();
          const day = dateToday.getDate();
          const month = dateToday.getMonth() + 1;
          const year = dateToday.getFullYear();
          const stringToday = day+""+month+""+year;
          const name = "Offer-"+ deal.type + "-term("+deal.terms+")-"+stringToday
          +".pdf";
          link.setAttribute("download", name);
          document.body.appendChild(link);
          link.click();
        })
        .catch((error) => {
          this.$toast.add({
            severity: "error",
            summary: error,
          });
        });
    },
    detailsDeal(deal) {
      this.deal = { ...deal };
      this.detailsDialog = true;
    },
    closeDeal(deal){
      this.deal = { ...deal };
      this.userDialog = true;
    },
    hideDialog() {
      this.userDialog = false;
      this.submitted = false;
    },
    hideDetailsDeal() {
      this.detailsDialog = false;
      this.submitted = false;
    },
    hasFilter(column) {
      return column.filter;
    },
    saveClosedDeal() {
      let payload = {
        ...{
          deal: this.deal.id,
          endDate: this.endDate,
          startDate: this.startDate,
        },
      };
      this.saveCloseDeal(payload)
        .then((response) => {
          this.$toast.add({
            severity: "success",
            summary: this.$t("closed_deal"),
            detail: this.$t("message_success"),
            life: 4000,
          });
          this.hideDialog();
          this.getData();
        })
        .catch((error) => {
          this.$toast.add({
            severity: "error",
            summary: error,
          });
          this.hideDialog();
          this.getData();
        });
    },
    downloadExcelDeals() {
      console.log("Donwload Deals");
      this.generateExcel()
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response]));
          const link = document.createElement("a");
          link.href = url;
          const dateToday = new Date();
          const day = dateToday.getDate();
          const month = dateToday.getMonth() + 1;
          const year = dateToday.getFullYear();
          const stringToday = day+""+month+""+year;
          const name = "Deals-"+stringToday
          +".xls";
          link.setAttribute("download", name);
          document.body.appendChild(link);
          link.click();
        })
        .catch((error) => {
          this.$toast.add({
            severity: "error",
            summary: error,
          });
        });
      },
  },
};
</script>
