<template>
  <div>
    <b-navbar toggleable="lg" type="light" class="layout-topbar-full bg-blue">
      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
      <b-collapse id="nav-collapse" is-nav>
        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto test">
          <button class="p-link" @click="changeLang()">
            <span class="layout-topbar-item-text">{{ $t("lang") }} </span>
            <span class="layout-topbar-icon pi pi-globe"></span>
          </button>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </div>
</template>
<style>
.test button {
  position: relative;
  color: #ffffff;
  margin-left: 20px;
  display: inline-block;
  text-decoration: none;
  transition: color 0.2s;
}
.p-link {
  text-align: left;
  background-color: transparent;
  margin: 0;
  padding: 0;
  border: 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.layout-topbar-full {
  height: 50px;
  color: #ffffff;

  transition: left 0.2s;
}

.layout-topbar-icon {
  font-size: 21px;
}

.layout-topbar-item-text {
  font-size: 21px;
}
</style>
<script>
export default {
  methods: {
    onMenuToggle(event) {
      this.$emit("menu-toggle", event);
    },
    changeLang() {
      if (this.$root.$i18n.locale == "fr") {
        this.$root.$i18n.locale = "en";
        window.localStorage.setItem("language", "en");
        console.log(window.localStorage.getItem("language"));
        location.reload()
      } else {
        this.$root.$i18n.locale = "fr";
        window.localStorage.setItem("language", "fr");
        console.log(window.localStorage.getItem("language"));
        location.reload()
      }
    },
  },
};
</script>