<template>
  <div id="app">
    <Toast />
    <component v-cloak :is="layout"></component>
    <vue-progress-bar></vue-progress-bar>
  </div>
</template>

<script>
const defaultLayout = "default";

export default {
  name: "App",
  computed: {
    layout() {
      return (this.$route.meta.layout || defaultLayout) + "-layout";
    },
  },
};
</script>

<style lang="scss">
@import '@/App.scss';
[v-cloak] {
  display: none;
}
</style>
