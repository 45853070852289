/** @format */

export const GET_SERVER_TIME = 'GET_SERVER_TIME'

export const GET_COMPANY = 'GET_COMPANY'
export const GET_COMPANY_SUCCESS = 'GET_COMPANY_SUCCESS'
export const GET_COMPANY_ERROR = 'GET_COMPANY_ERROR'

export const POST_UPLOAD_IMAGE = 'POST_UPLOAD_IMAGE'
export const POST_UPLOAD_IMAGE_SUCCESS = 'POST_UPLOAD_IMAGE_SUCCESS'
export const POST_UPLOAD_IMAGE_ERROR = 'POST_UPLOAD_IMAGE_ERROR'

export const POST_CONTRACT = 'POST_CONTRACT_IMAGE'
export const POST_CONTRACT_SUCCESS = 'POST_CONTRACT_SUCCESS'
export const POST_CONTRACT_ERROR = 'POST_CONTRACT_ERROR'

export const POST_SEND_MESSSAGE = 'POST_SEND_MESSSAGE'
