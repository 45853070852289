<template>
  <b-card>
    <h5>{{ $t("suppliers") }}</h5>
    <b-row class="justify-content-md-center">
      <b-col sm="12" md="6" lg="3" xl="3">
        <b-form-group label-size="sm">
          <template slot="label">
            <span>
              {{ $t("nameOfPrincipalSuppliers") }}
            </span>
          </template>
          <b-form-input v-model="form.nameOfsupp" :maxlength="65">
          </b-form-input>
          <b-form-invalid-feedback id="input-companyName-live-feedback">
            {{ $t("requiredTwoCharacters") }}
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>

      <b-col sm="12" md="6" lg="2" xl="2">
        <b-form-group label-size="sm">
          <template slot="label">
            <span>
              {{ $t("length") }}
            </span>
          </template>
          <b-form-input type="number" v-model="form.nameOfsupp" :maxlength="65">
          </b-form-input>
          <b-form-invalid-feedback id="input-companyName-live-feedback">
            {{ $t("requiredTwoCharacters") }}
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>

      <b-col sm="12" md="4" lg="4" xl="4">
        <b-form-group label-size="sm">
          <template slot="label">
            {{ $t("address") }}

            <span class="text-danger">*</span>
          </template>
          <b-form-input
            v-if="isAddressSelected"
            size="md"
            name="street"
            class="mb-3"
            v-model="address"
            maxlength="65"
            @focus="isAddressSelected = false"
          />
          <place-autocomplete-field
            v-else
            @autocomplete-select="selectAddress"
            v-model="form.address"
            name="address"
            api-key="AIzaSyBWj-z1916MmpcimZW3xPlRGXMikForpS4"
            placeholder="Saisissez une adresse, un code postal ou un emplacement"
            country="us"
          ></place-autocomplete-field>
        </b-form-group>
      </b-col>

      <b-col sm="12" md="6" lg="3" xl="3">
        <b-form-group label-size="sm" label="Mobile phone">
          <template slot="label">
            <span> Téléphone <span class="text-danger"> *</span> </span>
          </template>

          <vue-tel-input
            v-model="form.signatoryTelephone"
            defaultCountry="ca"
            :inputOptions="options"
            :dropdownOptions="telInputOptions"
            :autoFormat="true"
            :validCharactersOnly="true"
            @change="setValue('signatoryTelephone', $event)"
            :value="form.signatoryTelephone"
            mode="international"
          ></vue-tel-input>

          <b-form-invalid-feedback id="input-mobile-phone-live-feedback">
            {{ $t("numericField") }}
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>

      <b-col sm="12" md="6" lg="3" xl="3">
        <b-form-group label-size="sm">
          <template slot="label"> </template>
          <b-form-input v-model="form.nameOfsupp" :maxlength="65">
          </b-form-input>
          <b-form-invalid-feedback id="input-companyName-live-feedback">
            {{ $t("requiredTwoCharacters") }}
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>

      <b-col sm="12" md="6" lg="2" xl="2">
        <b-form-group label-size="sm">
          <template slot="label"> </template>
          <b-form-input type="number" v-model="form.nameOfsupp" :maxlength="65">
          </b-form-input>
          <b-form-invalid-feedback id="input-companyName-live-feedback">
            {{ $t("requiredTwoCharacters") }}
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>

      <b-col sm="12" md="4" lg="4" xl="4">
        <b-form-group label-size="sm">
          <b-form-input
            v-if="isAddressSelected"
            size="md"
            name="street"
            class="mb-3"
            v-model="address"
            maxlength="65"
            @focus="isAddressSelected = false"
          />
          <place-autocomplete-field
            v-else
            @autocomplete-select="selectAddress"
            v-model="form.address"
            name="address"
            api-key="AIzaSyBWj-z1916MmpcimZW3xPlRGXMikForpS4"
            placeholder="Saisissez une adresse, un code postal ou un emplacement"
            country="us"
          ></place-autocomplete-field>
        </b-form-group>
      </b-col>

      <b-col sm="12" md="6" lg="3" xl="3">
        <b-form-group label-size="sm">
          <vue-tel-input
            v-model="form.signatoryTelephone"
            defaultCountry="ca"
            :inputOptions="options"
            :dropdownOptions="telInputOptions"
            :autoFormat="true"
            :validCharactersOnly="true"
            @change="setValue('signatoryTelephone', $event)"
            :value="form.signatoryTelephone"
            mode="international"
          ></vue-tel-input>

          <b-form-invalid-feedback id="input-mobile-phone-live-feedback">
            {{ $t("numericField") }}
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>

      <b-col sm="12" md="6" lg="3" xl="3">
        <b-form-group label-size="sm">
          <b-form-input v-model="form.nameOfsupp" :maxlength="65">
          </b-form-input>
          <b-form-invalid-feedback id="input-companyName-live-feedback">
            {{ $t("requiredTwoCharacters") }}
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>

      <b-col sm="12" md="6" lg="2" xl="2">
        <b-form-group label-size="sm">
          <template slot="label"> </template>
          <b-form-input type="number" v-model="form.nameOfsupp" :maxlength="65">
          </b-form-input>
          <b-form-invalid-feedback id="input-companyName-live-feedback">
            {{ $t("requiredTwoCharacters") }}
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>

      <b-col sm="12" md="4" lg="4" xl="4">
        <b-form-group label-size="sm">
          <template slot="label"> </template>
          <b-form-input
            v-if="isAddressSelected"
            size="md"
            name="street"
            class="mb-3"
            v-model="address"
            maxlength="65"
            @focus="isAddressSelected = false"
          />
          <place-autocomplete-field
            v-else
            @autocomplete-select="selectAddress"
            v-model="form.address"
            name="address"
            api-key="AIzaSyBWj-z1916MmpcimZW3xPlRGXMikForpS4"
            placeholder="Saisissez une adresse, un code postal ou un emplacement"
            country="us"
          ></place-autocomplete-field>
        </b-form-group>
      </b-col>

      <b-col sm="12" md="6" lg="3" xl="3">
        <b-form-group label-size="sm">
          <template slot="label"> </template>

          <vue-tel-input
            v-model="form.signatoryTelephone"
            defaultCountry="ca"
            :inputOptions="options"
            :dropdownOptions="telInputOptions"
            :autoFormat="true"
            :validCharactersOnly="true"
            @change="setValue('signatoryTelephone', $event)"
            :value="form.signatoryTelephone"
            mode="international"
          ></vue-tel-input>

          <b-form-invalid-feedback id="input-mobile-phone-live-feedback">
            {{ $t("numericField") }}
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-row>
    <h5>{{ $t("creditors") }}</h5>
    <b-row class="justify-content-md-center">
      <b-col sm="12" md="6" lg="3" xl="3">
        <b-form-group label-size="sm">
          <template slot="label">
            <span>
              {{ $t("nameOfPrincipalSuppliers") }}
            </span>
          </template>
          <b-form-input v-model="form.nameOfsupp" :maxlength="65">
          </b-form-input>
          <b-form-invalid-feedback id="input-companyName-live-feedback">
            {{ $t("requiredTwoCharacters") }}
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>

      <b-col sm="12" md="6" lg="2" xl="2">
        <b-form-group label-size="sm">
          <template slot="label">
            <span>
              {{ $t("length") }}
            </span>
          </template>
          <b-form-input type="number" v-model="form.nameOfsupp" :maxlength="65">
          </b-form-input>
          <b-form-invalid-feedback id="input-companyName-live-feedback">
            {{ $t("requiredTwoCharacters") }}
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>

      <b-col sm="12" md="4" lg="4" xl="4">
        <b-form-group label-size="sm">
          <template slot="label">
            {{ $t("address") }}

            <span class="text-danger">*</span>
          </template>
          <b-form-input
            v-if="isAddressSelected"
            size="md"
            name="street"
            class="mb-3"
            v-model="address"
            maxlength="65"
            @focus="isAddressSelected = false"
          />
          <place-autocomplete-field
            v-else
            @autocomplete-select="selectAddress"
            v-model="form.address"
            name="address"
            api-key="AIzaSyBWj-z1916MmpcimZW3xPlRGXMikForpS4"
            placeholder="Saisissez une adresse, un code postal ou un emplacement"
            country="us"
          ></place-autocomplete-field>
        </b-form-group>
      </b-col>

      <b-col sm="12" md="6" lg="3" xl="3">
        <b-form-group label-size="sm" label="Mobile phone">
          <template slot="label">
            <span> Téléphone <span class="text-danger"> *</span> </span>
          </template>

          <vue-tel-input
            v-model="form.signatoryTelephone"
            defaultCountry="ca"
            :inputOptions="options"
            :dropdownOptions="telInputOptions"
            :autoFormat="true"
            :validCharactersOnly="true"
            @change="setValue('signatoryTelephone', $event)"
            :value="form.signatoryTelephone"
            mode="international"
          ></vue-tel-input>

          <b-form-invalid-feedback id="input-mobile-phone-live-feedback">
            {{ $t("numericField") }}
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>

      <b-col sm="12" md="6" lg="3" xl="3">
        <b-form-group label-size="sm">
          <template slot="label"> </template>
          <b-form-input v-model="form.nameOfsupp" :maxlength="65">
          </b-form-input>
          <b-form-invalid-feedback id="input-companyName-live-feedback">
            {{ $t("requiredTwoCharacters") }}
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>

      <b-col sm="12" md="6" lg="2" xl="2">
        <b-form-group label-size="sm">
          <template slot="label"> </template>
          <b-form-input type="number" v-model="form.nameOfsupp" :maxlength="65">
          </b-form-input>
          <b-form-invalid-feedback id="input-companyName-live-feedback">
            {{ $t("requiredTwoCharacters") }}
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>

      <b-col sm="12" md="4" lg="4" xl="4">
        <b-form-group label-size="sm">
          <b-form-input
            v-if="isAddressSelected"
            size="md"
            name="street"
            class="mb-3"
            v-model="address"
            maxlength="65"
            @focus="isAddressSelected = false"
          />
          <place-autocomplete-field
            v-else
            @autocomplete-select="selectAddress"
            v-model="form.address"
            name="address"
            api-key="AIzaSyBWj-z1916MmpcimZW3xPlRGXMikForpS4"
            placeholder="Saisissez une adresse, un code postal ou un emplacement"
            country="us"
          ></place-autocomplete-field>
        </b-form-group>
      </b-col>

      <b-col sm="12" md="6" lg="3" xl="3">
        <b-form-group label-size="sm">
          <vue-tel-input
            v-model="form.signatoryTelephone"
            defaultCountry="ca"
            :inputOptions="options"
            :dropdownOptions="telInputOptions"
            :autoFormat="true"
            :validCharactersOnly="true"
            @change="setValue('signatoryTelephone', $event)"
            :value="form.signatoryTelephone"
            mode="international"
          ></vue-tel-input>

          <b-form-invalid-feedback id="input-mobile-phone-live-feedback">
            {{ $t("numericField") }}
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>

      <b-col sm="12" md="6" lg="3" xl="3">
        <b-form-group label-size="sm">
          <b-form-input v-model="form.nameOfsupp" :maxlength="65">
          </b-form-input>
          <b-form-invalid-feedback id="input-companyName-live-feedback">
            {{ $t("requiredTwoCharacters") }}
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>

      <b-col sm="12" md="6" lg="2" xl="2">
        <b-form-group label-size="sm">
          <template slot="label"> </template>
          <b-form-input type="number" v-model="form.nameOfsupp" :maxlength="65">
          </b-form-input>
          <b-form-invalid-feedback id="input-companyName-live-feedback">
            {{ $t("requiredTwoCharacters") }}
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>

      <b-col sm="12" md="4" lg="4" xl="4">
        <b-form-group label-size="sm">
          <template slot="label"> </template>
          <b-form-input
            v-if="isAddressSelected"
            size="md"
            name="street"
            class="mb-3"
            v-model="address"
            maxlength="65"
            @focus="isAddressSelected = false"
          />
          <place-autocomplete-field
            v-else
            @autocomplete-select="selectAddress"
            v-model="form.address"
            name="address"
            api-key="AIzaSyBWj-z1916MmpcimZW3xPlRGXMikForpS4"
            placeholder="Saisissez une adresse, un code postal ou un emplacement"
            country="us"
          ></place-autocomplete-field>
        </b-form-group>
      </b-col>

      <b-col sm="12" md="6" lg="3" xl="3">
        <b-form-group label-size="sm">
          <template slot="label"> </template>

          <vue-tel-input
            v-model="form.signatoryTelephone"
            defaultCountry="ca"
            :inputOptions="options"
            :dropdownOptions="telInputOptions"
            :autoFormat="true"
            :validCharactersOnly="true"
            @change="setValue('signatoryTelephone', $event)"
            :value="form.signatoryTelephone"
            mode="international"
          ></vue-tel-input>

          <b-form-invalid-feedback id="input-mobile-phone-live-feedback">
            {{ $t("numericField") }}
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
export default {
  props: ["form"],
  data() {
    return {
      isAddressSelected: false,
      address: null,
      options: {
        type: "tel",
        validCharactersOnly: true,
        maxlength: 14,
      },
      telInputOptions: {
        showDialCodeInList: true,
        showFlags: true,
        showDialCodeInSelection: false,
      },
    };
  },
  methods: {
    selectAddress() {
      this.isAddressSelected = true;
    },
  },
};
</script>

<style>
</style>