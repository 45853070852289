export const GET_USERS_BUYER = "GET_USERS_BUYER";
export const GET_USERS_BUYER_SUCCESS = "GET_USERS_BUYER_SUCCESS";
export const GET_USERS_BUYER_ERROR = "GET_USERS_BUYER_ERROR";

export const PUT_USER_BUYER = "PUT_USER_BUYER_BUYER";
export const PUT_USER_BUYER_SUCCESS = "PUT_USER_BUYER_SUCCESS";
export const PUT_USER_BUYER_ERROR = "PUT_USER_BUYER_ERROR";

export const DELETE_USER_BUYER = "DELETE_USER_BUYER_BUYER";
export const DELETE_USER_BUYER_SUCCESS = "DELETE_USER_BUYER_SUCCESS";
export const DELETE_USER_BUYER_ERROR = "DELETE_USER_BUYER_ERROR";

export const PUT_USER_BUYER_PASSWORD = "PUT_USER_BUYER_BUYER_PASSWORD";
export const PUT_USER_BUYER_PASSWORD_SUCCESS =
  "PUT_USER_BUYER_PASSWORD_SUCCESS";
export const PUT_USER_BUYER_PASSWORD_ERROR = "PUT_USER_BUYER_PASSWORD_ERROR";
