<template>
  <div class="container">
    <div class="text-center">
      <h3>{{ $t("internalValidation") }}</h3>
    </div>
    <div class="row justify-content-end mr-2">
      <h5>
        <strong> 789632166 </strong>
      </h5>
    </div>
    <b-row class="mb-5">
      <b-col xs="12" sm="12" md="12" lg="6" xl="6">
        <b-card>
          <span>
            <h3>{{ $tc("companyValidations") }}</h3>
          </span>
          <hr class="hr" />

          <div class="m-4 text-break">
            {{ $tc("legalName") }}
            <b-form-checkbox name="checkbox-5" :value="1" :unchecked-value="0">
              Company Car
            </b-form-checkbox>
            <br />
            SAAQ:
            <b-form-checkbox
              id="checkbox-1"
              name="checkbox-1"
              :value="1"
              :unchecked-value="0"
            >
              87913216798465 </b-form-checkbox
            ><br />
            <span>NEQ </span>

            <b-form-checkbox
              id="checkbox-3"
              name="checkbox-3"
              :value="1"
              :unchecked-value="0"
            >
              97984765132196
            </b-form-checkbox>
          </div>
        </b-card>
      </b-col>
      <b-col xs="12" sm="12" md="12" lg="6" xl="6">
        <b-card>
          <hr class="hr mt-5" />
          <div class="m-4 text-break">
            <b-form-checkbox name="checkbox-5" :value="1" :unchecked-value="0">
              Pre-approval
            </b-form-checkbox>
            <br />

            <b-form-checkbox
              id="checkbox-8"
              name="checkbox-8"
              :value="1"
              :unchecked-value="0"
            >
              Dealer acceptance </b-form-checkbox
            ><br />
          </div>
        </b-card>
      </b-col>
    </b-row>

    <b-row align-h="center">
      <b-col xs="12" sm="12" md="12" lg="3" xl="3" class="mt-2">
        <b-btn
          block
          size="lg"
          class="btn btn-light-blue text-white"
          type="button"
          @click="showModal('notes-modal')"
        >
          {{ $t("notes") }}
          <b-icon icon="card-text"></b-icon> </b-btn
      ></b-col>
      <b-col xs="12" sm="12" md="12" lg="3" xl="3" class="mt-2">
        <b-btn
          block
          size="lg"
          class="btn btn-warning text-white"
          type="button"
          @click="showModal('negotiation-modal')"
        >
          {{ $t("negotiation") }}

          <b-icon icon="blockquote-left"></b-icon>
        </b-btn>
      </b-col>
    </b-row>

    <b-row class="mt-5 mb-3">
      <b-col xs="12" sm="12" md="12" lg="6" xl="6" class="mt-2"> </b-col>
      <b-col xs="12" sm="12" md="12" lg="3" xl="3" class="mt-2">
        <b-btn
          block
          size="lg"
          class="btn btn-danger text-white"
          type="button"
          @click="showModal('negotiation-modal')"
        >
          {{ $t("reject") }}
        </b-btn>
      </b-col>
      <b-col xs="12" sm="12" md="12" lg="3" xl="3" class="mt-2">
        <b-btn
          block
          size="lg"
          class="btn btn-success text-white"
          type="button"
          @click="showModal('negotiation-modal')"
        >
          {{ $t("approve") }}
        </b-btn>
      </b-col>
    </b-row>

    <Notes />
  </div>
</template>

<script>
import Notes from "@/modules/shared/notes/NotesModal.vue";
import { generalMixin } from "@/modules/mixin.js";

export default {
  components: { Notes },
  mixins: [generalMixin],

  data() {
    return {
      formValidation: null,
    };
  },
};
</script>

<style>
</style>