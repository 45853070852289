/** @format */

import { SET_NOTES_FILTERS, GET_NOTES } from './actions'
const notesMixin = {
  data() {
    return {}
  },
  methods: {
    openNotesModal() {
      this.$bvModal.show('notes-modal')
    },

    getNotes(resetFilters) {
      this.$store.commit(SET_NOTES_FILTERS, {
        reset: resetFilters
      })
      this.$store
        .dispatch(GET_NOTES)
        .then(() => {})
        .catch(() => {
          this.toastMessage('Notes could not obtained', 'Warning', 'warning')
        })
    }
  }
}

export { notesMixin }
