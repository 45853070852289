<template>
  <header>
    <b-navbar
      class="pt-3 text-grey"
      toggleable="lg"
      :key="configuration.data.logo"
      variant="white"
    >
      <b-navbar-brand
        :to="{ name: 'home', params: { company: $route.params.company } }"
      >
        <img
          :src="
            configuration.data.logo != ''
              ? configuration.data.logo
              : '/img/logo.1de2c45f.png'
          "
          width="250"
          height="auto"
          class="ml-1 mb-2 mt-2"
        />
      </b-navbar-brand>
      <b-nav class="w-100 d-none d-lg-inline-flex" pills align="end">
        <b-nav-item
          v-for="link in filterLinks"
          v-bind:key="link.routeName"
          :disabled="link.isDisabled"
          :active="$route.name && $route.name.includes(link.includesActive)"
          :to="{ name: link.routeName }"
        >
          <span class="text-grey">
            {{ link.display }}
          </span>
        </b-nav-item>

        <!-- Login button -->

        <b-button
          v-if="!this.profile"
          variant="none"
          pill
          class="btn-blue btn-lg ml-3"
          @click="login"
        >
          <span class="mr-3 ml-3">
            <b-icon
              icon="box-arrow-in-right"
              variant="white"
              class="mr-1"
            ></b-icon>
            Se connecter
          </span>
        </b-button>

        <b-button
          v-if="!this.profile"
          variant="none"
          pill
          class="btn btn-outline-blue btn-lg ml-3"
          @click="goToRegister()"
        >
          <span class="mr-3 ml-3"> S'inscrire </span>
        </b-button>
        <!-- Logout button -->
        <b-button
          v-else
          variant="none"
          class="btn btn-outline-danger btn-lg ml-3"
          @click="logout"
        >
          <span class="">Logout</span>
        </b-button>
      </b-nav>
      <b-navbar-toggle target="none" v-b-toggle.sidebar-menu v-if="isShowMenu">
      </b-navbar-toggle>
    </b-navbar>
    <b-sidebar
      id="sidebar-menu"
      title=""
      bg-variant="white"
      text-variant="black"
    >
      <template v-slot:title class="text-center">
        <img
          src="@/assets/img/logos/logo.png"
          width="98%"
          height="auto"
          class="image-responsive"
        />
      </template>

      <b-nav vertical class="text-center text-light">
        <b-nav-item
          v-for="link in filterLinks"
          v-bind:key="link.routeName"
          :disabled="link.isDisabled"
          :active="$route.name && $route.name.includes(link.includesActive)"
          :to="{ name: link.routeName }"
        >
          <span class="text-purple-blue">
            {{ link.display }}
          </span>
        </b-nav-item>
      </b-nav>

      <template v-slot:footer>
        <div
          v-if="profile"
          class="d-flex bg-white text-light justify-content-between align-items-center px-3 py-2"
        >
          <!-- Logout button -->
          <b-button
            pill
            variant="none"
            class="btn btn-outline-danger btn-lg ml-3"
            @click="logout"
          >
            <span class="">Logout</span>
          </b-button>
        </div>
        <div
          v-else
          class="d-flex text-light justify-content-between align-items-center px-3 py-2"
        >
          <!-- Login button -->
          <b-button
            pill
            variant="none"
            class="btn btn-blue btn-lg ml-3"
            @click="login"
          >
            <span class="mr-3 ml-3">
              <b-icon
                icon="box-arrow-in-right"
                variant="white"
                class="mr-1"
              ></b-icon>
              Se connecter
            </span>
          </b-button>

          <b-button
            variant="none"
            class="btn btn-outline-blue btn-lg ml-3"
            @click="goToRegister()"
          >
            <span class="mr-3 ml-3"> S'inscrire </span>
          </b-button>
        </div>
      </template>
    </b-sidebar>
    <Login />
  </header>
</template>

<script>
import { mapState } from "vuex";
import appRoles from "../../helpers/appRoles";
import Login from "@/modules/auth/login/LoginForm";
import { AUTH_LOGOUT } from "@/modules/auth/login/actions";
import { GET_CONFIG_COMPANY } from "@/modules/dealership/siteConfig/actions";
import store from "@/store";
export default {
  props: {
    isShowMenu: {
      value: true,
    },
  },
  components: { Login },
  data() {
    return {
      superAdminLinks: [],
      adminLinks: [
        {
          display: "Companies",
          routeName: "admin.users.validate",
          includesActive: "Companies",
        },
      ],
      companyLinks: [],
      clientLinks: [
        {
          display: "List Vehicles",
          routeName: "client.car-list",
          includesActive: "List Vehicles",
        },
        {
          display: "Users",
          routeName: "buyer.users",
          includesActive: "Users",
        },
      ],

      buyerLinks: [
        {
          display: "List Vehicles",
          routeName: "buyer.car-list",
          includesActive: "List Vehicles",
        },
      ],
      internalBuyerLinks: [
        {
          display: "List Vehicles",
          routeName: "internal-buyer.vehicles.list",
          includesActive: "List Vehicles",
        },
      ],
      trackingLinks: [],
    };
  },
  computed: {
    ...mapState({
      profile: (state) => state.auth.loginStore.profile.data,
      configuration: (state) =>
        state.dealership.siteConfiguration.configuration.data,
    }),
    filterLinks() {
      let links = [];
      if (this.profile && this.profile.user.roles.length) {
        const [superAdmin, admin, buyer, client, internalBuyer] = appRoles;

        this.profile.user.roles.forEach((value, index) => {
          switch (this.profile.user.roles[index].name) {
            case superAdmin.authority:
              links.push(...this.superAdminLinks); // Add super admin links
              break;
            case admin.authority:
              links.push(...this.adminLinks);
              break;
            case buyer.authority:
              links.push(...this.buyerLinks);
              break;
            case client.authority:
              links.push(...this.clientLinks); // Add buyer links
              break;
            case internalBuyer.authority:
              links.push(...this.internalBuyerLinks); // Add buyer links
              break;
            default:
              links.push();
          }
        });
      } else {
        links = [];
      }
      return links;
    },
  },
  beforeRouteEnter(to, from, next) {
    console.log("to2", to);
    const payload = {
      prefix: this.$route.params.company,
    };
    store
      .dispatch(GET_CONFIG_COMPANY, payload)
      .then((response) => {
        console.log(response);
        next();
      })
      .catch(() => {});
  },
  methods: {
    login() {
      this.$bvModal.show("login-modal");
    },
    logout() {
      this.$store
        .dispatch(AUTH_LOGOUT)
        .then(() => {
          this.$router.push({ name: "home" });
        })
        .catch(() => {
          this.$root.$bvToast.toast("Error to logout", {
            title: "Error",
            variant: "danger",
          });
        });
    },
    getCompanyConfig() {
      const payload = {
        prefix: this.$route.params.company,
      };
      this.$store
        .dispatch(GET_CONFIG_COMPANY, payload)
        .then((response) => {
          console.log(response);
        })
        .catch(() => {});
    },
    goToRegister() {
      if (this.$route.name !== "dealership.create")
        this.$router.push({ name: "dealership.create" });
    },
  },
  beforeMount() {
    this.getCompanyConfig();
  },
};
</script>

<style scoped>
.icon-text {
  font-size: 25px;
}
a:hover {
  text-decoration: none;
  color: grey;
}
.nav-item-color {
  color: grey;
}
.btn {
  border-radius: 50rem !important;
}
</style>
