<template>
  <div class="p-grid">
    <div class="p-col">
      <div class="card">
        <div class="row mb-2">
          <div class="col-lg-8 col-md-6 mt-2">
            <label>{{$t('condition')}}</label>
            <SelectButton
              v-model="status"
              :options="statusOptions"
              optionLabel="text"
              @input="
                resetValues();
                loadingYears();
                findRates();
                calculateResidualValue();
              "
            />
          </div>
          <div class="col-lg-4 col-md-6 mt-2">
            {{this.type}}
            <label>{{$t('type')}}</label><br/>
            <Dropdown
              class="form-control"
              style="padding:0"
              id="type"
              v-model="type"
              :options="typeOptions"
              optionLabel="text"
              :placeholder="$t('select_type')"
              :disabled="status == null"
              @change="
                eraseSelectionPrev();
                getCarRates($event);
                calculateResidualValue();
              "
            />
          </div>
        </div>
        <div class="p-formgrid p-grid" v-if="vinEnabled">
        <div
            class="p-field p-col"
            v-if="status != null && status.value == 'used'"
          >
            <label for="kilometers">{{ $t("kilometers") }}</label> <br/>
            <InputNumber
              class="p-inputtext-md"
              style="width:100%"
              id="kilometers"
              v-model="kilometers"
              suffix=" km"
              :disabled="!type"
            />
        </div>
          <div class="p-field p-col">
            <label for="vin">VIN</label><br/>
            <InputText
              class="p-inputtext-md"
              style="width:100%"
              id="vin"
              v-model="vin"
              placeholder="VIN"
              :disabled=isDisabled
              @input="checkVin()"
            />
          </div>
          <div class="p-field p-col" v-if="permit == 1">
            <label for="increasedInterest"> 
              <span v-if="increaseActive">{{$t('code')}} {{limit_code}}</span> <span v-else>Code</span></label>
            <br />
            <InputNumber
              class="p-inputtext-md"
              style="width:100%"
              mode="decimal"
              :minFractionDigits="2"
              :min=0
              :max=limit_code
              id="increasedInterest"
              v-model="incrementValue"
              :placeholder="$t('code')"
              @input="findRates();calculateResidualValue();"
            />
          </div>
        </div>
        <div class="p-formgrid p-grid">
          <div class="p-field p-col">
            <label for="year">{{ $t("year") }}</label><br/>
            <Dropdown
              id="year"
              v-model="year"
              :options="yearOptions"
              optionLabel="text"
              :placeholder="$t('select_year')"
              style="padding:0"
              class="form-control"
              @change="loadingModels()"
              :disabled="!type"
            />
          </div>
          <div class="p-field p-col">
            <label for="make">{{ $t("brand") }}</label> <br/>
            <Dropdown
              id="make"
              v-model="make"
              :options="markesOptions"
              optionLabel="text"
              :placeholder="$t('select_mark')"
              style="padding:0"
              class="form-control"
              @change="loadingModels()"
              :disabled="!type"
            />
          </div>
          <div class="p-field p-col">
            <label for="model">{{ $t("model") }}</label> <br/>
            <Dropdown
              id="model"
              v-model="model"
              :options="modelOptions"
              optionLabel="text"
              :placeholder="$t('select_model')"
              class="form-control"
              style="padding:0"
              :disabled="!type"
            />
          </div>
        </div>
        <div class="p-formgrid p-grid">
          <div class="p-field p-col">
            <label for="capitalCost"
              >{{ $t("capitalCost") }}
              <i
                class="pi pi-info-circle"
                v-tooltip="$t('seeReferral')"
                @click="showReferralPrice = true"
                style="cursor: pointer"
              ></i>
            </label>
            <InputNumber
              id="capitalCost"
              v-model="capitalCost"
              style="width: 100%;"
              mode="currency"
              currency="USD"
              locale="en-US"
              class="p-inputtext-md"
              @input="calculateResidualValue()"
            />
          </div>
          <div class="p-field p-col">
            <label for="residualValue">{{ $t("residualValue") }}</label> <br/>
            <InputNumber
              id="residualValue"
              v-model="residualValue"
              mode="currency"
              currency="USD"
              locale="en-US"
              class="p-inputtext-md"
              style="width: 100%;"
              :max="maxResidualValue"
            />
          </div>
          <div class="p-field p-col">
            <label for="amountAmorticed">{{ $t("amountAmorticed") }}</label> <br/>
            <InputNumber
              id="amountAmorticed"
              v-model="amountAmorticed"
              mode="currency"
              currency="USD"
              locale="en-US"
              cl
              style="width: 100%;"
            />
          </div>
        </div>
        <div class="p-formgrid p-grid">
          <div class="p-field p-col">
            <label for="term">{{$t('term')}} ( {{ term }} {{$t('months_c')}} )</label> <br/>
            <Dropdown
              id="model"
              v-model="term"
              :options="termsOptions"
              optionValue="value"
              optionLabel="text"
              :placeholder="$t('select_terms')"
              class="form-control"
              style="padding:0"
              @change="
                findRates();
                calculateResidualValue();
              "
            />
            <!-- Slider
              id="term"
              v-model="term"
              :step="12"
              :min="terms.min"
              :max="terms.max"
              @input="
                findRates();
                calculateResidualValue();
              "
              class="p-mt-2"
            /-->
          </div>
        </div>
        <div
          class="p-formgrid p-grid p-justify-center p-mt-3"
          v-if="capitalCost > 0 && interestRate > 0"
        >
          <span class="total">{{ data[0].Versement | currency }} </span>
        </div>
        <div class="p-formgrid p-grid p-mt-3 p-justify-end">
          <div class="p-field p-col-4" style="text-align: right;">
            <Button
              :label="$t('createDeal')"
              icon="pi pi-check-circle"
              @click="openCustomerModal"
              :disabled="!deal"
              v-if="buttonSend"
            ></Button>
          </div>
        </div>
      </div>
    </div>
    <Dialog :header="$t('referralTitle')" :visible.sync="showReferralPrice">
      <TableRef :financialReferralPriceInfo="pricesInfo" :refShow="refShow"/>
    </Dialog>
    <CustomerForm :deal="deal" ref="customerModal"/>
  </div>
</template>
<script>
import CarServices from "@/services/CarServices";
import CustomerForm from "@/modules/shared/calculator/CustomerForm.vue";
import { mapState, mapActions } from "vuex";
import { integer } from "vuelidate/lib/validators";
import TableRef from "@/modules/shared/calculator/TableRef.vue";
export default {
  components: { CustomerForm, TableRef },
  props: {
    fieldsP : null ,
    permit: {
      type: integer,
      default: 0
    },
    buttonSend:{
      type: Boolean,
      default: false,
    },
    number: {
      type: integer,
      default: 0
    },
    vinP: {
      type: String,
      default: null,
    },
    yearP: {
      type: String,
      default: null,
    },
    makeP: {
      type: String,
      default: null,
    },
    modelP: {
      type: String,
      default: null,
    },
    refShow: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      fields : this.fieldsP,
      vin: this.vinP,
      year: this.yearP,
      make: this.makeP,
      model: this.modelP,
      status: null,
      type: null,
      showReferralPrice: null,
      kilometers: 0,
      capitalCost: 0,
      interestRate: 0,
      residualRate: 0,
      increaseRate: 0,
      increaseActive: true,
      incrementValue: 0,
      residualValue: 0,
      maxResidualValue: 0,
      term: 24,
      rates: [],
      referralPrices: [],
      pricesInfo: [],
      item_cal: {},
      markesOptions: [],
      modelOptions: [],
      limit_code: 0,
      dealString: {},
      yearOptions: [],
      typeOptions: [
        { value: null, text:  this.$t('select_type') },
        { value: "Pickup", text: this.$t("type_vehicule_pickup") },
        { value: "Light truck", text: this.$t("type_vehicule_lighttruck") },
        { value: "Heavy truck", text: this.$t("type_vehicule_heavytruck") },
        { value: "Car", text: this.$t("type_vehicule_car") },
        { value: "SUV", text: this.$t("type_vehicule_suv") },
      ],
      termsOptions: [
        { value: 24, text: 24 },
        { value: 36, text: 36 },
        { value: 48, text: 48 },
        { value: 60, text: 60 }
      ]
    };
  },
  created() {
    // props are exposed on `this`
  },
  mounted(){
    this.loadingMarke();
  },
  computed: {
    ...mapState(["carRates"]),
    numericType() {
      switch (this.type.value) {
        case "Pickup":
          return 1
        case "Light truck": 
          return 2
        case "Heavy truck":
          return 3
        case "Car":
          return 4
        case "SUV":
          return 5
      }
    },
    deal() {
      return this.data[0].Versement > 0 ? 
        {
          condition: this.status.value,
          type: this.numericType,
          year: this.year.value,
          make: this.make.value,
          model: this.model.value,
          capitalCost: this.capitalCost,
          residual: this.residualRate,
          residualValue: this.residualValue,
          interestRate: this.increaseActive ? this.interestRate - this.increaseRate : this.interestRate,
          customerInterestRate: this.interestRate,
          amortizeAmount: this.amountAmorticed,
          monthlyPayment: parseFloat(this.data[0].Versement),
          terms: this.term,
          refShow: false,
        } 
        : 
        null
    },
    statusOptions() {
      return [
        { value: "new", text: this.$t("new") },
        { value: "used", text: this.$t("used") },
      ];
    },
    terms() {
      if (this.rates.length) {
        const terms = this.carRates.map((v) => {
          return v.months;
        });
        return { min: Math.min(...terms), max: Math.max(...terms) };
      } else {
        return { min: 24, max: 60 };
      }
    },
    isDisabled() {
      if(this.kilometers == 0 && this.status.value == "used"){
        return true;
      }else{
        return false;
      }
    },
    vinEnabled() {
      return (
        (this.type != null && this.type.value == "Car") ||
        (this.type != null && this.type.value == "Pickup")
      );
    },
    loadingModel(){

    },
    amountAmorticed() {
      return this.capitalCost - this.residualValue;
    },
    firstInterest() {
      return ((((this.interestRate)/ 100) * this.residualValue) / 12).toFixed(2);
    },
    interestPeriod() {
      return this.interestRate / 12 / 100;
    },
    interestPercent() {
      return this.interestRate / 100;
    },
    PMT() {
      return -((parseFloat(this.capitalCost) + ((parseFloat(this.capitalCost) + -this.residualValue) / (((1 + this.interestPeriod) ** this.term) - 1))) * (-this.interestPeriod / (1 + this.interestPeriod))).toFixed(2)
    },
    sumInterest() {
      let capital = 0;
      let interest = 0;
      let sum = 0;
      let solde = 0;
      for (let i = 1; i <= this.term; i++) {
        if (i == 1) {
          capital = this.PMT;
          interest = parseFloat(this.firstInterest);
          sum = sum + interest;
          solde = this.capitalCost - capital;
        } else {
          interest = ((solde * this.interestPercent) / 12).toFixed(2) * 1;
          capital = this.PMT - interest;
          solde = solde - capital;
          sum = sum + interest;
        }
      }
      return sum;
    },
    payment() {
      return ((this.amountAmorticed + this.sumInterest) / this.term).toFixed(2);
    },
    sumTerm() {
      let sum = 0;
      for (let i = 1; i <= this.term; i++) {
        sum += i;
      }
      return sum;
    },
    data() {
      let rows = [];
      let capital = 0;
      let interest = 0;
      let interestPercent = 0;
      let counter = 0;
      for (let i = this.term; i > 0; i--) {
        interestPercent = i / this.sumTerm;
        interest = interestPercent * this.sumInterest;
        capital = this.payment - interest;
        counter += 1;
        let total = (capital + interest).toFixed(2);
        rows.push({
          Termes: counter,
          Versement: total,
          Capital: capital.toFixed(2),
          Intérêt: interest.toFixed(2),
        });
      }
      return rows;
    },
  },
  methods: {
    ...mapActions(["fetchCarRates", "loadMarke", "loadModels"]),
    async getCarRates(carType) {
      await this.fetchCarRates(carType.value.value);
      this.rates = this.carRates;
      this.findRates();
    },
    resetValues(){
      this.kilometers = 0;
      this.year = "";
      this.vin = "";
      this.year = "";
      this.make = "";
      this.model = "";
    },
    loadingYears(){
      this.yearOptions = [];
      this.yearOptions = [{ value: null, text: this.$t('select_year') }];
      if(this.status.value == "used"){
        const currentYear = new Date().getFullYear();
        const oldYear = currentYear-5;
        for (let i = currentYear; i >= oldYear; i--) {
          this.yearOptions.push({ value: i, text: i });
        }
      }else{
        const currentYear = new Date().getFullYear();
        const oldYear = currentYear+1;
        for (let i = currentYear; i <= oldYear; i++) {
          this.yearOptions.push({ value: i, text: i });
        }
      }
    },
    loadingMarke() {
      const payload = {
        type: this.type,
        year: this.year
      }
      this.loadMarke().then((response) => {
          this.markesOptions = response.makes; 
        }).catch((error) => {
          this.$toast.add({
              summary: this.$t('toast_title_error'),
              detail: this.$t('toast_message_error'),
              severity: "error",
              life: 5000
          })
        })
    },
    loadingModels(){
      this.pricesInfo = [];
      this.vin = "";
      const payload = {
        year: this.year.value,
        make: this.make.value
      }
      this.loadModels(payload).then((response) => {
          this.modelOptions = response.models; 
        }).catch((error) => {
          this.$toast.add({
              summary: this.$t('toast_title_error'),
              detail: this.$t('toast_message_error'),
              severity: "error",
              life: 5000
          })
        })
    },
    findRates() {
      if (this.type != null) {
        const rate = this.rates.find((rate) => rate.months == this.term);
        if (this.status.value == "new") {
          this.interestRate = parseFloat(rate.rateNew);
          this.residualRate = parseFloat(rate.new);
          this.increaseRate = parseFloat(rate.increaseNew);
          this.limit_code = parseFloat(rate.increaseNew);
          if (parseFloat(this.incrementValue) != 0)
            this.interestRate += parseFloat(this.incrementValue);
          else
            this.interestRate += parseFloat(rate.increaseNew);
        } else if (this.status.value == "used") {
          this.interestRate = parseFloat(rate.rateUsed);
          this.residualRate = parseFloat(rate.used);
          this.increaseRate = parseFloat(rate.increaseUsed);
          this.limit_code = parseFloat(rate.increaseUsed);
          if (parseFloat(this.incrementValue) != 0 )
            this.interestRate += parseFloat(this.incrementValue);
          else
            this.interestRate += parseFloat(rate.increaseUsed);
        }
      }
    },
    checkVin() {
      if (this.vin.length >= 17) {
        const payload = {
          vinNumber: this.vin,
          condition: this.status.value,
          km: this.kilometers,
        }
        CarServices.getCarVinType(payload).then((response) => {
          const carDetails = response.data[0];
          switch (carDetails.vehicleType) {
            case "Pickup":
              this.type = {value: "Pickup", "text": this.$t("type_vehicule_pickup")};
              break;
            case "Light truck": 
              this.type = {value: "Light truck", "text": this.$t("type_vehicule_lighttruck")};
              break;
            case "Heavy truck":
              this.type = {value: "Heavy truck", "text": this.$t("type_vehicule_heavytruck")};
              break;
            case "Car":
              this.type = {value: "Car", "text": this.$t("type_vehicule_car")};
              break;
            case "SUV":
              this.type = {value: "Car", "text": this.$t("type_vehicule_suv")};
              break;
          }

          const currentYear = new Date().getFullYear();
          if(carDetails.year < currentYear){
            this.status = {value: "used", "text": this.$t('used')}
          }if(carDetails.year > currentYear){
            this.status = {value: "new", "text": this.$t('new')}
          }

          /*this.year = {
            value: parseInt(carDetails.year),
            text: parseInt(carDetails.year),
          };*/
          this.make = { value: carDetails.make, text: carDetails.make };
          //this.loadingModelsVIN(carDetails.model);
          this.loadingYearsVIN(carDetails.year);
          //this.capitalCost = carDetails.suggestedPrice; //ADD SUGGESTED PRICE IN TABLE*
          this.pricesInfo = carDetails.priceInfo;
          //this.refShow = true;
          //this.model = { value: carDetails.model, text: carDetails.model };
          //this.calculateResidualValue();
        });
      }
    },
    loadingYearsVIN(year){
      this.loadingYears();
    },
    loadingModelsVIN(model){
      const payload = {
        year: this.year.value,
        make: this.make.value
      }
      this.loadModels(payload).then((response) => {
          this.modelOptions = response.models; 
          let option = { value: model, text: model };
          this.modelOptions.push(option);
        }).catch((error) => {
          this.$toast.add({
              summary: this.$t('toast_title_error'),
              detail: this.$t('toast_message_error'),
              severity: "error",
              life: 5000
          })
        })
    },
    eraseSelectionPrev(){
      this.year = "";
      this.vin = "";
      this.pricesInfo; 
      this.make = "";
      this.model = "";
    },
    calculateResidualValue() {
      if (this.type != null) {
        this.maxResidualValue = parseFloat(
          (
            this.capitalCost -
            this.capitalCost * (this.residualRate / 100)
          ).toFixed(2)
        );
        this.residualValue = this.maxResidualValue;
      }
      this.updateCalculatriceModel()
    },
    openCustomerModal() {
      this.$refs.customerModal.openModal();
    },
    changeValueInput(){
      
    },
    updateCalculatriceModel(){
      this.dealString = this.deal;
        this.item_cal = {
          "number": this.number,
          "deal": this.deal
        }
        this.$parent.updateCalculatriceModel(this.item_cal)
    }
  },
};

</script>
<style lang="scss" scoped>
.total {
  color: #ffffff;
  font-size: 2.3rem;
  padding: 7px 14px;
  border-radius: 3px;
  background-color: #20d077;
}

</style>
