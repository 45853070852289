<template>
  <router-view>
  </router-view>
</template>

<script>
export default {
  name: "BaseAuth",
};
</script>

<style></style>
