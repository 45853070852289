<template>
  <b-card>
    <b-row class="justify-content-md-center">
      <b-col xs="12" sm="12" md="1" lg="1" xl="1"></b-col>

      <b-col xs="12" sm="12" md="10" lg="10" xl="10" class="">
        <b-row>
          <b-col xs="12" sm="12" md="10" lg="10" xl="10">
            <h2>{{ $t("financialInformation") }}</h2>
          </b-col>

          <!-- Company revenue -->
          <b-col align-self="center" xs="12" sm="12" md="3" lg="3" xl="3">
            <b-form-group label-size="sm">
              <template slot="label">
                <span>
                  {{ $t("companyRevenue") }}
                </span>
              </template>
              <b-form-input v-model="form.companyRevenue" :maxlength="65">
              </b-form-input>
              <b-form-invalid-feedback id="input-companyName-live-feedback">
                {{ $t("requiredTwoCharacters") }}
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>

          <!-- Number of employees -->
          <b-col align-self="center" xs="12" sm="12" md="3" lg="3" xl="3">
            <b-form-group label-size="sm">
              <template slot="label">
                <span>
                  {{ $t("numberEmployees") }}
                </span>
              </template>
              <b-form-input
                type="number"
                v-model="form.numberEmployess"
                :maxlength="65"
              >
              </b-form-input>
              <b-form-invalid-feedback id="input-companyName-live-feedback">
                {{ $t("requiredTwoCharacters") }}
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>

          <!-- Number of Vehicules in Use -->
          <b-col xs="12" sm="12" md="6" lg="6" xl="6">
            <b-form-group label-size="sm">
              <template slot="label">
                {{ $t("numberVehicles") }}
              </template>
              <b-form-input v-model="form.numberVehicles" :maxlength="65">
              </b-form-input>
              <b-form-invalid-feedback id="input-companyName-live-feedback">
                {{ $t("requiredTwoCharacters") }}
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>

          <!-- Name of Financial Institution -->
          <b-col align-self="center" xs="12" sm="12" md="12" lg="12" xl="12">
            <b-form-group label-size="sm">
              <template slot="label">
                <span>
                  {{ $tc("nameFinancialInstitution") }}
                </span>
              </template>
              <b-form-input
                @change="setValue('companyName', $event)"
                v-model="form.nameFinancialInstitution"
                :maxlength="65"
                readonly
              >
              </b-form-input>
            </b-form-group>
          </b-col>

          <!-- Adress -->
          <b-col sm="12" md="12" lg="6" xl="6">
            <b-form-group label-size="sm">
              <template slot="label">
                {{ $t("address") }}

                <span class="text-danger">*</span>
              </template>
              <b-form-input
                v-if="isAddressSelected"
                size="md"
                name="street"
                class="mb-3"
                v-model="address"
                maxlength="65"
                @focus="isAddressSelected = false"
              />
              <place-autocomplete-field
                v-else
                @autocomplete-select="selectAddress"
                v-model="address"
                name="address"
                api-key="AIzaSyBWj-z1916MmpcimZW3xPlRGXMikForpS4"
                placeholder="Saisissez une adresse, un code postal ou un emplacement"
                country="us"
              ></place-autocomplete-field>
            </b-form-group>
          </b-col>

          <!-- Phone number -->
          <b-col sm="12" md="6" lg="6" xl="6">
            <b-form-group label-size="sm" label="Mobile phone">
              <template slot="label">
                <span> Téléphone <span class="text-danger"> *</span> </span>
              </template>

              <vue-tel-input
                v-model="form.signatoryTelephone"
                defaultCountry="ca"
                :inputOptions="options"
                :dropdownOptions="telInputOptions"
                :autoFormat="true"
                :validCharactersOnly="true"
                @change="setValue('signatoryTelephone', $event)"
                :value="form.signatoryTelephone"
                mode="international"
              ></vue-tel-input>

              <b-form-invalid-feedback id="input-mobile-phone-live-feedback">
                {{ $t("numericField") }}
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>

          <!-- Contact name  -->
          <b-col align-self="center" xs="12" sm="12" md="6" lg="6" xl="6">
            <b-form-group label-size="sm">
              <template slot="label">
                <span>
                  {{ $t("contactName") }}
                </span>
              </template>
              <b-form-input
                type="number"
                v-model="form.contactName"
                :maxlength="65"
              >
              </b-form-input>
              <b-form-invalid-feedback id="input-companyName-live-feedback">
                {{ $t("requiredTwoCharacters") }}
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <!-- Account number 1 -->
          <b-col xs="12" sm="12" md="6" lg="3" xl="3">
            <b-form-group label-size="sm">
              <template slot="label">
                <span>
                  {{ $t("accountNumbers") }}
                </span>
              </template>

              <vue-tel-input
                v-model="form.signatoryTelephone"
                defaultCountry="ca"
                :inputOptions="options"
                :dropdownOptions="telInputOptions"
                :autoFormat="true"
                :validCharactersOnly="true"
                @change="setValue('signatoryTelephone', $event)"
                :value="form.signatoryTelephone"
                mode="international"
              ></vue-tel-input>

              <b-form-invalid-feedback id="input-mobile-phone-live-feedback">
                {{ $t("numericField") }}
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <!-- Account number 2 -->

          <b-col xs="12" sm="12" md="6" lg="3" xl="3">
            <b-form-group label-size="sm">
              <template slot="label">
                <span class="text-white">a </span>
              </template>

              <vue-tel-input
                v-model="form.signatoryTelephone"
                defaultCountry="ca"
                :inputOptions="options"
                :dropdownOptions="telInputOptions"
                :autoFormat="true"
                :validCharactersOnly="true"
                @change="setValue('signatoryTelephone', $event)"
                :value="form.signatoryTelephone"
                mode="international"
              ></vue-tel-input>

              <b-form-invalid-feedback id="input-mobile-phone-live-feedback">
                {{ $t("numericField") }}
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>

          <!-- Name of Financial Institution 2-->
          <b-col align-self="center" xs="12" sm="12" md="12" lg="12" xl="12">
            <b-form-group label-size="sm">
              <template slot="label">
                <span>
                  {{ $tc("nameFinancialInstitution") }}
                </span>
              </template>
              <b-form-input
                @change="setValue('companyName', $event)"
                v-model="form.nameFinancialInstitution2"
                :maxlength="65"
              >
              </b-form-input>
            </b-form-group>
          </b-col>
          <!-- Adress -->
          <b-col sm="12" md="12" lg="6" xl="6">
            <b-form-group label-size="sm">
              <template slot="label">
                {{ $t("address") }}

                <span class="text-danger">*</span>
              </template>
              <b-form-input
                v-if="isAddressSelected"
                size="md"
                name="street"
                class="mb-3"
                v-model="address"
                maxlength="65"
                @focus="isAddressSelected = false"
              />
              <place-autocomplete-field
                v-else
                @autocomplete-select="selectAddress"
                v-model="form.address"
                name="address"
                api-key="AIzaSyBWj-z1916MmpcimZW3xPlRGXMikForpS4"
                placeholder="Saisissez une adresse, un code postal ou un emplacement"
                country="us"
              ></place-autocomplete-field>
            </b-form-group>
          </b-col>

          <!-- Phone number -->
          <b-col sm="12" md="6" lg="6" xl="6">
            <b-form-group label-size="sm" label="Mobile phone">
              <template slot="label">
                <span> Téléphone <span class="text-danger"> *</span> </span>
              </template>

              <vue-tel-input
                v-model="form.signatoryTelephone"
                defaultCountry="ca"
                :inputOptions="options"
                :dropdownOptions="telInputOptions"
                :autoFormat="true"
                :validCharactersOnly="true"
                @change="setValue('signatoryTelephone', $event)"
                :value="form.signatoryTelephone"
                mode="international"
              ></vue-tel-input>

              <b-form-invalid-feedback id="input-mobile-phone-live-feedback">
                {{ $t("numericField") }}
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>

          <!-- Contact name  -->
          <b-col align-self="center" xs="12" sm="12" md="6" lg="6" xl="6">
            <b-form-group label-size="sm">
              <template slot="label">
                <span>
                  {{ $t("contactName") }}
                </span>
              </template>
              <b-form-input
                type="number"
                v-model="form.contactName"
                :maxlength="65"
              >
              </b-form-input>
              <b-form-invalid-feedback id="input-companyName-live-feedback">
                {{ $t("requiredTwoCharacters") }}
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <!-- Account number 1 -->
          <b-col xs="12" sm="12" md="6" lg="3" xl="3">
            <b-form-group label-size="sm">
              <template slot="label">
                <span>
                  {{ $t("accountNumbers") }}
                </span>
              </template>

              <vue-tel-input
                v-model="form.signatoryTelephone"
                defaultCountry="ca"
                :inputOptions="options"
                :dropdownOptions="telInputOptions"
                :autoFormat="true"
                :validCharactersOnly="true"
                @change="setValue('signatoryTelephone', $event)"
                :value="form.signatoryTelephone"
                mode="international"
              ></vue-tel-input>

              <b-form-invalid-feedback id="input-mobile-phone-live-feedback">
                {{ $t("numericField") }}
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <!-- Account number 2 -->

          <b-col xs="12" sm="12" md="6" lg="3" xl="3">
            <b-form-group label-size="sm">
              <template slot="label">
                <span class="text-white">a </span>
              </template>

              <vue-tel-input
                v-model="form.signatoryTelephone"
                defaultCountry="ca"
                :inputOptions="options"
                :dropdownOptions="telInputOptions"
                :autoFormat="true"
                :validCharactersOnly="true"
                @change="setValue('signatoryTelephone', $event)"
                :value="form.signatoryTelephone"
                mode="international"
              ></vue-tel-input>

              <b-form-invalid-feedback id="input-mobile-phone-live-feedback">
                {{ $t("numericField") }}
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>

          <br />
        </b-row>
      </b-col>
      <b-col xs="12" sm="12" md="1" lg="1" xl="1"></b-col>
    </b-row>
  </b-card>
</template>

<script>
export default {
  props: ["form"],
  data() {
    return {
      isAddressSelected: false,
      address: null,
      options: {
        type: "tel",
        validCharactersOnly: true,
        maxlength: 14,
      },
      telInputOptions: {
        showDialCodeInList: true,
        showFlags: true,
        showDialCodeInSelection: false,
      },
    };
  },
  methods: {
    selectAddress() {
      this.isAddressSelected = true;
    },
  },
};
</script>

<style>
</style>