<template>
  <div class="p-col-12">
    <div class="card">
      <div class="mb-4 d-flex justify-content-between">
        <div>
          <h1 class="text-primary">{{ $t("users") }}</h1>
        </div>
        <div>
          <Button class="p-button-success" @click="openNew">
            <div class="d-flex align-items-sm-center flex-column">
              <i class="pi pi-plus mx-auto" style="font-size: 1.5rem"></i>
              {{ $t("add_users") }}
            </div>
          </Button>
        </div>
      </div>

      <TableBase
        :items="selectedUsers"
        :fields="fields"
        :busy="loading === 'fetching' ? true : false"
      />

      <Dialog
        :visible.sync="userDialog"
        :style="{ width: '380px' }"
        :header="$t('user_details')"
        :modal="true"
        class="p-fluid"
      >
        <div class="p-field">
          <label for="firstName">{{ $t("first_name") }}</label>
          <span class="p-input-icon-left">
            <i class="pi pi-user" />
            <InputText
              id="firstName"
              v-model.trim="user.firstName"
              required="true"
              autofocus
              :class="{ 'p-invalid': submitted && !user.firstName }"
            />
          </span>
          <small
            class="p-invalid text-danger"
            v-if="submitted && !user.firstName"
            >{{ $t("valid_firstname_required") }}</small
          >
        </div>
        <div class="p-field">
          <label for="lastName">{{ $t("last_name") }}</label>
          <span class="p-input-icon-left">
            <i class="pi pi-user" />
            <InputText
              id="lastName"
              v-model.trim="user.lastName"
              required="true"
              autofocus
              :class="{ 'p-invalid': submitted && !user.lastName }"
            />
          </span>
          <small
            class="p-invalid text-danger"
            v-if="submitted && !user.lastName"
            >{{ $t("valid_lastname_required") }}</small
          >
        </div>
        <div class="p-field">
          <label for="email">{{ $t("c_email") }}</label>
          <span class="p-input-icon-left">
            <i class="pi pi-envelope" />
            <InputText
              id="email"
              v-model.trim="user.email"
              required="true"
              autofocus
              :class="{ 'p-invalid': submitted && !user.email }"
            />
          </span>
          <small
            class="p-invalid text-danger"
            v-if="submitted && !user.email"
            >{{ $t("valid_email_required") }}</small
          >
        </div>
        <div class="p-field">
          <label for="permit">{{ $t("permit_code") }}</label>
          <span class="p-input-icon-left">
            <InputSwitch
              id="permit"
              v-model="user.permit"
              :class="{ 'p-invalid': submitted }"
            />
          </span>
        </div>
        <template #footer>
          <Button
            :label="$t('cancel')"
            icon="pi pi-times"
            class="p-button-danger"
            @click="hideDialog"
          />
          <Button
            :label="$t('save')"
            icon="pi pi-check"
            class="p-button-success"
            @click="saveUser"
          />
        </template>
      </Dialog>
      <Dialog
        :visible.sync="deleteUserDialog"
        :style="{ width: '380px' }"
        :header="$t('confirm')"
        :modal="true"
      >
        <div class="confirmation-content">
          <i
            class="pi pi-exclamation-triangle p-mr-3"
            style="font-size: 2rem"
          />
          <span v-if="user"
            >{{ $t("message_delete") }} <b>{{ user.firstName }}</b
            >?</span
          >
        </div>
        <template #footer>
          <Button
            :label="$t('no')"
            icon="pi pi-times"
            class="p-button-text"
            @click="deleteUserDialog = false"
          />
          <Button
            :label="$t('yes')"
            icon="pi pi-check"
            class="p-button-text"
            @click="deleteUser"
          />
        </template>
      </Dialog>
    </div>
  </div>
</template>
<script>
import FormUser from "@/modules/dealership/users/FormUser.vue";
import TableBase from "@/modules/shared/table.vue";
import { mapState, mapActions } from "vuex";

export default {
  components: { FormUser, TableBase },
  computed: {
    ...mapState(["items"]),
  },
  data() {
    return {
      users: null,
      userDialog: false,
      deleteUserDialog: false,
      deleteUsersDialog: false,
      user: {},
      loading: "fetching",
      selectedUsers: null,
      filters: {},
      filter: "",
      submitted: false,
      totalRecords: 0,
      fields: [
        {
          key: "firstName",
          label: this.$t("firstName"),
          thClass: "bg-header text-dark",
        },
        {
          key: "lastName",
          label: this.$t("lastName"),
          thClass: "bg-header text-dark",
        },
        {
          key: "email",
          label: this.$t("c_email"),
          thClass: "bg-header text-dark",
        },
        {
          key: "actions",
          label: "Actions",
          thClass: "bg-header text-dark",
          note: false,
          editleaser: true,
          delete: true,
          images: false,
        },
      ],
      options: {
        assign: false,
        rolFilter: false,
        add: false,
      },
    };
  },
  created() {
    this.fetchUsers();
  },
  methods: {
    ...mapActions(["loadUserInDS", "postUser", "deleteAgentLeasing"]),
    onPage(event) {
      console.log(event);
    },
    async getData(filter = "") {
      const payload = {
        search: filter,
      };
      this.loadUserInDS(payload).then((response) => {
        this.selectedUsers = response;
        this.loading = "";
      });
    },
    changePage(page, filter = "") {
      const payload = {
        search: filter,
        page: page,
      };
      this.loadUserInDS(payload).then((response) => {
        this.selectedUsers = response;
        this.loading = "";
      });
    },
    fetchUsers(filter = "") {
      const payload = {
        search: filter,
      };
      this.loadUserInDS(payload).then((response) => {
        this.selectedUsers = response;
        this.loading = "";
      });
    },
    openNew() {
      this.user = {};
      this.submitted = false;
      this.userDialog = true;
    },
    hideDialog() {
      this.userDialog = false;
      this.submitted = false;
    },
    confirmDeleteSelected() {
      this.deleteUsersDialog = true;
    },
    saveUser() {
      this.submitted = true;
      const payload = {
        id: this.user.id,
        firstName: this.user.firstName,
        lastName: this.user.lastName,
        email: this.user.email,
        permit: this.user.permit,
        idRoles: "2",
      };
      this.postUser(payload)
        .then((response) => {
          if (
            response.message == "User created" ||
            response.message == "User updated"
          ) {
            this.$toast.add({
              severity: "success",
              summary: this.$t("message_success_title"),
              detail: this.$t("message_success"),
              life: 4000,
            });
            this.submitted = false;
            this.user = {};
            this.hideDialog();
            this.fetchUsers();
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: response[2],
              life: 4000,
            });
            this.hideDialog();
            this.fetchUsers();
          }
        })
        .catch((error) => {
          this.$toast.add({
            severity: "error",
            summary: error,
          });
        });
      this.hideDialog();
    },
    editAgentItem(user) {
      this.user = { ...user };
      this.userDialog = true;
    },
    deleteItem(idDelete) {
      this.deleteAgentLeasing(idDelete.id)
        .then((response) => {
          this.userDeleteDialog = false;
          this.$toast.add({
            severity: "success",
            variant: "success",
            summary: this.$t("message_success_delete_title"),
            detail: this.$t("message_success_delete"),
            life: 4000,
          });
          this.fetchUsers();
        })
        .catch((error) => {
          this.$toast.add({
            summary: this.$t("toast_title_error"),
            detail: this.$t("toast_message_error"),
            severity: "error",
            life: 5000,
          });
          this.userDeleteDialog = false;
        });
      this.fetchUsers();
    },
    confirmDeleteProduct(user) {
      this.user = user;
      this.deleteUserDialog = true;
    },
  },
};
</script>
<style lang="scss" scoped>
.table-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.confirmation-content {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
