<template>
  <div>
    <div class="brand shadow-lg">
      <div class="float-right mt-3 mr-4"><h3>wiitrade</h3></div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Brand",
  props: ["imgWidth", "imgHeight"],
};
</script>
<style lang="scss">
.brand {
  background-color: #12144a;
  height: 20%;
  color: white;
  background-color: #12144a;
  box-shadow: 0 7px 7px 0 rgba(0, 0, 0, 0.5) !important;
}
</style>
