<template>
  <div class="row">
    <div class="card">
      <div class="col-12">
        <h3>Document Validation</h3>
        <DataTable
          ref="dt"
          :rows="10"
          dataKey="id"
          :paginator="true"
          :filters="filters"
          :value="selectedUsers"
          :rowsPerPageOptions="[5, 10, 25]"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        >
          <template #header>
            <div class="table-header">
              <h5 class="p-m-0">Manage Deals</h5>
              <span class="p-input-icon-left">
                <i class="pi pi-search" />
                <InputText placeholder="Search..." />
              </span>
            </div>
          </template>
          <Column selectionMode="multiple" headerStyle="width: 3rem"></Column>
          <Column field="name" :header="$t('name')" sortable></Column>
          <Column>
            <template #body="slotProps">
              <Button
                icon="pi pi-list"
                class="p-button-primary p-mr-2"
                @click="editValidationDocuments(slotProps.data)"
              />
            </template>
          </Column>
        </DataTable>
        <Dialog
          :visible.sync="userDialog"
          :style="{ width: '380px' }"
          :header="$t('validation_documents')"
          :modal="true"
          class="p-fluid"
        >
          <div class="p-field">
            <div class="mt-3">
              <label
                ><a href=""
                  ><i class="pi pi-download"></i> {{ $t("document_1") }}</a
                ></label
              >
              <b-form-select
                v-model="selected_doc1"
                :options="selectStatus"
              ></b-form-select>
            </div>
            <div class="mt-3">
              <label
                ><a href=""
                  ><i class="pi pi-download"></i> {{ $t("document_2") }}</a
                ></label
              >
              <b-form-select
                v-model="selected_doc2"
                :options="selectStatus"
              ></b-form-select>
            </div>
            <div class="mt-3">
              <label
                ><a href=""
                  ><i class="pi pi-download"></i> {{ $t("document_3") }}</a
                ></label
              >
              <b-form-select
                v-model="selected_doc3"
                :options="selectStatus"
              ></b-form-select>
            </div>
          </div>
          <template #footer>
            <Button
              :label="$t('cancel')"
              icon="pi pi-times"
              class="p-button-text"
              @click="hideDialog"
            />
            <Button
              :label="$t('save')"
              icon="pi pi-check"
              class="p-button-text"
            />
          </template>
        </Dialog>
      </div>
    </div>
  </div>
</template>
<script>
import TableBase from "@/modules/shared/table.vue";
import { mapActions, mapState } from "vuex";
export default {
  components: { TableBase },
  data() {
    return {
      users: null,
      user: {},
      userDialog: false,
      selectedUsers: null,
      selected_doc1: null,
      selected_doc2: null,
      selected_doc3: null,
      selectStatus: [
        { value: null, text: "Please select an option" },
        { value: 1, text: "Approved" },
        { value: 2, text: "Rejected" },
      ],
      filters: {},
      submitted: false,
      totalRecords: 0,
      fields: [
        { key: "id", label: this.$t("id") },
        { key: "name", label: this.$t("email") },
      ],
      options: {
        assign: false,
        rolFilter: false,
        add: false,
      },
    };
  },
  created() {
    this.loadingUsers();
  },
  methods: {
    ...mapActions(["loadUsersLeaseAgents"]),
    loadingUsers() {
      this.loadUsersLeaseAgents()
        .then((response) => {
          this.selectedUsers = response;
        })
        .catch((error) => {
          this.$toast.add({
            severity: "error",
            summary: error,
          });
        });
    },
    editValidationDocuments(user) {
      this.user = { ...user };
      this.userDialog = true;
    },
    openNew() {
      this.user = {};
      this.submitted = false;
      this.userDialog = true;
    },
    hideDialog() {
      this.userDialog = false;
      this.submitted = false;
    },
  },
};
</script>
  <style lang="scss" scoped>
.table-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.confirmation-content {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
  