import { render, staticRenderFns } from "./AppProfileAdmin.vue?vue&type=template&id=5533c091&scoped=true&"
import script from "./AppProfileAdmin.vue?vue&type=script&lang=js&"
export * from "./AppProfileAdmin.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5533c091",
  null
  
)

export default component.exports