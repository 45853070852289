<template>
  <div class="m-2">
    <b-row>
      <b-col xs="12" sm="12" md="12" lg="6" xl="6" cols="12" class="mb-2">
        <font-picker
          :api-key="'AIzaSyDF9bb4YPqwwSen998gzG-W9rBEB9gKT1U'"
          :active-font="font"
          @change="changeFont"
        ></font-picker>
      </b-col>
      <b-col xs="12" sm="12" md="12" lg="6" xl="6" cols="12">
        <input class="form-control apply-font" type="text" v-model="signText" />
        <br
      /></b-col>
    </b-row>

    <div class="row mb-2 text-center minimun-size">
      <div class="col-md-12">
        <div>
          <h2 class="apply-font display-3" ref="signText">{{ signText }}</h2>
        </div>
      </div>
    </div>

    <b-row>
      <b-col xs="7" sm="7" md="8" lg="8" xl="8" cols="7">
        <button
          class="btn btn-outline-secondary m-2"
          @click="changeSignMethod()"
        >
          Draw Signature
        </button>
      </b-col>

      <b-col xs="5" sm="5" md="4" lg="4" xl="4" cols="5">
        <button class="btn btn-outline-secondary m-2" @click="gotoPDFView()">
          {{ $t("cancel") }}
        </button>
        <button class="btn btn-blue m-2" @click="save">
          {{ $t("apply") }}
        </button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  data() {
    return {
      font: "",
      signText: "",
    };
  },
  computed: {
    ...mapState({
      url: (state) => state.shared.signature.url,
    }),
  },
  methods: {
    changeSignMethod() {
      this.$emit("sign", "pad");
    },
    changeFont(font) {
      this.font = font.family;
    },
    async save() {
      let el = this.$refs.signText;
      const options = {
        type: "dataURL",
      };
      this.signImage = await this.$html2canvas(el, options);
      if (this.signText) {
        this.$store.commit("setSign", this.signImage);
        this.gotoPDFView();
      }
    },

    gotoPDFView() {
      /*       this.$router.push({ path: `/dealership/pre-approval` });
       */ this.$router.push({ path: `${this.url}` });
    },
  },
};
</script>

<style>
.minimun-size {
  min-height: 100px;
}
</style>
