<template>
  <div class="container-fluid">
    <b-navbar
      toggleable="md"
      variant="light"
      align="end"
      class="nav mt-2 mb-3 shadow"
    >
      <h2>{{ $t("myProfile") }}</h2>
      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
      <b-collapse id="nav-collapse" is-nav align="end">
        <b-navbar-nav class="ml-auto">
          <b-navbar-nav>
            <b-nav-item
              :active="$route.name === 'user.profile.user'"
              :to="{ name: 'user.profile.user' }"
            >
              {{ $t("user") }}
            </b-nav-item>
            <b-nav-item
              :active="$route.name === 'user.profile.company'"
              :to="{ name: 'user.profile.company' }"
            >
              {{ $t("company") }}
            </b-nav-item>
          </b-navbar-nav>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
    <div class="p-3">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
export default {};
</script>
<style></style>
