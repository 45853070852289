import * as constants from "./actions";
import axios from "@/plugins/axios.js";

function getDefaultState() {
  return {
    vehicles: {
      status: null,
      data: [],
      error: null,
      filters: {
        agent: null,
        client: null,
        vinNumber: null,
        location: null,
        search: null,
        make: null,
        model: null,
        trim: null,
        transmissionType: null,
        releaseDate1: null,
        releaseDate2: null,
        year1: null,
        year2: null,
        mile1: null,
        mile2: null,
        price1: null,
        price2: null,
        status: null,
        trackingStatus: null,
        mainOfferId: null,
        new: null,
        vehicleId: null,
        resourcesPerPage: 18,
        page: 1,
      },
    },
    filterAction: {
      action: "GET_VEHICLES",
    },
    vehicleFilters: {
      status: null,
      data: [],
      models: {
        status: null,
        data: [],
        error: null,
      },
      trims: {
        status: null,
        data: [],
        error: null,
      },
      error: null,
      filters: {
        make: null,
        model: null,
      },
    },
    notificationRead: { status: null, data: [], error: null },
  };
}

const actions = {
  [constants.GET_VEHICLES]: async ({ commit, state }) => {
    try {
      commit(constants.GET_VEHICLES);
      const payload = Object.entries(state.vehicles.filters).reduce(
        (a, [k, v]) => (v === null || v === "" ? a : { ...a, [k]: v }),
        {}
      );

      const response = await axios.get(`vehicle`, { params: payload });
      commit(constants.GET_VEHICLES_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.GET_VEHICLES_ERROR, error);
      throw error.response;
    }
  },
};

const mutations = {
  [constants.GET_VEHICLES]: (state) => {
    state.vehicles.status = "fetching";
    state.vehicles.error = null;
  },
  [constants.GET_VEHICLES_SUCCESS]: (state, data) => {
    state.vehicles.status = "success";
    state.vehicles.data = data;
    state.vehicles.error = null;
  },
  [constants.GET_VEHICLES_ERROR]: (state, error) => {
    state.vehicles.status = "error";
    state.vehicles.error = error;
  },
};

export default {
  state: getDefaultState(),
  actions,
  mutations,
};
