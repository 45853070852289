<template>
  <div :class="containerClass" @click="onWrapperClick">
    <AppTopBar @menu-toggle="onMenuToggle" />
    <transition name="layout-sidebar">
      <div
        :class="sidebarClass"
        @click="onSidebarClick"
        v-show="isSidebarVisible()"
      >
        <AppProfile />
        <AppMenu :model="menu" @menuitem-click="onMenuItemClick" />

        <img alt="Logo" :src="logo" class="img" />
      </div>
    </transition>
    <div class="layout-main">
      <router-view></router-view>
    </div>
    <AppFooter />
  </div>
</template>
  
  <script>
import AppTopBar from "@/AppTopbar.vue";
import AppProfile from "@/AppProfileDeal.vue";
import AppMenu from "@/AppMenu.vue";
import AppFooter from "@/AppFooter.vue";

export default {
  name: "Dealership",
  components: { AppTopBar, AppProfile, AppMenu, AppFooter },
  data() {
    return {
      layoutMode: "static",
      layoutColorMode: "light",
      staticMenuInactive: false,
      overlayMenuActive: false,
      mobileMenuActive: false,
      menu: [
        { label: "users", icon: "pi pi-fw pi-users", to: "/dealership/manage" },
        {
          label: "rates",
          icon: "pi pi-fw pi-sliders-h",
          to: "/dealership/rates",
        },
        {
          label: "calculator",
          icon: "pi pi-fw pi-th-large",
          to: "/dealership/calculator",
        },
        { label: "deals", icon: "pi pi-fw pi-dollar", to: "/dealership/deals" },
      ],
    };
  },
  computed: {
    showMenu() {
      return this.$route.name == "dealership.upload-files" ? false : true;
    },
    logo() {
      return this.layoutColorMode === "dark"
        ? require("../assets/img/logos/cb_2.png")
        : require("../assets/img/logos/logo.png");
    },
    sidebarClass() {
      return [
        "layout-sidebar",
        {
          "layout-sidebar-dark": this.layoutColorMode === "dark",
          "layout-sidebar-light": this.layoutColorMode === "light",
        },
      ];
    },
    containerClass() {
      return [
        "layout-wrapper",
        {
          "layout-overlay": this.layoutMode === "overlay",
          "layout-static": this.layoutMode === "static",
          "layout-static-sidebar-inactive":
            this.staticMenuInactive && this.layoutMode === "static",
          "layout-overlay-sidebar-active":
            this.overlayMenuActive && this.layoutMode === "overlay",
          "layout-mobile-sidebar-active": this.mobileMenuActive,
          "p-input-filled": this.$appState.inputStyle === "filled",
          "p-ripple-disabled": this.$primevue.ripple === false,
        },
      ];
    },
  },
  methods: {
    isDesktop() {
      return window.innerWidth > 1024;
    },
    onMenuItemClick(event) {
      if (event.item && !event.item.items) {
        this.overlayMenuActive = false;
        this.mobileMenuActive = false;
      }
    },
    onSidebarClick() {
      this.menuClick = true;
    },
    isSidebarVisible() {
      if (this.isDesktop()) {
        if (this.layoutMode === "static") return !this.staticMenuInactive;
        else if (this.layoutMode === "overlay") return this.overlayMenuActive;
        else return true;
      } else {
        return true;
      }
    },
    onWrapperClick() {
      if (!this.menuClick) {
        this.overlayMenuActive = false;
        this.mobileMenuActive = false;
      }
      this.menuClick = false;
    },
    onMenuToggle() {
      this.menuClick = true;

      if (this.isDesktop()) {
        if (this.layoutMode === "overlay") {
          if (this.mobileMenuActive === true) {
            this.overlayMenuActive = true;
          }
          this.overlayMenuActive = !this.overlayMenuActive;
          this.mobileMenuActive = false;
        } else if (this.layoutMode === "static") {
          this.staticMenuInactive = !this.staticMenuInactive;
        }
      } else {
        this.mobileMenuActive = !this.mobileMenuActive;
      }
      event.preventDefault();
    },
  },
};
</script>
  
<style scope>
.img {
  width: 60%;
  position: absolute;
  bottom: 0;
  margin-bottom: 10%;
  margin-left: 20%;
  margin-right: 20%;
}
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {
  .img {
    margin-bottom: 25%;
  }
}
</style>
  