import * as constants from "./actions";
import axios from "@/plugins/axios.js";

function getDefaultState() {
  return {
    vehicleItem: {
      status: null,
      data: null,
      error: null,
      filters: {
        vinNumber: null,
      },
    },
    usersNicknames: {
      status: null,
      data: null,
      error: null,
    },
  };
}

const actions = {
  [constants.GET_VEHICLE_BY_VIN]: async ({ commit, state }) => {
    try {
      commit(constants.GET_VEHICLE_BY_VIN);
      const payload = Object.entries(state.vehicleItem.filters).reduce(
        (a, [k, v]) => (v === null || v === "" ? a : { ...a, [k]: v }),
        {}
      );
      const response = await axios.get(`vehicle`, { params: payload });
      commit(constants.GET_VEHICLE_BY_VIN_SUCCESS, response.data);
      return response;
    } catch (error) {
      commit(constants.GET_VEHICLE_BY_VIN_ERROR, error);
      throw error.response;
    }
  },
  [constants.GET_USERS_NICKNAMES]: async ({ commit }) => {
    try {
      commit(constants.GET_USERS_NICKNAMES);
      const response = await axios.get(`user/listNickname`);
      commit(constants.GET_USERS_NICKNAMES_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.GET_USERS_NICKNAMES_ERROR, error);
      throw error.response;
    }
  },
  [constants.POST_UPDATE_VEHICLE]: async ({ commit }, data) => {
    try {
      commit(constants.POST_UPDATE_VEHICLE);
      const headers = {
        "Content-Type": "application/form-data",
      };
      const response = await axios.post(`vehicle/update`, data, { headers });
      commit(constants.POST_UPDATE_VEHICLE_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.POST_UPDATE_VEHICLE_ERROR, error);
      throw error.response;
    }
  },
};

const mutations = {
  [constants.SET_VEHICLE_VIN]: (state, filter) => {
    if (filter.reset) {
      state.vehicleItem.filters = {
        vinNumber: null,
      };
    }
    if (filter.fields) {
      state.vehicleItem.filters = {
        ...state.vehicleItem.filters,
        ...filter.fields,
      };
    }
  },
  [constants.GET_VEHICLE_BY_VIN]: (state) => {
    state.vehicleItem.status = "fetching";
    state.vehicleItem.error = null;
  },
  [constants.GET_VEHICLE_BY_VIN_SUCCESS]: (state, data) => {
    state.vehicleItem.status = "success";
    state.vehicleItem.data = data;
    state.vehicleItem.error = null;
  },
  [constants.GET_VEHICLE_BY_VIN_ERROR]: (state, error) => {
    state.vehicleItem.status = "error";
    state.vehicleItem.error = error;
  },
  [constants.GET_USERS_NICKNAMES]: (state) => {
    state.usersNicknames.status = "fetching";
    state.usersNicknames.error = null;
  },
  [constants.GET_USERS_NICKNAMES_SUCCESS]: (state, data) => {
    state.usersNicknames.status = "success";
    state.usersNicknames.data = data;
    state.usersNicknames.error = null;
  },
  [constants.GET_USERS_NICKNAMES_ERROR]: (state, error) => {
    state.usersNicknames.status = "error";
    state.usersNicknames.error = error;
  },
  [constants.POST_UPDATE_VEHICLE]: (state) => {
    state.vehicleItem.status = "fetching";
    state.vehicleItem.error = null;
  },
  [constants.POST_UPDATE_VEHICLE_SUCCESS]: (state, data) => {
    state.vehicleItem.status = "success";
    state.vehicleItem.data = data;
    state.vehicleItem.error = null;
  },
  [constants.POST_UPDATE_VEHICLE_ERROR]: (state, error) => {
    state.vehicleItem.status = "error";
    state.vehicleItem.error = error;
  },
};

export default {
  state: getDefaultState(),
  actions,
  mutations,
};
