/** @format */

export const GET_DEALS_LIST = 'GET_DEALS_LIST'
export const GET_DEALS_LIST_SUCCESS = 'GET_DEALS_LIST_SUCCESS'
export const GET_DEALS_LIST_ERROR = 'GET_DEALS_LIST_ERROR'

export const SET_DEALS_FILTERS = 'SET_DEALS_FILTERS'

export const GET_CUSTOMERS = 'GET_CUSTOMERS'
export const GET_CUSTOMERS_SUCCESS = 'GET_CUSTOMERS_SUCCESS'
export const GET_CUSTOMERS_ERROR = 'GET_CUSTOMERS_ERROR'
