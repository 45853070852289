export const GET_VALIDATIONS = "GET_VALIDATIONS";
export const GET_VALIDATIONS_SUCCESS = "GET_VALIDATIONS_SUCCESS";
export const GET_VALIDATIONS_ERROR = "GET_VALIDATIONS_ERROR";

export const UPDATE_VALIDATIONS = "UPDATE_VALIDATIONS";
export const UPDATE_VALIDATIONS_SUCCESS = "UPDATE_VALIDATIONS_SUCCESS";
export const UPDATE_VALIDATIONS_ERROR = "UPDATE_VALIDATIONS_ERROR";


export const PUT_COMPANY_STATUS = 'PUT_COMPANY_STATUS'
export const PUT_COMPANY_STATUS_SUCCESS = 'PUT_COMPANY_STATUS_SUCCESS'
export const PUT_COMPANY_STATUS_ERROR = 'PUT_COMPANY_STATUS_ERROR'