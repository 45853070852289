/** @format */

import * as constants from './actions'
import axios from '@/plugins/axios.js'
import CustomerService from '@/services/CustomerService.js'
import DealService from '../../../services/DealService'

function getDefaultState() {
  return {
    user: {
      status: null,
      data: null,
      error: null
    },

    emailValidation: {
      status: null,
      data: null,
      error: null,
      message: ''
    },

    customer: {
      firstName: null,
      lastName: null,
      company: null,
      email: null,
      phoneNumber: null
    },

    deal: {
      condition: null,
      type: null,
      year: null,
      make: null,
      model: null,
      capitalCost: null,
      residual: null,
      interestRate: null,
      customerInterestRate: null,
      amortizeAmount: null,
      monthlyPayment: null,
      terms: null
    },

    deals: null
  }
}

const actions = {
  [constants.POST_USER]: async ({ commit }, params) => {
    try {
      commit(constants.POST_USER)
      const response = await axios.post(`user`, params)
      commit(constants.POST_USER_SUCCESS, response)
      return response
    } catch (error) {
      commit(constants.POST_USER_ERROR, error)
      throw error.response
    }
  },
  [constants.GET_USER_CLIENT]: async ({ commit }, params) => {
    try {
      commit(constants.GET_USER_CLIENT)
      const response = await axios.get(`user`, { params: { ...params } })
      commit(constants.GET_USER_CLIENT_SUCCESS, response)
      return response
    } catch (error) {
      commit(constants.GET_USER_CLIENT_ERROR, error)
      throw error.response
    }
  },
  'search-user': async ({ commit }, params) => {
    try {
      //commit(constants.GET_USER_CLIENT)
      const response = await axios.get(`/user/search`, {
        params: { ...params }
      })
      //commit(constants.GET_USER_CLIENT_SUCCESS, response)
      return response
    } catch (error) {
      //commit(constants.GET_USER_CLIENT_ERROR, error)
      throw error.response
    }
  },

  [constants.POST_USER_COMPANY]: async ({ commit }, params) => {
    try {
      commit(constants.POST_USER_COMPANY)
      const response = await axios.put(`company`, params)
      commit(constants.POST_USER_COMPANY_SUCCESS, response)
      return response
    } catch (error) {
      commit(constants.POST_USER_COMPANY_ERROR, error)
      throw error.response
    }
  },

  [constants.POST_EMAIL_VALIDATION]: async ({ commit }, params) => {
    try {
      commit(constants.POST_EMAIL_VALIDATION)
      const response = await axios.post(`user/validate-repeat-email `, params)
      commit(constants.POST_EMAIL_VALIDATION_SUCCESS, response)
      return response
    } catch (error) {
      if (error.response.status == 400) {
        commit(constants.POST_EMAIL_VALIDATION_SUCCESS, error.response.data)
        return
      }
      commit(constants.POST_EMAIL_VALIDATION_ERROR, error)
      throw error.response
    }
  },

  [constants.POST_CUSTOMER]: async ({ commit }, params) => {
    commit(constants.POST_CUSTOMER, params)
    return await CustomerService.postCustomer(params)
  },

  [constants.POST_DEAL]: async ({ commit }, params) => {
    commit(constants.POST_DEAL, params)
    return await DealService.postDeal(params)
  },

  [constants.GET_DEALS]: async ({ commit }) => {
    await DealService.getDeals().then(response => {
      commit(constants.GET_DEALS, response)
    })
  },

  loadingDealsInDealership: async ({ commit }, params) => {
    if (params['search'] != '') {
      return await axios.get(
        '/deals?page=' + params['page'] + '&search=' + params['search']
      )
    } else {
      return await axios.get('/deals?page=' + params['page'])
    }
  },

  loadInAdminUsersLeasingAgent: async ({ commit }, params) => {
    return await axios.get('/user?roleName=ROLE_LEASE_AGENT', {
      params: { ...params }
    })
  },

  saveAgentLeasing: async ({ commit }, payload) => {
    return await axios.post('/user/lease_agent', payload)
  },
  editAgentLeasing: async ({ commit }, payload) => {
    return await axios.put('/user/update', payload)
  },
  deleteAgentLeasing: async ({ commit }, id) => {
    return await axios.delete('/user/delete/' + id)
  },
  saveUpdateAgentLeasing: async ({ commit }, payload) => {
    return await axios.put('/user/update', payload)
  },
  loadingDealershipSelect: async ({ commit }) => {
    return await axios.get('user/dealerships')
  },
  loadingDealsByDealership: async ({ commit }, payload) => {
    return await axios.post('deals/dealership', payload)
  }
}

const mutations = {
  [constants.POST_USER]: state => {
    state.user.status = 'fetching'
    state.user.error = null
  },
  [constants.POST_USER_SUCCESS]: (state, data) => {
    state.user.status = 'success'
    state.user.error = null
    state.user.data = data
  },
  [constants.POST_USER_ERROR]: (state, error) => {
    state.user.status = 'error'
    state.user.error = error
  },

  [constants.POST_USER_COMPANY]: state => {
    state.user.status = 'fetching'
    state.user.error = null
  },
  [constants.POST_USER_COMPANY_SUCCESS]: (state, data) => {
    state.user.status = 'success'
    state.user.error = null
    state.user.data = data
  },
  [constants.POST_USER_COMPANY_ERROR]: (state, error) => {
    state.user.status = 'error'
    state.user.error = error
  },

  [constants.GET_USER_CLIENT]: state => {
    state.user.status = 'fetching'
    state.user.error = null
  },
  [constants.GET_USER_CLIENT_SUCCESS]: (state, data) => {
    state.user.status = 'success'
    state.user.error = null
    state.user.data = data
  },
  [constants.GET_USER_CLIENT_ERROR]: (state, error) => {
    state.user.status = 'error'
    state.user.error = error
  },

  [constants.POST_EMAIL_VALIDATION]: state => {
    state.emailValidation.status = 'fetching'
    state.emailValidation.error = null
  },
  [constants.POST_EMAIL_VALIDATION_SUCCESS]: (state, data) => {
    state.emailValidation.status = 'success'
    state.emailValidation.error = null
    state.emailValidation.data = data
  },
  [constants.POST_EMAIL_VALIDATION_ERROR]: (state, error) => {
    state.emailValidation.status = 'error'
    state.emailValidation.error = error
  },
  [constants.POST_CUSTOMER]: (state, data) => {
    state.customer.firstName = data.firstName
    state.customer.lastName = data.lastName
    state.customer.company = data.company
    state.customer.email = data.email
    state.customer.phoneNumber = data.phoneNumber
  },
  [constants.POST_DEAL]: (state, data) => {
    state.deal.condition = data.condition
    state.deal.type = data.type
    state.deal.year = data.year
    state.deal.make = data.make
    state.deal.model = data.model
    state.deal.capitalCost = data.capitalCost
    state.deal.residual = data.residual
    state.deal.interestRate = data.interestRate
    state.deal.customerInterestRate = data.customerInterestRate
    state.deal.amortizeAmount = data.amortizeAmount
    state.deal.monthlyPayment = data.monthlyPayment
    state.deal.terms = data.terms
  },
  [constants.GET_DEALS]: (state, data) => {
    state.deals = data
  }
}

export default {
  state: getDefaultState(),
  actions,
  mutations
}
