/** @format */

import notes from '@/modules/shared/notes/store.js'
import validation from '@/modules/shared/validation/store.js'
import signature from '@/modules/shared/signature/store.js'
export default {
  state: {},
  mutations: {},
  actions: {},
  modules: {
    notes,
    validation,
    signature
  }
}
