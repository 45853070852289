/** @format */

import * as constants from './actions'
import axios from '@/plugins/axios.js'

function getDefaultState() {
  return {
    configuration: {
      status: null,
      data: {},
      error: null
    }
  }
}

const actions = {
  [constants.POST_CONFIG]: async ({ commit }, params) => {
    try {
      commit(constants.POST_CONFIG)

      const response = await axios.post(`company/configuration/update`, params)
      commit(constants.POST_CONFIG_SUCCESS, response)
      return response
    } catch (error) {
      commit(constants.POST_CONFIG_ERROR, error)
      throw error.response
    }
  },
  [constants.GET_CONFIG_COMPANY]: async ({ commit }, params) => {
    try {
      commit(constants.GET_CONFIG_COMPANY)

      const response = await axios.get(`company/configuration/find`, {
        params: { ...params }
      })
      commit(constants.GET_CONFIG_COMPANY_SUCCESS, response)
      return response
    } catch (error) {
      commit(constants.GET_CONFIG_COMPANY_ERROR, error)
      throw error.response
    }
  }
}

const mutations = {
  [constants.POST_CONFIG]: state => {
    state.configuration.status = 'fetching'
    state.configuration.error = null
  },
  [constants.POST_CONFIG_SUCCESS]: (state, data) => {
    state.configuration.status = 'success'
    state.configuration.error = null
    state.configuration.data = data
  },
  [constants.POST_CONFIG_ERROR]: (state, error) => {
    state.configuration.status = 'error'
    state.configuration.error = error
  },

  [constants.GET_CONFIG_COMPANY]: state => {
    state.configuration.status = 'fetching'
    state.configuration.error = null
  },
  [constants.GET_CONFIG_COMPANY_SUCCESS]: (state, data) => {
    state.configuration.status = 'success'
    state.configuration.error = null
    state.configuration.data = data
  },
  [constants.GET_CONFIG_COMPANY_ERROR]: (state, error) => {
    state.configuration.status = 'error'
    state.configuration.error = error
  }
}

export default {
  state: getDefaultState(),
  actions,
  mutations
}
