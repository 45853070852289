/** @format */

import Vue from 'vue'
import Vuex from 'vuex'
import auth from '@/modules/auth/store'
import dealership from '@/modules/dealership/store.js'
import admin from '@/modules/admin/store.js'
import UserServices from '@/services/UserServices.js'
import CarServices from '../services/CarServices'

import shared from '@/modules/shared/store.js'


Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    carTypeId: null,
    items: [],
    carRates: [],
  },
  mutations: {
    SET_ITEMS(state, items) {
      state.items = items;
    },
    SET_CAR_RATES(state, rates) {
      state.carRates = rates
    },
    SET_CAR_TYPE_ID(state, carTypeId) {
      state.carTypeId = carTypeId
    }
  },
  actions: {
    fetchUsers({commit}) {
      UserServices.getUsers("","","").then((result) => {
        commit('SET_ITEMS', result);
      });
    },
    fetchCarRates({commit}, type) {
      return CarServices.getCarRates(type).then((result) => {
        let percents = [];
        if (result.data.length) {
            commit('SET_CAR_TYPE_ID', result.data[0].id);
            percents = result.data[0].percents.map((percent, index) => {
            return {...percent, ...{id: index + 1}};
          });
        };
        commit('SET_CAR_RATES', percents);
      });
    },
    async updateRate({dispatch}, payload) {
      await CarServices.updateRate(payload);
      dispatch('fetchCarRates', payload.carSelected)
    },
  },
  modules: {
    admin,
    dealership,
    auth,
    shared
  },
  strict: true
})
