/** @format */

import * as constants from './actions'
import axios from '@/plugins/axios.js'

function getDefaultState() {
  return {
    itemUser: {},
    companyItem: {},
    ready: false,
    user: {},
    refreshData: null,
    validations: { status: null, data: null, error: null },
    companyStatus: { status: null, data: null, error: null }
  }
}
const actions = {
  [constants.GET_VALIDATIONS]: async ({ commit }, params) => {
    try {
      commit('setCompanyItem', {})
      const response = await axios.get(`company`, { params: { ...params } })
      commit('setCompanyItem', response.data[0])
      commit('setReady', true)
      return response
    } catch (error) {
      commit(constants.GET_VALIDATIONS_ERROR, error)
      throw error.response
    }
  },

  [constants.UPDATE_VALIDATIONS]: async ({ commit }, params) => {
    try {
      const response = await axios.put(`company/validations`, params)
      commit(constants.UPDATE_VALIDATIONS_SUCCESS, response)
      return response
    } catch (error) {
      this.$toast.add({
          summary: this.$t('toast_title_error'),
          detail: this.$t('toast_message_error'),
          severity: "error",
          life: 5000
      })
      throw error.response
    }
  },
  [constants.PUT_COMPANY_STATUS]: async ({ commit }, params) => {
    try {
      commit(constants.PUT_COMPANY_STATUS)
      const response = await axios.put(`/company/changeStatus`, params)
      commit(constants.PUT_COMPANY_STATUS_SUCCESS, response)
      return response
    } catch (error) {
      commit(constants.PUT_COMPANY_STATUS_ERROR, error)
      throw error.response
    }
  }
}

const mutations = {
  setItemUser: (state, item) => {
    state.itemUser = item
  },
  setConfig: (state, item) => {
    state.config = item
  },
  setCompanyItem: (state, item) => {
    state.companyItem = item
  },
  setReady: (state, item) => {
    state.ready = item
  },
  setRefreshData: (state, item) => {
    state.refreshData = item
  },

  [constants.GET_VALIDATIONS]: state => {
    state.user.status = 'fetching'
    state.user.error = null
  },

  [constants.GET_VALIDATIONS_SUCCESS]: (state, data) => {
    state.user.status = 'success'
    state.user.error = null
    state.user.data = data
  },
  [constants.GET_VALIDATIONS_ERROR]: (state, error) => {
    state.user.status = 'error'
    state.user.error = error
  },

  [constants.UPDATE_VALIDATIONS]: state => {
    state.validations.status = 'fetching'
    state.validations.error = null
  },

  [constants.UPDATE_VALIDATIONS_SUCCESS]: (state, data) => {
    state.validations.status = 'success'
    state.validations.error = null
    state.validations.data = data
  },
  [constants.UPDATE_VALIDATIONS_ERROR]: (state, error) => {
    state.validations.status = 'error'
    state.validations.error = error
  },

  [constants.PUT_COMPANY_STATUS]: state => {
    state.companyStatus.status = 'fetching'
    state.companyStatus.error = null
  },
  [constants.PUT_COMPANY_STATUS_SUCCESS]: (state, data) => {
    state.companyStatus.status = 'success'
    state.companyStatus.data = data
    state.companyStatus.error = null
  },
  [constants.PUT_COMPANY_STATUS_ERROR]: (state, error) => {
    state.companyStatus.status = 'success'
    state.companyStatus.error = error
  }
}

export default {
  state: getDefaultState(),
  mutations,
  actions
}
