/** @format */

import Home from './Home'

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: { layout: 'home' }
  },
  {
    path: '/:company',
    name: 'home-company',
    component: Home,
    meta: { layout: 'home' }
  }
]
export default routes
