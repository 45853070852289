/** @format */

export const filterList = {
  condition: [
    {
      value: 'new',
      text: 'New'
    },
    {
      value: 'used',
      text: 'Used '
    }
  ],
  type: [
    {
      value: 1,
      text: 'Pickup'
    },
    {
      value: 2,
      text: 'Light truck'
    },
    {
      value: 3,
      text: 'Heavy truck'
    },
    {
      value: 4,
      text: 'Auto'
    },
    {
      value: 5,
      text: 'SUV'
    }
  ]
}
