<template>
  <b-card class="shadow">
    <table class="table table-sm">
      <tr>
        <td>
          {{ $t("trim") }}
        </td>
        <td>
          <i>{{ vehicle.trim }}</i>
        </td>
      </tr>
      <tr>
        <td>{{ $t("year") }}</td>
        <td>
          <i>{{ vehicle.year }}</i>
        </td>
      </tr>
      <!--    <tr>
              <td>{{ $t("doors") }}</td>
              <td>
                <i>{{ m.doors }}</i>
              </td>
            </tr> -->
      <tr>
        <td>{{ $t("brand") }}</td>
        <td>
          <i>{{ vehicle.make }}</i>
        </td>
      </tr>
      <tr>
        <td>{{ $t("model") }}</td>
        <td>
          <i>{{ vehicle.model }}</i>
        </td>
      </tr>
      <tr>
        <td>{{ $t("vehicleType") }}</td>
        <td>
          <i>{{ vehicle.vehicleType }}</i>
        </td>
      </tr>
      <tr>
        <td>{{ $t("driveType") }}</td>
        <td>
          <i>{{ vehicle.driveType }}</i>
        </td>
      </tr>

      <tr>
        <td>{{ $t("style") }}</td>
        <td>
          <i>{{ vehicle.style }}</i>
        </td>
      </tr>

      <!--    <tr>
              <td>{{ $t("bodyType") }}</td>
              <td>
                <i>{{ m.bodyType }}</i>
              </td>
            </tr>
            <tr>
              <td>{{ $t("bodySubtype") }}</td>
              <td>
                <i>{{ m.bodySubtype }}</i>
              </td>
            </tr> -->
      <!--   <tr>
              <td>{{ $t("oemBodyStyle") }}</td>
              <td>
                <i>{{ m.oemBodyStyle }}</i>
              </td>
            </tr>
            <tr>
              <td>{{ $t("transmissionGears") }}</td>
              <td>
                <i>{{ m.transmissionGears }}</i>
              </td>
            </tr> -->

      <tr>
        <td>{{ $t("engineName") }}</td>
        <td>
          <i>{{ vehicle.engine }}</i>
        </td>
      </tr>

      <tr>
        <td>{{ $t("transmissionType") }}</td>
        <td>
          <i>{{ vehicle.transmissionType }}</i>
        </td>
      </tr>
      <!-- 
            <tr>
              <td>{{ $t("engineBrand") }}</td>
              <td>
                <i>{{ m.engineBrand }}</i>
              </td>
            </tr>
            <tr>
              <td>{{ $t("engineAvailability") }}</td>
              <td>
                <i>{{ m.engineAvailability }}</i>
              </td>
            </tr>
            <tr>
              <td>{{ $t("engineIceAspiration") }}</td>
              <td>
                <i>{{ m.engineIceAspiration }}</i>
              </td>
            </tr>
            <tr>
              <td>{{ $t("engineType") }}</td>
              <td>
                <i>{{ m.engineType }}</i>
              </td>
            </tr> -->
    </table>

    <template #footer>
      <b-button
        @click="editVehicle(vehicle)"
        :disabled="isLoading"
        block
        variant="success"
      >
        Select
      </b-button>
    </template>
  </b-card>
</template>

<script>
export default {
  props: ["vehicle", "vin"],
  data() {
    return {
      isLoading: false,
    };
  },
  methods: {
    editVehicle() {
      this.$router.push({
        name: "dealership.registry-vehicles",
        params: { vin: this.vin },
      });
    },
  },
};
</script>

<style></style>
