
	<template>
  <div class="p-col-12">
    <div class="card">
      <div class="ml-3">
        <h1 class="text-primary">{{ $t("dealsbydealership") }}</h1>
      </div>
      <div class="row">
        <div class="field col-lg-6 col-md-6 col-sm-12 mt-4 ml-3">
          <!--      <h5>{{ $t("dealership") }}</h5>
          <Dropdown
            v-model="dealerSelected"
            :options="dealershipList"
            optionLabel="name"
            optionValue="id"
            :placeholder="$t('select_dealership')"
            @change="getDealsByDealership($event.value)"
          /> -->
        </div>
        <div class="field col-12 md:col-12 sm:col-12 mt-4">
          <TableBase
            :items="dealsagent"
            :fields="fields"
            :options="options"
            :busy="loading === 'fetching' ? true : false"
          >
            <template slot="leftSide">
              <div class="text-left">
                <!--                 <h5>{{ $t("dealership") }}</h5>
 -->

                <Dropdown
                  v-model="dealerSelected"
                  :options="dealershipList"
                  optionLabel="name"
                  optionValue="id"
                  :placeholder="$t('select_dealership')"
                  @change="getDealsByDealership($event.value)"
                />
              </div>
            </template>
          </TableBase>

          <Dialog
            :visible.sync="detailsDialog"
            :style="{ width: '380px' }"
            :header="$t('details_deal')"
            :modal="true"
            class="p-fluid"
          >
            <div class="row">
              <div class="col-12 mt-1">
                <strong>{{ $t("company") }} AAAA</strong
                ><br />
                {{ deal.company }}
              </div>
              <div class="col-8 mt-1">
                <strong>{{ $t("customer") }}</strong
                ><br />
                {{ deal.customer }}
              </div>
              <div class="col-4 mt-1">
                <strong>{{ $t("condition") }}</strong
                ><br />
                {{ deal.condition }}
              </div>
              <div class="col-4 mt-1">
                <strong>{{ $t("type") }}</strong
                ><br />
                {{ deal.type }}
              </div>
              <div class="col-4 mt-1">
                <strong>{{ $t("brand") }}</strong
                ><br />
                {{ deal.make }}
              </div>
              <div class="col-4 mt-1">
                <strong>{{ $t("model") }}</strong
                ><br />
                {{ deal.model }}
              </div>
              <div class="col-12 mt-1">
                <strong>{{ $t("capitalCost") }}</strong
                ><br />
                {{ deal.capitalCost }}
              </div>
              <div class="col-4 mt-1">
                <strong>{{ $t("term") }}</strong
                ><br />
                {{ deal.terms }}
              </div>
              <div class="col-8 mt-1">
                <strong>{{ $t("monthlyPayment") }}</strong
                ><br />
                {{ deal.monthlyPayment }}
              </div>
            </div>
            <template #footer>
              <div class="mt-2">
                <Button
                  :label="$t('close')"
                  icon="pi pi-times"
                  class="p-button-danger"
                  @click="hideDetailsDeal"
                />
              </div>
            </template>
          </Dialog>
        </div>
      </div>
    </div>
  </div>
</template>
  <script>
import TableBase from "@/modules/shared/table.vue";
import { mapActions, mapState } from "vuex";
import FormUser from "@/modules/dealership/users/FormUser.vue";
import { GET_DEALS } from "./registrationForm/actions";
export default {
  components: { FormUser, TableBase },
  methods: {
    ...mapActions([GET_DEALS]),
  },
  computed: {
    ...mapState(["dealership"]),
  },
  data() {
    return {
      users: null,
      user: {},
      selectedDeals: null,
      dealerSelected: null,
      dealershipList: [],
      dealsagent: [],
      filters: {},
      loading: "fetching",
      detailsDialog: false,
      deal: {},
      submitted: false,
      totalRecords: 0,
      fields: [
        {
          key: "condition",
          label: this.$t("condition"),
          thClass: "bg-header text-dark",
        },
        { key: "type", label: this.$t("type"), thClass: "bg-header text-dark" },
        { key: "year", label: this.$t("year"), thClass: "bg-header text-dark" },
        { key: "make", label: this.$t("brand"), thClass: "bg-header text-dark" },
        {
          key: "model",
          label: this.$t("model"),
          thClass: "bg-header text-dark",
        },
        {
          key: "capitalCost",
          label: this.$t("capitalCost"),
          thClass: "bg-header text-dark",
          formatter: (capitalCost) =>
            this.$options.filters.currency(capitalCost),
        },
        {
          key: "residual",
          label: this.$t("residual"),
          thClass: "bg-header text-dark",
        },
        {
          key: "interestRate",
          label: this.$t("interestRate"),
          thClass: "bg-header text-dark",
        },
        {
          key: "amortizeAmount",
          label: this.$t("amountAmorticed"),
          thClass: "bg-header text-dark",
        },
        {
          key: "monthlyPayment",
          label: this.$t("monthlyPayment"),
          thClass: "bg-header text-dark",
        },
        {
          key: "terms",
          label: this.$t("term"),
          thClass: "bg-header text-dark",
        },
        //{ key: "status", label:  this.$t("status") },
        // { key: "approvalDate", label:  this.$t("approvalDate") },
        {
          key: "customer",
          label: this.$t("customer"),
          thClass: "bg-header text-dark",
        },
        //{ key: "company", label:  this.$t("company") },
        //{ key: "customerId", label:  this.$t("customerId") },
        //{ key: "createdAt", label:  this.$t("createdAt") },
        {
          key: "actions",
          label: "Actions",
          thClass: "bg-header text-dark",
          downloaddeal: true,
          detailsdeal: true,
        },
      ],
      options: {
        assign: false,
        rolFilter: false,
        add: false,
      },
    };
  },
  created() {
    //this.GET_DEALS()
  },
  mounted() {
    this.getData();
    this.loadDealershipSelect();
  },
  methods: {
    ...mapActions([
      "GET_DEALS",
      "generatePDF",
      "loadingDealsInDealership",
      "loadingDealershipSelect",
      "loadingDealsByDealership",
    ]),
    async getData(filter = "") {
      this.loading = "fetching";
      const payload = {
        search: filter,
        field: null,
        page: 1,
      };

      this.loadingDealsInDealership(payload)
        .then((response) => {
          this.dealsagent = response;
          this.loading = "";
        })
        .catch((error) => {
          this.$toast.add({
            summary: this.$t("toast_title_error"),
            detail: this.$t("toast_message_error"),
            severity: "error",
            life: 5000,
          });
          this.loading = "";
        });
    },
    getDealsByDealership(event) {
      this.loading = "fetching";
      let payload = {
        dealership: event,
      };

      this.loadingDealsByDealership(payload)
        .then((response) => {
          console.log(response);
          this.dealsagent = response;
          this.loading = "";
        })
        .catch((error) => {
          this.$toast.add({
            summary: this.$t("toast_title_error"),
            detail: this.$t("toast_message_error"),
            severity: "error",
            life: 5000,
          });
          this.loading = "";
        });
    },
    changePage(page, filter = "") {
      console.log("changepage");
      const payload = {
        search: filter,
        role: "ROLE_LEASE_AGENT",
        page: page,
      };
      this.loadingDealsInDealership(payload)
        .then((response) => {
          this.loading = "fetching";
          this.dealsagent = response;
          this.loading = "";
        })
        .catch((error) => {
          this.$toast.add({
            summary: this.$t("toast_title_error"),
            detail: this.$t("toast_message_error"),
            severity: "error",
            life: 5000,
          });
        });
      this.loading = "";
    },
    loadDealershipSelect() {
      this.loadingDealershipSelect().then((response) => {
        console.log(response);
        this.dealershipList = response;
      });
    },
    exportDealPDF(deal) {
      this.generatePDF(deal.id)
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response]));
          const link = document.createElement("a");
          link.href = url;
          const dateToday = new Date();
          const day = dateToday.getDate();
          const month = dateToday.getMonth() + 1;
          const year = dateToday.getFullYear();
          const stringToday = day+""+month+""+year;
          const name = "Offer-"+ deal.type + "-term("+deal.terms+")-"+stringToday
          +".pdf";
          link.setAttribute("download", name);
          document.body.appendChild(link);
          link.click();
        })
        .catch((error) => {
          this.$toast.add({
            severity: "error",
            summary: error,
          });
        });
    },
    detailsDeal(deal) {
      this.deal = { ...deal };
      this.detailsDialog = true;
    },

    hideDetailsDeal() {
      this.detailsDialog = false;
      this.submitted = false;
    },
  },
};
</script>
  <style lang="scss" scoped>
.table-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.confirmation-content {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
  