/** @format */

import Base from '@/modules/auth/Base.vue'

import UserForm from '@/modules/dealership/registrationForm/UserForm.vue'
import Calculator from '@/modules/shared/calculator/Calculator.vue'
import CompanyForm from '@/modules/dealership/registrationForm/CompanyForm.vue'
import Document from '@/modules/dealership/document/Document.vue'
import Signature from '@/modules/dealership/document/signature/Signature.vue'
import ConfirmInfo from '@/modules/dealership/document/ConfirmInfo.vue'
import Dashboard from '@/modules/dealership/dashboard/Dashboard.vue'
import CompanyConfirm from '@/modules/dealership/registrationForm/CompanyConfirm.vue'
import UploadFiles from '@/modules/shared/UploadFiles.vue'
import PreApproval from '@/modules/dealership/preApproval/PreApproval.vue'
import SignaturePreApproval from '@/modules/shared/signature/Signature.vue'
import ConfigSite from '@/modules/dealership/siteConfig/SiteConfiguration.vue'
import { ifRoleAuthenticated, ifAuthenticated } from '@/router/navigationGuards'
import SearchVehicleByVin from './vehicles/registry/SearchVehicleByVin'
import GeneralInformation from './vehicles/registry/GeneralInformation'
import VehiclesList from './vehicles/list/VehiclesList'
import Nav from '@/modules/dealership/Nav.vue'
import Home from '@/layouts/Home.vue'
import Manage from '@/modules/dealership/users/Manage.vue'
import Rates from '@/modules/dealership/Rates.vue'
import Deals from '@/modules/dealership/Deals.vue'
import DealsByDealership from '@/modules/dealership/DealsByDealership.vue'
import DealsTable from '@/modules/dealership/deals/DealsTable.vue'
const routes = [
  {
    path: '/:company/dealership',
    component: Base,
    //beforeEnter: (to, from, next) => {
    //  ifRoleAuthenticated(to, from, next, 'ROLE_DEALERSHIP')
    //},
    children: [
      {
        path: 'dashboard',
        component: Dashboard,
        meta: { layout: 'company' },
        name: 'dealership.dashboard'
      },
      {
        path: 'config-site',
        component: ConfigSite,
        name: 'dealership.config-site'
      }
    ]
  },
  {
    path: '/dealership',
    name: 'dealership',
    component: Home,
    beforeEnter: (to, from, next) => {
      ifRoleAuthenticated(to, from, next, 'ROLE_DEALERSHIP')
    },
    children: [
      {
        path: 'manage',
        component: Manage,
        name: 'dealership.manage',
        meta: { layout: 'dealership' }
      },
      {
        path: 'rates',
        component: Rates,
        name: 'dealership.rates',
        meta: { layout: 'dealership' }
      },
      {
        path: 'deals',
        component: DealsTable,
        name: 'dealership.deals',
        meta: { layout: 'dealership' }
      },
      {
        path: 'deals-dealearship',
        component: DealsByDealership,
        name: 'dealership.deals-dealership',
        meta: { layout: 'dealership' }
      },
      /*{
        path: 'create',
        component: UserForm,
        name: 'dealership.create',
        meta: { layout: 'nomenu' }
      },*/
      {
        path: 'calculator',
        component: Calculator,
        name: 'dealership.calculator',
        meta: { layout: 'dealership' }
      },
      /*
      {
        path: 'company-create/:token',
        component: CompanyForm,
        name: 'dealership.company-create',
        meta: { layout: 'nomenu' }
      },
      {
        path: 'company/confirm/:token',
        component: CompanyConfirm,
        name: 'dealership.company-confirm'
      },*/
      {
        path: 'document/:token',
        component: Document,
        name: 'dealership.document'
      },
      {
        path: 'confirm/:token',
        component: ConfirmInfo,
        name: 'dealership.confirm'
      },
      {
        path: 'signature',
        component: Signature,
        name: 'dealership.signature'
      },
      {
        path: 'dashboard',
        component: Dashboard,
        name: 'dealership.dashboard2',
        beforeEnter: (to, from, next) => {
          ifAuthenticated(to, from, next)
        }
      },
      {
        path: 'upload-files/:token',
        component: UploadFiles,
        name: 'dealership.upload-files'
      },
      {
        path: 'pre-approval',
        component: PreApproval,
        name: 'dealership.pre-approval'
      },
      {
        path: 'pre-approval/signature/:token',
        component: SignaturePreApproval,
        name: 'dealership.pre-approval.signature'
      },
      {
        path: 'search-vehicle',
        component: SearchVehicleByVin,
        name: 'dealership.search-vehicle'
      },
      {
        path: 'registry-vehicles/:vin',
        component: GeneralInformation,
        name: 'dealership.registry-vehicles'
      },
      {
        path: 'list-vehicles',
        component: VehiclesList,
        name: 'dealership.list-vehicles'
      }
    ]
  }
]

export default routes
