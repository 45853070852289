/** @format */

import users from './users/store'

export default {
  state: {},
  actions: {},
  mutations: {},
  modules: {
    users
  }
}
