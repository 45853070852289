<template>
  <div>
    <b-navbar
      toggleable="lg"
      variant="light"
      align="end"
      class="nav mt-4 mb-5 shadow"
    >
      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
      <b-collapse id="nav-collapse" is-nav align="end">
        <b-navbar-nav class="ml-auto">
          <b-nav-item
            :to="{ name: 'dealership.manage' }"
            :active="$route.name === 'dealership.manage'"
          >
            Manage agents
          </b-nav-item>
          <b-nav-item
            :to="{ name: 'dealership.rates' }"
            :active="$route.name === 'dealership.rates'"
          >
            Rates
          </b-nav-item>
          <b-nav-item
            :to="{ name: 'dealership.calculator' }"
            :active="$route.name === 'dealership.calculator'"
          >
            Calculator
          </b-nav-item>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
    <router-view></router-view>
  </div>
</template>
<script>
export default {};
</script>
