import Base from "@/modules/auth/Base.vue";
import LoginForm from "./login/LoginForm.vue";
import ForgotPassword from "./forgotPassword/ForgotPassword.vue";
import RecoverPassword from "./forgotPassword/RecoverPassword.vue";
import UserForm from '@/modules/dealership/registrationForm/UserForm.vue'
import CompanyForm from '@/modules/dealership/registrationForm/CompanyForm.vue'
import CompanyConfirm from '@/modules/dealership/registrationForm/CompanyConfirm.vue'

const routes = [
  {
    path: "/auth",
    name: "auth",
    component: Base,
    children: [
      {
        path: "login",
        name: "auth.login",
        component: LoginForm,
        meta: { layout: "auth" },
      },
      {
        path: "forgotPassword",
        name: "auth.forgotPassword",
        component: ForgotPassword,
        meta: { layout: "nomenu" },
      },
      {
        path: "password/:token",
        name: "auth.recoverPassword",
        component: RecoverPassword,
        meta: { layout: "nomenu" },
      },
      {
        path: 'dealership-create',
        component: UserForm,
        name: 'auth.create',
        meta: { layout: 'nomenu' }
      },
      {
        path: 'company-create/:token',
        component: CompanyForm,
        name: 'auth.company-create',
        meta: { layout: 'nomenu' }
      },
      {
        path: 'company/confirm/:token',
        component: CompanyConfirm,
        name: 'auth.company-confirm'
      },
    ],
  },
];

export default routes;
