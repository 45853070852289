<template>
  <div class="container mx-auto">
    <b-form @submit.stop.prevent="onSubmit()" v-if="!isComplete">
      <b-row>
        <b-col sm="12" md="12" lg="3" xl="3"> </b-col>
        <b-col sm="12" md="12" lg="6" xl="6">
          <h4>{{ $t("company") }}: {{ form.legalName }}</h4>

          <b-form-group label-size="sm">
            <template slot="label">
              {{ $t("logo") }}
            </template>
            <template slot="description" v-if="previewFiles.length == 0">
            </template>

            <b-input-group size="sm">
              <b-form-file
                multiple
                size="sm"
                min="1"
                max=""
                :capture="true"
                v-model="form.files"
                :placeholder="$t('chooseFile')"
                :drop-placeholder="$t('dropFile')"
                @change="handleImages"
              >
                <template slot="file-name" slot-scope="{ names }">
                  <b-badge
                    class="mr-1"
                    v-for="(name, index) in names"
                    v-bind:key="index"
                    variant="blue"
                    >{{ name }}</b-badge
                  >
                </template>
              </b-form-file>

              <b-form-invalid-feedback>
                <div>{{ $t("onlyTwoFiles") }}</div>
              </b-form-invalid-feedback>
            </b-input-group>
          </b-form-group>
          <b-row>
            <b-col
              cols="4"
              sm="4"
              md="4"
              lg="4"
              xl="4"
              v-for="(file, index) in previewFiles"
              v-bind:key="index"
            >
              <b-img class="my-auto" thumbnail fluid :src="file"></b-img>
            </b-col>
          </b-row>

          <div></div>
        </b-col>
        <b-col sm="12" md="12" lg="3" xl="3"> </b-col>

        <b-col sm="12" md="12" lg="4" xl="4"> </b-col>
        <b-col sm="12" md="12" lg="4" xl="4">
          <b-btn
            block
            size="lg"
            class="btn btn-success mt-4"
            :disabled="form.files == null || isLoading"
            type="submit"
          >
            {{ $t("submit") }}

            <b-spinner v-if="isLoading" small variant="white"></b-spinner>
          </b-btn>
        </b-col>
        <b-col sm="12" md="12" lg="4" xl="4"> </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import { POST_CONFIG, GET_CONFIG_COMPANY } from "./actions";
import { generalMixin } from "@/modules/mixin.js";
import { mapState } from "vuex";

export default {
  mixins: [generalMixin],

  data() {
    return {
      form: {
        token: null,
        id: null,
        tradeName: "",

        files: null,
      },
      isComplete: false,
      previewFiles: [],
    };
  },

  computed: {
    ...mapState({
      configuration: (state) =>
        state.dealership.siteConfiguration.configuration,
    }),
    isLoading() {
      return this.configuration.status == "fetching" ? true : false;
    },
  },
  methods: {
    handleImages(e) {
      this.previewFiles = [];
      let images = e.target.files;
      images.forEach((file) => {
        if (file.type.includes("image")) {
          let reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = (e) => {
            this.previewFiles.push(e.target.result);
          };
        }
      });
    },
    addImageToPreview(e) {
      let image = e.target.files[0];
      let reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onload = (e) => {
        this.previewFiles.push(e.target.result);
      };
    },

    onSubmit() {
      const payload = this.formatPayload();
      this.$store
        .dispatch(POST_CONFIG, payload)
        .then(() => {
          this.isComplete = true;
          this.makeToast(
            this.$t("success"),
            this.$t("logoUploadedSuccessfully"),
            "success"
          );
          this.getCompanyConfig();
        })
        .catch((error) => {
          this.makeToast(
            this.$t("warning"),
            this.$t("errorMessage"),
            "warning"
          );
        });
    },
    formatPayload() {
      const data = new FormData();
      data.set("id", this.configuration.data.data.id);
      data.set("logo", this.form.files[0]);

      return data;
    },

    getCompany() {
      this.$store
        .dispatch(GET_CONFIG_COMPANY, {
          companyToken: this.$route.params.token,
        })
        .then((response) => {
          this.form = { ...response.data[0] };
        })
        .catch(() => {
          this.$toast.add({
              summary: this.$t('toast_title_error'),
              detail: this.$t('toast_message_error'),
              severity: "error",
              life: 5000
          })
        });
    },
    getCompanyConfig() {
      const payload = {
        prefix: this.$route.params.company,
      };
      this.$store
        .dispatch(GET_CONFIG_COMPANY, payload)
        .then((response) => {
          console.log(response);
        })
        .catch(() => {
          this.$toast.add({
              summary: this.$t('toast_title_error'),
              detail: this.$t('toast_message_error'),
              severity: "error",
              life: 5000
          })
        });
    },
    goToHome() {
      this.$router.push({ path: "/" });
    },
    openFileBrowser() {
      document.getElementById("addOneFile").click();
    },
  },
  beforeMount() {
    //this.getCompany();
  },
};
</script>

<style>
</style>