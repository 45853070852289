/** @format */

export const GET_MODELS = 'GET_MODELS'
export const GET_MODELS_SUCCESS = 'GET_MODELS_SUCCESS'
export const GET_MODELS_ERROR = 'GET_MODELS_ERROR'

export const GET_MAKES = 'GET_MAKES'
export const GET_MAKES_SUCCESS = 'GET_MAKES_SUCCESS'
export const GET_MAKES_ERROR = 'GET_MAKES_ERROR'

export const GET_AGENTS = 'GET_AGENTS'
export const GET_AGENTS_SUCCESS = 'GET_AGENTS_SUCCESS'
export const GET_AGENTS_ERROR = 'GET_AGENTS_ERROR'

export const GET_LEASE_AGENTS = 'GET_LEASE_AGENTS'
export const GET_LEASE_AGENTS_SUCCESS = 'GET_LEASE_AGENTS_SUCCESS'
export const GET_LEASE_AGENTS_ERROR = 'GET_LEASE_AGENTS_ERROR'

export const GET_DEALERSHIPS = 'GET_DEALERSHIPS'
export const GET_DEALERSHIPS_SUCCESS = 'GET_DEALERSHIPS_SUCCESS'
export const GET_DEALERSHIPS_ERROR = 'GET_DEALERSHIPS_ERROR'

export const GET_CUSTOMERS = 'GET_CUSTOMERS'
export const GET_CUSTOMERS_SUCCESS = 'GET_CUSTOMERS_SUCCESS'
export const GET_CUSTOMERS_ERROR = 'GET_CUSTOMERS_ERROR'
