<template>
  <b-container fluid>
    <b-row>
      <b-col
        v-for="(vehicle, index) in vehicles.data"
        v-bind:key="index"
        cols="4"
      >
        <VehicleCard :vehicle="vehicle" />
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { GET_VEHICLES } from "./actions";
import { mapState } from "vuex";
import VehicleCard from "./components/VehicleCard";
export default {
  components: {
    VehicleCard,
  },
  computed: {
    ...mapState({
      vehicles: (state) => state.dealership.vehiclesStore.list.vehicles.data,
    }),
  },
  methods: {
    getVehicles() {
      this.$store.dispatch(GET_VEHICLES);
    },
  },
  beforeMount() {
    this.getVehicles();
  },
};
</script>

<style></style>
