<template>
  <div>
    <b-navbar
      toggleable="lg"
      variant="primary"
      type="dark"
      align="end"
      class="nav mt-4"
    >
      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
      <b-collapse id="nav-collapse" is-nav align="end">
        <b-navbar-nav class="ml-auto">
          <b-navbar-nav>
            <b-nav-item
              :to="{ name: 'admin.users.pre-registry' }"
              :active="$route.name === 'admin.users.pre-registry'"
            >
              <h5 style="margin: 5px;">{{$t('pre-registered')}}</h5>
            </b-nav-item>
            <b-nav-item
              :to="{ name: 'admin.users.registry' }"
              :active="$route.name === 'admin.users.registry'"
            >
            <h5 style="margin: 5px;">{{$t('inscription')}}</h5>
            </b-nav-item>
            <b-nav-item
              :to="{ name: 'admin.users.active' }"
              :active="$route.name === 'admin.users.active'"
            >
            <h5 style="margin: 5px;">{{$t('active')}}</h5>
            </b-nav-item>
            <!-- <b-nav-item
              :to="{ name: 'admin.users.on-hold' }"
              :active="$route.name === 'admin.users.on-hold'"
            >
            <h5 style="margin: 5px;">{{$t('on-hold')}}</h5>
            </b-nav-item-->
            <!-- b-nav-item
              :to="{ name: 'admin.users.validate' }"
              :active="$route.name === 'admin.users.validate'"
            >
            <h5 style="margin: 5px;">{{$t('validated')}}</h5>
            </b-nav-item -->
            <b-nav-item
              :to="{ name: 'admin.users.rejected' }"
              :active="$route.name === 'admin.users.rejected'"
            >
            <h5 style="margin: 5px;">{{$t('rejected')}}</h5>
            </b-nav-item>
          </b-navbar-nav>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
    <router-view></router-view>
  </div>
</template>
<script>
export default {};
</script>
