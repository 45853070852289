<template>
    <div class="row">
        <div class="col-12" v-if="profile.updateProfile == 0">
            <h1>{{$t("upload_information")}}</h1>
            <span>{{$t("upload_information_message")}}</span>
            <div class="row">
                <div class="mt-3 col-6">
                    <div class="col-12">
                    <label for="firstName">{{$t('first_name')}}</label>
                    </div>
                    <div class="col-12">
                        <b-input-group>
                        <b-input-group-prepend>
                            <span class="input-group-text"><i class="fa fa-user fa-md"></i></span>
                        </b-input-group-prepend>
                        <b-form-input size="md" :placeholder="$t('placeholder_firstname')" type="text"  v-model="customer.firstName">
                        </b-form-input>
                        </b-input-group>
                        <small class="p-invalid" v-if="submitted && !customer.firstName">{{$(t('valid_firstname_required'))}}</small>
                    </div>
                </div>
                <div class="mt-3 col-6">
                    <div class="col-12">
                    <label for="lastName">{{$t('last_name')}}</label>
                    </div>
                    <div class="col-12">
                        <b-input-group>
                        <b-input-group-prepend>
                            <span class="input-group-text"><i class="fa fa-user fa-md"></i></span>
                        </b-input-group-prepend>
                        <b-form-input size="md" :placeholder="$t('placeholder_lastname')" type="text"  v-model="customer.lastName">
                        </b-form-input>
                        </b-input-group>
                        <small class="p-invalid md" v-if="submitted && !customer.lastName">{{$(t('valid_lastname_required'))}}</small>
                    </div>
                </div>
                <div class="mt-3 col-6">
                    <div class="col-12">
                    <label for="email">{{$t('c_email')}}</label>
                    </div>
                    <div class="col-12">
                        <b-input-group>
                        <b-input-group-prepend>
                            <span class="input-group-text"><i class="fa fa-at fa-md"></i></span>
                        </b-input-group-prepend>
                        <b-form-input size="md" :placeholder="$t('placeholder_email')" type="email"  v-model="customer.email">
                        </b-form-input>
                        </b-input-group>
                        <small class="p-invalid md" v-if="submitted && !customer.email">{{$(t('valid_email_required'))}}</small>
                    </div>
                </div>
                <div class="mt-3 col-6">
                    <div class="col-12">
                    <label for="phone">{{$t('phone_number')}}</label>
                    </div>
                    <div class="col-12">
                        <b-input-group>
                        <b-input-group-prepend>
                            <span class="input-group-text"><i class="fa fa-phone fa-md"></i></span>
                        </b-input-group-prepend>
                        <b-form-input size="md" :placeholder="$t('placeholder_phone')" type="text"  v-model="customer.phoneNumber">
                        </b-form-input>
                        </b-input-group>
                        <small class="p-invalid md" v-if="submitted && !customer.phoneNumber">{{$(t('valid_phone_required'))}}</small>
                    </div>
                </div>
                <div class="mt-3 col-6">
                    <div class="col-12">
                    <label for="website">{{$t('website')}}</label>
                    </div>
                    <div class="col-12">
                        <b-input-group>
                        <b-input-group-prepend>
                            <span class="input-group-text"><i class="fa fa-globe fa-md"></i></span>
                        </b-input-group-prepend>
                        <b-form-input size="md" :placeholder="$t('placeholder_website')" type="url"  v-model="customer.website">
                        </b-form-input>
                        </b-input-group>
                        <small class="p-invalid md" v-if="submitted && !customer.website">{{$(t('valid_website_required'))}}</small>
                    </div>
                </div>
                <div class="mt-3 col-6">
                    <div class="col-12">
                    <label for="password">{{$t('new_password')}}</label>
                    </div>
                    <div class="col-12">
                        <b-input-group>
                        <b-input-group-prepend>
                            <span class="input-group-text"><i class="fa fa-key fa-md"></i></span>
                        </b-input-group-prepend>
                        <b-form-input size="md" :placeholder="$t('placeholder_password')" type="password"  v-model="customer.password">
                        </b-form-input>
                        </b-input-group>
                        <small class="p-invalid md" v-if="submitted && !customer.password">{{$(t('valid_password_required'))}}</small>
                    </div>
                </div>
                <div class="mt-3 col-12 mr-1">
                    <div class="form-group col-12">
                        <Button
                            :label="$t('update')"
                            icon="pi pi-check"
                            style="float:right"
                            :disabled="!isComplete"
                            class="p-button-primary p-button-md"
                            @click="onUpdateUserInfo()"
                        ></Button>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-6" v-if="profile.updateProfile != 0">
        <h1>{{$t('welcome')}}</h1>
        <span>{{$t('upload_documents')}}</span>
        <div class="form-group mb-3 mt-3">
            <label>{{$t('document_1')}}</label>
            <b-form-file
                id="document1"
                name="document1"
                accept="image/png,image/jpeg,image/jpg,application/pdf"
                :placeholder='$tc("chooseFile")'
                @change="selectFile1($event)"
                size="md"
                required
            />
            </div>
            <div class="form-group mb-3 mt-3">
                <label>{{$t('document_2')}}</label>
                <b-form-file
                    id="document2"
                    name="document2"
                    accept="image/png,image/jpeg,image/jpg,application/pdf"
                    :placeholder='$tc("chooseFile")'
                    size="md"
                    @change="selectFile2($event)"
                    required
                />
            </div>
            <div class="form-group mb-3 mt-3">
            <label>{{$t('document_3')}}</label>
            <b-form-file
                id="document3"
                name="document3"
                accept="image/png,image/jpeg,image/jpg,application/pdf"
                :placeholder='$tc("chooseFile")'
                @change="selectFile3($event)"
                size="md"
                required
            />
            </div>
            <div class="form-group mb-3 mt-3">
                <Button
                    :label="$t('submit')"
                    icon="pi pi-plus"
                    :disabled="!previewFile1 && !previewFile2 && !previewFile3"
                    class="p-button-success p-mr-2"
                    @click="onSubmit"
                ></Button>
            </div>
        </div>
        </div>
</template>
<script>
import { mapActions, mapState } from 'vuex';
export default {
    name:'imageUpload',
    data() {
        return {
            user: null,
            submitted: false,
            previewFile1:null,
            previewFile2:null,
            previewFile3:null,
            customer: {
                firstName: "",
                lastName: "",
                website: "",
                email: "",
                phoneNumber: "",
                password: "",
            },
        };
    },
    computed: {
			...mapState(['auth']),
			profile: (state) => state.auth.loginStore.profile.data.user,
            isComplete() {
                return (
                    this.customer.firstName != '' &&
                    this.customer.lastName != '' &&
                    this.customer.phoneNumber != '' &&
                    this.customer.email != '' &&
                    this.customer.website != '' &&
                    this.customer.password != ''
                )
            },
    },
    mounted: () =>
    {

    },
    beforeMount() {
      this.customer.firstName =  this.profile.firstName;
      this.customer.email =  this.profile.email;
      this.customer.lastName =  this.profile.lastName;
    },
    methods: {
        ...mapActions(["uploadDocuments","updateUserInformation"]),
        selectFile1(e){
            const image = e.target.files[0];
            const reader = new FileReader();
            reader.readAsDataURL(image);
            reader.onload = e =>{
                this.previewFile1 = e.target.result;
            };
        },
        selectFile2(e){
            const image = e.target.files[0];
            const reader = new FileReader();
            reader.readAsDataURL(image);
            reader.onload = e =>{
                this.previewFile2 = e.target.result;
            };
        },
        selectFile3(e){
            const image = e.target.files[0];
            const reader = new FileReader();
            reader.readAsDataURL(image);
            reader.onload = e =>{
                this.previewFile3 = e.target.result;
            };
        },
        onSubmit(e) {
                let payload = {...{
                    document1: this.previewFile1,
                    document2: this.previewFile2,
                    document3: this.previewFile3,
                }}
                this.uploadDocuments(payload).then((response) => {
                    this.$toast.add({
                        severity: "success",
                        summary: "Update documents correctly!",
                        detail: "Update documents correctly!",
                        life: 4000,
                    });
                }).catch((error) => {
                    this.$toast.add({
                        severity: 'error',
                        summary: error
                    })
            })
        },
        onUpdateUserInfo(e){
            let payload = {
                firstName : this.customer.firstName,
                lastName : this.customer.lastName,
                email: this.customer.email,
                phone: this.customer.phoneNumber,
                password: this.customer.password
            }
            this.updateUserInformation(payload).then((response) => {
                    this.$toast.add({
                        severity: "success",
                        summary: "Update profile correctly!",
                        detail: "Update profile correctly!",
                        life: 4000,
                    });
            }).catch((error) => {
                console.log("error");
                this.$toast.add({
                    severity: 'error',
                    summary: error
                })
            })
        }
    }
}
</script>