/** @format */

export default [
  {
    authority: 'ROLE_SUPER_ADMIN',
    mainRoute: ''
  },
  {
    authority: 'ROLE_ADMIN',
    mainRoute: 'admin.agents-list'
  }, 
  {
    authority: 'ROLE_CUSTOMER',
    mainRoute: 'buyer.buyerdashboard'
  },
  {
    authority: 'ROLE_AGENT',
    mainRoute: 'agent.list-deals'
  },
  {
    authority: 'ROLE_DEALERSHIP',
    mainRoute: 'dealership.manage'
  },
  {
    authority: 'ROLE_LEASE_AGENT',
    mainRoute: 'leasingagent.list-deals',
  }
]
