<template>
  <b-card>
    <h2>{{ $t("contactInformation") }}</h2>

    <b-row>
      <b-col align-self="center" xs="12" sm="12" md="6" lg="6" xl="6">
        <b-form-group label-size="sm">
          <template slot="label">
            <span> Email </span>
          </template>
          <b-form-input
            @change="setValue('companyName', $event)"
            v-model="information.email"
            :maxlength="65"
            readonly
          >
          </b-form-input>
        </b-form-group>
      </b-col>

      <b-col align-self="center" xs="12" sm="12" md="6" lg="6" xl="6">
        <b-form-group label-size="sm">
          <template slot="label">
            <span> {{ $t("phoneNumber") }} </span>
          </template>
          <b-form-input
            @change="setValue('companyName', $event)"
            v-model="information.userDetails.phoneNumber"
            :maxlength="65"
            readonly
          >
          </b-form-input>
        </b-form-group>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
export default {
  props: ["information"],
};
</script>

<style>
</style>