<template>
  <div>
    <!-- iterate model in checks-->
    <div v-if="isLoading">
      <div class="text-center text-danger my-2">
        <b-spinner class="align-middle" variant="yellow" />
      </div>
    </div>
    <div v-else>
      <b-form-group>
        <b-form-checkbox
          v-for="model in models"
          v-model="modelFilter"
          :key="model.value"
          :value="model.value"
          name="flavour-3a"
          @change="onSelectedModel"
        >
          {{ model.text }}
        </b-form-checkbox>
      </b-form-group>
    </div>
  </div>
</template>

<script>
export default {
  name: "ModelFilter",
  props: ["modelSelected"],
  data() {
    return {
      models: [],
      modelFilter: [],
      isLoading: false,
    };
  },
  watch: {
    modelSelected: {
      handler: function (val) {
        this.modelFilter = val;
      },
      deep: true,
    },
  },
  methods: {
    getAgents() {
      this.isLoading = true;
      this.$store
        .dispatch("GET_MODELS")
        .then((response) => {
          this.models = response;
          this.modelFilter = this.modelSelected;
          this.isLoading = false;
        })
        .catch(() => {
          this.isLoading = false;
        });
    },
    onSelectedModel() {
      this.$emit("selectedModel", this.modelFilter);
    },
  },

  mounted() {
    this.getAgents();
  },
};
</script>

<style>
</style>
