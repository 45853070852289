/** @format */

import * as constants from './actions'
import axios from '@/plugins/axios.js'

function getDefaultState() {
  return {
    customers: {
      status: null,
      data: [],
      error: null
    },
    agents: {
      status: null,
      data: [],
      error: null
    },
    dealerships: {
      status: null,
      data: [],
      error: null
    },
    leaseAgents: {
      status: null,
      data: [],
      error: null
    },
    makes: {
      status: null,
      data: [],
      error: null
    },
    models: {
      status: null,
      data: [],
      error: null
    }
  }
}

const actions = {
  [constants.GET_CUSTOMERS]: async ({ commit }, payload) => {
    try {
      commit(constants.GET_CUSTOMERS)
      const response = await axios.get(`user/customers`, { params: payload })
      commit(constants.GET_CUSTOMERS_SUCCESS, response)
      return response
    } catch (error) {
      commit(constants.GET_CUSTOMERS_ERROR, error)
      throw error.response
    }
  },
  [constants.GET_AGENTS]: async ({ commit }, payload) => {
    try {
      commit(constants.GET_AGENTS)
      const response = await axios.get(`user/agents`, { params: payload })
      commit(constants.GET_AGENTS_SUCCESS, response)
      return response
    } catch (error) {
      commit(constants.GET_AGENTS_ERROR, error)
      throw error.response
    }
  },
  [constants.GET_DEALERSHIPS]: async ({ commit }, payload) => {
    try {
      commit(constants.GET_DEALERSHIPS)
      const response = await axios.get(`user/dealerships`, { params: payload })
      commit(constants.GET_DEALERSHIPS_SUCCESS, response)
      return response
    } catch (error) {
      commit(constants.GET_DEALERSHIPS_ERROR, error)
      throw error.response
    }
  },
  [constants.GET_LEASE_AGENTS]: async ({ commit }, payload) => {
    try {
      commit(constants.GET_LEASE_AGENTS)
      const response = await axios.get(`user/lease_agents`, {
        params: payload
      })
      commit(constants.GET_LEASE_AGENTS_SUCCESS, response)
      return response
    } catch (error) {
      commit(constants.GET_LEASE_AGENTS_ERROR, error)
      throw error.response
    }
  },
  [constants.GET_MAKES]: async ({ commit }, payload) => {
    try {
      commit(constants.GET_MAKES)
      const response = await axios.get(`taxonomies/marks`, { params: payload })
      commit(constants.GET_MAKES_SUCCESS, response)
      return response
    } catch (error) {
      commit(constants.GET_MAKES_ERROR, error)
      throw error.response
    }
  },
  [constants.GET_MODELS]: async ({ commit }, payload) => {
    try {
      commit(constants.GET_MODELS)
      const response = await axios.get(`taxonomies/deal_models`, {
        params: payload
      })
      commit(constants.GET_MODELS_SUCCESS, response)
      return response
    } catch (error) {
      commit(constants.GET_MODELS_ERROR, error)
      throw error.response
    }
  }
}

const mutations = {
  [constants.GET_CUSTOMERS]: state => {
    state.customers.status = 'fetching'
    state.customers.error = null
  },
  [constants.GET_CUSTOMERS_SUCCESS]: (state, data) => {
    state.customers.status = 'success'
    state.customers.error = null
    state.customers.data = data
  },
  [constants.GET_CUSTOMERS_ERROR]: (state, error) => {
    state.customers.status = 'error'
    state.customers.error = error
  },
  [constants.GET_AGENTS]: state => {
    state.agents.status = 'fetching'
    state.agents.error = null
  },
  [constants.GET_AGENTS_SUCCESS]: (state, data) => {
    state.agents.status = 'success'
    state.agents.error = null
    state.agents.data = data
  },
  [constants.GET_AGENTS_ERROR]: (state, error) => {
    state.agents.status = 'error'
    state.agents.error = error
  },
  [constants.GET_DEALERSHIPS]: state => {
    state.dealerships.status = 'fetching'
    state.dealerships.error = null
  },
  [constants.GET_DEALERSHIPS_SUCCESS]: (state, data) => {
    state.dealerships.status = 'success'
    state.dealerships.error = null
    state.dealerships.data = data
  },
  [constants.GET_DEALERSHIPS_ERROR]: (state, error) => {
    state.dealerships.status = 'error'
    state.dealerships.error = error
  },
  [constants.GET_LEASE_AGENTS]: state => {
    state.leaseAgents.status = 'fetching'
    state.leaseAgents.error = null
  },
  [constants.GET_LEASE_AGENTS_SUCCESS]: (state, data) => {
    state.leaseAgents.status = 'success'
    state.leaseAgents.error = null
    state.leaseAgents.data = data
  },
  [constants.GET_LEASE_AGENTS_ERROR]: (state, error) => {
    state.leaseAgents.status = 'error'
    state.leaseAgents.error = error
  },
  [constants.GET_MAKES]: state => {
    state.makes.status = 'fetching'
    state.makes.error = null
  },
  [constants.GET_MAKES_SUCCESS]: (state, data) => {
    state.makes.status = 'success'
    state.makes.error = null
    state.makes.data = data
  },
  [constants.GET_MAKES_ERROR]: (state, error) => {
    state.makes.status = 'error'
    state.makes.error = error
  },
  [constants.GET_MODELS]: state => {
    state.models.status = 'fetching'
    state.models.error = null
  },
  [constants.GET_MODELS_SUCCESS]: (state, data) => {
    state.models.status = 'success'
    state.models.error = null
    state.models.data = data
  },
  [constants.GET_MODELS_ERROR]: (state, error) => {
    state.models.status = 'error'
    state.models.error = error
  }
}

export default {
  state: getDefaultState(),
  actions,
  mutations
}
