<template>
  <b-form container class="container" @submit.stop.prevent="onSubmit">
    <b-row>
      <b-col sm="12" md="12" lg="12" xl="12">
        <b-form-group>
          <template slot="label">
            <b>{{$t('c_email')}}</b>
          </template>
          <b-form-input
            v-model="form.email"
            autocomplete="off"
            type="email"
            :state="validateState('email')"
            v-model.trim="$v.form.email.$model"
            @change="setValue('email', $event)"
          >
          </b-form-input>
          <small v-bind:class="{ 'text-danger': !$v.form.email.required }">
            {{ $t("requiredField") }}
            <br />
          </small>
          <small v-bind:class="{ 'text-danger': !$v.form.email.email }">
            {{ $t("requiredValidEmail") }}
          </small>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col sm="12" md="12" xl="12">
        <b-button
          :disabled="$v.form.$invalid"
          class="float-right bg-blue btn-block"
          type="submit"
        >
          <b-spinner
            v-if="recoverPasswordStatus === 'fetching'"
            variant="white"
            label="Spinning"
            small
          />
          <span v-else> {{$t('recover_password')}} </span>
        </b-button>
      </b-col>
    </b-row>
  </b-form>
</template>

<script>
import { POST_FORGOT_PASSWORD } from "./actions";
import { required, email } from "vuelidate/lib/validators";
import { mapState } from "vuex";
export default {
  data() {
    return {
      form: {
        email: null,
      },
    };
  },
  computed: {
    ...mapState({
      recoverPasswordStatus: (state) =>
        state.auth.forgotPasswordStore.forgotPassword.status,
    }),
  },
  validations: {
    form: {
      email: {
        required,
        email,
      },
    },
  },
  methods: {
    setValue(key, value) {
      this.form[key] = value;
      this.$v.form[key].$touch();
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    formatPayload() {
      const payload = {
        email: this.form.email,
      };
      return payload;
    },
    resetForm() {
      this.form = {
        email: null,
      };

      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
    onSubmit() {
      this.$store
        .dispatch(POST_FORGOT_PASSWORD, this.form)
        .then(() => {
          this.resetForm();
          this.$toast.add({
              summary: this.$t('toast_title_success'),
              detail: this.$t('toast_message_recovery'),
              severity: "success",
              life: 5000
          })
          window.location.reload();
        })
        .catch(() => {
          this.$toast.add({
              summary: this.$t('toast_title_success'),
              detail: this.$t('toast_message_recovery'),
              severity: "success",
              life: 5000
          })
          window.location.reload();
        });
    },
  },
};
</script>
