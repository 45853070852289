<template>
  <FilterTypeDate fromType="endDateFrom" toType="endDateTo" />
</template>

<script>
import FilterTypeDate from "./FilterTypeDate.vue";
export default {
  name: "EndDateFilter",
  components: { FilterTypeDate },
};
</script>

<style>
</style>