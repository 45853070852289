<template>
  <Dialog
    :header="$t('title_customer')"
    :visible.sync="showModal"
    :containerStyle="{ width: '380px' }"
    :modal="true"
  >
    <div class="p-fluid">
      <h6 class="mt-2">
        <strong>{{ $t("title_search_customer") }}</strong>
      </h6>
      <div class="p-field">
        <label for="search"
          ><strong>{{ $t("search_customer") }}</strong></label
        >
        <Dropdown
          id="id"
          v-model="model"
          :options="selectedUsers"
          optionLabel="fullName"
          :placeholder="$t('select_customer')"
          class="p-inputtext-sm"
        />
        <small class="p-invalid" v-if="submitted && !customer.firstName">{{
          $t("first_name")
        }}</small>
      </div>
      <h6>
        <strong>{{ $t("title_new_customer") }}</strong>
      </h6>
      <div class="p-field">
        <label for="firstName">{{ $t("first_name") }}</label>
        <span class="p-input-icon-left">
          <i class="pi pi-user" />
          <InputText
            id="firstName"
            v-model.trim="customer.firstName"
            required="true"
            :class="{ 'p-invalid': submitted && !customer.firstName }"
          />
        </span>
        <small class="p-invalid" v-if="submitted && !customer.firstName">{{
          $(t("valid_firstname_required"))
        }}</small>
      </div>
      <div class="p-field">
        <label for="lastName">{{ $t("last_name") }}</label>
        <span class="p-input-icon-left">
          <i class="pi pi-user" />
          <InputText
            id="lastName"
            v-model.trim="customer.lastName"
            required="true"
            :class="{ 'p-invalid': submitted && !customer.lastName }"
          />
        </span>
        <small class="p-invalid" v-if="submitted && !customer.lastName">{{
          $(t("valid_lastname_required"))
        }}</small>
      </div>
      <div class="p-field">
        <label for="company">{{ $t("company") }}</label>
        <span class="p-input-icon-left">
          <i class="pi pi-building" />
          <InputText id="company" v-model.trim="customer.company" />
        </span>
      </div>
      <div class="p-field">
        <label for="email">{{ $t("c_email") }}</label>
        <span class="p-input-icon-left">
          <i class="pi pi-envelope" />
          <InputText
            type="email"
            id="email"
            v-model.trim="customer.email"
            required="true"
            :class="{ 'p-invalid': submitted && !customer.email }"
          />
        </span>
        <small class="p-invalid" v-if="submitted && !customer.email">{{
          $(t("valid_email_required"))
        }}</small>
      </div>
      <div class="p-field">
        <label for="phoneNumber">{{ $t("phone_number") }}</label>
        <span class="p-input-icon-left">
          <i class="pi pi-phone" />
          <InputMask
            id="phoneNumber"
            mask="999-9999999"
            v-model.trim="customer.phoneNumber"
            required="true"
            :class="{ 'p-invalid': submitted && !customer.phoneNumber }"
          />
        </span>
        <small class="p-invalid" v-if="submitted && !customer.phoneNumber">{{
          $(t("valid_phone_required"))
        }}</small>
      </div>
    </div>
    <template #footer>
      <Button
        :label="$t('cancel')"
        icon="pi pi-times"
        class="p-button-danger"
        @click="closeModal"
      />
      <Button
        :label="$t('save')"
        icon="pi pi-check"
        class="p-button-success"
        @click="saveCustomer"
      />
    </template>
  </Dialog>
</template>
<script>
import {
  POST_CUSTOMER,
  POST_DEAL,
} from "@/modules/dealership/registrationForm/actions.js";
import { mapActions } from "vuex";
export default {
  data() {
    return {
      users: null,
      model: this.modelP,
      selectedUsers: null,
      customer: {
        firstName: null,
        lastName: null,
        company: null,
        email: null,
        phoneNumber: null,
      },
      submitted: false,
      showModal: false,
    };
  },
  created() {
    this.loadCustomer();
  },
  props: {
    deal: Object,

    modelP: {
      type: String,
      default: null,
    },
  },
  methods: {
    ...mapActions(["loadUsers", "fetchUsers", POST_CUSTOMER, POST_DEAL]),
    openModal() {
      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;
    },
    loadCustomer() {
      this.loadUsers()
        .then((response) => {
          this.selectedUsers = response;
        })
        .catch((error) => {
          this.$toast.add({
            summary: this.$t("toast_title_error"),
            detail: this.$t("toast_message_error"),
            severity: "error",
            life: 5000,
          });
        });
    },
    dowloadComparation(customer) {
      this.$parent.dowloadComparation(customer);
      this.showModal = false;
      this.$parent.resetValues();
    },
    saveCustomer() {
      if (this.model == null) {
        this.POST_CUSTOMER(this.customer).then((response) => {
          if (response.message == "User created") {
            let newDeal = { ...this.deal, ...{ customerId: response.user.id } };
            this.POST_DEAL(newDeal).then(() => {
              this.$toast.add({
                summary: this.$t("toast_title_success"),
                detail: this.$t("toast_message_success"),
                severity: "success",
                life: 5000,
              });
              this.dowloadComparation(response.customer);
            });
          } else if (response[2].includes("Duplicate")) {
            this.$toast.add({
              summary: this.$t("toast_title_error"),
              detail: this.$t("toast_message_duplicated"),
              severity: "error",
              life: 5000,
            });
          }
        });
      } else {
        let newDeal = { ...this.deal, ...{ customerId: this.model.id } };
        this.POST_DEAL(newDeal)
          .then((response) => {
            this.$toast.add({
              summary: this.$t("toast_title_success"),
              detail: this.$t("toast_message_success"),
              severity: "success",
              life: 5000,
            });
            this.dowloadComparation(response.customer);
          })
          .catch((error) => {
            this.$toast.add({
              summary: this.$t("toast_title_error"),
              detail: this.$t("toast_message_error"),
              severity: "error",
              life: 5000,
            });
          });
      }
    },
  },
};
</script>
