<template>
  <div>
    <b-form-group label-size="sm">
      <template slot="label"> From </template>
      <b-form-input
        v-model="from"
        size="sm"
        type="number"
        @change="applyFilter()"
        :max="maxYear"
        :min="minYear"
      />
    </b-form-group>
    <b-form-group label-size="sm">
      <template slot="label"> To: </template>
      <b-form-input
        v-model="to"
        size="sm"
        type="number"
        @change="applyFilter()"
        :min="minYear"
      />
    </b-form-group>
  </div>
</template>

<script>
import { SET_DEALS_FILTERS } from "../actions";
export default {
  data() {
    return {
      from: 2015,
      to: this.getCurrentYear(),
      minYear: 2015,
      maxYear: 2021,
    };
  },
  methods: {
    applyFilter() {
      this.$store.commit(SET_DEALS_FILTERS, {
        fields: {
          [this.fromType]: this.from ? this.from : null,
          [this.toType]: this.to ? this.to : null,
        },
      });
    },
    getCurrentYear() {
      return new Date().getFullYear();
    },
  },
};
</script>

<style>
</style>