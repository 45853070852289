/** @format */

import Base from '@/modules/auth/Base.vue'
import Deals from '@/modules/leasing-agent/Deals'
import DealsClosing from '@/modules/leasing-agent/DealsClosing'
import Calculator from '@/modules/shared/calculator/Calculator'
import DealsSearching from '@/modules/leasing-agent/SearchDeal'
import DealsTable from '@/modules/dealership/deals/DealsTable'
import { ifRoleAuthenticated } from '@/router/navigationGuards'
const routes = [
  {
    path: '/leasing-agent',
    component: Base,
    beforeEnter: (to, from, next) => {
      ifRoleAuthenticated(to, from, next, 'ROLE_LEASE_AGENT')
    },
    children: [
      {
        path: '/',
        path: 'deals',
        component: Deals,
        name: 'leasingagent.list-deals',
        meta: { layout: 'leasingagent' }
      },
      {
        path: 'deals-ending',
        component: DealsClosing,
        name: 'leasingagent.list-deals-ending',
        meta: { layout: 'leasingagent' }
      },
      {
        path: 'deals-search',
        component: DealsSearching,
        name: 'leasingagent.list-deals-searching',
        meta: { layout: 'leasingagent' }
      },
      {
        path: 'calculator',
        component: Calculator,
        name: 'leasingagent.calculator',
        meta: { layout: 'leasingagent' }
      },
      {
        path: 'deals-table',
        component: DealsTable,
        name: 'leasingagent.list-deals-table',
        meta: { layout: 'leasingagent' }
      }
    ]
  }
]

export default routes
