import {
  GET_USERS_NICKNAMES,
  SET_VEHICLE_VIN,
  GET_VEHICLE_BY_VIN,
  POST_UPDATE_VEHICLE,
} from "./actions";
import { mapState } from "vuex";

const registryVehicleMixin = {
  computed: {
    ...mapState({
      car: (state) => state.dealership.vehiclesStore.registry.vehicleItem.data,
    }),
  },
  data() {
    return {
      vehiclesFound: [],
      vehicle: {
        id: null,
        userId: null,
        stockNumber: null,
        releaseDate: null,
        crUrl: null,
        location: null,
        status: null,
        vehicleCostDetail: {
          price: 0.0,
          cost: 0.0,
        },
        vehicleInformation: {
          color: null,
          kilometers: null,
        },
        vinData: {
          doors: null,
          driveType: null,
          engine: null,
          madeIn: null,
          madeInCity: null,
          make: null,
          model: null,
          options: null,
          year: null,
          style: null,
          transmissionType: null,
          trim: null,
          vehicleType: null,
          vinNumber: null,
        },
      },
    };
  },
  methods: {
    setVehicleData() {
      // Vehicle //
      this.vehicle.id = this.car.id;
      this.vehicle.userId = this.car.usersId;
      this.vehicle.stockNumber = this.car.stockNumber;
      this.vehicle.releaseDate = this.car.releaseDate;
      this.vehicle.crUrl = this.car.crUrl;
      this.vehicle.location = this.car?.location ? this.car.location : null;
      // Vin Number //
      this.vehicle.vinData.year = this.car?.vinNumber?.year ? this.car.vinNumber.year : this.car[0].year;
      this.vehicle.vinData.doors = this.car?.vinNumber?.doors ? this.car.vinNumber.doors : this.car[0].doors;
      this.vehicle.vinData.make = this.car?.vinNumber?.make ? this.car.vinNumber.make : this.car[0].make;
      this.vehicle.vinData.model = this.car?.vinNumber?.model ? this.car.vinNumber.model : this.car[0].model;
      this.vehicle.vinData.trim = this.car?.vinNumber?.trim ? this.car.vinNumber.trim : this.car[0].trim;
      this.vehicle.vinData.style = this.car?.vinNumber?.style ? this.car.vinNumber.style : this.car[0].style;
      this.vehicle.vinData.vehicleType = this.car?.vinNumber?.vehicleType ? this.car.vinNumber.vehicleType : this.car[0].vehicleType;
      this.vehicle.vinData.madeIn = this.car?.vinNumber?.madeIn ? this.car.vinNumber.madeIn : this.car[0].madeIn;
      this.vehicle.vinData.madeInCity = this.car?.vinNumber?.madeInCity ? this.car.vinNumber.madeInCity : this.car[0].madeInCity;
      this.vehicle.vinData.driveType = this.car?.vinNumber?.driveType ? this.car.vinNumber.driveType : this.car[0].driveType;
      this.vehicle.vinData.transmissionType =
        this.car?.vinNumber?.transmissionType ? this.car.vinNumber.transmissionType : this.car[0].transmissionType;
      this.vehicle.vinData.engine = this.car?.vinNumber?.engine ? this.car.vinNumber.engine : this.car[0].engine;
      this.vehicle.vinData.options = this.car?.vinNumber?.options ? this.car.vinNumber.options : this.car[0].options;

      // Vehicle Information //
      this.vehicle.vehicleInformation.color = this.car?.vehicleInformation?.color ? this.car.vehicleInformation.color : this.car[0].color;
      this.vehicle.vehicleInformation.kilometers =
        this.car?.vehicleInformation?.kilometers ? this.car.vehicleInformation.kilometers : this.car[0].kilometers;
      // Vehcile Cost Detail //
      this.vehicle.vehicleCostDetail.price = this.car?.vehicleCostDetail?.price ? this.car.vehicleCostDetail?.price : this.car[0].price;
      this.vehicle.vehicleCostDetail.cost = this.car?.vehicleCostDetail?.cost ? this.car.vehicleCostDetail?.cost : this.car[0].cost;
      // Vehicle Parts //
      this.vehicleHasParts = this.car.vehicleHasParts;
    },
    async getVehicle(vin = null) {
      const vinNumber = vin ? vin : this.$route.params.vin;
      this.$store.commit(SET_VEHICLE_VIN, { fields: { vinNumber } });
      await this.$store.dispatch(GET_VEHICLE_BY_VIN).then(({ data }) => {
        this.vehiclesFound.push(data[0]);
        if (this.$route.name === "dealership.registry-vehicles")
          this.setVehicleData();
      });
    },
    async getUserNickNames() {
      await this.$store.dispatch(GET_USERS_NICKNAMES);
    },
    nextPage() {
      const data = new FormData();
      data.set("id", this.car.id);
      data.set("vehicle", JSON.stringify(this.vehicle));

      if (this.vehicle.stockNumber)
        data.set("stockNumber", this.vehicle.stockNumber);

      if (this.vehicle.releaseDate)
        data.set("releaseDate", this.vehicle.releaseDate);

      if (this.vehicle.userId) data.set("userId", this.vehicle.userId);

      if (this.vehicle.location) data.set("location", this.vehicle.location);

      data.set("crUrl", this.vehicle.crUrl);
      this.$store
        .dispatch(POST_UPDATE_VEHICLE, data)
        .then(() => {
          this.$router.push({ name: "dealership.list-vehicles" });
        })
        .catch(() => {
          this.$root.$bvToast.toast(
            "The vehicle information could not be updated",
            {
              title: "Warning",
              variant: "warning",
            }
          );
        });
    },
  },
};

export { registryVehicleMixin };
