<template>
  <div>
    <b-modal size="xl" :id="modalId" ref="modal" scrollable v-if="files">
      <div>
        <h2>
          {{ $tc("documentValidation") }}
        </h2>
        <h4>
          {{ $tc("authorizedSignatory") }}:
          <u>
            {{ userItem.signatoryFirstName }} {{ userItem.signatoryLastName }}
          </u>
        </h4>
        <h4>
          {{ $tc("company") }}: <u> {{ userItem.legalName }}</u>
        </h4>
      </div>
      <b-row>
        <b-col sm="12" md="5" lg="5" xl="5" class="my-auto text-center">
          <img
            v-if="filterImages.length > 0"
            :src="filterImages[0].photo"
            class="mt-2 img-fluid"
          />
          <br />
          <img
            v-if="filterImages.length > 1"
            :src="filterImages[1].photo"
            class="mt-2 img-fluid"
          />

          <div v-if="filterImages.length == 0" class="text-center my-auto">
            <b-icon
              icon="exclamation-circle"
              class="mt-1"
              variant="blue"
              font-scale="7.5"
            />
            <h2>
              {{ $tc("noImagesToShow") }}
            </h2>
          </div>
        </b-col>
        <!-- PDF -->

        <b-col sm="12" md="7" lg="7" xl="7">
          <vue-pdf-app
            v-if="filterPDF != null"
            class="h-100"
            :pdf="filterPDF"
            :currentPageNumber="1"
            :config="configPDF"
          ></vue-pdf-app>

          <div v-else class="text-center my-auto">
            <b-icon
              icon="exclamation-circle"
              class="mt-1"
              variant="blue"
              font-scale="7.5"
            />
            <h2>
              {{ $tc("noContractToShow") }}
            </h2>
          </div>
        </b-col>
      </b-row>

      <template v-slot:modal-footer="{ ok }">
        <b-btn variant="pink" @click="changeCompanyStatus(ok, 'Rejected')">
          Reject
        </b-btn>
        <b-btn variant="success" @click="changeCompanyStatus(ok, 'Validated')">
          <span>Accept</span>
        </b-btn>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { generalMixin } from "../../mixin";
/*  import { validationMixin } from "@/modules/shared/validation/mixin";**/

import { mapState } from "vuex";
import VuePdfApp from "vue-pdf-app";
// import this to use default icons for buttons
import "vue-pdf-app/dist/icons/main.css";

import { PUT_COMPANY_STATUS } from "./actions";
export default {
  name: "ImagesValidation",
  components: { VuePdfApp },
  mixins: [generalMixin],
  data() {
    return {
      modalId: "images-modal",
      configPDF: {
        toolbar: {
          toolbarViewerRight: {
            openFile: false,
            viewBookmark: false,
          },
        },
      },
    };
  },
  computed: {
    ...mapState({
      companyItem: (state) => state.shared.validation.companyItem,
      ready: (state) => state.shared.validation.ready,
      refreshData: (state) => state.shared.validation.refreshData,
      userItem: (state) => state.shared.validation.itemUser,
      files: (state) => state.shared.validation.itemUser.companyImage,
    }),
    filterPDF() {
      let pdf = null;
      this.files.forEach((file) => {
        if (file.type == "Contract") {
          pdf = file.photo;
        }
      });
      return pdf;
    },
    filterImages() {
      let images = [];
      this.files.forEach((file) => {
        if (file.type != "Contract") {
          images.push(file);
        }
      });
      return images;
    },
  },
  methods: {
    changeCompanyStatus(ok, status) {
      const payload = {
        id: this.userItem.id,
        status: status,
        typeValidation: "images",
      };
      this.$store
        .dispatch(PUT_COMPANY_STATUS, payload)
        .then(() => {
          this.makeToast("Success", "Company status updated", "success");
          this.refreshData();

          ok();
        })
        .catch((error) => {
          console.log(error);
          this.$toast.add({
              summary: this.$t('toast_title_error'),
              detail: this.$t('toast_message_error'),
              severity: "error",
              life: 5000
          })
        });
    },
  },

  mounted() {},
};
</script>

<style scoped>
.h-100 {
  min-height: 60vh !important;
}
</style>