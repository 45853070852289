<template>
  <div class="layout-topbar bg-blue">
    <button class="p-link layout-menu-button ml-2" @click="onMenuToggle">
      <span class="pi pi-bars"></span>
    </button>
    <div class="layout-topbar-icons">
      <button class="p-link" @click="changeLang()">
        <span class="layout-topbar-icon text-min pi pi-globe"></span>
        <span class="layout-topbar-item-text text-min ml-1 mr-2"
          >{{ $t("lang") }}
        </span>
      </button>
      <!-- 	<button class="p-link" @click="logout">
				<span class="layout-topbar-icon text-min pi pi-fw pi-power-off" ></span>  <span class="layout-topbar-item-text text-min">{{$t("logout")}}</span>
			</button> -->
    </div>
  </div>
</template>

<script>
import { AUTH_LOGOUT } from "@/modules/auth/login/actions";
export default {
  methods: {
    onMenuToggle(event) {
      this.$emit("menu-toggle", event);
    },
    changeLang() {
      if (this.$root.$i18n.locale == "fr") {
        this.$root.$i18n.locale = "en";
        window.localStorage.setItem("language", "en");
        location.reload()
      } else {
        this.$root.$i18n.locale = "fr";
        window.localStorage.setItem("language", "fr");
        location.reload()
      }
    },
    logout() {
      this.$store
        .dispatch(AUTH_LOGOUT)
        .then(() => {
          this.$router.push({ name: "home" });
        })
        .catch(() => {
          this.$toast.add({
            severity: "danger",
            summary: "Error",
            detail: "Error to logout",
            life: 4000,
          });
        });
    },
  },
};
</script>
<style>
.text-min {
  font-size: 16px !important;
}
</style>