<template>
  <b-modal
    size="md"
    :id="modalId"
    ref="modal"
    @show="getCompanyValidationInfo()"
    @hidden="resetForm()"
    scrollable
  >
    <!-- Brand / -->
    <span>
      <h3 class="text-primary">{{ $tc("companyValidations") }}</h3>
    </span>
    <div class="align-self-center text-center" v-if="!ready">
      <b-spinner class="m-1 align-self-center" label="Busy"></b-spinner>
    </div>
    <div class="m-4 text-break" v-if="ready">
      {{ $tc("legalName") }}
      <b-form-checkbox
        v-model="formValidation.legalName"
        name="checkbox-5"
        :checked="formValidation.legalName"
        :value="1"
        :unchecked-value="0"
      >
        {{ companyItem.legalName }}
      </b-form-checkbox>
      <br />
      SAAQ
      <b-form-checkbox
        id="checkbox-1"
        v-model="formValidation.saaq"
        name="checkbox-1"
        :value="1"
        :unchecked-value="0"
      >
        {{ companyItem.saaq }} </b-form-checkbox
      ><br />
      <span v-if="companyItem.typeNumber == 'NEQ'">NEQ </span>
      <span v-else> NEQ</span>

      <b-form-checkbox
        id="checkbox-3"
        v-model="formValidation.corporationNumber"
        name="checkbox-3"
        :value="1"
        :unchecked-value="0"
      >
        {{ companyItem.corporationNumber }}
      </b-form-checkbox>
      <div class="mt-3">
        <label >{{$t('select_leaseagent')}}</label>
        <b-form-select  v-model="selected" :options="selectedLeaseAgents" value-field="id" text-field="name" size="md" placeholder="Select"></b-form-select>
      </div>
    </div>
    <template v-slot:modal-footer="{ ok }">
      <b-btn variant="pink" @click="changeCompanyStatus(ok, 'Rejected')">
        {{$t('reject')}}
      </b-btn>
      <b-btn
        v-if="
          formValidation.legalName &&
          formValidation.saaq &&
          formValidation.corporationNumber &&
          formValidation.selected
        "
        variant="success"
        @click="changeCompanyStatus(ok, 'Validated')"
      >
        <span>{{$t('accept')}}</span>
      </b-btn>
      <b-btn
        v-if="
          !(
            formValidation.legalName &&
            formValidation.saaq &&
            formValidation.corporationNumber &&
            formValidation.selected
          )
        "
        variant="blue"
        :disabled="(formValidation.legalName==0 || formValidation.saaq==0 || formValidation.corporationNumber==0 || !selected!='' )"
        @click="updateValidations(ok)"
      >
        <span>{{$t('save')}}</span>
      </b-btn>
    </template>
  </b-modal>
</template>
<script>
import Brand from "@/modules/shared/brand.vue";
import { generalMixin } from "../../mixin";
import { validationMixin } from "@/modules/shared/validation/mixin";
import { mapState } from "vuex";
import { mapActions } from "vuex";
import { UPDATE_VALIDATIONS, PUT_COMPANY_STATUS } from "./actions";
export default {
  mixins: [generalMixin, validationMixin],
  components: { Brand },
  data() {
    return {
      modalId: "validation-modal",
      userItem: {},
      selectedLeaseAgents: null,
      selected: null,
    };
  },
  computed: {
    ...mapState({
      config: (state) => state.shared.validation.config,
      companyItem: (state) => state.shared.validation.companyItem,
      ready: (state) => state.shared.validation.ready,
      refreshData: (state) => state.shared.validation.refreshData,
    }),
  },
  created() {
          this.loadLeaseAgents()
  },
  methods: {
    ...mapActions(["loadUsersLeaseAgents"]),
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    closeModal(callback) {
      callback();
    },
    loadLeaseAgents(){
      this.loadUsersLeaseAgents().then((response) => {
        this.selectedLeaseAgents = response;
      }).catch((error) => {
      })
    },
    formatPayload() {
      const payload = {
        id: this.companyItem.validations.id,
        legalName: this.formValidation.legalName,
        saaq: this.formValidation.saaq ? this.formValidation.saaq : false,
        leaseAgent: this.selected,
        corporationNumber: this.formValidation.corporationNumber
          ? this.formValidation.corporationNumber
          : false,

        companyId: this.companyItem.id,
      };
      return payload;
    },
    changeCompanyStatus(ok, status) {
      const payload = {
        id: this.companyItem.id,
        status: status,
        typeValidation: "validation",
      };
       this.$store
        .dispatch(PUT_COMPANY_STATUS, payload)
        .then(() => {
          this.makeToast("Success", "Company status updated", "success");
          this.refreshData();
          ok();
        })
        .catch((error) => {
          this.$toast.add({
              summary: this.$t('toast_title_error'),
              detail: this.$t('toast_message_error'),
              severity: "error",
              life: 5000
          })
        });
    },
    resetForm() {},

    async updateValidations() {
      const payload = this.formatPayload();
      
      this.$store
        .dispatch(UPDATE_VALIDATIONS, payload)
        .then(() => {
          this.makeToast(
            "Success",
            "Validations updated succesfully",
            "success"
          );
          this.resetForm();
          this.refreshData();
          window.location.reload();
        })
        .catch(() => {
          this.$toast.add({
              summary: this.$t('toast_title_error'),
              detail: this.$t('toast_message_error'),
              severity: "error",
              life: 5000
          })
          this.resetForm();
          this.refreshData();
          window.location.reload();
        });
    },
  },
  mounted() {},
};
</script>
