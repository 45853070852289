/** @format */

import * as constants from './actions'
import axios from '@/plugins/axios.js'

function getDefaultState() {
  return {
    trackingNumber: {
      status: null,
      data: null,
      error: null
    }
  }
}

const actions = {
  [constants.POST_TRACKING_NUMBER]: async ({ commit }, params) => {
    try {
      commit(constants.POST_TRACKING_NUMBER)
      const response = await axios.post(`tracking/findOrCreate`, params)
      commit(constants.POST_TRACKING_NUMBER_SUCCESS, response)
      return response
    } catch (error) {
      commit(constants.POST_TRACKING_NUMBER_ERROR, error)
      throw error.response
    }
  }
}

const mutations = {
  [constants.POST_TRACKING_NUMBER]: state => {
    state.trackingNumber.status = 'fetching'
    state.trackingNumber.error = null
  },
  [constants.POST_TRACKING_NUMBER_SUCCESS]: (state, data) => {
    state.trackingNumber.status = 'success'
    state.trackingNumber.error = null
    state.trackingNumber.data = data
  },
  [constants.POST_TRACKING_NUMBER_ERROR]: (state, error) => {
    state.trackingNumber.status = 'error'
    state.trackingNumber.error = error
  }
}

export default {
  state: getDefaultState(),
  actions,
  mutations
}
