<template>
  <b-card class="mt-3">
    <Gallery
      @onSelectImage="selectImage"
      @onChangeTab="changeTab"
      :exterior="exterior"
      :interior="interior"
      :damages="damages"
      :damagesDots="damagesDots"
      :vehicleModel="vehicle.vinNumber.model"
      :vinNumber="vehicle.stockNumber"
      :make="vehicle.vinNumber.make"
      :model="vehicle.vinNumber.model"
      size="width: auto;  height: 4rem!important;"
      classImg="img-thumbnail"
    >
      <template #top>
        <b-row class="mb-3 pl-3 pr-3">
          <b-col class="text-center m-0 p-0">
            <Photoswipe rotate>
              <img
                style="height: 18rem"
                class="carousel-item active main-img img-thumbnail"
                :src="
                  imgNotFound || imgError
                    ? imgError
                    : tab === 0
                    ? exterior[index]
                    : interior[index]
                "
                v-pswp="
                  imgNotFound || imgError
                    ? imgError
                    : tab === 0
                    ? exterior[index]
                    : interior[index]
                "
                alt="car"
                @error="errorLoadImg"
              />
            </Photoswipe>
            <a
              class="carousel-control-prev"
              role="button"
              data-slide="prev"
              v-if="index > 0"
              @click="backImg"
            >
              <b-icon scale="2" icon="arrow-left-circle-fill" />
              <span class="sr-only">Previous</span>
            </a>
            <a
              class="carousel-control-next"
              role="button"
              data-slide="next"
              v-if="index < exterior.length - 1"
              @click="nextImg"
            >
              <b-icon scale="2" icon="arrow-right-circle-fill" />
              <span class="sr-only">Next</span>
            </a>
          </b-col>
        </b-row>
      </template>
    </Gallery>
    <b-row style="padding-left: -2rem; margin-bottom: -2px">
      <b-col class="mt-3">
        <h6 v-if="vehicle.stockNumber" class="stockNumber-mt">
          Stock:
          <i>
            {{ vehicle.stockNumber }}
          </i>
        </h6>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-row>
          <b-col>
            <b-row>
              <b-col v-if="vehicle.make || vehicle.vinNumber.make">
                <div class="mb-2">
                  Make:
                  <i>{{ vehicle.make || vehicle.vinNumber.make }}</i>
                </div>
              </b-col>
              <b-col v-if="vehicle.vinNumber.year || vehicle.year">
                <div class="ml-2 mb-2">
                  Year:
                  <i>{{ vehicle.vinNumber.year || vehicle.year }}</i>
                </div>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <div class="mb-2">
              Model:
              <i>
                {{ vehicle.model || vehicle.vinNumber.model }}
              </i>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            v-if="
              vehicle.trim ||
              (typeof vehicle.vinNumber === 'object' && vehicle.vinNumber.trim)
            "
          >
            <div class="mb-2">
              <i>{{ vehicle.trim || vehicle.vinNumber.trim }}</i>
            </div>
          </b-col>
          <b-col v-if="vehicle.options || vehicle.vinNumber.options">
            <div
              class="mb-2"
              :class="{
                'ml-2':
                  vehicle.trim ||
                  (typeof vehicle.vinNumber === 'object' &&
                    vehicle.vinNumber.trim),
              }"
            >
              <i>{{ vehicle.options || vehicle.vinNumber.options }}</i>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            v-if="vehicle.kilometers || vehicle.vehicleInformation.kilometers"
            class="ml-1"
          >
            Mileage:
            <i>
              {{ vehicle.kilometers || vehicle.vehicleInformation.kilometers }}
            </i>
          </b-col>
        </b-row>
        <b-row v-if="vehicle.releaseDateInReady" class="mb-2 mt-2">
          <b-col> Release Date: <i>Ready</i></b-col>
        </b-row>
        <b-row v-else-if="vehicle.releaseDate" class="mb-2 mt-2">
          <b-col>
            Release Date:
            <i>
              {{ vehicle.releaseDate | formatDate }}
            </i>
          </b-col>
        </b-row>
        <VehicleDetails :vehicle="vehicle" />
        <b-row
          v-if="
            vehicle.cost ||
            (vehicle.vehicleCostDetail && vehicle.vehicleCostDetail.cost)
          "
          class="mt-2 mb-2"
        >
          <b-col>
            Cost:
            <i v-if="vehicle.cost">{{ vehicle.cost | currency }}</i>
            <i v-else>{{ vehicle.vehicleCostDetail.cost | currency }}</i>
          </b-col>
        </b-row>
        <b-row v-if="vehicle.status && vehicle.status !== 'Sold'">
          <b-col class="mt-2 ml-1 mb-2">
            Status:
            <i>
              {{
                vehicle.status === "ReadyToSell"
                  ? "Ready To Sell"
                  : vehicle.status
              }}
            </i>
          </b-col>
        </b-row>
        <!--<b-row class="mb-2">
          <b-col :id="'tooltip-google-maps-' + vehicle.id">
            <img
              src="@/assets/img/mapaIcon.png"
              class="img-responsive"
              style="max-width: 20px"
            />
            Location
            <i>{{ vehicle.location }}</i>
            <NewYorkTooltip
              :id="vehicle.id"
              v-if="vehicle.location === 'NEW YORK'"
            />
            <MichiganTooltip :id="vehicle.id" v-else />
          </b-col>
        </b-row>-->
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import Gallery from "./Gallery";
import VehicleDetails from "./VehicleExtraDetails";

export default {
  components: { Gallery, VehicleDetails },
  props: ["vehicle"],
  data() {
    return {
      damages: [],
      interior: [],
      exterior: [],
      index: 0,
      damagesDots: [],
      imgError: "",
      imgNotFound: false,
      intervalPhotos: null,
      isGalleryGoingBack: false,
      tab: 0,
    };
  },
  methods: {
    selectImage(val) {
      this.imgError = "";
      this.imgNotFound = false;
      this.index = val.index;
      this.tab = val.tab;
      this.imgNotFound = val.imgNotFound;
    },
    changeTab(tab) {
      this.tab = tab;
      this.index = 0;
    },
    setDefaultPhotosState() {
      this.exterior = [];
      this.interior = [];
      this.damagePhotos = [];
      this.damages = [];
    },
    setImages() {
      this.setDefaultPhotosState();
      this.vehicle?.vehicleHasParts?.forEach((p) => {
        this.exterior.push(p.photo);
      });
      if (this.vehicle?.vehicleExternalImages?.length > 0) {
        this.vehicle?.vehicleExternalImages?.forEach((p) => {
          this.exterior.push(p.photo);
        });
      }
      if (this.vehicle?.damagePhotos?.length > 0) {
        this.damagesDots = this.vehicle.damagePhotos;
      }
      this.vehicle?.damagePhotos?.forEach((p) => {
        this.damages.push(p.damagePhoto);
      });
    },
    nextImg() {
      this.index++;
      this.imgNotFound = false;
      this.imgError = "";
    },
    backImg() {
      this.index--;
      this.imgNotFound = false;
      this.imgError = "";
    },
    errorLoadImg() {
      this.imgError = require("@/assets/img/image_not_found.png");
    },
    initIntervalPhotos() {
      this.intervalPhotos = setInterval(this.autoChangePhotos, 4000);
    },
    autoChangePhotos() {
      if (this.isGalleryGoingBack) {
        if (this.index === 0) {
          this.nextImg();
          this.isGalleryGoingBack = false;
        } else {
          this.backImg();
        }
      } else {
        if (this.index === this.exterior.length - 1) {
          this.backImg();
          this.isGalleryGoingBack = true;
        } else {
          this.nextImg();
        }
      }
    },
  },
  mounted() {
    this.setImages();
  },
};
</script>

<style></style>
