<template>
    <div class="container-fluid">
      <div v-if="refShow">
        <!-- p class="mt-3">
          Trim: <strong>{{ financialInfo.trim }}</strong> <br />
          Style: <strong>{{ financialInfo.style }}</strong> <br />
          Kms:
          <strong> {{ financialInfo.kms }}</strong>
        </p -->
          <b-table
            stacked="md"
            :items="financialInformationList"
            :fields="fields"
            head-row-variant="success"
          >
          </b-table>
      </div>
      <div v-else class="text-center">
        <span class="h3">{{ $t('message_not_ref_prices') }}</span>
      </div>
    </div>
  </template>
  <script>
  export default {
    name: "FinancialReferralPriceTable",
    props: ["financialReferralPriceInfo", "trimSelected", "refShow"],
    computed: {
      financialInformationList() {
        const financialInformationForma = this.formatFinancialInformation();
        return financialInformationForma;
      },
    },
    data() {
      return {
        fields: [
          { key: "suffix", label: "", variant: "success" },
          {
            key: "extraClean",
            label: this.$t('tref_extraclean'),
            class: "text-center",
            formatter: (extraClean) => this.$options.filters.currency(extraClean),
          },
          {
            key: "clean",
            label: this.$t('tref_clean'),
            class: "text-center",
            formatter: (clean) => this.$options.filters.currency(clean),
          },
          {
            key: "average",
            label: this.$t('tref_average'),
            class: "text-center",
            formatter: (average) => this.$options.filters.currency(average),
          },
          {
            key: "rough",
            label: this.$t('tref_rough'),
            class: "text-center",
            formatter: (rough) => this.$options.filters.currency(rough),
          },
        ],
        financialSelected: this.financialReferralPriceInfo,
      };
    },
    methods: {
      formatFinancialInformation() {
        let data = [
          {
            suffix: this.$t('tref_base'),
            extraClean: this.financialReferralPriceInfo.baseRetailXclean,
            clean: this.financialReferralPriceInfo.baseRetailClean,
            average: this.financialReferralPriceInfo.baseRetailAvg,
            rough: this.financialReferralPriceInfo.baseRetailRough,
          },
          {
            suffix: this.$t('tref_add_deduct'),
            extraClean: this.financialReferralPriceInfo.addDeductRetailXclean,
            clean: this.financialReferralPriceInfo.addDeductRetailClean,
            average: this.financialReferralPriceInfo.addDeductRetailAvg,
            rough: this.financialReferralPriceInfo.addDeductRetailRough,
          },
          {
            suffix: this.$t('tref_mileage'),
            extraClean: this.financialSelected.mileageRetailXclean,
            clean: this.financialSelected.mileageRetailClean,
            average: this.financialSelected.mileageRetailAvg,
            rough: this.financialSelected.mileageRetailRough,
          },
          {
            suffix: this.$t('tref_region'),
            extraClean: this.financialSelected.regionalRetailXclean,
            clean: this.financialSelected.regionalRetailClean,
            average: this.financialSelected.regionalRetailAvg,
            rough: this.financialSelected.regionalRetailRough,
          },
          {
            suffix: "Total",
            extraClean: this.financialSelected.adjustedRetailXclean,
            clean: this.financialSelected.adjustedRetailClean,
            average: this.financialSelected.adjustedRetailAvg,
            rough: this.financialSelected.adjustedRetailRough,
          },
        ];
        return data;
      },
      /*
      getFinancialReferralPricePDF() {
        this.$store
          .dispatch(GET_FINANCIAL_REFERRAL_PRICE_PDF, {
            id: this.financialSelected.id,
          })
          .then((response) => {
            const blob = new Blob([response], {
              type: "application/pdf",
            });
            const link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = this.vinNumber + ".pdf" || "file.pdf";
            link.click();
          })
          .catch(() => {
            this.$root.$bvToast.toast("Could not get file", {
              title: "Error",
              variant: "warning",
            });
          });
      },*/
    },
  };
  </script>