<template>
  <b-modal id="terms-modal" scrollable size="lg" class="text-justify">
    <div v-html="htmlString"></div>
    <template v-slot:modal-footer="{ cancel }">
      <b-button class="btn btn-pink" @click="closeModal('terms-modal', cancel)"
        >{{$t('close')}}</b-button
      >
    </template>
  </b-modal>
</template>

<script>
import { generalMixin } from "@/modules/mixin.js";

export default {
  mixins: [generalMixin],

  data() {
    return {
      htmlString: this.$t('terms_conditions'),
    };
  }
};
</script>

<style>
</style>