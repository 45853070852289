<template>
  <div class="p-col-12">
    <div class="card">
      <div>
        <template>
          <h1 class="text-primary">{{ $t("rates") }}</h1>
        </template>
      </div>
      <b-row>
        <b-col md="3" sm="12" class="mt-2">
          <h5>{{ $t("carType") }}</h5>
          <Dropdown
            v-model="carSelected"
            :options="options"
            optionLabel="text"
            :placeholder="$t('select_type')"
            style="width: 100%"
            @change="getCarRates($event.value.value)"
          />
        </b-col>
        <b-col lg="4" md="6" sm="12" v-if="commissionshow" class="mt-2">
          <h5>{{ $t("default_comission") }}</h5>
          <Dropdown
            v-model="carComission"
            :options="comission_options"
            optionLabel="text"
            style="width: 100%"
            :placeholder="$t('select_comission')"
          />
          <Button
            :label="$t('save')"
            icon="pi pi-check"
            class="p-button-success mt-2 float-right"
            @click="saveIncrease"
          />
        </b-col>
        <b-col md="4" sm="12" class="mt-2"> </b-col>
        <b-col cols="12" class="mt-3">
          <h4>{{ $t("interestRateH4") }}</h4>
          <!--    <DataTable
            :value="carRates"
            :loading="loading"
            editMode="cell"
            responsiveLayout="scroll"
            class="editable-cells-table dark"
            @cell-edit-complete="handleInput"
          >
            <Column
              v-for="col in fields"
              :field="col.field"
              :header="$t(col.header)"
              :key="col.key"
              :headerStyle="{
                backgroundColor: col.colorheader,
                color: '#FFF',
                borderLeft: col.border,
              }"
              :bodyStyle="{
                backgroundColor: col.color,
                width: col.size + '%',
                borderLeft: col.border,
              }"
            >
              <template #body="slotProps">
                <span v-if="col.percent">
                  {{ slotProps.data[slotProps.column.field] }} %</span
                >
                <span v-if="col.months">
                  {{ slotProps.data[slotProps.column.field] }}
                </span>
              </template>
              <template #editor="slotProps" v-if="col.editable">
                <InputText
                  v-model="slotProps.data[slotProps.column.field]"
                  autofocus
                />
              </template>
            </Column>
          </DataTable>
 -->
          <b-table
            responsive=""
            show-empty
            bordered
            small
            stacked="md"
            :items="carRates"
            :fields="fields"
            tbody-tr-class=""
            :busy="loading"
          >
            <template #table-busy>
              <div class="text-center text-danger my-2">
                <b-spinner class="align-middle" variant="primary" />
              </div>
            </template>

            <template v-slot:cell()="row">
              <div v-if="row.field.editable">
                <b-input-group append="%">
                  <b-form-input
                    @change="
                      handleInput({
                        data: row.item,
                        field: row.field.key,
                        value: row.item[row.field.key],
                        newValue: $event,
                      })
                    "
                    :value="row.item[row.field.key]"
                    type="number"
                    min="0"
                    max="100"
                    step="0.01"
                  />
                </b-input-group>
              </div>
              <span v-else>
                {{ row.item[row.field.key] }}
                {{ row.field.percent ? " %" : "" }}
              </span>
            </template>
          </b-table>
        </b-col>
      </b-row>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";

export default {
  data() {
    return {
      loading: false,
      filter: null,
      commissionshow: false,
      carComission: null,
      carSelected: { value: "Pickup", text: "Pickup" },
      options: [
        { value: "Pickup", text: this.$t("type_vehicule_pickup") },
        { value: "Light truck", text: this.$t("type_vehicule_lighttruck") },
        { value: "Heavy truck", text: this.$t("type_vehicule_heavytruck") },
        { value: "Car", text: this.$t("type_vehicule_car") },
        { value: "SUV", text: this.$t("type_vehicule_suv") },
      ],
      comission_options: [
        { value: 1, text: "1 %" },
        { value: 2, text: "2 %" },
        { value: 3, text: "3 %" },
      ],
    };
  },
  computed: {
    ...mapState(["carRates", "carTypeId", "auth"]),
    columnsWidth() {
      //return 100 / this.fields.length
    },
    profile: (state) => state.auth.loginStore.profile.data.user,

    fields() {
      let fields = [];
      if (this.$route.meta.layout == "admin") {
        fields = [
          {
            key: "months",
            label: this.$t("term"),
            editable: false,
            size: 8,
            percent: false,
            months: true,
            class: " align-middle",
          },

          {
            key: "new",
            label: this.$t("residual_new"),
            editable: true,
            thClass: "th-blue",
            border: "3px solid black",
            percent: true,
            formatter: (value) => {
              return value + " " + "%";
            },
          },
          {
            key: "used",
            label: this.$t("residual_used"),
            editable: true,
            percent: true,
            formatter: (value) => {
              return value + " " + "%";
            },
          },
          {
            key: "rateNew",
            label: this.$t("interest_new"),
            editable: true,

            color: "#DBFFCE",
            border: "3px solid black",
            percent: true,
            formatter: (value) => {
              return value + " " + "%";
            },
          },
          {
            key: "rateUsed",
            label: this.$t("interest_used"),
            editable: true,
            color: "#DBFFCE",
            percent: true,
            formatter: (value) => {
              return value + " " + "%";
            },
          },
        ];
      } else {
        fields = [
          {
            key: "months",
            label: this.$t("term"),
            editable: false,
            size: 8,
            thClass: "bg-custom-black text-white",
            percent: false,
            months: true,
            class: " align-middle",
          },
          {
            key: "new",
            label: this.$t("residual_new"),
            editable: false,
            thClass: "th-blue",
            border: "3px solid black",
            percent: true,
            formatter: (value) => {
              return value + " " + "%";
            },
          },
          {
            key: "used",
            label: this.$t("residual_used"),
            editable: false,
            size: 23,
            colorheader: "#4375C8",
            color: "#E2EDFF",
            percent: true,
            formatter: (value) => {
              return value + " " + "%";
            },
          },
          {
            key: "rateNew",
            label: this.$t("interest_new"),
            editable: false,

            border: "3px solid black",
            percent: true,
            formatter: (value) => {
              return value + " " + "%";
            },
          },
          //{ field: "increaseNew", header: "increase_new", editable: true, size: 11.5, color: "#DBFFCE",colorheader: "#09890C", percent: true },
          //{ field: "totalNew", header: "total_new", editable: false, size: 11.5, color: "#DBFFCE",colorheader: "#09890C", percent: true},
          {
            key: "rateUsed",
            label: this.$t("interest_used"),
            editable: false,
            size: 23,
            percent: true,
            formatter: (value) => {
              return value + " " + "%";
            },
          },
          //{ field: "increaseUsed", header: "increase_used", editable: true, size: 11.5, color: "#FEFDDC", colorheader: "#B69F0F",percent: true},
          // { field: "totalUsed", header: "total_used", editable: false, size: 11.5, color: "#FEFDDC",colorheader: "#B69F0F", percent: true},
        ];
      }
      return fields;
    },
    isAdmin() {
      return (
        this.auth.loginStore.profile.data.user.roles.filter(
          (role) => role.name == "ROLE_ADMIN"
        ).length > 0
      );
    },
  },
  methods: {
    ...mapActions([
      "updateRate",
      "fetchCarRates",
      "updateIncrease",
      "loadIncrease",
    ]),
    getCarRates(event) {
      this.loading = true;
      this.fetchCarRates(event).then(() => {
        this.loading = false;
        this.loadingIncrease(event);
      });
    },
    loadingIncrease(type) {
      console.log(type);
      this.loadIncrease(type).then((result) => {
        console.log(result);
        this.carComission = { value: result.increase, text: result.increase };
      });
    },
    saveIncrease() {
      const payload = {
        increase: this.carComission.value,
        type: this.carSelected.value,
      };
      this.updateIncrease(payload)
        .then((response) => {
          console.log(response);
          this.$toast.add({
            severity: "success",
            summary: this.$t("toast_title_success"),
            detail: this.$t("toast_message_success"),
            life: 4000,
          });
        })
        .catch(() => {
          this.$toast.add({
            severity: "error",
            summary: this.$t("toast_title_error"),
            detail: this.$t("toast_message_error"),
            life: 3000,
          });
        });
    },
    handleInput(event) {
      const { data, newValue, field, value } = event;
      if (value != newValue) {
        const payload = {
          id: this.carTypeId,
          months: data.months,
          modify: field,
          value: newValue,
          carSelected: this.carSelected.value,
        };
        this.updateRate(payload)
          .then(() => {
            this.$toast.add({
              severity: "success",
              summary: this.$t("toast_title_success"),
              detail: this.$t("toast_message_success"),
              life: 4000,
            });
          })
          .catch(() => {
            this.$toast.add({
              severity: "error",
              summary: this.$t("toast_title_error"),
              detail: this.$t("toast_message_error"),
              life: 3000,
            });
          });
      }
    },
  },
  mounted() {
    const role = this.profile.roles[0].name;
    if (role == "ROLE_DEALERSHIP") {
      this.commissionshow = true;
    }
  },
  created() {
    this.getCarRates(this.carSelected.value);
  },
};
</script>
<style lang="scss" scoped>
.p-dropdown {
  width: 14rem;
}
.text-white {
  color: #ffffff;
}

.th-blue {
  background-color: #4375c8 !important;
  color: #ffffff;
}
.th-green {
  background-color: #09890c;
  color: #ffffff;
}
.input-group .input-group-text {
  padding: 0rem 0.75rem;
  color: black;
}
</style>
