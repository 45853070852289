<template>
  <div>
    <b-form @submit.stop.prevent="save">
      <b-row class="mr-0 ml-0 mt-5">
        <b-col xs="12" sm="12" md="2" lg="2" xl="2"></b-col>
        <b-col xs="12" sm="12" md="8 " lg="8" xl="8">
          <h4>Company confirmation confirm</h4>
          <b-row>
            <!-- Company name -->
            <b-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
              <b-form-group label-size="sm">
                <template slot="label">
                  <span>
                    {{ $t("legalName") }}
                    <span class="text-danger"> *</span>
                  </span>
                </template>
                <b-form-input
                  size="sm"
                  @change="setValue('legalName', $event)"
                  :value="form.legalName"
                  v-model.trim="$v.form.legalName.$model"
                  :state="validateState('legalName')"
                  :maxlength="65"
                >
                </b-form-input>
                <b-form-invalid-feedback id="input-legalName-live-feedback">
                  This is a required field and must at least 2 characters.
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>

            <!-- NEQ -->
            <b-col sm="12" md="12" lg="6" xl="6">
              <b-form-group label-size="md" label="Taxid 1 *">
                <template slot="label">
                  <b-form-radio-group
                    id="radio-group-2"
                    v-model="form.typeNumber"
                    name="radio-sub-component"
                    maxlength="8"
                  >
                    <b-form-radio value="NEQ">NEQ</b-form-radio>
                    <b-form-radio value="BusinessNumber"
                      >Bussines number
                      <span class="text-danger">*</span></b-form-radio
                    >
                  </b-form-radio-group>
                </template>
                <b-form-input
                  required
                  size="sm"
                  name="corporationNumber"
                  maxlength="10"
                  @change="setValue('corporationNumber', $event)"
                  v-model.trim="$v.form.corporationNumber.$model"
                  :state="validateState('corporationNumber')"
                  :value="form.corporationNumber"
                />
                <b-form-invalid-feedback>
                  <div v-if="!$v.form.corporationNumber.required">
                    {{ $t("requiredField") }}
                  </div>
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>

            <!-- SAAQ -->
            <b-col sm="12" md="12" lg="6" xl="6">
              <b-form-group label-size="md" label="Dealer license *">
                <template slot="label">
                  SAAQ <span class="text-danger">*</span>
                </template>

                <b-form-input
                  required
                  size="sm"
                  name="saaq"
                  maxlength="10"
                  @change="setValue('saaq', $event)"
                  :value="form.saaq"
                  v-model.trim="$v.form.saaq.$model"
                  :state="validateState('saaq')"
                />
                <b-form-invalid-feedback>
                  <div v-if="!$v.form.saaq.required">
                    {{ $t("requiredField") }}
                  </div>
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col sm="12" md="3" lg="3" xl="3" class="mb-4"> </b-col>
            <b-col sm="12" md="6" lg="6" xl="6" class="mb-4">
              <b-button
                class="btn btn-light-blue text-white"
                type="submit"
                block
                :disabled="$v.form.$invalid || isLoading"
              >
                {{ $tc("confirm") }}

                <b-spinner v-if="isLoading" small variant="white"></b-spinner>
              </b-button>
            </b-col>
            <b-col sm="12" md="3" lg="3" xl="3" class="mb-4"> </b-col>
          </b-row>
        </b-col>
        <b-col xs="12" sm="12" md="2" lg="2" xl="2" cols="12"></b-col>
      </b-row>
      <br />
    </b-form>
  </div>
</template>

<script>
import { required, numeric } from "vuelidate/lib/validators";
import { POST_USER_COMPANY } from "./actions";
import { GET_COMPANY } from "../document/actions";
import { mapState } from "vuex";

export default {
  data() {
    return {
      form: {
        typeNumber: null,
        legalName: null,
        corporationNumber: null,
        saaq: null,
      },
    };
  },
  validations: {
    form: {
      legalName: {
        required,
      },
      corporationNumber: {
        required,
      },

      saaq: {
        required,
        numeric,
      },
    },
  },
  computed: {
    ...mapState({
      user: (state) => state.dealership.buyerStore.user,
    }),
    isLoading() {
      return this.user.status == "fetching" ? true : false;
    },
  },
  methods: {
    setValue(key, value) {
      this.form[key] = value;
      this.$v.form[key].$touch();
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    save() {
      const payload = this.formatPayload();
      this.$store
        .dispatch(POST_USER_COMPANY, payload)
        .then(() => {
          this.$root.$bvToast.toast(this.$tc("dataSuccessfullyUpdated"), {
            title: this.$tc("success"),
            variant: "success",
          });
          this.goTo();
        })
        .catch(() => {
          this.$toast.add({
              summary: this.$t('toast_title_error'),
              detail: this.$t('toast_message_error'),
              severity: "error",
              life: 5000
          })
        });
    },

    formatPayload() {
      const payload = {
        id: this.form.id,
        legalName: this.form.legalName,
        corporationNumber: this.form.corporationNumber,
        saaq: this.form.saaq,
        typeNumber: this.form.typeNumber,
      };
      return payload;
    },
    getCompany() {
      this.$store
        .dispatch(GET_COMPANY, {
          companyToken: this.$route.params.token,
        })
        .then((response) => {
          this.form = { ...response.data[0] };
        })
        .catch(() => {
          this.$toast.add({
              summary: this.$t('toast_title_error'),
              detail: this.$t('toast_message_error'),
              severity: "error",
              life: 5000
          })
        });
    },
    goTo() {
      this.$router.push({ path: "/" });
    },
  },
  beforeMount() {
    this.getCompany();
  },
};
</script>

<style>
</style>