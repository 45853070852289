import list from "./list/store";
import registry from "./registry/store";

export default {
  state: {},
  actions: {},
  mutations: {},
  modules: {
    list,
    registry
  },
};
