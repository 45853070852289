/** @format */

export const POST_NOTE = 'POST_NOTE'
export const POST_NOTE_SUCCESS = 'POST_NOTE_SUCCESS'
export const POST_NOTE_ERROR = 'POST_NOTE_ERROR'

export const SET_NOTES_FILTERS = 'SET_NOTES_FILTERS'

export const GET_NOTES = 'GET_NOTES'
export const GET_NOTES_SUCCESS = 'GET_NOTES_SUCCESS'
export const GET_NOTES_ERROR = 'GET_NOTES_ERROR'
