<template>
  <!--<div v-if="status === 'fetching'" class="text-center">
    <b-spinner variant="blue" />
  </div>-->
  <div>
    <b-card
      header-bg-variant="blue"
      header-text-variant="white"
      class="shadow mb-3 mt-3"
    >
      <template #header>
        <span class="float-right text-white">
          <strong>(VIN) {{ $route.params.vin }} </strong>
        </span>
      </template>

      <!-- Row 1 -->
      <div class="grid">
        <div class="nested-grid">
          <div>
            <small>
              <strong>Year</strong><span class="text-danger"> *</span>
            </small>
          </div>
          <div>
            <b-form-group>
              <b-form-input
                class="text-center"
                type="text"
                @change="setValue('vinData', 'year', $event)"
                :value="vehicle.vinData.year"
                v-model.trim="$v.vehicle.vinData.year.$model"
                :state="validateState('vinData', 'year')"
              />
              <b-form-invalid-feedback>
                <div v-if="!$v.vehicle.vinData.year.required">
                  This field is required
                </div>
                <div
                  v-if="
                    !$v.vehicle.vinData.year.minValue ||
                    !$v.vehicle.vinData.year.maxValue
                  "
                >
                  Invalid value
                </div>
              </b-form-invalid-feedback>
            </b-form-group>
          </div>
        </div>
        <!-- Make -->
        <div class="nested-grid">
          <div>
            <small>
              <strong>Make</strong>
              <span class="text-danger"> *</span>
            </small>
          </div>
          <div>
            <b-form-group>
              <b-form-input
                class="text-center"
                type="text"
                @change="setValue('vinData', 'make', $event)"
                :value="vehicle.vinData.make"
                v-model.trim="$v.vehicle.vinData.make.$model"
                :state="validateState('vinData', 'make')"
              />
              <b-form-invalid-feedback>
                <div v-if="!$v.vehicle.vinData.make.required">
                  This field is required
                </div>
                <div
                  v-if="
                    !$v.vehicle.vinData.make.maxLength ||
                    !$v.vehicle.vinData.make.minLength
                  "
                >
                  Invalid value
                </div>
              </b-form-invalid-feedback>
            </b-form-group>
          </div>
        </div>
        <!-- Model -->
        <div class="nested-grid">
          <div>
            <small>
              <strong>Model</strong>
              <span class="text-danger"> *</span>
            </small>
          </div>
          <div>
            <b-form-group>
              <b-form-input
                class="text-center"
                type="text"
                @change="setValue('vinData', 'model', $event)"
                :value="vehicle.vinData.model"
                v-model.trim="$v.vehicle.vinData.model.$model"
                :state="validateState('vinData', 'model')"
              />
              <b-form-invalid-feedback>
                <div v-if="!$v.vehicle.vinData.model.required">
                  This field is required
                </div>
                <div
                  v-if="
                    !$v.vehicle.vinData.model.maxLength ||
                    !$v.vehicle.vinData.model.minLength
                  "
                >
                  Invalid value
                </div>
              </b-form-invalid-feedback>
            </b-form-group>
          </div>
        </div>
        <!-- Trim -->
        <div class="nested-grid">
          <div>
            <small><strong>Trim</strong></small>
          </div>
          <div>
            <b-form-group>
              <b-form-input
                class="text-center"
                type="text"
                :value="vehicle.vinData.trim"
                v-model.trim="vehicle.vinData.trim"
              />
            </b-form-group>
          </div>
        </div>
      </div>

      <!-- Row 2 -->
      <div class="grid">
        <!-- Options -->
        <div class="nested-grid">
          <div>
            <small>
              <strong>Options</strong>
            </small>
          </div>
          <div>
            <b-form-group>
              <b-form-input
                class="text-center"
                type="text"
                :value="vehicle.vinData.options"
                v-model.trim="vehicle.vinData.options"
              />
            </b-form-group>
          </div>
        </div>
        <!-- Style -->
        <div class="nested-grid">
          <div>
            <small><strong>Style</strong></small>
          </div>
          <div>
            <b-form-group>
              <b-form-input
                class="text-center"
                type="text"
                :value="vehicle.vinData.style"
                v-model.trim="vehicle.vinData.style"
              />
            </b-form-group>
          </div>
        </div>
        <!--Doors -->
        <div class="nested-grid">
          <div>
            <small><strong>Doors</strong></small>
          </div>
          <div>
            <b-form-group>
              <b-form-input
                class="text-center"
                type="text"
                :value="vehicle.vinData.doors"
                v-model.trim="vehicle.vinData.doors"
              />
            </b-form-group>
          </div>
        </div>
        <!-- Vehicle type -->
        <div class="nested-grid">
          <div>
            <small><strong>Vehicle Type</strong></small>
          </div>
          <div>
            <b-form-group>
              <b-form-input
                class="text-center"
                type="text"
                :value="vehicle.vinData.vehicleType"
                v-model.trim="vehicle.vinData.vehicleType"
              />
            </b-form-group>
          </div>
        </div>
      </div>

      <!-- Row 3 -->
      <div class="grid">
        <!-- Drive type -->
        <div class="nested-grid">
          <div>
            <small><strong>Drive Type</strong></small>
          </div>
          <div>
            <b-form-group>
              <b-form-input
                class="text-center"
                type="text"
                :value="vehicle.vinData.driveType"
                v-model.trim="vehicle.vinData.driveType"
              />
            </b-form-group>
          </div>
        </div>

        <!-- Engine -->
        <div class="nested-grid">
          <div>
            <small><strong>Engine</strong></small>
          </div>
          <div>
            <b-form-group>
              <b-form-input
                class="text-center"
                type="text"
                :value="vehicle.vinData.engine"
                v-model.trim="vehicle.vinData.engine"
              />
            </b-form-group>
          </div>
        </div>

        <!-- Transmission -->
        <div class="nested-grid">
          <div>
            <small><strong>Transmission</strong></small>
          </div>
          <div>
            <b-input-group v-if="!disabled" class="d-flex justify-content-end">
              <b-form-radio-group
                style="max-height: 2.3rem"
                class="transmission"
                :value="vehicle.vinData.transmissionType"
                v-model.trim="vehicle.vinData.transmissionType"
                :options="typeOptions"
                name="transmission"
                button-variant="outline-primary"
                buttons
              />
            </b-input-group>
            <b-form-group v-else>
              <b-form-input
                class="text-center"
                :disabled="disabled"
                type="text"
                :value="car.transmissionType"
              />
            </b-form-group>
          </div>
        </div>

        <!-- Color -->
        <div class="nested-grid">
          <div>
            <small>
              <strong>Color</strong>
              <span class="text-danger"> *</span>
            </small>
          </div>
          <div>
            <b-form-group>
              <b-form-input
                class="text-center"
                :disabled="disabled"
                @change="setValue('vehicleInformation', 'color', $event)"
                :value="vehicle.vehicleInformation.color"
                v-model.trim="$v.vehicle.vehicleInformation.color.$model"
                :state="validateState('vehicleInformation', 'color')"
                type="text"
              />
              <b-form-invalid-feedback>
                <div v-if="!$v.vehicle.vehicleInformation.color.required">
                  This field is required
                </div>
              </b-form-invalid-feedback>
            </b-form-group>
          </div>
        </div>
      </div>

      <!-- Row 4 -->
      <div class="grid">
        <!-- Miles -->
        <div class="nested-grid">
          <div>
            <small>
              <strong>Kilometers</strong>
            </small>
          </div>
          <div>
            <b-form-group>
              <b-form-input
                class="text-center"
                :disabled="disabled"
                v-model="vehicle.vehicleInformation.kilometers"
                type="number"
              />
            </b-form-group>
          </div>
        </div>

        <!-- Price -->
        <div class="nested-grid">
          <div>
            <small>
              <strong>Price <span class="text-danger"> *</span> </strong>
            </small>
          </div>
          <div>
            <b-form-group>
              <Money
                style="background-color: #0047ba"
                class="form-control text-center text-white"
                v-model.trim="$v.vehicle.vehicleCostDetail.price.$model"
                v-bind="money"
                :state="validateState('vehicleCostDetail', 'price')"
                @change="setValue('vehicleCostDetail', 'price', $event)"
              />
              <b-form-invalid-feedback>
                <div v-if="!$v.vehicle.vehicleCostDetail.price.required">
                  This field is required
                </div>
                <div
                  v-if="
                    !$v.vehicle.vehicleCostDetail.price.maxLength ||
                    !$v.vehicle.vehicleCostDetail.price.minLength
                  "
                >
                  Invalid value
                </div>
              </b-form-invalid-feedback>
            </b-form-group>
          </div>
        </div>

        <!-- Stock -->
        <div class="nested-grid">
          <div>
            <small>
              <strong>Stock</strong>
            </small>
          </div>
          <div>
            <b-form-group>
              <b-form-input class="text-center" v-model="vehicle.stockNumber" />
            </b-form-group>
          </div>
        </div>

        <!-- Release Date -->
        <div class="nested-grid">
          <div>
            <small><strong>Release Date</strong></small>
          </div>
          <div>
            <b-form-group>
              <b-form-input
                class="text-center"
                type="date"
                v-model.trim="vehicle.releaseDate"
              />
            </b-form-group>
          </div>
        </div>
      </div>

      <!-- Row 5 -->
      <div class="grid">
        <!-- CrUrL -->
        <div class="nested-grid">
          <div>
            <small>
              <strong>CrUrl</strong>
              <span class="text-danger"> *</span>
            </small>
          </div>
          <div>
            <b-form-group>
              <b-form-input
                class="text-center"
                @change="setValue(null, 'crUrl', $event)"
                :value="vehicle.crUrl"
                v-model.trim="$v.vehicle.crUrl.$model"
                :state="validateState(null, 'crUrl')"
                type="text"
              />
              <b-form-invalid-feedback>
                <div v-if="!$v.vehicle.crUrl.required">
                  This field is required
                </div>
                <div
                  v-if="
                    !$v.vehicle.crUrl.maxLength || !$v.vehicle.crUrl.minLength
                  "
                >
                  Invalid value
                </div>
              </b-form-invalid-feedback>
            </b-form-group>
          </div>
        </div>

        <!-- Agent -->
        <div v-if="usersNicknames" class="nested-grid">
          <div>
            <small>
              <strong>Agent</strong>
              <span class="text-danger"> *</span>
            </small>
          </div>
          <div>
            <b-form-group>
              <b-form-select
                v-model="vehicle.userId"
                :options="usersNicknames.data"
                value-field="id"
                text-field="nickname"
                class="agent"
                @change="setValue(null, 'userId', $event)"
                :state="validateState(null, 'userId')"
              >
                <template v-slot:first>
                  <b-form-select-option :value="null">
                    Select
                  </b-form-select-option>
                </template>
              </b-form-select>
              <b-form-invalid-feedback>
                <div v-if="!$v.vehicle.userId.required">
                  This field is required
                </div>
              </b-form-invalid-feedback>
            </b-form-group>
          </div>
        </div>

        <!-- Location -->
        <div class="nested-grid">
          <div>
            <small>
              <strong>Location</strong>
              <span class="text-danger"> *</span>
            </small>
          </div>
          <div>
            <b-form-group>
              <b-form-select
                :value="vehicle.location"
                v-model.trim="$v.vehicle.location.$model"
                :options="locations"
                class="location"
                @change="setValue(null, 'location', $event)"
                :state="validateState(null, 'location')"
              >
                <template v-slot:first>
                  <b-form-select-option :value="null">
                    Select
                  </b-form-select-option>
                </template>
              </b-form-select>

              <b-form-invalid-feedback>
                <div v-if="!$v.vehicle.location.required">
                  This field is required
                </div>
              </b-form-invalid-feedback>
            </b-form-group>
          </div>
        </div>
      </div>
    </b-card>
    <b-container fluid class="mt-4">
      <b-row>
        <b-col sm="2" xl="6" class="d-flex">
          <!---<b-button
            variant="danger"
            size="lg"
            class="trash-button"
            @click="deleteVehicle()"
          >
            <b-icon icon="trash-fill" />
          </b-button>-->
        </b-col>
        <b-col sm="2" xl="6" class="d-flex justify-content-end">
          <div class="cancel-next-button">
            <b-button
              variant="outline-secondary"
              class="mr-2"
              size="lg"
              @click="cancelEdit()"
            >
              Cancel
            </b-button>
            <b-button
              size="lg"
              type="submit"
              variant="blue"
              :disabled="$v.vehicle.$invalid"
              @click="nextPage"
            >
              Next
              <b-icon icon="chevron-compact-right" scale="1" />
            </b-button>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { Money } from "v-money";
import { mapState } from "vuex";
import {
  required,
  maxValue,
  minValue,
  maxLength,
  minLength,
} from "vuelidate/lib/validators";
import { registryVehicleMixin } from "../mixins";
export default {
  mixins: [registryVehicleMixin],
  components: { Money },
  props: ["disabled"],
  computed: {
    ...mapState({
      usersNicknames: (state) =>
        state.dealership.vehiclesStore.registry.usersNicknames.data,
    }),
  },
  data() {
    return {
      money: {
        decimal: ".",
        thousands: ",",
        prefix: "USD$ ",
        suffix: "",
        precision: 2,
        masked: false /* doesn't work with directive */,
      },
      typeOptions: [
        { value: "Manual", text: "M" },
        { value: "Automatic", text: "A" },
        { value: "CVT", text: "CVT" },
      ],
      locations: [
        { value: "MANITOBA", text: "MANITOBA" },
        { value: "NUNAVUT", text: "NUNAVUT" },
        { value: "ONTARIO", text: "ONTARIO" },
        { value: "QUEBEC", text: "QUEBEC" },
      ],
    };
  },
  validations: {
    vehicle: {
      crUrl: {
        required,
        maxLength: maxLength(255),
        minLength: minLength(5),
      },
      userId: {
        required,
      },
      location: {
        required,
      },
      vehicleCostDetail: {
        price: {
          required,
          maxValue: maxValue(1000000),
          minValue: minValue(1),
        },
      },
      vehicleInformation: {
        color: {
          required,
        },
      },
      vinData: {
        year: {
          required,
          maxValue: maxValue(2050),
          minValue: minValue(1900),
        },
        make: {
          required,
          maxLength: maxLength(50),
          minLength: minLength(2),
        },
        model: {
          required,
          maxLength: maxLength(50),
          minLength: minLength(2),
        },
      },
    },
  },
  methods: {
    setValue(key, attribute, value) {
      if (key) {
        this.vehicle[key][attribute] = value;
        this.$v.vehicle[key][attribute].$touch();
      } else {
        this.vehicle[attribute] = value;
        this.$v.vehicle[attribute].$touch();
      }
    },
    validateState(key, name) {
      const { $dirty, $error } = key
        ? this.$v.vehicle[key][name]
        : this.$v.vehicle[name];
      return $dirty ? !$error : null;
    },
    confirmDeleteVehicle() {
      return this.$bvModal.msgBoxConfirm(
        "Please confirm that you want to delete the vehicle.",
        {
          title: "Please Confirm",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "YES",
          cancelTitle: "NO",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        }
      );
    },
    async deleteVehicle() {
      const confirm = await this.confirmDeleteVehicle();
      if (!confirm) {
        return;
      } else {
        this.updateStatus("Inactive");
      }
    },
    cancelEdit() {
      this.$router.push({
        name: "dealership.list-vehicles",
      });
    },
  },
  beforeMount() {
    this.getVehicle();
    this.getUserNickNames();
  },
};
</script>

<style scoped>
.border-left {
  border-left: 5px black;
}
.bg-primary {
  background-color: #35a4dd;
}
.font-small {
  font-size: 12px;
}
.border-b {
  border-bottom: 10px black;
}
.transmission {
  width: 100%;
}
.grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1em;
}

.nested-grid {
  display: grid;
  grid-template-columns: 30% 70%;
}

@media (max-width: 576px) {
  .grid {
    grid-template-columns: repeat(1, 1fr);
  }

  .cancel-next-button {
    margin-top: -45px;
  }
}

@media (max-width: 300px) {
  .nested-grid {
    grid-template-columns: repeat(1, 1fr);
  }
  .trash-button {
    position: absolute;
    margin-top: -15px;
    margin-left: 10px;
  }
  .cancel-next-button {
    margin-top: 40px;
  }
}
</style>
