<template>
  <div class="card">
    <div class="d-flex justify-content-between">
      <template>
        <h1 class="text-primary">{{ $t("calculator") }}</h1>
      </template>
      <template>
<!--        <Button
          :label="$t('download')"
          :disabled="!unlock"
          icon="pi pi-cloud-download"
          @click="dowloadComparation()"
        />-->
      </template>
    </div>
<!--    <div class="p-grid p-align-center p-justify-center">-->
    <div class="p-grid p-align-center p-justify-center" style="display: flex; justify-content: space-between;">
      <div style="display: flex; align-items: center; margin-left: 10px">
        <i class="pi pi-car p-mr-5" style="font-size: 2rem"></i>
        <h1 class="p-mr-5" style="margin-top: 10px">A</h1>
      </div>

      <div style="display: flex; align-items: center; margin-right: 10px">
        <h1 class="p-mr-5" style="margin-top: 10px">B</h1>
        <i class="pi pi-car" style="font-size: 2rem"></i>
      </div>
    </div>



    <div class="p-grid">
      <!-- <div class="p-lg-6 p-md-12" id="calculator-1">
                    <CalculatorComponent :buttonSend="buttonSend" :permit="profile.incrementRate" :number="1" :fieldsP="fields" :vinP="vin" :yearP="yearSelected" :makeP="makeSelected" :modelP="modelSelected" />
                </div>
                <div class="p-lg-6 p-md-12" id="calculator-2">
                    <CalculatorComponent :buttonSend="buttonSend" :number="2" :permit="profile.incrementRate"/>
                </div> -->
    </div>
    <div class="p-grid">
      <div class="p-lg-6 p-md-12">
        <NewCalculator
          :buttonSend="buttonSend"
          :permit="profile.incrementRate"
          :number="1"
        ></NewCalculator>
      </div>
      <div class="p-lg-6 p-md-12">
        <NewCalculator
          :buttonSend="buttonSend"
          :permit="profile.incrementRate"
          :number="2"
        ></NewCalculator>
      </div>
    </div>
  </div>
</template>

<script>
import CalculatorComponent from "@/modules/shared/calculator/CalculatorComponent.vue";
import NewCalculator from "@/modules/shared/calculator/NewCalculatorComponent.vue";
import { mapActions, mapState } from "vuex";
export default {
  components: { CalculatorComponent, NewCalculator },
  data() {
    return {
      isCarAdded: false,
      vin: null,
      yearSelected: null,
      makeSelected: null,
      modelSelected: null,
      fields: "",
      unlock: false,
      calculator1: {},
      calculator2: {},
      payload: {},
      buttonSend: false,
    };
  },
  methods: {
    ...mapActions(["downloadDocumentComparation"]),
    addCar() {
      this.isCarAdded = true;
    },
    removeCar() {
      this.isCarAdded = false;
    },
    dowloadComparation(customer) {
      var deal = this.calculator1.deal !== undefined ? this.calculator1.deal : this.calculator2.deal;
      const currentDate = new Date();
      const currentDay = ('0' + currentDate.getDate()).slice(-2);
      const currentMonth = ('0' + (currentDate.getMonth() + 1)).slice(-2);
      const currentYear = String(currentDate.getFullYear()).slice(-2);
      var name = 'offre-'+deal.typeValue+'-term('+deal.terms+')-('+currentDay + currentMonth + currentYear+').pdf';
      this.payload = {
        calculator1: this.calculator1,
        calculator2: this.calculator2,
        name: customer.fullName,
        email: customer.email,
        phone: '7777777777'
      };
      this.downloadDocumentComparation(this.payload)
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", name);
          document.body.appendChild(link);
          link.click();
        })
        .catch((error) => {
          this.$toast.add({
            severity: "error",
            summary: error,
          });
        });
    },
    updateCalculatriceModel(item) {
      if (item.number == 1) {
        this.calculator1 = item;
        if (this.calculator1.condition != "") {
          this.unlock = true;
        }
      } else {
        this.calculator2 = item;
        if (this.calculator2.condition != "") {
          this.unlock = true;
        }
      }
    },
  },
  beforeMount() {
    this.vin = this.$route.query.vin;
    this.yearSelected = this.$route.query.year;
    this.makeSelected = this.$route.query.make;
    this.modelSelected = this.$route.query.model;
  },
  computed: {
    ...mapState(["auth"]),
    profile: (state) => state.auth.loginStore.profile.data.user,
  },
  mounted() {
    const role = this.profile.roles[0].name;
    if (role == "ROLE_AGENT" || role == "ROLE_DEALERSHIP") {
      this.buttonSend = true;
    }
    locale: window.localStorage.getItem("language") ||
      window.navigator.systemLanguage ||
      window.navigator.language;
  },
};
</script>

<style>
.h-custom {
  color: #0047ba;
}

.input-l {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  box-sizing: border-box;
  border: 3px solid #0047ba;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  outline: none;
}

select {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  box-sizing: border-box;
  border: 3px solid #0047ba;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  outline: none;
}
</style>
