/** @format */

import * as constants from './actions'
import axios from '@/plugins/axios.js'

function getDefaultState() {
  return {
    sign: null,
    company: {
      status: null,
      data: null,
      error: null
    },
    uploadImage: {
      status: null,
      data: null,
      error: null
    },
    contract: {
      status: null,
      data: null,
      error: null
    }
  }
}

const actions = {
  [constants.GET_SERVER_TIME]: async () => {
    try {
      const response = await axios.get(`auth/dateTime`)
      return response
    } catch (error) {
      throw error.response
    }
  },
  [constants.GET_COMPANY]: async ({ commit }, params) => {
    try {
      commit(constants.GET_COMPANY)

      const response = await axios.get(`company`, {
        params: { ...params }
      })
      commit(constants.GET_COMPANY_SUCCESS, response)

      return response
    } catch (error) {
      commit(constants.GET_COMPANY_ERROR, error)

      throw error.response
    }
  },

  [constants.POST_UPLOAD_IMAGE]: async ({ commit }, data) => {
    try {
      commit(constants.POST_UPLOAD_IMAGE)
      const headers = {
        'Content-Type': 'application/form-data'
      }
      const response = await axios.post(
        `company/validations/uploadImages`,
        data,
        {
          headers
        }
      )
      commit(constants.POST_UPLOAD_IMAGE_SUCCESS, response)
      return response
    } catch (error) {
      commit(constants.POST_UPLOAD_IMAGE_ERROR, error)
      throw error.response
    }
  },

  [constants.POST_CONTRACT]: async ({ commit }, params) => {
    try {
      commit(constants.POST_CONTRACT)
      const response = await axios.post(`user/contract`, params)
      commit(constants.POST_CONTRACT_SUCCESS, response)
      return response
    } catch (error) {
      commit(constants.POST_CONTRACT_ERROR, error)
      throw error.response
    }
  },

  [constants.POST_SEND_MESSSAGE]: async ({ commit }, params) => {
    console.log('parrams', commit)

    try {
      const response = await axios.post(
        `company/validations/sendSmsImage`,
        params
      )
      return response
    } catch (error) {
      throw error.response
    }
  }
}

const mutations = {
  setSign: (state, sign) => {
    state.sign = sign
  },

  [constants.GET_COMPANY]: state => {
    state.company.status = 'fetching'
    state.company.error = null
  },
  [constants.GET_COMPANY_SUCCESS]: (state, data) => {
    state.company.status = 'success'
    state.company.error = null
    state.company.data = data
  },
  [constants.GET_COMPANY_ERROR]: (state, error) => {
    state.company.status = 'error'
    state.company.error = error
  },

  [constants.POST_UPLOAD_IMAGE]: state => {
    state.uploadImage.status = 'fetching'
    state.uploadImage.error = null
  },
  [constants.POST_UPLOAD_IMAGE_SUCCESS]: (state, data) => {
    state.uploadImage.status = 'success'
    state.uploadImage.error = null
    state.uploadImage.data = data
  },
  [constants.POST_UPLOAD_IMAGE_ERROR]: (state, error) => {
    state.uploadImage.status = 'error'
    state.uploadImage.error = error
  },

  [constants.POST_CONTRACT]: state => {
    state.contract.status = 'fetching'
    state.contract.error = null
  },
  [constants.POST_CONTRACT_SUCCESS]: (state, data) => {
    state.contract.status = 'success'
    state.contract.error = null
    state.contract.data = data
  },
  [constants.POST_CONTRACT_ERROR]: (state, error) => {
    state.contract.status = 'error'
    state.contract.error = error
  }
}

export default {
  state: getDefaultState(),
  actions,
  mutations
}
