var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-card',{staticClass:"mt-3"},[_c('Gallery',{attrs:{"exterior":_vm.exterior,"interior":_vm.interior,"damages":_vm.damages,"damagesDots":_vm.damagesDots,"vehicleModel":_vm.vehicle.vinNumber.model,"vinNumber":_vm.vehicle.stockNumber,"make":_vm.vehicle.vinNumber.make,"model":_vm.vehicle.vinNumber.model,"size":"width: auto;  height: 4rem!important;","classImg":"img-thumbnail"},on:{"onSelectImage":_vm.selectImage,"onChangeTab":_vm.changeTab},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('b-row',{staticClass:"mb-3 pl-3 pr-3"},[_c('b-col',{staticClass:"text-center m-0 p-0"},[_c('Photoswipe',{attrs:{"rotate":""}},[_c('img',{directives:[{name:"pswp",rawName:"v-pswp",value:(
                _vm.imgNotFound || _vm.imgError
                  ? _vm.imgError
                  : _vm.tab === 0
                  ? _vm.exterior[_vm.index]
                  : _vm.interior[_vm.index]
              ),expression:"\n                imgNotFound || imgError\n                  ? imgError\n                  : tab === 0\n                  ? exterior[index]\n                  : interior[index]\n              "}],staticClass:"carousel-item active main-img img-thumbnail",staticStyle:{"height":"18rem"},attrs:{"src":_vm.imgNotFound || _vm.imgError
                  ? _vm.imgError
                  : _vm.tab === 0
                  ? _vm.exterior[_vm.index]
                  : _vm.interior[_vm.index],"alt":"car"},on:{"error":_vm.errorLoadImg}})]),(_vm.index > 0)?_c('a',{staticClass:"carousel-control-prev",attrs:{"role":"button","data-slide":"prev"},on:{"click":_vm.backImg}},[_c('b-icon',{attrs:{"scale":"2","icon":"arrow-left-circle-fill"}}),_c('span',{staticClass:"sr-only"},[_vm._v("Previous")])],1):_vm._e(),(_vm.index < _vm.exterior.length - 1)?_c('a',{staticClass:"carousel-control-next",attrs:{"role":"button","data-slide":"next"},on:{"click":_vm.nextImg}},[_c('b-icon',{attrs:{"scale":"2","icon":"arrow-right-circle-fill"}}),_c('span',{staticClass:"sr-only"},[_vm._v("Next")])],1):_vm._e()],1)],1)]},proxy:true}])}),_c('b-row',{staticStyle:{"padding-left":"-2rem","margin-bottom":"-2px"}},[_c('b-col',{staticClass:"mt-3"},[(_vm.vehicle.stockNumber)?_c('h6',{staticClass:"stockNumber-mt"},[_vm._v(" Stock: "),_c('i',[_vm._v(" "+_vm._s(_vm.vehicle.stockNumber)+" ")])]):_vm._e()])],1),_c('b-row',[_c('b-col',[_c('b-row',[_c('b-col',[_c('b-row',[(_vm.vehicle.make || _vm.vehicle.vinNumber.make)?_c('b-col',[_c('div',{staticClass:"mb-2"},[_vm._v(" Make: "),_c('i',[_vm._v(_vm._s(_vm.vehicle.make || _vm.vehicle.vinNumber.make))])])]):_vm._e(),(_vm.vehicle.vinNumber.year || _vm.vehicle.year)?_c('b-col',[_c('div',{staticClass:"ml-2 mb-2"},[_vm._v(" Year: "),_c('i',[_vm._v(_vm._s(_vm.vehicle.vinNumber.year || _vm.vehicle.year))])])]):_vm._e()],1)],1)],1),_c('b-row',[_c('b-col',[_c('div',{staticClass:"mb-2"},[_vm._v(" Model: "),_c('i',[_vm._v(" "+_vm._s(_vm.vehicle.model || _vm.vehicle.vinNumber.model)+" ")])])])],1),_c('b-row',[(
            _vm.vehicle.trim ||
            (typeof _vm.vehicle.vinNumber === 'object' && _vm.vehicle.vinNumber.trim)
          )?_c('b-col',[_c('div',{staticClass:"mb-2"},[_c('i',[_vm._v(_vm._s(_vm.vehicle.trim || _vm.vehicle.vinNumber.trim))])])]):_vm._e(),(_vm.vehicle.options || _vm.vehicle.vinNumber.options)?_c('b-col',[_c('div',{staticClass:"mb-2",class:{
              'ml-2':
                _vm.vehicle.trim ||
                (typeof _vm.vehicle.vinNumber === 'object' &&
                  _vm.vehicle.vinNumber.trim),
            }},[_c('i',[_vm._v(_vm._s(_vm.vehicle.options || _vm.vehicle.vinNumber.options))])])]):_vm._e()],1),_c('b-row',[(_vm.vehicle.kilometers || _vm.vehicle.vehicleInformation.kilometers)?_c('b-col',{staticClass:"ml-1"},[_vm._v(" Mileage: "),_c('i',[_vm._v(" "+_vm._s(_vm.vehicle.kilometers || _vm.vehicle.vehicleInformation.kilometers)+" ")])]):_vm._e()],1),(_vm.vehicle.releaseDateInReady)?_c('b-row',{staticClass:"mb-2 mt-2"},[_c('b-col',[_vm._v(" Release Date: "),_c('i',[_vm._v("Ready")])])],1):(_vm.vehicle.releaseDate)?_c('b-row',{staticClass:"mb-2 mt-2"},[_c('b-col',[_vm._v(" Release Date: "),_c('i',[_vm._v(" "+_vm._s(_vm._f("formatDate")(_vm.vehicle.releaseDate))+" ")])])],1):_vm._e(),_c('VehicleDetails',{attrs:{"vehicle":_vm.vehicle}}),(
          _vm.vehicle.cost ||
          (_vm.vehicle.vehicleCostDetail && _vm.vehicle.vehicleCostDetail.cost)
        )?_c('b-row',{staticClass:"mt-2 mb-2"},[_c('b-col',[_vm._v(" Cost: "),(_vm.vehicle.cost)?_c('i',[_vm._v(_vm._s(_vm._f("currency")(_vm.vehicle.cost)))]):_c('i',[_vm._v(_vm._s(_vm._f("currency")(_vm.vehicle.vehicleCostDetail.cost)))])])],1):_vm._e(),(_vm.vehicle.status && _vm.vehicle.status !== 'Sold')?_c('b-row',[_c('b-col',{staticClass:"mt-2 ml-1 mb-2"},[_vm._v(" Status: "),_c('i',[_vm._v(" "+_vm._s(_vm.vehicle.status === "ReadyToSell" ? "Ready To Sell" : _vm.vehicle.status)+" ")])])],1):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }