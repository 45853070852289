<template>
  <div class="pb-2">
    <b-row class="mb-4 mr-1 ml-1">
      <b-col xs="12" sm="12" md="12" lg="6" xl="6" class="">
        <slot name="leftSide"></slot>
      </b-col>

      <!-- Using components -->
      <b-col xs="12" sm="12" md="12" lg="6" xl="6" v-if="!hide_recherche">
        <b-input-group>
          <template #prepend>
            <b-input-group-text variant="dark"
              >{{ $t("search") }} {{ col_filter }}</b-input-group-text
            >
          </template>
          <b-form-input
            size="lg"
            class="filter-input rounded-right rounded-left"
            v-model="filter"
            type="search"
            id="filterInput"
            @change="getDataFilter()"
          ></b-form-input>

          <template #append>
            <b-button variant="dark">
              <b-icon icon="search" variant="light"></b-icon>
            </b-button>
            <!-- <b-dropdown :text="$t('filter')" variant="primary">
              <b-dropdown-item v-if="item.key != 'actions'"  v-for="item in fields" @click="onFilterSelected(item.label, item.key)">{{item.label}}</b-dropdown-item>
            </b-dropdown> -->
          </template>
        </b-input-group>
      </b-col>
    </b-row>
    <div class="container-fluid">
      <b-table
        thead-class="bg-header"
        head-variant="light"
        show-empty
        outlined
        striped
        responsive
        stacked="md"
        :fields="fields"
        :items="items.data"
        :cell-style="{ padding: '0', height: '20px' }"
        id="tableMain"
        :busy="busy"
      >
        <template #empty>
          <div class="text-center">
            <h6>{{ $t("noRecords") }}</h6>
          </div>
        </template>
        <template #table-busy>
          <div class="text-center text-purple-blue my-2">
            <b-spinner variant="purple-blue" class="align-middle"></b-spinner>
            <p>{{ $t("loading") }}</p>
          </div>
        </template>
        <template v-slot:cell(condition)="{item}">
            <div>
              {{ $t(item.condition) }}
            </div>
          </template>
          <template v-slot:cell(interestRate)="{item}">
            <div>
              {{ item.interestRate }} %
            </div>
          </template>
          <template v-slot:cell(type)="{item}">
            <div>
              {{ $t(item.type) }}
            </div>
          </template>
        <template #cell(selected)="{ item }">
          <div class="d-flex justify-content-center">
            <b-form-checkbox @change="(val) => onRowSelected(val, item)" />
          </div>
        </template>
        <template v-slot:cell(actions)="row">
          <div class="text-center align-middle d-flex justify-content-around">
            <Button
              v-if="row.field.telephone"
              class="p-button-primary p-mr-2"
              icon="pi pi-phone"
            />
            <font-awesome-icon
              v-if="row.field.telephone"
              class="mx-2 text-dark cursor-pointer"
              :icon="['far', 'phone']"
              size="xl"
            />

            <!--     <Button
              v-if="row.field.email"
              class="p-button-primary p-mr-2"
              icon="pi pi-at"
            /> -->
            <font-awesome-icon
              v-if="row.field.email"
              class="mx-2 text-dark cursor-pointer"
              :icon="['far', 'at']"
              size="xl"
            />
            <!--   <Button
              v-if="row.field.validation"
              icon="pi pi-list"
              title="Validations"
              class="p-button-primary p-mr-2"
              @click="
                openValidationModal(row.item, options.fun, 'validation-modal')
              "
            /> -->
            <font-awesome-icon
              v-if="row.field.validation"
              class="mx-2 text-dark cursor-pointer"
              :icon="['far', 'list']"
              size="xl"
              @click="
                openValidationModal(row.item, options.fun, 'validation-modal')
              "
            />

            <!--  <Button
              v-if="row.field.images"
              class="p-button-primary p-mr-2"
              icon="pi pi-id-card"
              title="Identification"
              @click="
                openValidationModal(row.item, options.fun, 'images-modal')
              "
            /> -->
            <font-awesome-icon
              v-if="row.field.images"
              class="mx-2 text-dark cursor-pointer"
              :icon="['far', 'id-card']"
              size="xl"
              @click="
                openValidationModal(row.item, options.fun, 'images-modal')
              "
            />

            <!--      <Button
              v-if="row.field.details"
              icon="pi pi-eye"
              title="Details"
              @click="editItem(row.item)"
              class="p-button-primary p-mr-2"
            /> -->

            <font-awesome-icon
              v-if="row.field.details"
              class="mx-2 text-dark cursor-pointer"
              :icon="['far', 'eye']"
              size="xl"
              @click="editItem(row.item)"
            />
            <!--    <Button
              v-if="row.field.delete"
              class="p-button-danger p-mr-2"
              title="Delete"
              :icon="row.item.status == 0 ? 'pi pi-times' : 'pi pi-check'"
              @click="
                row.item.status == 0
                  ? activateItem(row.item)
                  : deleteItem(row.item)
              "
            />
 -->
            <font-awesome-icon
              v-if="row.field.delete"
              class="mx-2 text-dark cursor-pointer"
              :icon="['far', row.item.status == 0 ? 'check' : 'x']"
              size="xl"
              @click="
                row.item.status == 0
                  ? activateItem(row.item)
                  : deleteItem(row.item)
              "
            />

            <!--   <font-awesome-icon
              v-if="row.field.delete && row.item.status != 0"
              class="mx-2 text-dark cursor-pointer"
              :icon="['far', 'x']"
              size="xl"
              @click="
                row.item.status == 0
                  ? activateItem(row.item)
                  : deleteItem(row.item)
              "
            /> -->

            <!--     <Button
              v-if="row.field.uploadfiles"
              class="p-button-primary p-mr-2"
              icon="pi pi-upload"
              @click="openUploadFileDialog(row.item)"
            /> -->
            <font-awesome-icon
              v-if="row.field.uploadfiles"
              class="mx-2 text-dark cursor-pointer"
              :icon="['far', 'upload']"
              size="xl"
              @click="openUploadFileDialog(row.item)"
            />

            <!--     <Button
              v-if="row.field.selectdocsdeal && row.item.closedDeal == 0"
              icon="pi pi-list"
              class="p-button-primary p-mr-2"
              @click="chooseDocumentsDeal(row.item)"
            />
 -->
            <font-awesome-icon
              v-if="row.field.selectdocsdeal && row.item.closedDeal == 0"
              class="mx-2 text-dark cursor-pointer"
              :icon="['far', 'list']"
              size="xl"
              @click="chooseDocumentsDeal(row.item)"
            />
            <!--   <Button
              v-if="row.field.validationdocsdeal"
              icon="pi pi-paperclip"
              class="p-button-help p-mr-2"
              @click="editValidationDocuments(row.item)"
            /> -->
            <font-awesome-icon
              v-if="row.field.validationdocsdeal"
              class="mx-2 text-dark cursor-pointer"
              :icon="['far', 'paperclip']"
              size="xl"
              @click="editValidationDocuments(row.item)"
            />
            <!--  <Button
              v-if="row.field.closeddeal && row.item.closedDeal == 0"
              icon="pi pi-check"
              class="p-button-success p-mr-2"
              @click="closeDeal(row.item)"
            /> -->
            <font-awesome-icon
              v-if="row.item.closedDeal == 0"
              class="mx-2 text-dark cursor-pointer"
              :icon="['far', 'check']"
              size="xl"
              @click="closeDeal(row.item)"
            />
            <!--   <Button
              v-if="row.field.canceldeal && row.item.closedDeal == 0"
              icon="pi pi-times"
              class="p-button-danger p-mr-2"
              @click="cancelDeal(row.item)"
            /> -->
            <font-awesome-icon
              v-if="row.field.canceldeal && row.item.closedDeal == 0"
              class="mx-2 text-dark cursor-pointer"
              :icon="['far', 'x']"
              size="xl"
              @click="cancelDeal(row.item)"
            />

            <!--      <Button
              v-if="row.field.downloaddeal"
              icon="pi pi-download"
              class="p-button-info p-mr-2"
              @click="exportDealPDF(row.item)"
            /> -->
            <font-awesome-icon
              v-if="row.field.downloaddeal"
              class="mx-2 text-dark cursor-pointer"
              :icon="['far', 'download']"
              size="xl"
              @click="exportDealPDF(row.item)"
            />

            <!--   <Button
              v-if="row.field.detailsdeal"
              icon="pi pi-eye"
              class="p-button-primary p-mr-2"
              @click="detailsDeal(row.item)"
            /> -->
            <font-awesome-icon
              v-if="row.field.detailsdeal"
              class="mx-2 text-dark cursor-pointer"
              :icon="['far', 'eye']"
              size="xl"
              @click="detailsDeal(row.item)"
            />

            <!-- ADMIN -->
            <!--   <Button
              v-if="row.field.detailsuser"
              class="p-button-primary p-mr-2"
              icon="pi pi-user"
              @click="leaseDetails(row.item)"
            /> -->
            <font-awesome-icon
              v-if="row.field.detailsuser"
              class="mx-2 text-dark cursor-pointer"
              :icon="['far', 'user']"
              size="xl"
              @click="leaseDetails(row.item)"
            />
            <!--    <Button
              v-if="row.field.deleteleaser"
              class="p-button-danger p-mr-2"
              icon="pi pi-times"
              @click="deleteItem(row.item)"
            /> -->
            <font-awesome-icon
              v-if="row.field.deleteleaser"
              class="mx-2 text-dark cursor-pointer"
              :icon="['far', 'x']"
              size="xl"
              @click="deleteItem(row.item)"
            />
            <!--     <Button
              v-if="row.field.editleaser"
              class="p-button-info p-mr-2"
              icon="pi pi-pencil"
              @click="editAgentItem(row.item)"
            /> -->
            <font-awesome-icon
              v-if="row.field.editleaser"
              class="mx-2 text-dark cursor-pointer"
              :icon="['far', 'pencil']"
              size="xl"
              @click="editAgentItem(row.item)"
            />
          </div>
        </template>
      </b-table>

      <b-row>
        <b-col
          xs="12"
          sm="12"
          md="12"
          lg="12"
          xl="12"
          class="d-flex justify-content-between"
        >
          <span>
            <p v-show="itemsSelected.length > 0">
              Selected: {{ itemsSelected.length }}
            </p>
          </span>
          <b-pagination
            v-model="table.currentPage"
            :total-rows="table.totalRows"
            :per-page="table.perPage"
            @change="changePage($event)"
            class="mb-2"
            variant="dark"
          ></b-pagination>
        </b-col>
      </b-row>
    </div>
    <ValidationModal />
    <ImagesValidation />
  </div>
</template>
<script>
import { generalMixin } from "@/modules/mixin.js";
import store from "@/store";
import ValidationModal from "@/modules/shared/validation/validationModal.vue";
import ImagesValidation from "@/modules/shared/validation/ImagesValidation.vue";
import { validationMixin } from "@/modules/shared/validation/mixin.js";

export default {
  mixins: [generalMixin, validationMixin],
  components: { ValidationModal, ImagesValidation },
  props: {
    col_filter: String,
    fields: Array,
    items: {},
    options: {},
    selectable: Boolean,
    busy: Boolean,
    hide_recherche: Boolean,
  },
  data() {
    return {
      user: null,
      filter: null,
      roles: [
        { text: "seller", value: 1 },
        { text: "representative", value: 2 },
      ],
      roleSelected: null,
      itemsSelected: [],
    };
  },
  computed: {
    table() {
      return {
        totalRows: this.items.total,
        currentPage: this.items.currentPage,
        perPage: this.items.perPage,
        col_filter: "",
        filter: null,
      };
    },
  },
  mounted() {
    //this.fields.splice(key, "actions");
  },
  methods: {
    deleteItem(item) {
      this.$parent.deleteItem(item);
    },
    editAgentItem(item) {
      this.$parent.editAgentItem(item);
    },
    editItem(item) {
      this.$parent.editItem(item);
    },
    activateItem(item) {
      this.$parent.activateItem(item);
    },
    showModal() {
      this.$parent.$refs[store.state.general.modalId].show();
    },
    onFilterSelected(label, key) {
      this.col_filter = label;
    },
    openValidationModal(item, fun, modalId) {
      this.$store.commit("setReady", false);

      if (fun) {
        this.$store.commit("setRefreshData", fun);
      }
      this.$store.commit("setItemUser", item);

      this.$bvModal.show(modalId);
    },
    changePage(page) {
      this.$parent.changePage(page);
    },
    getDataFilter() {
      this.$parent.getData(this.filter);
    },
    onRowSelected(val, item) {
      if (val) {
        this.itemsSelected.push(item);
      } else {
        const index = this.itemsSelected.indexOf(item);
        this.itemsSelected.splice(index, 1);
      }
      this.$parent.getSelectedItems([...this.itemsSelected]);
    },
    openUploadFileDialog(item) {
      this.$parent.openUploadFileDialog(item);
    },
    chooseDocumentsDeal(item) {
      this.$parent.chooseDocumentsDeal(item);
    },
    editValidationDocuments(item) {
      this.$parent.editValidationDocuments(item);
    },
    closeDeal(item) {
      this.$parent.closeDeal(item);
    },
    cancelDeal(item) {
      this.$parent.cancelDeal(item);
    },
    exportDealPDF(item) {
      this.$parent.exportDealPDF(item);
    },
    detailsDeal(item) {
      this.$parent.detailsDeal(item);
    },
  },
};
</script>
<style>
.bg-test {
  /*background-color: #e4f4fd;*/
}

.bg-colt {
  background-color: #abdefa;
}

.bg-header {
  background-color: #f8f9fa !important;
}

#tableMain {
  border-spacing: 15px !important;
}
.cursor-pointer {
  cursor: pointer !important;
}
</style>