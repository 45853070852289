<!-- @format -->

<template>
  <div v-if="tokenok">
    <b-progress
      :value="50"
      show-progress
      class="mb-3"
      variant="blue"
    ></b-progress>

    <b-navbar variant="dark-grey" class="v-25 text-blue mb-2 bg-shape">
      <b-row class="mb-5 mt-xl-n4">
        <b-col class="mt-2">
          <h1 class="text-light-blue">
            {{ $t("welcome") }}: {{ form.fullName }}
          </h1>
          
          <h4 class="text-white">{{ $t("company") }}: {{ form.legalName }}</h4>
        </b-col>
      </b-row>
    </b-navbar>
    <div class="container mt-3">
      <b-row>
        <b-col xs="12" sm="12" md="12" lg="12" xl="1" cols="12"></b-col>

        <b-col xs="12" sm="12" md="12" lg="12" xl="10" cols="12">
          <div class="mt-3 mx-auto justify-content-center">
            <b-row>
              <b-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
                <b-form class="mb-3" @submit.stop.prevent="save">
                  <b-row>
                    <!-- Adress -->
                    <b-col sm="12" md="12" lg="12" xl="12">
                      <b-form-group label-size="md">
                        <template slot="label">
                          {{ $t("address") }}
                          <span class="text-danger">*</span>
                        </template>
                        <b-form-input
                          v-if="isAddressSelected"
                          size="md"
                          name="street"
                          class="mb-3"
                          v-model="address"
                          maxlength="65"
                          @focus="isAddressSelected = false"
                        />
                        <place-autocomplete-field
                          v-else
                          @autocomplete-select="selectAddress"
                          v-model="address"
                          name="address"
                          api-key="AIzaSyBWj-z1916MmpcimZW3xPlRGXMikForpS4"
                          placeholder="Saisissez une adresse, un code postal ou un emplacement"
                          v-place-autofill:street="form.street"
                          v-place-autofill:city="form.cityId"
                          v-place-autofill:state="form.state"
                          v-place-autofill:zipcode="form.zip"
                          country="us"
                        ></place-autocomplete-field>
                      </b-form-group>
                    </b-col>
                    <!-- street -->
                    <b-col sm="12" md="12" lg="6" xl="6">
                      <b-form-group label-size="md">
                        <template slot="label">
                          {{ $t("street") }}
                          <span class="text-danger">*</span>
                        </template>
                        <b-form-input
                          required
                          size="sm"
                          name="street"
                          maxlength="65"
                          @change="setValue('street', $event)"
                          :value="form.street"
                          v-model.trim="$v.form.street.$model"
                          :state="validateState('street')"
                        />
                        <b-form-invalid-feedback>
                          <div v-if="!$v.form.street.required">
                            {{ $t("requiredField") }}
                          </div>
                          <div v-if="!$v.form.street.maxLength">
                            {{ $t("limitCharacters") }}
                          </div>
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </b-col>
                    <!-- Postal code -->
                    <b-col sm="12" md="12" lg="6" xl="6">
                      <b-form-group label-size="md">
                        <template slot="label">
                          {{ $tc("zipCode") }}
                          <span class="text-danger">*</span>
                        </template>
                        <b-form-input
                          required
                          size="sm"
                          name="zip"
                          maxlength="10"
                          @change="setValue('zip', $event)"
                          :value="form.zip"
                          v-model.trim="$v.form.zip.$model"
                          :state="validateState('zip')"
                        />
                        <b-form-invalid-feedback>
                          <div v-if="!$v.form.zip.required">
                            {{ $t("requiredField") }}
                          </div>
                          <div v-if="!$v.form.zip.maxLength">
                            {{ $t("limitCharacters") }}
                          </div>
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </b-col>
                    <!-- State -->
                    <b-col sm="12" md="12" lg="6" xl="6">
                      <b-form-group label-size="md">
                        <template slot="label">
                          {{ $tc("state" )}}
                          <span class="text-danger">*</span>
                        </template>
                        <b-form-input
                          required
                          size="sm"
                          name="state"
                          maxlength="250"
                          @change="setValue('state', $event)"
                          :value="form.state"
                          v-model.trim="$v.form.state.$model"
                          :state="validateState('state')"
                        />
                        <b-form-invalid-feedback>
                          <div v-if="!$v.form.state.required">
                            {{ $t("requiredField") }}
                          </div>
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </b-col>
                    <!-- city -->
                    <b-col sm="12" md="12" lg="6" xl="6">
                      <b-form-group label-size="md">
                        <template slot="label">
                          <!--{{ $t("city") }}-->
                          {{ $tc("city" )}}
                          <span class="text-danger">*</span>
                        </template>
                        <b-form-input
                          required
                          size="sm"
                          name="city"
                          maxlength="250"
                          @change="setValue('cityId', $event)"
                          :value="form.cityId"
                          v-model.trim="$v.form.cityId.$model"
                          :state="validateState('cityId')"
                        />
                        <b-form-invalid-feedback>
                          <div v-if="!$v.form.cityId.required">
                            {{ $t("requiredField") }}
                          </div>
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </b-col>
                    <!-- Website -->
                    <b-col sm="12" md="12" lg="6" xl="6">
                      <b-form-group label-size="md">
                        <template slot="label">
                          Website
                          <span class="text-danger">*</span>
                        </template>
                        <b-form-input
                          required
                          size="sm"
                          name="website"
                          maxlength="250"
                          @change="setValue('website', $event)"
                          :value="form.website"
                          v-model.trim="$v.form.website.$model"
                          :state="validateState('website')"
                        />
                        <b-form-invalid-feedback>
                          <div v-if="!$v.form.website.required">
                            {{ $t("requiredField") }}
                          </div>
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </b-col>
                    <!-- Years in business -->
                    <b-col sm="12" md="12" lg="6" xl="6">
                      <b-form-group label-size="md">
                        <template slot="label">
                          {{ $t("yearsOfBussiness") }}
                          <span class="text-danger">*</span>
                        </template>
                        <b-form-input
                          type="number"
                          required
                          size="sm"
                          onkeydown="javascript: return event.keyCode == 69 ? false : true"
                          min="0"
                          max="1000"
                          oninput="validity.valid||(value='')"
                          name="yearsBusiness"
                          @change="setValue('yearsBusiness', $event)"
                          :value="form.yearsBusiness"
                          v-model.trim="$v.form.yearsBusiness.$model"
                          :state="validateState('yearsBusiness')"
                        />
                        <b-form-invalid-feedback>
                          <div v-if="!$v.form.yearsBusiness.required">
                            {{ $t("requiredField") }}
                          </div>
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </b-col>
                    <!-- NEQ -->
                    <b-col sm="12" md="12" lg="6" xl="6">
                      <b-form-group label-size="md" label="Taxid 1 *">
                        <template slot="label">
                          NEQ
                        </template>
                        <b-form-input
                          required
                          size="sm"
                          name="corporationNumber"
                          maxlength="10"
                          @change="setValue('corporationNumber', $event)"
                          v-model.trim="$v.form.corporationNumber.$model"
                          :state="validateState('corporationNumber')"
                          :value="form.corporationNumber"
                        />
                        <b-form-invalid-feedback>
                          <div v-if="!$v.form.corporationNumber.required">
                            {{ $t("requiredField") }}
                          </div>
                          <div v-if="!$v.form.corporationNumber.numeric">
                            {{ $t("numericField") }}
                          </div>
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </b-col>
                    <!-- Dealer License -->
                    <b-col sm="12" md="12" lg="6" xl="6">
                      <b-form-group label-size="md" label="Taxid 1 *">
                        <template slot="label">
                          {{ $tc("dealerLicense") }}
                          <span class="text-danger">*</span>
                        </template>
                        <b-form-file
                          id="file1"
                          accept="image/*, application/pdf"
                          v-model="form.dealerLicense"
                          :state="Boolean(form.dealerLicense)"
                          @change="fileUpload($event)"
                          :placeholder='$tc("chooseFile")'
                          :browse-text='$tc("browse")'
                          size="sm"
                          required
                        >
                        </b-form-file>
                      </b-form-group>
                    </b-col>
                    <!-- SAAQ -->
                    <b-col sm="12" md="12" lg="6" xl="6">
                      <b-form-group label-size="md" label="Dealer license *">
                        <template slot="label">
                          SAAQ <span class="text-danger">*</span>
                        </template>

                        <b-form-input
                          required
                          size="sm"
                          name="saaq"
                          maxlength="10"
                          @change="setValue('saaq', $event)"
                          :value="form.saaq"
                          v-model.trim="$v.form.saaq.$model"
                          :state="validateState('saaq')"
                        />
                        <b-form-invalid-feedback>
                          <div v-if="!$v.form.saaq.required">
                            {{ $t("requiredField") }}
                          </div>
                          <div v-if="!$v.form.saaq.numeric">
                            {{ $t("numericField") }}
                          </div>
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <hr />
                  <h4 class="">{{ $t("toLogIn") }}</h4>
                  <b-row>
                    <b-col sm="12" md="12" lg="6" xl="6">
                      <b-form-group label-size="md">
                        <template slot="label">
                          {{ $t("password") }}
                          <span class="text-danger">*</span>
                        </template>

                        <b-input-group>
                          <b-form-input
                            size="md"
                            autocomplete="off"
                            :type="showPassword ? 'text' : 'password'"
                            name="password-input"
                            :value="form.password"
                            v-model.trim="$v.form.password.$model"
                            :state="validateState('password')"
                            @change="setValue('password', $event)"
                          />

                          <b-input-group-append>
                            <b-button variant="outline-primary">
                              <b-icon
                                v-if="showPassword == false"
                                icon="eye"
                                class=""
                                @click="hanldePasswordVisibility(true)"
                              />
                              <b-icon
                                v-else
                                icon="eye-slash"
                                class=""
                                @click="hanldePasswordVisibility(false)"
                              />
                            </b-button>
                          </b-input-group-append>
                        </b-input-group>

                        <small
                          v-bind:class="{
                            'text-danger': !$v.form.password.required,
                            'text-success': !(
                              !$v.form.password.required &
                              !$v.form.password.$dirty
                            ),
                          }"
                        >
                          {{ $t("requiredField") }}
                          <br />
                        </small>
                        <small
                          v-bind:class="{
                            'text-success': minLenght,

                            'text-danger': !minLenght,
                          }"
                        >
                          {{ $t("eightCharacters") }}
                          <br />
                        </small>
                        <small
                          v-bind:class="{
                            'text-danger': !$v.form.password.containsUppercase,
                            'text-success': !(
                              !$v.form.password.containsUppercase &
                              !$v.form.password.$dirty
                            ),
                          }"
                        >
                          {{ $t("oneUpperCase") }}

                          <br />
                        </small>
                        <small
                          v-bind:class="{
                            'text-danger': !$v.form.password.containsLowercase,
                            'text-success': !(
                              !$v.form.password.containsLowercase &
                              !$v.form.password.$dirty
                            ),
                          }"
                        >
                          {{ $t("oneLowerCase") }}
                          <br />
                        </small>
                        <small
                          v-bind:class="{
                            'text-danger': !$v.form.password.containsNumber,
                            'text-success': !(
                              !$v.form.password.containsNumber &
                              !$v.form.password.$dirty
                            ),
                          }"
                        >
                          {{ $t("oneNumber") }}
                          <br />
                        </small>
                        <small
                          v-bind:class="{
                            'text-danger': !$v.form.password.containsSpecial,
                            'text-success': !(
                              !$v.form.password.containsSpecial &
                              !$v.form.password.$dirty
                            ),
                          }"
                        >
                          {{ $t("oneSpecialCharacter") }}
                          <br />
                        </small>
                      </b-form-group>
                    </b-col>

                    <!-- confirm password -->
                    <b-col sm="12" md="12" lg="6" xl="6">
                      <b-form-group label-size="md">
                        <template slot="label">
                          {{ $t("confirmPassword") }}
                          <span class="text-danger">*</span>
                        </template>
                        <b-input-group>
                          <b-form-input
                            required
                            size="md"
                            :type="showPassword ? 'text' : 'password'"
                            autocomplete="off"
                            name="confirmPassword"
                            :value="form.confirmPassword"
                            v-model.trim="$v.form.confirmPassword.$model"
                            :state="validateState('confirmPassword')"
                            @change="setValue('confirmPassword', $event)"
                          />
                          <b-input-group-append>
                            <b-button variant="outline-primary">
                              <b-icon
                                v-if="showPassword == false"
                                icon="eye"
                                class=""
                                @click="hanldePasswordVisibility(true)"
                              />
                              <b-icon
                                v-else
                                icon="eye-slash"
                                class=""
                                @click="hanldePasswordVisibility(false)"
                              />
                            </b-button>
                          </b-input-group-append>
                        </b-input-group>

                        <b-form-invalid-feedback>
                          <div v-if="!$v.form.password.sameAsPassword">
                            {{ $t("passwordNotMatch") }}
                          </div>
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </b-col>
                    <hr />

                    <b-col sm="12" md="3" lg="3" xl="3" class="mb-4"> </b-col>
                    <b-col sm="12" md="6" lg="6" xl="6" class="mb-4">
                      <b-button
                        class="btn btn-light-blue text-white"
                        type="submit"
                        block
                        :disabled="$v.form.$invalid || isLoading"
                      >
                        {{ $t("registerNow") }}
                        <b-spinner
                          v-if="isLoading"
                          small
                          variant="white"
                        ></b-spinner>
                      </b-button>
                    </b-col>
                    <b-col sm="12" md="3" lg="3" xl="3" class="mb-4"> </b-col>
                  </b-row>
                </b-form>
              </b-col>
            </b-row></div
        ></b-col>
        <b-col xs="12" sm="12" md="12" lg="12" xl="1" cols="12"></b-col>
      </b-row>
    </div>
  </div>
  <div v-else class="text-center">
    <h1>{{ $t('loading')}}</h1>
  </div>
</template>

<script>
import {
  required,
  minLength,
  sameAs,
  maxLength,
  email,
  numeric,
  url
} from "vuelidate/lib/validators";
import { POST_USER_COMPANY, GET_USER_CLIENT } from "./actions";
import { mapState } from "vuex";
import { mapActions } from 'vuex';
import { generalMixin } from "@/modules/mixin.js";

export default {
  mixins: [generalMixin],
  data() {
    return {
      id: "",
      file64: null,
      isAddressSelected: false,
      address: null,
      tokenok:false,
      form: {
        password: "",
        confirmPassword: "",
        fullName: "",
        street: "",
        zip: "",
        state: "",
        cityId: "",
        corporationNumber: "",
        saaq: "",
        website: "",
        legalName: "",
        dealerLicense: null,
        yearsBusiness: 0
      },
      showPassword: false,
      typeNumber: null,
      states: [],
      cities: [],

      options: {
        type: "tel",
        validCharactersOnly: true,
        maxlength: 14,
      },
      telInputOptions: {
        showDialCodeInList: true,
        showFlags: true,
        showDialCodeInSelection: false,
      },
      ip: null,
    };
  },
  validations: {
    form: {
      password: {
        required,
        minLength: minLength(8),
        containsUppercase: (value) => /[A-Z]/.test(value),
        containsLowercase: (value) => /[a-z]/.test(value),
        containsNumber: (value) => /[0-9]/.test(value),
        containsSpecial: (value) => /[#?!@$%^&*-]/.test(value),
      },
      confirmPassword: {
        sameAsPassword: sameAs("password"),
      },

      street: {
        required,
        maxLength: maxLength(250),
      },
      zip: {
        required,
        maxLength: maxLength(10),
      },
      state: {
        required,
      },
      cityId: {
        required,
      },

      corporationNumber: {
        required,
        maxLength: maxLength(10),
        numeric,
      },

      saaq: {
        required,
        numeric,
      },
     
      yearsBusiness: {
        required,
      },
      
      website: {
        required,
        url
      }
    },
  },
  computed: {
    minLenght() {
      return this.form.password.length > 7 ? true : false;
    },
    ...mapState({
      user: (state) => state.dealership.buyerStore.user,
    }),
    isLoading() {
      return this.user.status == "fetching" ? true : false;
    },
  },
  methods: {
    ...mapActions(["search-user"]),
    setValue(key, value) {
      this.form[key] = value;
      this.$v.form[key].$touch();
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    save() {
      const payload = this.formatPayload();
      this.$store
        .dispatch(POST_USER_COMPANY, payload)
        .then((response) => {
          if (!response.error) {
            this.$toast.add({
              summary: this.$t('toast_title_success'),
              detail: this.$t('toast_message_success'),
              severity: "success",
              life: 5000
          })
            setTimeout(() => {
              this.goTo();
            }, 6000);
          }
        })
        .catch(() => {
          this.$toast.add({
              summary: this.$t('toast_title_error'),
              detail: this.$t('toast_message_error'),
              severity: "error",
              life: 5000
          })
        });
    },

    formatPayload() {
      const payload = {
        registrationToken: this.$route.params.token,
        street: this.form.street,
        zip: this.form.zip,
        state: this.form.state,
        saaq: this.form.saaq,
        city: this.form.cityId,
        password: this.form.password,
        yearsBusiness: this.form.yearsBusiness,
        typeNumber: this.typeNumber,
        corporationNumber: this.form.corporationNumber,
        ip: this.ip,
        website: this.form.website,
        id: this.id,
        file: this.file64
        //file: document.querySelector('#file1').files[0]
      };
      return payload;
    },
    getBuyer() {
      this.$store
        .dispatch('search-user', {
          registrationToken: this.$route.params.token,
        })
        .then((response) => {
          const user = response;
          this.form.legalName = user.userDetails.company.legalName;
          this.form.fullName = user.firstName + " " + user.lastName;
          this.id = user.userDetails.company.id;
          this.tokenok = true;
        })
        .catch(() => {
          this.$toast.add({
              summary: this.$t('toast_title_error'),
              detail: this.$t('toast_message_tokenused'),
              severity: "error",
              life: 4000
          })
          this.tokenok = false;
          setTimeout(() => {
              this.goTo();
            }, 3000);
        });
    },
    selectAddress() {
      this.isAddressSelected = true;
    },
    goTo() {
      this.$router.push({ path: "/" });
      this.$bvModal.show("login-modal");
    },
    async getIp() {
      await fetch("https://api.ipify.org?format=json", {})
        .then((x) => x.json())
        .then(({ ip }) => {
          this.ip = ip;
        });
    },
    hanldePasswordVisibility(isShow) {
      this.showPassword = isShow;
    },
    fileUpload(event) {
      const reader = new FileReader()
      reader.readAsDataURL(event.target.files[0])
      reader.onload = () => {
            this.file64 = reader.result
        };
    }
  },
  beforeMount() {
    this.getBuyer();
    this.getIp();
  },
};
</script>

<style scoped >
.bg-shape {
  clip-path: polygon(100% 0, 100% 60%, 56% 60%, 50% 90%, 44% 60%, 0% 60%, 0 0);
  height: 25vh !important;
}

@media only screen and (max-width: 600px) {
  .bg-shape {
    clip-path: none !important;
    height: auto !important;
  }
}
.v-center {
  position: relative;
  top: 40%;
  transform: translateY(-50%);
}
</style>
