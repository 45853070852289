/** @format */

import Base from '@/modules/auth/Base.vue'
import Dashboard from '@/modules/buyer/Dashboard'
import ValidationDocuments from '@/modules/buyer/ValidationDocuments'
import { ifRoleAuthenticated } from '@/router/navigationGuards'
const routes = [
  {
    path: '/customer',
    component: Base,
    beforeEnter: (to, from, next) => {
      ifRoleAuthenticated(to, from, next, 'ROLE_CUSTOMER')
    },
    children: [
      {
        path: '/',
        path: 'welcome',
        component: Dashboard,
        name: 'buyer.buyerdashboard',
        meta: { layout: "customer" },
      },
      {
        path: '/',
        path: 'validation',
        component: ValidationDocuments,
        name: 'buyer.validation',
        meta: { layout: "customer" },
      }
    ]
  }
]

export default routes
