<template>
  <b-form container class="container p-4 mb-3" @submit.stop.prevent="onSubmit">
    <b-row>
      <b-col sm="12" md="12" lg="4" xl="6">
        <b-form-group label-size="md">
          <template slot="label">
            {{ $t('new_password')}}
            <span class="text-danger"> *</span></template
          >
          <b-form-input
            size="sm"
            autocomplete="off"
            type="password"
            name="password-input"
            :value="form.password"
            v-model.trim="$v.form.password.$model"
            :state="validateState('password')"
            @change="setValue('password', $event)"
          />
          <small
            v-bind:class="{
              'text-danger': !$v.form.password.required,
              'text-success': !(
                !$v.form.password.required & !$v.form.password.$dirty
              ),
            }"
          >
            {{ $t('password_required')}}
            <br />
          </small>
          <small
            v-bind:class="{
              'text-success': minLenght,

              'text-danger': !minLenght,
            }"
          >
          {{ $t('password_chars')}}

            <br />
          </small>
          <small
            v-bind:class="{
              'text-danger': !$v.form.password.containsUppercase,
              'text-success': !(
                !$v.form.password.containsUppercase & !$v.form.password.$dirty
              ),
            }"
          >
          {{ $t('password_upper')}}
            <br />
          </small>
          <small
            v-bind:class="{
              'text-danger': !$v.form.password.containsLowercase,
              'text-success': !(
                !$v.form.password.containsLowercase & !$v.form.password.$dirty
              ),
            }"
          >
          {{ $t('password_lower')}}
            <br />
          </small>
          <small
            v-bind:class="{
              'text-danger': !$v.form.password.containsNumber,
              'text-success': !(
                !$v.form.password.containsNumber & !$v.form.password.$dirty
              ),
            }"
          >
          {{ $t('password_number')}}
            <br />
          </small>
          <small
            v-bind:class="{
              'text-danger': !$v.form.password.containsSpecial,
              'text-success': !(
                !$v.form.password.containsSpecial & !$v.form.password.$dirty
              ),
            }"
          >
          {{ $t('password_special')}}
            <br />
          </small>
        </b-form-group>
      </b-col>
      <b-col sm="12" md="12" lg="6" xl="6">
        <b-form-group label-size="md">
          <template slot="label">
            {{ $t('confirm_password')}}
            <span class="text-danger"> *</span>
          </template>
          <b-form-input
            required
            size="sm"
            type="password"
            autocomplete="off"
            name="confirmPassword"
            :value="form.confirmPassword"
            v-model.trim="$v.form.confirmPassword.$model"
            :state="validateState('confirmPassword')"
            @change="setValue('confirmPassword', $event)"
          />
          <b-form-invalid-feedback>
            <div v-if="!$v.form.password.sameAsPassword">
              {{ $t('password_notmatch')}}
            </div>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col xl="3"></b-col>
      <b-col sm="12" md="12" xl="6">
        <b-button
          :disabled="$v.form.$invalid"
          class="float-right bg-blue btn-block"
          type="submit"
        >
          <b-spinner
            v-if="changePasswordStatus === 'fetching'"
            variant="white"
            label="Spinning"
            small
          />
          <span v-else>{{$t('change_password')}}</span>
        </b-button>
      </b-col>
    </b-row>
  </b-form>
</template>

<script>
import { POST_SET_NEW_PASSWORD } from "./actions";
import { required, minLength, sameAs } from "vuelidate/lib/validators";
import { mapState } from "vuex";
export default {
  data() {
    return {
      form: {
        password: null,
        confirmPassword: null,
      },
    };
  },
  computed: {
    ...mapState({
      changePasswordStatus: (state) =>
        state.auth.forgotPasswordStore.setNewPassword.status,
    }),
    minLenght() {
      return this.form.password && this.form.password.length > 7 ? true : false;
    },
  },
  validations: {
    form: {
      password: {
        required,
        minLength: minLength(8),
        containsUppercase: (value) => /[A-Z]/.test(value),
        containsLowercase: (value) => /[a-z]/.test(value),
        containsNumber: (value) => /[0-9]/.test(value),
        containsSpecial: (value) => /[#?!@$%^&*-]/.test(value),
      },
      confirmPassword: {
        sameAsPassword: sameAs("password"),
      },
    },
  },
  methods: {
    setValue(key, value) {
      this.form[key] = value;
      this.$v.form[key].$touch();
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    resetForm() {
      this.form = {
        password: null,
        confirmPassword: null,
      };

      this.$nextTick(() => {
        this.$v.$reset();
      });
    },

    payload() {
      return {
        token: this.$route.params.token,
        password: this.form.password,
      };
    },
    onSubmit() {
      this.$store
        .dispatch(POST_SET_NEW_PASSWORD, this.payload())
        .then(() => {
          this.resetForm();
          this.$toast.add({
              summary: this.$t('toast_title_success'),
              detail: this.$t('toast_message_password_changed'),
              severity: "success",
              life: 5000
          })
          window.location.reload();
        })
        .catch(() => {
          this.$toast.add({
              summary: this.$t('toast_title_error'),
              detail: this.$t('toast_message_error'),
              severity: "error",
              life: 5000
          })
          window.location.reload();
        });
    },
  },
};
</script>

<style></style>
