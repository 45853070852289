<template>
  <b-container class="mt-5">
    <b-row class="mt-5">
      <b-col
        :class="{
          'justify-content-end': !isSmallScreen,
          'justify-content-center': isSmallScreen,
        }"
      >
        <!--<hr v-if="!isSmallScreen" id="divider" class="shadow" />-->

        <div class="mt-3">
          <b-row>
            <b-col class="d-flex justify-content-center">
              <img
                class="img-logo mb-5 img-fluid"
                src="@/assets/img/logos/logo.png"
                alt="logo"
              />
            </b-col>
          </b-row>
          <b-row class="mt-2">
            <b-col class="d-flex justify-content-center">
              <b-form @submit.stop.prevent="searchVinNumber">
                <table>
                  <tr>
                    <th>
                      <b-form-input
                        class="input-border-pink"
                        v-model="vinNumber"
                        type="text"
                        placeholder="Vin Number"
                        required
                      />
                    </th>
                    <th>
                      <b-button
                        :disabled="isLoading"
                        type="submit"
                        variant="pink"
                      >
                        <b-spinner
                          v-if="isLoading"
                          variant="white"
                          label="Spinning"
                          small
                        ></b-spinner>
                        <span v-else>Go</span>
                      </b-button>
                    </th>
                  </tr>
                </table>
              </b-form>
            </b-col>
          </b-row>
        </div>
      </b-col>

      <!-- <b-row class="mt-4">
        <b-col class="col-centered">
          <b-tabs justified>
            <b-tab title="Upload vehicles">
              <UploadVehicles :upload="true" />
            </b-tab>
            <b-tab title="Update vehicle status">
              <UploadVehicles :update="true" />
            </b-tab>
          </b-tabs>
        </b-col>
      </b-row>-->
    </b-row>

    <b-row class="mt-3 d-flex justify-content-center">
      <b-col
        v-for="(v, key) in vehiclesFound"
        :key="key"
        lx="4"
        lg="4"
        md="6"
        sm="12"
        class="m-3"
      >
        <VehicleFound :vehicle="v" :vin="vinNumber" />
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
//import UploadVehicles from "./components/UploadVehicles";
import VehicleFound from "./components/VehicleFound";
import { registryVehicleMixin } from "./mixins";
export default {
  //components: { UploadVehicles },
  components: { VehicleFound },
  mixins: [registryVehicleMixin],
  props: {
    showProgressBar: {
      type: Boolean,
      default: true,
    },
    showUploadVehiclesFile: {
      type: Boolean,
      default: true,
    },
    redirectToTable: {
      type: Boolean,
      default: false,
    },
    closeUploadVehicleModal: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      vinNumber: "",
      isLoading: false,
      markets: [],
      locations: [],
      fileMultipleCr: null,
      locationMultipleCr: null,
      vinNumberCr: "",
      locationCr: ""
    };
  },
  computed: {
    isSmallScreen() {
      return this.$vssWidth <= 990;
    },
  },
  methods: {
    payload() {
      const data = {
        vinNumber: this.vinNumber,
      };
      return data;
    },
    async searchVinNumber() {
      this.isLoading = true;
      await this.getVehicle(this.vinNumber);
      this.isLoading = false;
    },
  },
};
</script>

<style scoped>
#divider {
  border: none;
  border-left: 1px solid hsla(200, 10%, 50%, 100);
  height: 27vh;
  width: 1px;
  background-color: #f2f2f2;
}
.small-container-pink {
  border: solid #df0632 1px;
  border-radius: 10px;
}
.small-container-blue {
  border: solid #35a4dd 1px;
  border-radius: 15px;
}
.input-border-pink {
  border: solid #df0632 2px;
  border-radius: 6px;
}

.input-border-blue {
  border: solid #35a4dd 2px;
  border-radius: 6px;
}
@media screen and (min-width: 1000px) {
  .main-cont {
    padding-left: 30%;
    padding-right: 30%;
  }
}
.img-logo {
  max-width: 242px;
  max-height: 92;
}
</style>
