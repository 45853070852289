var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-form',{staticClass:"container p-4 mb-3",attrs:{"container":""},on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_c('b-row',[_c('b-col',{attrs:{"sm":"12","md":"12","lg":"4","xl":"6"}},[_c('b-form-group',{attrs:{"label-size":"md"}},[_c('template',{slot:"label"},[_vm._v(" "+_vm._s(_vm.$t('new_password'))+" "),_c('span',{staticClass:"text-danger"},[_vm._v(" *")])]),_c('b-form-input',{attrs:{"size":"sm","autocomplete":"off","type":"password","name":"password-input","value":_vm.form.password,"state":_vm.validateState('password')},on:{"change":function($event){return _vm.setValue('password', $event)}},model:{value:(_vm.$v.form.password.$model),callback:function ($$v) {_vm.$set(_vm.$v.form.password, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.form.password.$model"}}),_c('small',{class:{
            'text-danger': !_vm.$v.form.password.required,
            'text-success': !(
              !_vm.$v.form.password.required & !_vm.$v.form.password.$dirty
            ),
          }},[_vm._v(" "+_vm._s(_vm.$t('password_required'))+" "),_c('br')]),_c('small',{class:{
            'text-success': _vm.minLenght,

            'text-danger': !_vm.minLenght,
          }},[_vm._v(" "+_vm._s(_vm.$t('password_chars'))+" "),_c('br')]),_c('small',{class:{
            'text-danger': !_vm.$v.form.password.containsUppercase,
            'text-success': !(
              !_vm.$v.form.password.containsUppercase & !_vm.$v.form.password.$dirty
            ),
          }},[_vm._v(" "+_vm._s(_vm.$t('password_upper'))+" "),_c('br')]),_c('small',{class:{
            'text-danger': !_vm.$v.form.password.containsLowercase,
            'text-success': !(
              !_vm.$v.form.password.containsLowercase & !_vm.$v.form.password.$dirty
            ),
          }},[_vm._v(" "+_vm._s(_vm.$t('password_lower'))+" "),_c('br')]),_c('small',{class:{
            'text-danger': !_vm.$v.form.password.containsNumber,
            'text-success': !(
              !_vm.$v.form.password.containsNumber & !_vm.$v.form.password.$dirty
            ),
          }},[_vm._v(" "+_vm._s(_vm.$t('password_number'))+" "),_c('br')]),_c('small',{class:{
            'text-danger': !_vm.$v.form.password.containsSpecial,
            'text-success': !(
              !_vm.$v.form.password.containsSpecial & !_vm.$v.form.password.$dirty
            ),
          }},[_vm._v(" "+_vm._s(_vm.$t('password_special'))+" "),_c('br')])],2)],1),_c('b-col',{attrs:{"sm":"12","md":"12","lg":"6","xl":"6"}},[_c('b-form-group',{attrs:{"label-size":"md"}},[_c('template',{slot:"label"},[_vm._v(" "+_vm._s(_vm.$t('confirm_password'))+" "),_c('span',{staticClass:"text-danger"},[_vm._v(" *")])]),_c('b-form-input',{attrs:{"required":"","size":"sm","type":"password","autocomplete":"off","name":"confirmPassword","value":_vm.form.confirmPassword,"state":_vm.validateState('confirmPassword')},on:{"change":function($event){return _vm.setValue('confirmPassword', $event)}},model:{value:(_vm.$v.form.confirmPassword.$model),callback:function ($$v) {_vm.$set(_vm.$v.form.confirmPassword, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.form.confirmPassword.$model"}}),_c('b-form-invalid-feedback',[(!_vm.$v.form.password.sameAsPassword)?_c('div',[_vm._v(" "+_vm._s(_vm.$t('password_notmatch'))+" ")]):_vm._e()])],2)],1)],1),_c('b-row',[_c('b-col',{attrs:{"xl":"3"}}),_c('b-col',{attrs:{"sm":"12","md":"12","xl":"6"}},[_c('b-button',{staticClass:"float-right bg-blue btn-block",attrs:{"disabled":_vm.$v.form.$invalid,"type":"submit"}},[(_vm.changePasswordStatus === 'fetching')?_c('b-spinner',{attrs:{"variant":"white","label":"Spinning","small":""}}):_c('span',[_vm._v(_vm._s(_vm.$t('change_password')))])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }