<template>
  <div>
    <div class="container">
      <div class="row">
        <div class="col-12 mt-2">
          <VueSignaturePad
            id="signature"
            width="100%"
            height="35vh"
            ref="signaturePad"
            :options="options"
          />
        </div>
      </div>
      <b-row>
        <b-col xs="7" sm="7" md="8" lg="8" xl="8" cols="7">
          <button
            class="btn btn-outline-secondary m-2"
            @click="changeSignMethod()"
          >
            {{ $t("typeSignature") }}
          </button>
        </b-col>
        <b-col xs="5" sm="5" md="4" lg="4" xl="4" cols="5">
          <button class="btn btn-outline-secondary m-2" @click="gotoPDFView()">
            {{ $t("cancel") }}
          </button>
          <button class="btn btn-blue m-2" @click="save">
            {{ $t("apply") }}
          </button>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  data() {
    return {
      options: {
        penColor: "black",
      },
    };
  },
  computed: {
    ...mapState({
      url: (state) => state.shared.signature.url,
    }),
  },
  methods: {
    undo() {
      this.$refs.signaturePad.undoSignature();
    },
    save() {
      const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
      if (isEmpty) return;

      this.$store.commit("setSign", data);
      this.gotoPDFView();
    },
    changeSignMethod() {
      this.$emit("sign", "font");
    },

    gotoPDFView() {
      /*       this.$router.push({ path: `/dealership/pre-approval` });
       */ this.$router.push({ path: `${this.url}` });
    },
  },
};
</script>

<style scoped>
#signature {
  border: solid 1px;
  border-radius: 5px;
  background-image: linear-gradient(white, white),
    radial-gradient(circle at top left, #0000, #0000);
  background-origin: border-box;
  background-clip: content-box, border-box;
}
</style>
