/** @format */

import * as constants from './actions'
import axios from '@/plugins/axios.js'

function getDefaultState() {
  return {
    saveNote: {
      status: null,
      data: null,
      error: null
    },
    notes: {
      status: null,
      data: [],
      error: null,
      filters: {
        vehicle: null,
        resourcesPerPage: 10,
        page: 1
      }
    }
  }
}

const actions = {
  [constants.POST_NOTE]: async ({ commit }, payload) => {
    try {
      commit(constants.POST_NOTE)
      const response = await axios.post('vehicle/note', payload)
      commit(constants.POST_NOTE_SUCCESS, response)
      return response
    } catch (error) {
      commit(constants.POST_NOTE_ERROR, error)
      throw error.response
    }
  },
  [constants.GET_NOTES]: async ({ commit, state }) => {
    try {
      commit(constants.GET_NOTES)
      const payload = Object.entries(state.notes.filters).reduce(
        (a, [k, v]) => (v === null || v === '' ? a : { ...a, [k]: v }),
        {}
      )
      const response = await axios.get('vehicle/note', { params: payload })
      commit(constants.GET_NOTES_SUCCESS, response)
      return response
    } catch (error) {
      commit(constants.GET_NOTES_ERROR, error)
      throw error.response
    }
  }
}

const mutations = {
  [constants.POST_NOTE]: state => {
    state.saveNote.status = 'fetching'
    state.saveNote.error = null
  },
  [constants.POST_NOTE_SUCCESS]: (state, data) => {
    state.saveNote.status = 'success'
    state.saveNote.data = data
    state.saveNote.error = null
  },
  [constants.POST_NOTE_ERROR]: (state, error) => {
    state.saveNote.status = 'error'
    state.saveNote.data = []
    state.saveNote.error = error
  },
  [constants.SET_NOTES_FILTERS]: (state, filters) => {
    if (filters.reset) {
      state.notes.filters = {
        vehicle: null,
        resourcesPerPage: 10,
        page: 1
      }
    }
    if (filters.fields) {
      state.notes.filters = {
        ...state.notes.filters,
        ...filters.fields
      }
    }
  },
  [constants.GET_NOTES]: state => {
    state.notes.status = 'fetching'
    state.notes.error = null
  },
  [constants.GET_NOTES_SUCCESS]: (state, data) => {
    state.notes.status = 'success'
    state.notes.data = data
    state.notes.error = null
  },
  [constants.GET_NOTES_ERROR]: (state, error) => {
    state.notes.status = 'error'
    state.notes.data = []
    state.notes.error = error
  }
}

export default {
  state: getDefaultState(),
  actions,
  mutations
}
