<template>
  <div class="container mb-4">
    <div v-if="!isLandScape" class="mb-4">
      <div class="container col-auto mb-2">
        <div class="center-animation">
          <v-lottie-player
            class="center-animation"
            name="scooterAnim"
            loop
            path="https://assets3.lottiefiles.com/private_files/lf30_qo9qo8oq.json"
          />
        </div>
        <div class="text-center mb-4">
          <h2>Rotate to sign</h2>
        </div>
      </div>
    </div>
    <b-row v-if="isLandScape" class="mb-4">
      <b-col xs="12" sm="12" md="12" lg="12" xl="2" cols="12" class="p-0">
      </b-col>
      <b-col xs="12" sm="12" md="12" lg="12" xl="8" cols="12">
        <div>
          <SignaturePad v-if="isPad" v-on:sign="toggleSignatureMethod" />
        </div>
        <div>
          <SignatureFont v-if="isFont" v-on:sign="toggleSignatureMethod" />
        </div>
      </b-col>
      <b-col xs="12" sm="12" md="12" lg="12" xl="2" cols="12" class="p-0">
      </b-col>
    </b-row>
  </div>
</template>

<script>
import SignaturePad from "./SignaturePad";
import SignatureFont from "./SignatureFont";
import VueLottiePlayer from "vue-lottie-player";

export default {
  components: { SignaturePad, SignatureFont, vLottiePlayer: VueLottiePlayer },
  data() {
    return {
      isPad: true,
      isFont: false,
      isLandScape: null,
    };
  },

  methods: {
    cancel() {},
    toggleSignatureMethod(method) {
      if (method == "pad") {
        this.isPad = true;
        this.isFont = false;
      } else {
        this.isFont = true;
        this.isPad = false;
      }
    },
    handleOrientationChange() {
      const orientation = window.screen.orientation.type;
      if (orientation === "portrait-primary") {
        this.isLandScape = false;
      } else if (orientation === "landscape-primary") {
        this.isLandScape = true;
      }
    },
  },
  mounted() {
    window.addEventListener("orientationchange", this.handleOrientationChange);
    this.handleOrientationChange();
  },
};
</script>

<style>
.center-animation {
  display: block;
  margin: auto;
}
</style>
