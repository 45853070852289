<template>
	<div class="layout-profile">
		<div>
			<!-- <span style="color: white; ">{{this.auth.loginStore.profile.data.user.logo}}</span> -->
		</div>
		<button class="p-link layout-profile-link" @click="onClick">
			<span class="username">{{profile.fullName}} </span>
			<!-- i class="pi pi-fw pi-cog"></i -->
		</button>
        <transition name="layout-submenu-wrapper">
            <ul v-show="expanded">
                <!-- <li><button class="p-link"><i class="pi pi-fw pi-user"></i><span>{{$t("account")}}</span></button></li> -->
				<!-- li><button class="p-link"  @click="openImageModal"><i class="pi pi-fw pi-image"></i><span>{{$t("change_image")}}</span></button></li -->
				<!-- <li><button class="p-link"><i class="pi pi-fw pi-inbox"></i><span>{{$t("notifications")}}</span></button></li> -->
                <!--<li><button class="p-link"><i class="pi pi-fw pi-inbox"></i><span>{{$t("notifications")}}</span><span class="menuitem-badge">2</span></button></li>-->
                <!-- li><button class="p-link" @click="logout"><i class="pi pi-fw pi-power-off"></i><span>{{$t("logout")}}</span></button></li -->
            </ul>
        </transition>
		<ImageForm ref="imageModal"/>
	</div>
</template>

<script>
import { AUTH_LOGOUT } from "@/modules/auth/login/actions";
import ImageForm from "@/modules/dealership/profile/uploadImageForm.vue";
import { mapState } from "vuex";

	export default {
		components: { ImageForm },
		data() {
			return {
				expanded: false
			}
		},
		methods: {
			onClick(event){
				this.expanded = !this.expanded;
				event.preventDefault();
			},
			logout() {
				this.$store
					.dispatch(AUTH_LOGOUT)
					.then(() => {
						this.$router.push({ name: "home" });
					})
					.catch(() => {
						this.$toast.add({
							severity: "danger",
							summary: "Error",
							detail: "Error to logout",
							life: 4000,
						});
					});
			},
			openImageModal() {
				this.$refs.imageModal.openModal();
			}
		},
		computed: {
			...mapState(['auth']), 
			profile: (state) => state.auth.loginStore.profile.data.user,
			/*userName() {
				if (this.auth.loginStore.profile.data) {
					const firstName = this.auth.loginStore.profile.data.user.firstName
					const lastName = this.auth.loginStore.profile.data.user.lastName
					return `${firstName.toUpperCase()} ${lastName.toUpperCase()}`
				} else {
					return null
				}
			}*/

		},
	}
</script>

<style scoped>

</style>