<template>
	<div class="layout-footer-full" style="background-color: white; padding: 15px;">
		<span class="footer-text" style="margin-right: 5px; background-color: white;">
			{{$t('copyright')}} <a href="https://propulsoft.ca/"> Propulsoft.ca</a>
		</span>
	</div>
</template>

<script>
	export default {
		name: "AppFooter"
	}
</script>

<style scoped>
	.layout-footer-fulls {
    transition: margin-left 0.2s;
    background-color: red;
    padding: 1em 2em;
}
</style>