<template>
  <div>
    <div class="layout-menu-container">
      <AppSubmenu
        :items="model"
        class="layout-menu"
        :root="true"
        @menuitem-click="onMenuItemClick"
      />
      <ul v-if="userHasRole('ROLE_DEALERSHIP')" class="layout-menu">
        <li>
          <a href="#" @click="onClick" role="menuitem">
            <i class="pi pi-fw pi-cog"></i>
            <span>{{ $t("settings") }}</span>
          </a>
        </li>
      </ul>
      <transition name="layout-submenu-wrapper">
        <ul v-show="expanded">
          <!-- <li><button class="p-link"><i class="pi pi-fw pi-user"></i><span>{{$t("account")}}</span></button></li> -->
          <li>
            <button class="p-link" @click="openImageModal">
              <i class="pi pi-fw pi-image"></i
              ><span>{{ $t("change_image") }}</span>
            </button>
          </li>
          <!-- <li><button class="p-link"><i class="pi pi-fw pi-inbox"></i><span>{{$t("notifications")}}</span></button></li> -->
          <!--<li><button class="p-link"><i class="pi pi-fw pi-inbox"></i><span>{{$t("notifications")}}</span><span class="menuitem-badge">2</span></button></li>-->
          <!-- li><button class="p-link" @click="logout"><i class="pi pi-fw pi-power-off"></i><span>{{$t("logout")}}</span></button></li -->
        </ul>
      </transition>
      <ImageForm ref="imageModal" />
      <ul class="layout-menu">
        <li>
          <a href="#" @click="logout" role="menuitem">
            <i class="pi pi-fw pi-power-off"></i>
            <span>{{ $t("logout") }}</span>
          </a>
        </li>
      </ul>
    </div>
    <!-- logout -->
  </div>
</template>

<script>
import AppSubmenu from "./AppSubmenu";
import ImageForm from "@/modules/dealership/profile/uploadImageForm.vue";
import { generalMixin } from "./modules/mixin";

export default {
  props: {
    model: Array,
  },
  mixins: [generalMixin],
  data() {
    return {
      expanded: false,
    };
  },
  methods: {
    onMenuItemClick(event) {
      this.$emit("menuitem-click", event);
    },
    onClick(event) {
      this.expanded = !this.expanded;
      event.preventDefault();
    },
    logout() {
      this.$store
        .dispatch("AUTH_LOGOUT")
        .then(() => {
          this.$router.push({ name: "home" });
        })
        .catch(() => {
          this.$toast.add({
            severity: "danger",
            summary: "Error",
            detail: "Error to logout",
            life: 4000,
          });
        });
    },
    openImageModal() {
      console.log("openImageModal", this.$refs.imageModal);
      this.$refs.imageModal.openModal();
    },
  },
  components: {
    AppSubmenu: AppSubmenu,
    ImageForm: ImageForm,
  },
};
</script>

<style scoped>
</style>