<template>
  <b-modal
    ref="detailsModal"
    id="detailsModal"
    size="lg"
    hide-header
    body-class="pl-0 pr-0"
    ok-disabled
  >
    <Brand />
    <div class="m-4 text-break">
      <b-container class="mt-2">
        <h2 class="text-primary">{{$t('user_details')}}</h2>
        <b-row>
          <b-col xs="12" sm="12" md="12" lg="12" xl="4" cols="12">
            <label for=""><strong>{{$t('full_name')}}</strong></label> <br />
            {{ userItem.user.firstName }}
            {{ userItem.user.lastName }}
          </b-col>
          <b-col xs="12" sm="12" md="12" lg="12" xl="4" cols="12">
            <label for=""><strong>{{$t('c_email')}}</strong></label> <br />
            {{ userItem.user.email }}
          </b-col>
          <b-col xs="12" sm="12" md="12" lg="12" xl="4" cols="12">
            <label for=""><strong>{{$t('phone_mobile')}}</strong></label> <br />
            {{ userItem.user.userDetails.phoneNumber }}
          </b-col>
        </b-row>
        <b-row class="mt-1 mb-1">
          <b-col xs="12" sm="12" md="12" lg="12" xl="4" cols="12">
            <label for=""><strong>{{$t('phone_office')}}</strong></label> <br />
              {{ userItem.user.userDetails.officePhoneNumber }}
          </b-col>
          <b-col xs="12" sm="12" md="12" lg="12" xl="4" cols="12">
            <label for=""><strong>{{$t('phone_extension')}}</strong></label> <br />
              {{ userItem.user.userDetails.extension }}
          </b-col>

          <b-col xs="12" sm="12" md="12" lg="12" xl="6" cols="12"></b-col>
        </b-row>
        <div v-if="userItem.user.status != 0">
          <h2 class="text-primary">{{$t('company_details')}}</h2>
          <b-row class="mb-1">
            <b-col xs="12" sm="12" md="12" lg="12" xl="8" cols="12">
              <label for=""><strong>{{$t('company_name')}}</strong></label> <br />
              {{ userItem.legalName }}
            </b-col>
            <b-col xs="12" sm="12" md="12" lg="12" xl="4" cols="12">
              <label for=""><strong>{{$t('website')}}</strong></label> <br />
              {{ userItem.user.userDetails.website }}
            </b-col>
          </b-row>
          <b-row class="mb-1">
            <b-col xs="12" sm="12" md="12" lg="12" xl="6" cols="12">
              <label for=""><strong>{{$t('street')}}</strong></label> <br />
              {{ userItem.street }}
            </b-col>
          </b-row>
          <b-row class="mb-1">
            <b-col xs="12" sm="12" md="12" lg="12" xl="4" cols="12">
              <label for=""><strong>{{$t('city')}}</strong></label> <br />
              {{ userItem.city.name }}
            </b-col>
            <b-col xs="12" sm="12" md="12" lg="12" xl="4" cols="12">
              <label for=""><strong>{{$t('state')}}</strong></label> <br />
               {{userItem.city.state.name}} 
            </b-col>
            <b-col xs="12" sm="12" md="12" lg="12" xl="4" cols="12">
              <label for=""><strong>{{$t('zip_code')}}</strong></label> <br />
              {{ userItem.zip }}
            </b-col>
          </b-row>
          <b-row>
            <b-col xs="12" sm="12" md="12" lg="12" xl="4" cols="12">
              <span v-if="userItem.typeNumber == 'NEQ'"><strong>NEQ</strong></span>
              <span v-else> <strong>{{$t('business_number')}}</strong></span>
              <br />
              {{ userItem.corporationNumber }}
            </b-col>
            <b-col xs="12" sm="12" md="12" lg="12" xl="4" cols="12">
              <label for=""> <strong>SAAQ</strong></label> <br />
              {{ userItem.saaq }}
            </b-col>
            <b-col xs="12" sm="12" md="12" lg="12" xl="4" cols="12">
              <label for=""><strong>{{$t('signatory_name')}}</strong></label> <br />
              {{ userItem.signatoryFirstName }} {{ userItem.signatoryLastName }}
            </b-col>
            <b-col xs="12" sm="12" md="12" lg="12" xl="4" cols="12">
              <label for=""><strong>{{$t('signatory_email')}}</strong></label> <br />
              {{ userItem.signatoryEmail }}
            </b-col>
            <b-col xs="12" sm="12" md="12" lg="12" xl="4" cols="12"></b-col>
          </b-row>
        </div>
      </b-container>
    </div>
    <template #modal-footer>
      <div class="w-100">
        <b-button
          variant="danger"
          size="lg"
          class="float-right"
          @click="$bvModal.hide('detailsModal')"
        >
          {{$t('close')}}
        </b-button>
      </div>
    </template>
  </b-modal>
</template>
<script>
import Brand from "@/modules/shared/brand.vue";

export default {
  components: { Brand },
  props: {
    userItem: {},
  },
};
</script>
