<template>
  <div class="container">
    <div class="row justify-content-end">
      <h5>
        <strong> 789632166 </strong>
      </h5>
    </div>
    <div class="row justify-content-end">
      <b-btn
        size="lg"
        class="btn btn-light-blue text-white"
        type="button"
        @click="showModal('notes-modal')"
      >
        Notes
        <b-icon icon="card-text"></b-icon>
      </b-btn>
      <br />
    </div>
    <div class="row ml-0 mr-0">
      <b-col xs="12" sm="12" md="12" lg="12" xl="1" cols="12"></b-col>
      <b-col xs="12" sm="12" md="12" lg="12" xl="10" cols="12">
        <div class="container text-justify" ref="PDFText">
          <h2>Lease agreement</h2>

          <p>
            Subject to our acceptance of this authorization, you acknowledge the
            following: (i) the capacities in which the Individual is authorized
            as your agent apply at and with us our subscribing AuctionACCESS
            customers (“Customers”), including affiliates; (ii) the
            authorization will be valid until one business day after we receive
            and process your written notice of termination in the form of a
            Dealer Removal Letter; and (iii) once processed, the most recent
            authorization received and accepted by us will replace any prior
            authorization(s) for the Individual for transactions thereafter.
          </p>
          <p>
            If you designate the Individual in the capacity of “Representative”,
            “Owner”, or “Officer/Manager” in this form, the Individual may buy
            and sell automobiles for you and execute company checks, drafts,
            certificates of title, and/or any other instruments or documents on
            your behalf at or with any Customer. You are responsible for any use
            of such Individual’s AuctionACCESS ID on your account. All
            categories, including “Drivers”, may pick up, receive, and/or
            deliver cars of other property on or for your account.
          </p>
          <p>
            You guarantee performance of all obligations and payment of all
            debts incurred or authorized by this Individual on your account with
            us or any Customer. This guaranty includes but is not limited to
            payment of losses from dishonored checks or drafts, defective
            titles, or false or inaccurate Odometer Mileage Statements. You also
            guarantee that the Individual will honor the AutoTec Terms and
            Conditions (as they may be amended from time to time and posted on
            www.AuctionACCESS.com), conduct business with us and our Customers
            in a fair and ethical manner, honoring all policies and procedures
            of each and respecting decisions of ours or our Customers with
            respect to same. You will indemnify and hold harmless Customers,
            AutoTec, LLC, each’s affiliates, and the officers, directors,
            employees, and agents of each of the foregoing, against any
            liability, loss, damages (including punitive damages), claim,
            settlement payment, cost, expense, award judgment, fee, or other
            charge, including reasonable attorneys’ fees, arising out of or
            relating to the authorized Individual’s actions, as well as any
            expense incurred in attempting to collect such losses, including
            attorney fees.
          </p>
          <p>
            Our Customers are third-party beneficiaries of this authorization
            agreement. At their election, and in their sole discretion, they may
            avail themselves of any provision of this agreement. However, this
            document is a supplement to, and not a replacement of modification
            of, any agreement you have with those Customers, or any policies and
            procedures of such Customer. AuctionACCESS and Customers each
            retains the right to stop doing business with this Individual as it
            sees fit.
          </p>
          <p>
            Sign:
            <u class="sign">
              <img
                v-if="signImage"
                :src="signImage"
                class="sign"
                alt=""
                @click="gotoSignature()"
              />

              <button v-if="!signImage" @click="gotoSignature()">sign</button>
            </u>
          </p>
        </div>
      </b-col>

      <b-col xs="12" sm="12" md="12" lg="12" xl="1" cols="12"></b-col>
    </div>
    <b-row class="mr-0 ml-0">
      <b-col sm="12" md="4" lg="4" xl="4" class="mb-4"> </b-col>
      <b-col sm="12" md="6" lg="4" xl="4" class="mb-4">
        <b-button
          block
          class="btn btn-success"
          :disabled="!signImage"
          @click="generatePDF"
        >
          Confirm
          <b-spinner
            v-if="contract.status == 'fetching' ? true : false"
            small
            variant="white"
          ></b-spinner>
        </b-button>
      </b-col>
      <b-col sm="12" md="4" lg="4" xl="4" class="mb-4"> </b-col>
    </b-row>

    <Notes />

    <!--  <div class="text-center">
      <button class="btn btn-danger mb-4" @click="generatePDF">Envoye</button>
    </div> -->
  </div>
</template>

<script>
import { generalMixin } from "@/modules/mixin.js";
import { mapState } from "vuex";
import Notes from "@/modules/shared/notes/NotesModal.vue";

export default {
  components: { Notes },

  mixins: [generalMixin],

  props: {
    userItem: {},
  },
  data() {
    return {
      sign: "",
      font: "",
      form: {},
      modalPDF: false,
      piedPage: "",
      name: "Jorge Luca Anton Juarez",
      isPrint: false,
      token: null,
      serverTime: "",
    };
  },
  computed: {
    ...mapState({
      signImage: (state) => state.dealership.document.sign,
      contract: (state) => state.dealership.document.contract,
    }),
  },
  methods: {
    async generatePDF() {},

    async setPiedPage() {},
    async getIp() {},

    getBrowserInfo() {},

    getServerTime() {},

    gotoSignature() {
      this.$store.commit("setURLName", "/admin/lease-agreement");

      this.$router.push({
        name: "admin.lease-agreement.signature",
        params: { token: "trackingNumber" },
      });
    },
    async formatPayload() {},

    getCompany() {},

    goTo() {
      this.$router.push({ path: "/" });
    },
  },
  mounted() {
    this.setPiedPage();
    this.getCompany();
  },
};
</script>

<style scoped>
.pdf {
  width: 522px !important;
}

.sign {
  max-height: 100px;
  margin-top: -10px;
  margin-left: 8px;
}
</style>
