import axios from "@/plugins/axios.js";

export default {
  getCar(vinNumber) {
    return axios.get("/vehicle?vinNumber=" + vinNumber);
  },
  getCarVinType(payload){
    return axios.post("/vehicle/find", payload); //SKYCHAR
  },
  getRates() {
    return axios.get("/vehicle_types");
  },
  getCarRates(type) {
    return axios.get("/vehicle_types?type=" + type);
  },
  updateRate(payload) {
    if (payload.modify == 'increaseNew' || payload.modify == 'increaseUsed') {
      payload.modify = payload.modify.replace('increase', '').toLowerCase()
      return axios.post("/vehicle_types/increase_rate", payload);
    } else
      return axios.post("/vehicle_types/update", payload);
  },
};
