/** @format */

import Vue from 'vue'
import VueRouter from 'vue-router'
import AuthRoutes from '@/modules/auth/routes'
import BuyerRoutes from '@/modules/dealership/routes'
import AdminRoutes from '@/modules/admin/routes'
import HomeRoutes from '@/modules/home/routes'
import UserRoutes from '@/modules/user/routes'
import BuyerClientRoutes from '@/modules/buyer/routes'
import LeasingAgentRoutes from '@/modules/leasing-agent/routes'
import AgentRoutes from '@/modules/agent/routes'

Vue.use(VueRouter)

const routes = [
  ...AuthRoutes,
  ...BuyerRoutes,
  ...HomeRoutes,
  ...AdminRoutes,
  ...UserRoutes,
  ...BuyerClientRoutes,
  ...LeasingAgentRoutes,
  ...AgentRoutes
]

const router = new VueRouter({
  routes
})

export default router
