/** @format */

import Vue from 'vue'
import Auth from './Auth.vue'
import Default from './Default.vue'
import Admin from './Admin.vue'
import Home from './Home.vue'
import Company from './Company.vue'
import NoMenu from './NoMenu.vue'
import Customer from './Customer.vue'
import LeasingAgent from './LeasingAgent.vue'
import Agent from './Agent.vue'
import Delearship from './Dealership.vue'

Vue.component('auth-layout', Auth)
Vue.component('default-layout', Default)
Vue.component('admin-layout', Admin)
Vue.component('home-layout', Home)
Vue.component('company-layout', Company)
Vue.component('nomenu-layout', NoMenu )
Vue.component('customer-layout', Customer )
Vue.component('leasingagent-layout', LeasingAgent )
Vue.component('agent-layout', Agent )
Vue.component('dealership-layout', Delearship)
