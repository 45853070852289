<template>
  <div>
    <b-row>
      <b-col xs="12" sm="12" md="12" lg="12" xl="2" cols="12"></b-col>
      <b-col xs="12" sm="12" md="12" lg="12" xl="8" cols="12">
        <b-form class="m-3" @submit.stop.prevent="update">
          <b-row class="mb-3">
            <b-col>
              <h2 class="text-purple-blue">{{ $t("user") }}</h2>
            </b-col>
          </b-row>

          <b-row>
            <!-- First name -->

            <b-col xs="12" sm="12" md="12" lg="12" xl="6" cols="12">
              <b-form-group label-size="sm">
                <template slot="label"> {{ $t("firstName") }} </template>
                <b-form-input
                  size="md"
                  id="firstName-input"
                  name="firstName-input"
                  @change="setValue('firstName', $event)"
                  :value="form.firstName"
                  :state="validateState('firstName')"
                  v-model.trim="$v.form.firstName.$model"
                  aria-describedby="input-firstName-live-feedback"
                  maxlength="100"
                >
                </b-form-input>
                <b-form-invalid-feedback id="input-firstName-live-feedback">
                  {{ $t("requiredTwoCharacters") }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>

            <!-- Last name -->
            <b-col xs="12" sm="12" md="12" lg="12" xl="6">
              <b-form-group label-size="sm" label="Last name ">
                <template slot="label">
                  {{ $t("lastName") }}
                </template>
                <b-form-input
                  size="md"
                  id="lastName-input"
                  name="lastName-input"
                  @change="setValue('lastName', $event)"
                  :value="form.lastName"
                  v-model.trim="$v.form.lastName.$model"
                  :state="validateState('lastName')"
                  aria-describedby="input-lastName-live-feedback"
                  maxlength="100"
                >
                </b-form-input>
                <b-form-invalid-feedback id="input-lastName-live-feedback">
                  {{ $t("requiredTwoCharacters") }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>

            <!-- Email -->
            <b-col sm="12" md="12" lg="12" xl="6">
              <b-form-group label-size="sm" label="Email ">
                <b-form-input
                  size="md"
                  id="email-input"
                  name="email-input"
                  @change="setValue('email', $event), verifyEmail()"
                  :value="form.email"
                  :state="validateState('email')"
                  v-model.trim="$v.form.email.$model"
                  aria-describedby="input-email-live-feedback"
                  maxlength="255"
                >
                </b-form-input>
                <b-form-invalid-feedback id="input-email-live-feedback">
                  {{ $t("requiredValidEmail") }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>

            <!-- Phone number -->
            <b-col sm="12" md="6" lg="6" xl="6">
              <b-form-group label-size="sm" label="Mobile phone">
                <template slot="label">
                  <span>
                    {{ $tc("telephone") }}<span class="text-danger"> *</span>
                  </span>
                </template>

                <vue-tel-input
                  v-model="form.userDetails.phoneNumber"
                  defaultCountry="ca"
                  :inputOptions="options"
                  :dropdownOptions="telDdOptions"
                  :autoFormat="true"
                  :validCharactersOnly="true"
                  @change="setValue('mobilePhone', $event)"
                  :value="form.userDetails.phoneNumber"
                  mode="international"
                ></vue-tel-input>

                <b-form-invalid-feedback id="input-mobile-phone-live-feedback">
                  {{ $t("numericField") }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col sm="12" md="12" lg="12" xl="3" class="mb-3">
              <b-btn
                block
                size="lg"
                variant="light-blue"
                :disabled="$v.form.$invalid || isLoading"
                type="button"
                @click="changePassword()"
                >{{ $t("changePassword") }}
                <b-spinner
                  v-if="isLoadingPassword"
                  small
                  variant="white"
                ></b-spinner
              ></b-btn>
            </b-col>
            <b-col sm="12" md="12" lg="12" xl="6"> </b-col>
            <b-col sm="12" md="12" lg="12" xl="3">
              <b-btn
                block
                size="lg"
                variant="light-blue"
                :disabled="$v.form.$invalid || isLoading"
                type="submit"
                >{{ $t("update") }}
                <b-spinner v-if="isLoading" small variant="white"></b-spinner
              ></b-btn>
            </b-col>
          </b-row>
        </b-form>
      </b-col>
      <b-col xs="12" sm="12" md="12" lg="12" xl="2" cols="12"></b-col>
    </b-row>
    <b-modal
      centered
      ref="emailModal"
      id="emailModal"
      size="md"
      hide-header-close
      hide-header
      hide-footer
      body-class="pl-0 pr-0"
    >
      <div class="text-center p-5">
        <p>
          <b-icon font-scale="5" icon="envelope"></b-icon>
        </p>
        <h5>{{ $t("weSendAnEmail") }}</h5>
        <b-btn
          size="lg"
          class="btn-main mt-2"
          @click="$bvModal.hide('emailModal')"
          >{{ $t("accept") }}
        </b-btn>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { required, minLength, email } from "vuelidate/lib/validators";
import { generalMixin } from "../../mixin";
import { mapState } from "vuex";

export default {
  mixins: [generalMixin],
  data() {
    return {
      isLoading: false,
      isLoadingPassword: false,
      form: {
        firstName: null,
        lastName: null,
        email: null,
        officePhoneNumber: null,
        userDetails: {
          phoneNumber: null,
        },
        extension: null,
        mobilePhone: null,
        preferredLanguage: null,
        repeat_password: null,
      },
      options: {
        type: "tel",
        validCharactersOnly: true,
        maxlength: 14,
      },
      telDdOptions: {
        showDialCodeInList: true,
        showFlags: true,
        showDialCodeInSelection: false,
      },

      canEdit: false,
    };
  },
  validations: {
    form: {
      firstName: {
        required,
        minLength: minLength(2),
      },
      lastName: {
        required,
        minLength: minLength(2),
      },
      email: {
        required,
        email,
      },

      userDetails: {
        phoneNumber: {},
      },
    },
  },
  computed: {
    ...mapState({
      profile: (state) => state.auth.loginStore.profile.data.user,
    }),
  },
  beforeMount() {
    this.form = { ...this.profile };
  },
  methods: {
    setValue(key, value) {
      this.form[key] = value;
      this.$v.form[key].$touch();
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },

    update() {},

    changePassword() {},

    formatPayload() {
      const payload = {
        id: this.form.id ? this.form.id : 0,
        firstName: this.form.firstName,
        lastName: this.form.lastName,
        email: this.form.email,
        preferredLanguage: this.form.preferredLanguage,
        userDetails: {
          extension: this.form.extension,
          mobileNumber: this.form.mobileNumber,
          officePhoneNumber: this.form.officePhoneNumber,
          languageId: this.form.preferredLanguage,
        },
      };
      return payload;
    },
  },
};
</script>

<style scoped>
</style>

