<template>
  <div class="container-fluid bg-white" style="padding-top:50px">
    <div class="row">
      <div class="col-12">
        <h2 class="ml-3 text-primary">{{$t('validated')}} {{ this.filter }}</h2>
        <div class="pt-4">
      <TableBase
        :items="users.data"
        :fields="fields"
        :options="options"
        :busy="users.status === 'fetching' ? true : false"
      />
    </div>
    <UserDetails v-if="userItem" :userItem="userItem" />
  </div>
</div>
</div>
</template>
<script>
import { mapState } from "vuex";

import TableBase from "@/modules/shared/table.vue";
import UserDetails from "@/modules/dealership/Details.vue";
import Brand from "@/modules/shared/brand.vue";
import { GET_USERS_COMPANY } from "./actions";

import { generalMixin } from "@/modules/mixin.js";
export default {
  components: { TableBase, UserDetails, Brand },
  mixins: [generalMixin],
  computed: {
    ...mapState({
      users: (state) => state.admin.users.users,
      fields(){
        return [
          { key: "id", label:this.$t("id"),  thClass: 'bg-header text-dark'},
          { key: "user.firstName", label: this.$t("firstName"),  thClass: 'bg-header text-dark' },
          { key: "user.lastName", label: this.$t("lastName"),  thClass: 'bg-header text-dark' },
          { key: "user.email", label: this.$t("c_email") ,  thClass: 'bg-header text-dark' },
          { key: "user.userDetails.phoneNumber", label: this.$t("phoneNumber") ,  thClass: 'bg-header text-dark' },
          {
            key: "actions",
            label: "Actions",
            thClass: 'bg-header text-dark',
            note: false,
            details: true,
            validation: false,
          },
        ]
      }
    }),
  },

  data() {
    return {
      options: {
        assign: true,
        rolFilter: false,
        add: false,
        fun: this.getData,
      },
      userItem: {},
      filter: null,
    };
  },
  methods: {
    getData(filter = "") {
      const payload = {
        search: filter,
        status: "Validated",
      };
      this.$store.dispatch(GET_USERS_COMPANY, payload).catch(() => {
        this.$toast.add({
              summary: this.$t('toast_title_error'),
              detail: this.$t('toast_message_error'),
              severity: "error",
              life: 5000
          })
      });
    },
    editItem(item) {
      this.userItem = item;
      this.$bvModal.show("detailsModal");
    },
    changePage(page, filter = "") {
      const payload = {
        search: filter,
        status: "Validated",
        page: page,
      };
      this.$store.dispatch(GET_USERS_COMPANY, payload).catch(() => {
        this.$toast.add({
              summary: this.$t('toast_title_error'),
              detail: this.$t('toast_message_error'),
              severity: "error",
              life: 5000
          })
      });
    },
  },
  mounted() {
    this.getData();
  },
};
</script>
