<template>
  <div class="wrapper">
    <NavBar :isShowMenu="showMenu" />
    <div class="content">
      <router-view></router-view>
    </div>
    <div class="bg-dark-grey text-center footer">
      <p class="text-white pb-3 pt-4 mt-0 mb-0 container">
        {{$t('copyright')}}
        <a href="https://propulsoft.ca/"> Propulsoft.ca</a>
      </p>
    </div>
  </div>
</template>

<script>
import NavBar from "@/layouts/components/CompanyNavBar.vue";

export default {
  name: "Default",
  components: { NavBar },
  computed: {
    showMenu() {
      return this.$route.name == "dealership.upload-files" ? false : true;
    },
  },
};
</script>

<style scope>
.mt-8 {
  margin-top: 8rem !important;
}
@media only screen and (max-width: 600px) {
  .mt-8 {
    margin-top: 2rem !important;
  }
}
.wrapper {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
.content {
  flex-grow: 1;
}
.footer {
  height: auto;
}
</style>
