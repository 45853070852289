<template>
  <b-container fluid class="div-center p-0 m-0">
    <b-row class="justify-content-end px-0 mx-0">
      <div class="text-white mt-5 mr-5">
        <button class="p-link text-white" @click="changeLang()">
          <span class="pi pi-globe mr-2"></span>
          <span class="">{{ $t("lang") }} </span>
        </button>
      </div>
    </b-row>
    <b-row class="justify-content-center p-0 m-0">
      <b-col cols="7" md="6" class="center">
        <Login />
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import Login from "../auth/login/LoginForm.vue";
export default {
  components: { Login },
  data() {
    return {};
  },
  computed: {},
  methods: {
    changeLang() {
      if (this.$root.$i18n.locale == "fr") {
        this.$root.$i18n.locale = "en";
        window.localStorage.setItem("language", "en");
        location.reload()
      } else {
        this.$root.$i18n.locale = "fr";
        window.localStorage.setItem("language", "fr");
        location.reload()
      }
    },
  },

  beforeMount() {},
};
</script>

<style>
.div-center {
  background-image: url("../../assets/img/login_bg.png");
  background-position: center;
  background-size: cover;
  height: 100vh;
}

.center {
  margin-top: 20vh;
}
</style>
