/** @format */

import * as constants from './actions'
import axios from '@/plugins/axios.js'
import catalogs from './catalogs/store'
function getDefaultState() {
  return {
    deals: {
      status: null,
      data: [],
      error: null,
      filters: {
        page: 1,
        limit: 10,

        search: '',
        dealerId: null,
        condition: null,
        type: null,
        startDateFrom: null,
        startDateTo: null,
        endDateFrom: null,
        endDateTo: null
      }
    }
  }
}

const actions = {
  [constants.GET_DEALS_LIST]: async ({ commit, state }) => {
    try {
      commit(constants.GET_DEALS_LIST)
      const payload = Object.entries(state.deals.filters).reduce(
        (a, [k, v]) => (v === null || v === '' ? a : { ...a, [k]: v }),
        {}
      )
      const response = await axios.get(`deals`, {
        params: payload
      })
      commit(constants.GET_DEALS_LIST_SUCCESS, response)
      return response
    } catch (error) {
      commit(constants.GET_DEALS_LIST_ERROR, error)
      throw error.response
    }
  }
}

const mutations = {
  [constants.GET_DEALS_LIST]: state => {
    state.deals.status = 'fetching'
    state.deals.error = null
  },
  [constants.GET_DEALS_LIST_SUCCESS]: (state, data) => {
    state.deals.status = 'success'
    state.deals.error = null
    state.deals.data = data
  },
  [constants.GET_DEALS_LIST_ERROR]: (state, error) => {
    state.deals.status = 'error'
    state.deals.error = error
  },
  [constants.SET_DEALS_FILTERS]: (state, filters) => {
    if (filters.reset) {
      state.deals.filters = {
        search: null,
        resourcesPerPage: 25,
        page: 1
      }
    }
    if (filters.fields) {
      state.deals.filters = {
        ...state.deals.filters,
        ...filters.fields
      }
    }
  }
}

export default {
  state: getDefaultState(),
  actions,
  mutations,
  modules: {
    catalogs
  }
}
