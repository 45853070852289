import * as constants from "./actions";
import axios from "@/plugins/axios.js";

axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

function getDefaultState() {
  return {
    users: {
      status: null,
      data: [],
      error: null,
    },
    user: {
      status: null,
      data: null,
      error: null,
    },
  };
}

const actions = {
  [constants.GET_USERS_BUYER]: async ({ commit }, params) => {
    try {
      commit(constants.GET_USERS_BUYER);

      const response = await axios.get(`company/listUserByCompany`, {
        params: { ...params },
      });
      commit(constants.GET_USERS_BUYER_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.GET_USERS_BUYER_ERROR, error);
      throw error.response;
    }
  },
  [constants.PUT_USER_BUYER]: async ({ commit }, params) => {
    try {
      commit(constants.PUT_USER_BUYER);

      const response = await axios.put(`user`, params);
      commit(constants.PUT_USER_BUYER_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.PUT_USER_BUYER_ERROR, error);
      throw error.response;
    }
  },

  [constants.DELETE_USER_BUYER]: async ({ commit }, params) => {
    try {
      commit(constants.DELETE_USER_BUYER);

      const response = await axios.delete(
        `company/deleteUserNotValidate?userId=${params.id}`
      );
      commit(constants.DELETE_USER_BUYER_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.DELETE_USER_BUYER_ERROR, error);
      throw error.response;
    }
  },

  [constants.PUT_USER_BUYER_PASSWORD]: async ({ commit }, params) => {
    try {
      commit(constants.PUT_USER_BUYER_PASSWORD);

      const response = await axios.put(`user/completeRegistration`, params);
      commit(constants.PUT_USER_BUYER_PASSWORD_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.PUT_USER_BUYER_PASSWORD_ERROR, error);
      throw error.response;
    }
  },

  postUser: async ({commit}, payload) => {
    return await axios.post('user/create', payload);
  },
  generatePDF : async ({commit}, id) => {
    return await axios.get('deals/pdf/'+id,{ responseType: 'blob' });
  },
  generateExcel : async ({commit}, id) => {
    return await axios.get('/deals/excel',{ responseType: 'blob' });
  },
  loadUsers: async ({commit}) => {
    return await axios.get("user/customers");
  },
  loadUsersLeaseAgents: async ({commit}) => {
    return await axios.get("user/lease_agents");
  },
  uploadFileImage : async ({commit}, payload ) => {
    return await axios.post('/user/profile', payload,{ 
    });
  },
  uploadDocuments : async ({commit}, payload ) => {
    return await axios.post('/deals/upload_documents', payload,{
    });
  },
  updateUserInformation : async ({commit}, payload) => {
    return await axios.put('/user/profile', payload,{});
  },
  loadCustomersLease: async ({commit}) => {
    return await axios.get("/deals");
  },
  saveDocumentsRequired: async({commit}, payload) => {
    return await axios.post('/deals/required_documents', payload, {});
  },
  downloadDocumentComparation: async ({commit}, payload) => {
    return await axios.post('/deals/pdf_preview', payload, { responseType: 'blob' });
  },
  validationDocuments: async({commit}, payload) => {
    return await axios.post('/deals/validation_documents', payload, {});
  },
  saveCloseDeal: async({commit}, payload) => {
    return await axios.post('/deals/close', payload, {});
  },
  loadDealsClosing: async({commit}, rank) => {
    return await axios.get('/deals/closed/'+rank);
  },
  saveCancelDeals: async({commit}, payload) => {
    return await axios.post('/deals/cancel', payload, {});
  },
  loadIncrease: async({commit}, type) => {
    return await axios.get("/vehicle_types/increases?type="+type, {});
  },
  updateIncrease: async({commit}, payload) => {
    return await axios.post("/vehicle_types/increase_rate_default", payload, {});
  },
  loadSearchingDeals: async({commit}, payload) => {
    return await axios.post('/deals/advanced_search', payload, {});
  },
  loadMarke: async({commit}, payload) => {
    return await axios.post("/vehicle/makes", payload);
  },
  loadModels: async({commit}, payload) => {
    return await axios.post("/vehicle/models", payload, {});
  },
  loadSeries: async({commit}, payload) => {
    return await axios.post("/vehicle/series", payload,{});
  },
  loadStyles: async({commit}, payload) => {
    return await axios.post("/vehicle/styles", payload, {});
  },
  loadPricesDrilldown: async({commit}, payload) => {
    return await axios.post("/vehicle/prices", payload, {});
  },
  loadSAConditions: async({commit}, payload) => {
    return await axios.get("/taxonomies/conditions", payload, {});
  },
  loadSACompanies: async({commit}, payload) => {
    return await axios.get("/taxonomies/companies", payload, {});
  },
  loadSACustumers: async({commit}, payload) => {
    return await axios.get("/taxonomies/customers", payload, {});
  },
  loadSATypes: async({commit}, payload) => {
    return await axios.get("/taxonomies/types", payload, {});
  },
  loadSAMarks: async({commit}, payload) => {
    return await axios.get("/taxonomies/marks", payload, {});
  },
  loadSAModels: async({commit}, payload) => {
    return await axios.get("/taxonomies/models", payload, {});
  },
  loadUserInDS: async({commit}, payload) => {
    console.log(payload);
      return axios.get("/user", payload, {});
  }
};

const mutations = {
  [constants.GET_USERS_BUYER]: (state) => {
    state.users.status = "fetching";
    state.users.error = null;
  },
  [constants.GET_USERS_BUYER_SUCCESS]: (state, data) => {
    state.users.status = "success";
    state.users.error = null;
    state.users.data = data;
  },
  [constants.GET_USERS_BUYER_ERROR]: (state, error) => {
    state.users.status = "error";
    state.users.error = error;
  },

  [constants.PUT_USER_BUYER]: (state) => {
    state.user.status = "fetching";
    state.user.error = null;
  },
  [constants.PUT_USER_BUYER_SUCCESS]: (state, data) => {
    state.user.status = "success";
    state.user.error = null;
    state.user.data = data;
  },
  [constants.PUT_USER_BUYER_ERROR]: (state, error) => {
    state.user.status = "error";
    state.user.error = error;
  },

  [constants.DELETE_USER_BUYER]: (state) => {
    state.user.status = "fetching";
    state.user.error = null;
  },
  [constants.DELETE_USER_BUYER_SUCCESS]: (state, data) => {
    state.user.status = "success";
    state.user.error = null;
    state.user.data = data;
  },
  [constants.DELETE_USER_BUYER_ERROR]: (state, error) => {
    state.user.status = "error";
    state.user.error = error;
  },

  [constants.PUT_USER_BUYER_PASSWORD]: (state) => {
    state.user.status = "fetching";
    state.user.error = null;
  },
  [constants.PUT_USER_BUYER_PASSWORD_SUCCESS]: (state, data) => {
    state.user.status = "success";
    state.user.error = null;
    state.user.data = data;
  },
  [constants.PUT_USER_BUYER_PASSWORD_ERROR]: (state, error) => {
    state.user.status = "error";
    state.user.error = error;
  },
};

export default {
  state: getDefaultState(),
  actions,
  mutations,
};
