<template>
  <div class="container">
    <div class="row justify-content-end">
      <h5>
        <strong>
          Tracking Number:
          {{
            tracking.tracking != null
              ? tracking.tracking.trackingNumber
              : "0000000"
          }}
        </strong>
      </h5>
    </div>
    <div class="row justify-content-end">
      <b-btn
        size="lg"
        class="btn btn-light-blue text-white"
        type="button"
        @click="showModal('notes-modal')"
      >
        {{ $t("notes") }}
        <b-icon icon="card-text"></b-icon>
      </b-btn>
      <br />
    </div>
    <b-row>
      <b-col cols="12" class="mx-0 r-0">
        <ContactInformation :information="profile" class="mt-5" />
      </b-col>
      <b-col cols="12" class="mx-0 r-0">
        <CreditRequest class="mt-3" />
      </b-col>
    </b-row>
    <b-row class="mt-3 mb-5">
      <b-col
        align-self="center"
        xs="12"
        sm="12"
        md="4"
        lg="4"
        xl="4"
        class="border px-0"
      >
        <img
          v-if="signImage"
          :src="signImage"
          class="sign"
          alt=""
          @click="gotoSignature()"
        />

        <b-button
          block
          v-if="!signImage"
          class="btn block text-center"
          @click="gotoSignature()"
        >
          Sign
        </b-button>
      </b-col>
      <b-col align-self="center" xs="12" sm="12" md="4" lg="4" xl="4"> </b-col>
      <b-col align-self="center" xs="12" sm="12" md="4" lg="4" xl="4">
        <b-button variant="success" block> Confirm </b-button>
      </b-col>
    </b-row>

    <Notes />
  </div>
</template>

<script>
import CreditRequest from "./creditRequest/Form.vue";
import Notes from "@/modules/shared/notes/NotesModal.vue";
import ContactInformation from "@/modules/dealership/preApproval/ContactInformation.vue";
import { generalMixin } from "@/modules/mixin.js";
import { POST_TRACKING_NUMBER } from "./actions";
import { mapState } from "vuex";

export default {
  components: { CreditRequest, Notes, ContactInformation },
  mixins: [generalMixin],
  computed: {
    ...mapState({
      signImage: (state) => state.dealership.document.sign,

      profile: (state) => state.auth.loginStore.profile.data.user,
      tracking: (state) => state.dealership.preApproval.trackingNumber.data,
    }),
  },

  methods: {
    getTrackingNumber() {
      const payload = {
        id: this.profile.id,
      };
      this.$store
        .dispatch(POST_TRACKING_NUMBER, payload)
        .then((response) => {
          console.log(response);
        })
        .catch(() => {
          this.$toast.add({
              summary: this.$t('toast_title_error'),
              detail: this.$t('toast_message_error'),
              severity: "error",
              life: 5000
          })
        });
    },
    gotoSignature() {
      this.$store.commit("setURLName", "/dealership/pre-approval");
      this.$router.push({
        name: "dealership.pre-approval.signature",
        params: { token: this.tracking.tracking.trackingNumber },
      });
    },
  },
  beforeMount() {
    this.getTrackingNumber();
  },
};
</script>

<style scoped>
.sign {
  max-width: 200px !important;
  max-height: 100px !important;
  margin-top: -10px;
  margin-left: 8px;
}
</style>