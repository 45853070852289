<template>
  <div>
    <b-row>
      <b-col xs="12" sm="12" md="12" lg="12" xl="2" cols="12"></b-col>
      <b-col xs="12" sm="12" md="12" lg="12" xl="8" cols="12">
        <b-form class="m-3" @submit.stop.prevent="saveCompany">
          <b-row class="mb-3">
            <b-col>
              <h2 class="text-purple-blue">{{ $t("company") }}</h2>
            </b-col>
          </b-row>

          <b-row>
            <b-col sm="12" md="12" lg="6" xl="6">
              <b-form-group label-size="sm">
                <template slot="label"> {{ $t("street") }} * </template>
                <b-form-input
                  required
                  size="sm"
                  name="street"
                  maxlength="65"
                  @change="setValue('street', $event)"
                  :value="form.street"
                  v-model.trim="$v.form.street.$model"
                  :state="validateState('street')"
                />
                <b-form-invalid-feedback>
                  <div v-if="!$v.form.street.required">
                    {{ $t("requiredField") }}
                  </div>
                  <div v-if="!$v.form.street.maxLength">
                    {{ $t("limitCharacters") }}
                  </div>
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>

            <b-col sm="12" md="12" lg="6" xl="6">
              <b-form-group label-size="sm">
                <template slot="label"> {{ $t("zipPostalCode") }} * </template>
                <b-form-input
                  required
                  size="sm"
                  name="zip"
                  maxlength="20"
                  @change="setValue('zip', $event)"
                  :value="form.zip"
                  v-model.trim="$v.form.zip.$model"
                  :state="validateState('zip')"
                />
                <b-form-invalid-feedback>
                  <div v-if="!$v.form.zip.required">
                    {{ $t("requiredField") }}
                  </div>
                  <div v-if="!$v.form.zip.maxLength">
                    {{ $t("limitCharacters") }}
                  </div>
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col sm="12" md="12" lg="6" xl="6">
              <b-form-group label-size="sm">
                <template slot="label"> {{ $t("stateProvince") }} * </template>
                <b-form-select
                  required
                  :options="states"
                  value-field="id"
                  text-field="name"
                  @change="
                    setValue('state', $event);
                    getCities();
                  "
                  v-model="form.state"
                  :state="validateState('state')"
                  :value="form.state"
                  size="sm"
                >
                  <template #first>
                    <b-form-select-option :value="null" disabled
                      >--
                      {{ $t("pleaseSelectOption") }} --</b-form-select-option
                    >
                  </template></b-form-select
                >
                <b-form-invalid-feedback>
                  <div v-if="!$v.form.state.required">
                    {{ $t("requiredField") }}
                  </div>
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>

            <b-col sm="12" md="12" lg="6" xl="6">
              <b-form-group label-size="sm">
                <template slot="label"> {{ $t("city") }} * </template>
                <b-form-select
                  required
                  :options="cities"
                  size="sm"
                  value-field="id"
                  text-field="name"
                  @change="setValue('cityId', $event)"
                  :state="validateState('cityId')"
                  :value="form.cityId"
                >
                  <template #first>
                    <b-form-select-option :value="null" disabled
                      >-- {{ $t("pleaseSelectOption") }}--</b-form-select-option
                    >
                  </template></b-form-select
                >
                <b-form-invalid-feedback>
                  <div v-if="!$v.form.cityId.required">
                    {{ $t("requiredField") }}
                  </div>
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>

            <b-col sm="12" md="12" lg="6" xl="6">
              <b-form-group label-size="sm">
                <template slot="label"> {{ $t("webSite") }} * </template>
                <b-form-input
                  required
                  size="sm"
                  type="url"
                  name="webSite"
                  maxlength="65"
                  @change="setValue('webSite', $event)"
                  :value="form.webSite"
                  v-model.trim="$v.form.webSite.$model"
                  :state="validateState('webSite')"
                />
                <b-form-invalid-feedback>
                  <div v-if="!$v.form.webSite.required">
                    {{ $t("requiredField") }}
                  </div>
                  <div v-if="!$v.form.webSite.url">
                    {{ $t("validURL") }}
                  </div>
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>

            <b-col sm="12" md="12" lg="6" xl="6">
              <b-form-group label-size="sm">
                <template slot="label">
                  {{ $t("billingContactName") }} *
                </template>
                <b-form-input
                  required
                  size="sm"
                  name="billingContactName"
                  maxlength="100"
                  @change="setValue('billingContactName', $event)"
                  :value="form.billingContactName"
                  v-model.trim="$v.form.billingContactName.$model"
                  :state="validateState('billingContactName')"
                />
                <b-form-invalid-feedback>
                  <div v-if="!$v.form.billingContactName.required">
                    {{ $t("requiredField") }}
                  </div>
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>

            <b-col sm="12" md="12" lg="6" xl="6">
              <b-form-group label-size="sm">
                <template slot="label">
                  {{ $t("billingContactEmail") }} *
                </template>
                <b-form-input
                  required
                  size="sm"
                  name="billingContactEmail"
                  maxlength="100"
                  @change="setValue('billingContactEmail', $event)"
                  :value="form.billingContactEmail"
                  v-model.trim="$v.form.billingContactEmail.$model"
                  :state="validateState('billingContactEmail')"
                />
                <b-form-invalid-feedback>
                  <div v-if="!$v.form.billingContactEmail.required">
                    {{ $t("requiredField") }}
                  </div>
                  <div v-if="!$v.form.billingContactEmail.email">
                    {{ $t("validEmail") }}
                  </div>
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col xs="12" sm="12" md="12" lg="12" xl="6" cols="12">
              <!-- Company name -->
              <b-form-group label-size="sm">
                <template slot="label"> {{ $t("companyName") }} </template>
                <b-form-input
                  size="sm"
                  id="companyName-input"
                  name="companyName-input"
                  @change="setValue('companyName', $event)"
                  :value="form.companyName"
                  v-model.trim="$v.form.companyName.$model"
                  :maxlength="65"
                  disabled
                >
                </b-form-input>
              </b-form-group>
            </b-col>

            <b-col sm="12" md="12" lg="6" xl="6">
              <b-form-group label-size="sm" label="Taxid 1 ">
                <b-form-input
                  required
                  size="sm"
                  name="taxid1"
                  maxlength="50"
                  @change="setValue('taxid1', $event)"
                  :value="form.taxid1"
                  v-model.trim="$v.form.taxid1.$model"
                  :disabled="true"
                />
              </b-form-group>
            </b-col>
            <b-col sm="12" md="12" lg="6" xl="6">
              <b-form-group label-size="sm" label="Taxid 2 ">
                <b-form-input
                  required
                  size="sm"
                  name="taxid2"
                  maxlength="50"
                  @change="setValue('taxid2', $event)"
                  :value="form.taxid2"
                  v-model.trim="$v.form.taxid2.$model"
                  :disabled="true"
                />
              </b-form-group>
            </b-col>

            <b-col sm="12" md="12" lg="6" xl="6">
              <b-form-group label-size="sm" label="Dealer license ">
                <template slot="description"> </template>
                <b-form-input
                  required
                  size="sm"
                  name="dealerLicense"
                  maxlength="50"
                  @change="setValue('dealerLicense', $event)"
                  :value="form.dealerLicense"
                  v-model.trim="$v.form.dealerLicense.$model"
                  :disabled="true"
                />
              </b-form-group>
            </b-col>
            <b-col sm="12" md="12" lg="6" xl="6">
              <b-form-group label-size="sm" label="SAAQ record number ">
                <b-form-input
                  required
                  size="sm"
                  name="saaqRecordNumber"
                  maxlength="50"
                  @change="setValue('saaqRecordNumber', $event)"
                  :value="form.saaqRecordNumber"
                  v-model.trim="$v.form.saaqRecordNumber.$model"
                  :disabled="true"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col sm="12" md="12" lg="12" xl="6" class="mb-3">
              <small>{{ $t("changeDiabledFields") }} </small>
            </b-col>
            <b-col sm="12" md="12" lg="12" xl="3"> </b-col>
            <b-col sm="12" md="12" lg="12" xl="3">
              <b-btn
                block
                size="lg"
                class="btn-main"
                :disabled="$v.form.$invalid || isLoading"
                type="submit"
                >{{ $t("update") }}
                <b-spinner v-if="isLoading" small variant="white"></b-spinner
              ></b-btn>
            </b-col>
          </b-row>
        </b-form>
      </b-col>
      <b-col xs="12" sm="12" md="12" lg="12" xl="2" cols="12"></b-col>
    </b-row>
  </div>
</template>
<script>
import { required, maxLength, url, email } from "vuelidate/lib/validators";
import { generalMixin } from "../../mixin";

export default {
  mixins: [generalMixin],

  data() {
    return {
      form: {
        street: "",
        zip: "",
        state: "",
        cityId: "",
        webSite: "",
        taxid1: "",
        taxid2: "",
        dealerLicense: "",
        saaqRecordNumber: "",
        billingContactName: "",
        billingContactEmail: "",
        companyName: "",
      },

      states: [],
      cities: [],

      isLoading: false,
    };
  },
  validations: {
    form: {
      street: {
        required,
        maxLength: maxLength(250),
      },
      zip: {
        required,
        maxLength: maxLength(14),
      },
      state: {
        required,
      },
      cityId: {
        required,
      },
      webSite: {
        required,
        url,
      },

      billingContactEmail: {
        required,
        email,
      },
      billingContactName: {
        required,
      },
      companyName: {
        required,
      },
    },
  },
  methods: {
    setValue(key, value) {
      this.form[key] = value;
      this.$v.form[key].$touch();
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    formatPayload() {
      const payload = {
        street: this.form.street,
        zip: this.form.zip,
        stateId: this.form.state,
        webSite: this.form.webSite,
        taxid1: this.form.taxid1,
        taxid2: this.form.taxid2,
        dealerLicense: this.form.dealerLicense,
        saaqRecordNumber: this.form.saaqRecordNumber,
        billingContactName: this.form.billingContactName,
        billingContactEmail: this.form.billingContactEmail,
        citiesId: this.form.cityId,
        companyName: this.form.companyName,
      };
      return payload;
    },
    async getCompanyDetails() {},
    async saveCompany() {},

    formatForm(data) {
      this.form = data;
      this.form.state = data.city.state.id;
      this.form.cityId = data.citiesId;
      this.form.companyName = data.companyName;
    },
  },
  beforeMount() {
    this.$store.commit("general/setEndPointUrl", "company");
    this.getStates();

    this.getCompanyDetails();
    this.getCities();
  },
};
</script>
<style></style>
