<template>
  <div>
    <b-progress
      :value="25"
      show-progress
      class="mb-3"
      variant="blue"
    ></b-progress>

    <div class="mb-5">
      <b-row class="mr-0 ml-0 bg-dark-grey b-shadows">
        <b-col xs="12" sm="12" md="12" lg="4" xl="4" class="bg-dark-grey">
          <div class="text-right v-center mb-2 my-auto">
            <strong>
              <h2 class="text-light-blue my-auto">
                {{ $tc("preRegistration") }}
              </h2>
              <span class="text-white"> {{ $tc("contactInformation") }} </span
              ><br />
              <span>
                <b-icon
                  icon="telephone"
                  variant="white"
                  class="h5 m-3"
                ></b-icon>
                <b-icon icon="globe" variant="white" class="h5 m-3"></b-icon>

                <b-icon icon="person" variant="white" class="h5 m-3"></b-icon>
                <b-icon icon="envelope" variant="white" class="h5 m-3"></b-icon>
              </span>
            </strong>
          </div>
        </b-col>
        <b-col xs="12" sm="12" md="12" lg="8" xl="8" class="bg-image b-shadows">
          <b-form @submit.stop.prevent="save">
            <b-row class="mr-0 ml-0 mt-5">
              <b-col xs="12" sm="12" md="2" lg="2" xl="2" cols="12"></b-col>
              <b-col xs="12" sm="12" md="8 " lg="8" xl="8" cols="12">
                <h4 class="text-white">{{ $tc("company") }}</h4>
                <b-row>
                  <!-- Company name -->
                  <b-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
                    <b-form-group label-size="md">
                      <template slot="label">
                        <span class="text-white">
                          {{ $tc("legalName") }}
                          <span class="text-danger"> *</span>
                        </span>
                      </template>
                      <b-form-input
                        size="md"
                        @change="setValue('legalName', $event)"
                        :value="form.legalName"
                        v-model.trim="$v.form.legalName.$model"
                        :maxlength="65"
                      >
                      </b-form-input>
                      <b-form-invalid-feedback
                        id="input-legalName-live-feedback"
                      >
                        {{ $tc("requiredTwoCharacters") }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                  <!-- Commercial name (DBA) -->
                  <b-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
                    <b-form-group label-size="md">
                      <template slot="label">
                        <span class="text-white">{{
                          $tc("comercialName")
                        }}</span>
                        <span class="text-danger"> *</span>
                      </template>
                      <b-form-input
                        size="md"
                        name="commercialName"
                        maxlength="65"
                        @change="setValue('commercialName', $event)"
                        :value="form.commercialName"
                        v-model.trim="$v.form.commercialName.$model"
                      />
                    </b-form-group>
                  </b-col>
                  <!-- Company phone number -->
                  <b-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
                    <b-form-group label-size="sm" label="Phone Number">
                      <template slot="label">
                        <span class="text-white">
                          {{ $tc("telephone") }}
                          <span class="text-danger"> *</span>
                        </span>
                      </template>

                      <vue-tel-input
                        v-model="form.phoneNumber"
                        defaultCountry="ca"
                        :inputOptions="options"
                        :dropdownOptions="telDdOptions"
                        :autoFormat="true"
                        size="md"
                        :validCharactersOnly="true"
                        @change="setValue('phoneNumber', $event)"
                        :value="form.phoneNumber"
                        mode="international"
                      ></vue-tel-input>

                      <b-form-invalid-feedback
                        id="input-phoneNumber-live-feedback"
                      >
                        {{ $t("numericField") }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                  <!-- Brand -->
                  <b-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12">
                    <b-form-group label-size="sm">
                      <template slot="label">
                        <span class="text-white">{{ $tc("brand") }}</span>
                      </template>

                      <Multiselect
                        v-model="form.brand"
                        :options="brands"
                        :multiple="true"
                      ></Multiselect>
                    </b-form-group>
                  </b-col>
                </b-row>
                <h4 class="text-white">
                  {{ $tc("contactPerson") }}
                </h4>
                <b-row>
                  <!-- First name -->
                  <b-col xs="12" sm="12" md="6" lg="6" xl="6" cols="6">
                    <b-form-group label-size="sm">
                      <template slot="label">
                        <span class="text-white">
                          {{ $tc("firstName") }}
                          <span class="text-danger"> *</span>
                        </span>
                      </template>
                      <b-form-input
                        size="sm"
                        id="firstName-input"
                        name="firstName-input"
                        @change="setValue('firstName', $event)"
                        :value="form.firstName"
                        v-model.trim="$v.form.firstName.$model"
                        aria-describedby="input-firstName-live-feedback"
                        maxlength="100"
                      >
                      </b-form-input>
                      <b-form-invalid-feedback
                        id="input-firstName-live-feedback"
                      >
                        {{ $t("requiredTwoCharacters") }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                  <!-- Last name -->
                  <b-col xs="12" sm="12" md="6" lg="6" xl="6" cols="6">
                    <b-form-group label-size="sm">
                      <template slot="label">
                        <span class="text-white">
                          {{ $tc("lastName") }}
                          <span class="text-danger"> *</span>
                        </span>
                      </template>
                      <b-form-input
                        size="sm"
                        id="lastName-input"
                        name="lastName-input"
                        @change="setValue('lastName', $event)"
                        :value="form.lastName"
                        v-model.trim="$v.form.lastName.$model"
                        aria-describedby="input-lastName-live-feedback"
                        maxlength="100"
                      >
                      </b-form-input>
                      <b-form-invalid-feedback
                        id="input-lastName-live-feedback"
                      >
                        {{ $t("requiredTwoCharacters") }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                  <!-- Email -->
                  <b-col sm="12" md="6" lg="6" xl="6">
                    <b-form-group label-size="sm">
                      <template slot="label">
                        <span class="text-white">
                          {{ $t("c_email") }}
                          <span class="text-danger"> *</span>
                        </span>
                      </template>
                      <b-form-input
                        size="sm"
                        id="email-input"
                        name="email-input"
                        @change="setValue('email', $event)"
                        :value="form.email"
                        v-model.trim="$v.form.email.$model"
                        aria-describedby="input-email-live-feedback"
                        maxlength="255"
                      >
                      </b-form-input>
                      <b-form-invalid-feedback id="input-email-live-feedback">
                        {{ $t("requiredValidEmail") }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                  <!-- Secondary email -->
                  <b-col sm="12" md="6" lg="6" xl="6">
                    <b-form-group label-size="sm">
                      <template slot="label">
                        <span class="text-white">
                          {{ $tc("secondaryEmail") }}
                        </span>
                      </template>
                      <b-form-input
                        size="sm"
                        id="secondaryEmail-input"
                        name="secondaryEmail-input"
                        @change="setValue('secondaryEmail', $event)"
                        :value="form.secondaryEmail"
                        v-model.trim="$v.form.secondaryEmail.$model"
                        aria-describedby="input-secondaryEmail-live-feedback"
                        maxlength="255"
                      >
                      </b-form-input>
                      <b-form-invalid-feedback
                        id="input-secondaryEmail-live-feedback"
                      >
                        {{ $t("requiredValidEmail") }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                  <!-- Office Phone Number -->
                  <b-col sm="9" md="9" lg="9" xl="9">
                    <b-form-group label-size="sm" label="Office phone number">
                      <template slot="label">
                        <span class="text-white">
                          {{ $t("officePhone")
                          }}<span class="text-danger"> *</span>
                        </span>
                      </template>

                      <vue-tel-input
                        v-model="form.officePhoneNumber"
                        defaultCountry="ca"
                        :inputOptions="options"
                        :dropdownOptions="telDdOptions"
                        :autoFormat="true"
                        :validCharactersOnly="true"
                        @change="setValue('officePhoneNumber', $event)"
                        :value="form.officePhoneNumber"
                        mode="international"
                      ></vue-tel-input>

                      <b-form-invalid-feedback
                        id="input-officePhoneNumber-live-feedback"
                      >
                        {{ $t("numericField") }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                  <!-- Extension number -->
                  <b-col sm="3" md="3" lg="3" xl="3">
                    <b-form-group label-size="sm" label="Extension">
                      <template slot="label">
                        <span class="text-white"> Extension </span>
                      </template>
                      <b-form-input
                        size="sm"
                        id="extension-input"
                        name="extension-input"
                        type="number"
                        @change="setValue('extension', $event)"
                        :value="form.extension"
                        v-model.trim="$v.form.extension.$model"
                        aria-describedby="input-extension-live-feedback"
                        maxlength="255"
                      >
                      </b-form-input>
                      <b-form-invalid-feedback
                        id="input-extension-live-feedback"
                      >
                        {{ $t("numericField") }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                  <!-- Phone number -->
                  <b-col sm="12" md="12" lg="12" xl="12">
                    <b-form-group label-size="sm" label="Mobile phone">
                      <template slot="label">
                        <span class="text-white">
                          {{ $tc("mobilePhone")
                          }}<span class="text-danger"> *</span>
                        </span>
                      </template>

                      <vue-tel-input
                        v-model="form.mobilePhone"
                        defaultCountry="ca"
                        :inputOptions="options"
                        :dropdownOptions="telDdOptions"
                        :autoFormat="true"
                        :validCharactersOnly="true"
                        @change="setValue('mobilePhone', $event)"
                        :value="form.mobilePhone"
                        mode="international"
                      ></vue-tel-input>

                      <b-form-invalid-feedback
                        id="input-mobile-phone-live-feedback"
                      >
                        {{ $t("numericField") }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <b-form-checkbox
                      id="checkbox-1"
                      class="z-index-0 text-white mb-2"
                      v-model="isAccepTerms"
                      name="checkbox-1"
                      :value="true"
                      :unchecked-value="false"
                    >
                      {{ $t("iAcceptThe") }}
                      <a href="#" v-b-modal.terms-modal class="text-success">
                        {{ $t("termsAndConditions") }}
                      </a>
                    </b-form-checkbox>
                    <b-form-checkbox
                      id="checkbox-2"
                      class="z-index-0 text-white mb-2"
                      v-model="smsNotification"
                      name="checkbox-1"
                      :value="true"
                      :unchecked-value="false"
                    >
                      {{ $t("acceptSMSNotifications") }}
                    </b-form-checkbox>
                    <b-form-checkbox
                      id="checkbox-3"
                      class="z-index-0 text-white mb-2"
                      v-model="emailNotification"
                      name="checkbox-1"
                      :value="true"
                      :unchecked-value="false"
                    >
                      {{ $t("acceptEmailNotifications") }}
                    </b-form-checkbox>
                  </b-col>
                  <!-- Submit -->
                  <b-col sm="12" md="12" lg="12" xl="12">
                    <b-btn
                      block
                      size="lg"
                      class="btn btn-light-blue text-white"
                      :disabled="$v.form.$invalid || isLoading || !isAccepTerms"
                      type="submit"
                    >
                      <strong>
                        {{ $t("registerNow") }}
                      </strong>

                      <b-spinner
                        v-if="isLoading"
                        small
                        variant="white"
                      ></b-spinner
                    ></b-btn>
                  </b-col>
                </b-row>
              </b-col>
              <b-col xs="12" sm="12" md="2" lg="2" xl="2" cols="12"></b-col>
            </b-row>
            <br />
          </b-form>
        </b-col>
      </b-row>
    </div>
    <TermsAndConditions />

    <div class="container">
      <b-modal
        centered
        ref="emailModal"
        id="emailModal"
        size="md"
        hide-header-close
        hide-header
        hide-footer
        body-class="pl-0 pr-0"
      >
        <div class="text-center p-5">
          <p>
            <b-icon font-scale="5" icon="envelope"></b-icon>
          </p>
          <h5>
            {{ $t("weSendAnEmail") }}
          </h5>
          <b-btn
            size="lg"
            class="btn-main mt-2"
            @click="
              $bvModal.hide('emailModal');
              goTo();
            "
          >
            {{ $t("accept") }}
          </b-btn>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import { required, minLength, email } from "vuelidate/lib/validators";
import { Multiselect } from "vue-multiselect";
import { POST_EMAIL_VALIDATION, POST_USER } from "./actions";
import { mapState } from "vuex";
import TermsAndConditions from "./TermsAndConditions.vue";
import Makes from "./Cars.js";
export default {
  components: { TermsAndConditions, Multiselect },

  computed: {
    ...mapState({
      emailValidation: (state) =>
        state.dealership.buyerStore.emailValidation.data,
      user: (state) => state.dealership.buyerStore.user,
    }),
    isLoading() {
      return this.user.status == "fetching" ? true : false;
    },
  },

  data() {
    return {
      status: false,
      isAccepTerms: false,
      brands: Makes,
      form: {
        firstName: null,
        lastName: null,
        email: null,
        secondaryEmail: null,
        phoneNumber: null,
        officePhoneNumber: null,
        extension: null,
        mobilePhone: null,
        legalName: null,
        commercialName: null,
        brand: null,
      },
      options: {
        type: "tel",
        validCharactersOnly: true,
        maxlength: 14,
      },
      telDdOptions: {
        showDialCodeInList: true,
        showFlags: true,
        showDialCodeInSelection: false,
      },
      emailNotification: false,
      smsNotification: false,
    };
  },
  validations: {
    form: {
      firstName: {
        required,
        minLength: minLength(2),
      },
      lastName: {
        required,
        minLength: minLength(2),
      },
      email: {
        required,
        email,
      },
      secondaryEmail: {
        email,
      },
      officePhoneNumber: {
        required,
      },
      legalName: {
        required,
      },
      commercialName: {
        required,
      },
      brand: {},
      officeNumber: {},
      extension: {},
    },
  },
  methods: {
    closeModal(callback) {
      callback();
    },
    setValue(key, value) {
      this.form[key] = value;
      this.$v.form[key].$touch();
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    async save() {
      await this.verifyEmail();
      if (this.emailValidation.message === "Invalid email") {
        return;
      }
      const payload = this.formatPayload();
      this.$store
        .dispatch(POST_USER, payload)
        .then(() => {
          this.$refs["emailModal"].show();
        })
        .catch(() => {
          this.$root.$bvToast.toast(this.$tc("errorMessage"), {
            title: this.$tc("warning"),
            variant: "warning",
          });
        });
    },
    async verifyEmail() {
      await this.$store
        .dispatch(POST_EMAIL_VALIDATION, this.form)
        .then(() => {
          if (this.emailValidation.message === "Invalid email") {
            this.$root.$bvToast.toast(this.$tc("emailUsed"), {
              title: this.$tc("warning"),
              variant: "warning",
            });
          }
        })
        .catch(() => {
          this.$root.$bvToast.toast(this.$tc("errorMessage"), {
            title: this.$tc("warning"),
            variant: "warning",
          });
        });
    },
    goTo() {
      this.$router.push({ path: "/" });
    },
    formatPayload() {
      let role = 2; //7=ROLE_DEALERSHIP

      const payload = {
        firstName: this.form.firstName,
        lastName: this.form.lastName,
        email: this.form.email,
        rolesId: [role],
        userDetails: {
          extension: this.form.extension,
          mobilePhone: this.form.mobilePhone,
          officePhoneNumber: this.form.officePhoneNumber,
          secondaryEmail: this.form.secondaryEmail,
        },
        company: {
          legalName: this.form.legalName,
          phoneNumber: this.form.officePhoneNumber,
          commercialName: this.form.commercialName,
          brand: this.form.brand,
        },
        emailNotification: this.emailNotification,
        smsNotification: this.smsNotification,
      };
      return payload;
    },
  },
  mounted() {},
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
.append-input {
  max-width: 40%;
}
.z-index-2 {
  z-index: 2;
}

.bg-image {
  background-image: url("../../../assets/img/car_layout.png");
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%, 0 60%, 10% 50%, 0 40%);
  max-width: 100%;
  height: auto;
  background-position: left;
  position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.bg-arrow {
  clip-path: polygon(78% 0, 78% 36%, 94% 50%, 79% 62%, 79% 100%, 0 100%, 0 0);
}

@media only screen and (max-width: 600px) {
  .bg-image {
    clip-path: none !important;
  }
}
.v-center {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
.vue-tel-input {
  background-color: white !important;
}
.multiselect__tag {
  background: #bba83b !important;
}
</style>
