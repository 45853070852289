<template>
  <div>
    <VehicleForm />
  </div>
</template>

<script>
import VehicleForm from "./components/VehicleForm.vue";
import { registryVehicleMixin } from "./mixins";
export default {
  components: { VehicleForm },
  mixins: [registryVehicleMixin],
};
</script>

<style></style>
