/** @format */

import store from '@/store'
import { GET_VALIDATIONS } from './actions'
const validationMixin = {
  data() {
    return {
      userItem: {},
      formValidation: {
        legalName: Boolean,
        corporationNumber: Boolean,
        saaq: Boolean
      }
    }
  },

  methods: {
    async getCompanyValidationInfo() {
      this.$store.commit('setCompanyItem', {})
      this.userItem = store.state.shared.validation.itemUser
      const payload = {
        userId: this.userItem.user.userDetails.id
      }
      this.$store
        .dispatch(GET_VALIDATIONS, payload)
        .then(() => {
          this.setValidations()
        })
        .catch(() => {
          this.$toast.add({
            summary: this.$t('toast_title_error'),
            detail: this.$t('toast_message_error'),
            severity: "error",
            life: 5000
        })
        })
    },

    setValidations() {
      this.formValidation.legalName =
        store.state.shared.validation.companyItem.validations.legalName
      this.formValidation.saaq =
        store.state.shared.validation.companyItem.validations.saaq
      this.formValidation.corporationNumber =
        store.state.shared.validation.companyItem.validations.corporationNumber
    }
  }
}
export { validationMixin }
