/** @format */
import axios from '@/plugins/axios.js'

function getDefaultState() {
  return {
    user: {
      status: null,
      data: null,
      error: null
    },
    deals: null
  }
}

const actions = {
  loadInAgentDeals: async ({commit}, params) => {
    if(params["search"]!=""){
      return await axios.get("/deals?page="+params['page']+"&search="+params["search"]);
    }else{
      return await axios.get("/deals?page="+params['page']);
    }
  },
}

const mutations = {
}

export default {
  state: getDefaultState(),
  actions,
  mutations
}
