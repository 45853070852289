<template>
  <b-modal
    centered
    no-close-on-backdrop
    scrollable
    id="notes-modal"
    ref="modal"
    title="Notes"
    @show="getNotes(true)"
    @hidden="resetForm()"
  >
    <NoteRegistrationForm />

    <b-list-group v-if="notes.status == 'fetching'" class="mt-2">
      <b-list-group-item class="text-center">
        <b-spinner variant="blue" />
      </b-list-group-item>
    </b-list-group>
    <b-list-group v-else class="mt-2 mb-2 note-list" tag="ul">
      <b-list-group-item
        v-if="notes.data.data && !notes.data.data.length"
        class="text-center"
      >
        <b>No notes to show</b>
      </b-list-group-item>

      <b-list-group-item
        v-for="(note, i) in notes.data.data"
        :key="i"
        class="flex-column align-items-start"
        tag="li"
      >
        <b-row>
          <b-col cols="10">
            <small class="text-muted">
              {{ note.date | formatDateTime }} - {{ note.user.lastName }}
              {{ note.user.firstName }}
            </small>
          </b-col>
          <b-col>
            <div class="text-right">
              <span
                class="dot"
                :class="[note.status ? 'dot-success' : 'dot-danger']"
              />
            </div>
          </b-col>
        </b-row>
        <p class="text-justify mb-1">
          {{ note.description }}
        </p>
      </b-list-group-item>
    </b-list-group>

    <template v-slot:modal-footer="{ close }">
      <b-pagination
        :value="notes.data.currentPage"
        :total-rows="notes.data.total"
        :per-page="notes.data.perPage"
        @change="changePage($event)"
        class="mt-2"
      />
      <div class="justify-content-end">
        <b-button
          variant="outline-secondary"
          class="mr-2"
          @click="() => close()"
        >
          Cancel
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import { mapState } from "vuex";
import { SET_NOTES_FILTERS } from "./actions";
import NoteRegistrationForm from "./RegistrationForm";
import { notesMixin } from "./mixins";

export default {
  components: { NoteRegistrationForm },
  mixins: [notesMixin],

  computed: {
    ...mapState({
      notes: (state) => state.shared.notes.notes,
    }),
  },
  methods: {
    resetForm() {
    },
    changePage(page) {
      this.$store.commit(SET_NOTES_FILTERS, {
        fields: { page },
      });
      this.getNotes(false);
    },
  },
};
</script>

<style>
.resize-none {
  resize: none;
}
.note-list {
  max-height: 500px;
  overflow-y: auto;
}
</style>
