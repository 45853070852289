<template>
  <div class="card">
    <div class="d-flex justify-content-md-between">
      <div>
        <h1 class="text-primary">{{ $t("leasing_agents") }}</h1>
      </div>
      <div>
        <Button class="p-button-primary" @click="dialogUser">
          <div class="d-flex align-items-sm-center flex-column">
            <i class="pi pi-plus mx-auto" style="font-size: 1.5rem"></i>
            {{ $t("new_agent") }}
          </div>
        </Button>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="pt-4">
          <TableBase
            :items="records"
            :fields="fields"
            :options="options"
            :busy="loading === 'fetching' ? true : false"
          />

          <Dialog
            :visible.sync="userDialog"
            :style="{ width: '380px' }"
            :header="$t('user_details')"
            :modal="true"
            class="p-fluid"
          >
            <div class="p-field">
              <label for="firstName">{{ $t("first_name") }}</label>
              <span class="p-input-icon-left">
                <i class="pi pi-user" />
                <InputText
                  id="firstName"
                  v-model.trim="userItem.firstName"
                  required="true"
                  autofocus
                  :class="{ 'p-invalid': submitted && !userItem.firstName }"
                />
              </span>
              <small
                class="p-invalid text-danger"
                v-if="submitted && !userItem.firstName"
                >{{ $t("valid_firstname_required") }}</small
              >
            </div>
            <div class="p-field">
              <label for="lastName">{{ $t("last_name") }}</label>
              <span class="p-input-icon-left">
                <i class="pi pi-user" />
                <InputText
                  id="lastName"
                  v-model.trim="userItem.lastName"
                  required="true"
                  autofocus
                  :class="{ 'p-invalid': submitted && !userItem.lastName }"
                />
              </span>
              <small
                class="p-invalid text-danger"
                v-if="submitted && !userItem.lastName"
                >{{ $t("valid_lastname_required") }}</small
              >
            </div>
            <div class="p-field">
              <label for="email">{{ $t("c_email") }}</label>
              <span class="p-input-icon-left">
                <i class="pi pi-envelope" />
                <InputText
                  id="email"
                  v-model.trim="userItem.email"
                  required="true"
                  autofocus
                  :class="{ 'p-invalid': submitted && !userItem.email }"
                />
              </span>
              <small
                class="p-invalid text-danger"
                v-if="submitted && !userItem.email"
                >{{ $t("valid_email_required") }}</small
              >
            </div>
            <!-- <div class="p-field">
          <label for="phone">{{$t('phoneNumber')}}</label>
          <span class="p-input-icon-left">
            <i class="pi pi-phone" />
            <InputText
              id="phone"
              v-model.trim="userItem.mobile_phone"
              required="true"
              autofocus
              :class="{ 'p-invalid': submitted && !userItem.mobile_phone }"
            />
          </span>
          <small class="p-invalid text-danger" v-if="submitted && !userItem.mobile_phone"
            >{{$t('valid_phone_required')}}</small
          >
        </div>-->
            <!-- div class="p-field">
          <label for="permit">{{$t('permit_code')}}</label>
          <span class="p-input-icon-left">
            <i class="pi pi-envelope" />
            <InputSwitch
              id="permit"
              v-model.trim="userItem.permit"
              :class="{ 'p-invalid': submitted }"
            />
          </span>
        </div-->
            <template #footer>
              <Button
                :label="$t('cancel')"
                icon="pi pi-times"
                class="p-button-danger"
                @click="hideDialogUser"
              />
              <Button
                :label="$t('save')"
                icon="pi pi-check"
                class="p-button-success"
                v-if="buttonCreate"
                @click="saveUserAgentLease"
              />
              <Button
                :label="$t('save')"
                icon="pi pi-check"
                class="p-button-success"
                v-if="buttonUpdate"
                @click="saveUpdateUserAgentLease"
              />
            </template>
          </Dialog>
          <!-- DIALOG DELETE -->
          <Dialog
            :visible.sync="userDeleteDialog"
            :style="{ width: '380px' }"
            :header="$t('confirm')"
            :modal="true"
          >
            <div class="confirmation-content">
              <i
                class="pi pi-exclamation-triangle p-mr-3"
                style="font-size: 2rem"
              />
              <span v-if="userItem"
                >{{ $t("message_delete") }}
                <b>{{ userItem.firstName }} {{ userItem.lastName }}</b
                >?</span
              >
            </div>
            <template #footer>
              <Button
                :label="$t('no')"
                icon="pi pi-times"
                class="p-button-danger"
                @click="hideDialogDeleteUser"
              />
              <Button
                :label="$t('yes')"
                icon="pi pi-check"
                class="p-button-success"
                @click="deleteUser(userItem.id)"
              />
            </template>
          </Dialog>
        </div>
      </div>
    </div>
  </div>
</template>
  <script>
import TableBase from "@/modules/shared/table.vue";
import UserDetails from "@/modules/dealership/Details.vue";
import { mapActions, mapState } from "vuex";

/*import { generalMixin } from "@/modules/mixin.js";*/
export default {
  components: { TableBase, UserDetails },
  /*mixins: [generalMixin],*/
  computed: {
    ...mapState({
      users: (state) => state.admin.users.users,
      fields() {
        return [
          { key: "id", label: this.$t("id"), thClass: "bg-header text-dark" },
          {
            key: "firstName",
            label: this.$t("firstName"),
            sortable: true,
            thClass: "bg-header text-dark",
          },
          {
            key: "lastName",
            label: this.$t("lastName"),
            sortable: true,
            thClass: "bg-header text-dark",
          },
          {
            key: "email",
            label: this.$t("c_email"),
            class: "text-break",
            sortable: true,
            thClass: "bg-header text-dark",
          },
          /* { key: "status", label: this.$t("status"), class: "text-break", sortable: true , thClass: 'bg-header text-dark'}, */
          {
            key: "userDetails.phoneNumber",
            label: this.$t("phoneNumber"),
            class: "text-break",
            sortable: true,
            thClass: "bg-header text-dark",
          },
          {
            key: "actions",
            label: "Actions",
            deleteleaser: true,
            editleaser: true,
            thClass: "bg-header text-dark",
          },
        ];
      },
    }),
  },
  data() {
    return {
      filter: "",
      data: [],
      loading: "fetching",
      records: [],
      items: [],
      options: {
        assign: false,
        rolFilter: false,
        add: false,
        fun: this.getData,
      },
      userDialog: false,
      submitted: false,
      userDeleteDialog: false,
      userItem: {},
      buttonCreate: false,
      buttonUpdate: false,
    };
  },
  methods: {
    ...mapActions([
      "loadInAdminUsersLeasingAgent",
      "saveAgentLeasing",
      "deleteAgentLeasing",
      "saveUpdateAgentLeasing",
    ]),
    async getData(filter = "") {
      this.loading = "fetching";
      const payload = {
        search: filter,
        field: null /* FIELD TO SEARCH */,
        roleName: "ROLE_LEASE_AGENT",
      };
      this.loadInAdminUsersLeasingAgent(payload)
        .then((response) => {
          this.records = response;
          this.loading = "";
        })
        .catch((error) => {
          this.$toast.add({
            summary: this.$t("toast_title_error"),
            detail: this.$t("toast_message_error"),
            severity: "error",
            life: 5000,
          });
        });
    },
    editAgentItem(item) {
      this.userItem = item;
      this.userDialog = true;
      this.buttonUpdate = true;
      this.buttonCreate = false;
    },
    dialogUser() {
      this.userItem = {};
      this.userDialog = true;
      this.buttonUpdate = false;
      this.buttonCreate = true;
    },
    hideDialogUser() {
      this.userDialog = false;
      this.userItem = {};
    },
    deleteItem(item) {
      this.userDeleteDialog = true;
      this.userItem = item;
    },
    hideDialogDeleteUser() {
      this.userDeleteDialog = false;
    },
    saveUpdateUserAgentLease() {
      this.submitted = true;
      const payload = {
        id: this.userItem.id,
        firstName: this.userItem.firstName,
        lastName: this.userItem.lastName,
        email: this.userItem.email,
        permit: this.userItem.permit,
      };

      this.saveUpdateAgentLeasing(payload)
        .then((response) => {
          this.userDialog = false;
          this.$toast.add({
            severity: "success",
            variant: "success",
            summary: this.$t("message_success_title"),
            detail: this.$t("message_success"),
            life: 4000,
          });
          this.getData();
        })
        .catch((error) => {
          this.$toast.add({
            summary: this.$t("toast_title_error"),
            detail: this.$t("toast_message_error"),
            severity: "error",
            life: 5000,
          });
          this.userDialog = false;
        });
      this.getData();
    },
    saveUserAgentLease() {
      this.submitted = true;
      const payload = {
        id: this.userItem.id,
        firstName: this.userItem.firstName,
        lastName: this.userItem.lastName,
        email: this.userItem.email,
        permit: this.userItem.permit,
      };

      this.saveAgentLeasing(payload)
        .then((response) => {
          this.userDialog = false;
          this.$toast.add({
            severity: "success",
            variant: "success",
            summary: this.$t("message_success_title"),
            detail: this.$t("message_success"),
            life: 4000,
          });
          this.getData();
        })
        .catch((error) => {
          this.$toast.add({
            summary: this.$t("toast_title_error"),
            detail: this.$t("toast_message_error"),
            severity: "error",
            life: 5000,
          });
          this.userDialog = false;
        });
      this.getData();
    },
    editItem(item) {
      this.userItem = item;
    },
    deleteUser(idDelete) {
      this.deleteAgentLeasing(idDelete)
        .then((response) => {
          this.userDeleteDialog = false;
          this.$toast.add({
            severity: "success",
            variant: "success",
            summary: this.$t("message_success_delete_title"),
            detail: this.$t("message_success_delete"),
            life: 4000,
          });
          this.getData();
        })
        .catch((error) => {
          console.log(error);
          this.$toast.add({
            summary: this.$t("toast_title_error"),
            detail: this.$t("toast_message_error"),
            severity: "error",
            life: 5000,
          });
          this.userDeleteDialog = false;
        });
      this.getData();
    },
    changePage(page, filter = "") {
      const payload = {
        search: filter,
        roleName: "ROLE_LEASE_AGENT",
        page: page,
      };

      this.loadInAdminUsersLeasingAgent(payload)
        .then((response) => {
          this.records = response;
        })
        .catch((error) => {
          this.$toast.add({
            summary: this.$t("toast_title_error"),
            detail: this.$t("toast_message_error"),
            severity: "error",
            life: 5000,
          });
        });
    },
  },
  mounted() {
    this.getData("");
  },
};
</script>
    <style lang="scss" scoped>
.table-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
  