/** @format */

import Vue from 'vue'

import { library } from '@fortawesome/fontawesome-svg-core'
import { faMobile, faCar } from '@fortawesome/free-solid-svg-icons'
import {
  faPencil,
  faX,
  faEye,
  faUser,
  faDownload,
  faCheck,
  faPaperclip,
  faList,
  faUpload,
  faIdCard,
  faAt,
  faPhone
} from '@fortawesome/pro-regular-svg-icons'

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
library.add(faMobile)
library.add(faCar)
library.add(faCheck)
library.add(faPencil)
library.add(faX)
library.add(faEye)
library.add(faUser)
library.add(faDownload)
library.add(faCheck)
library.add(faPaperclip)
library.add(faList)
library.add(faUpload)
library.add(faIdCard)
library.add(faAt)
library.add(faPhone)
Vue.component('font-awesome-icon', FontAwesomeIcon)
