<template>
  <b-container>
    <b-row>
      <b-form @submit.prevent="id ? update() : create()">
        <b-form-group
          id="firstNameLabel"
          :label="$t('firstName')"
          label-for="firstName"
        >
          <b-form-input
            id="firstName"
            v-model.trim="firstName"
          ></b-form-input>
        </b-form-group>
        <b-form-group
          id="secondNameLabel"
          :label="$t('lastName')"
          label-for="secondName"
        >
          <b-form-input id="secondName" v-model="secondName"></b-form-input>
        </b-form-group>
        <b-form-group id="emailLabel" label="Email" label-for="email">
          <b-form-input id="email" v-model="email" type="email"></b-form-input>
        </b-form-group>
        <b-form-group id="passwordLabel" :label="$t('password')" label-for="password">
          <b-form-input
            id="password"
            type="password"
            v-model="password"
          ></b-form-input>
        </b-form-group>
        <b-button variant="info" type="submit"> {{ $t("submit") }} </b-button>
      </b-form>
    </b-row>
  </b-container>
</template>
<script>
import UserServices from "@/services/UserServices.js";
export default {
  data() {
    return {
      id: null,
      firstName: null,
      secondName: null,
      email: null,
      password: null,
      rol: null,
    };
  },
  methods: {
    update() {},
    create() {
      UserServices.postUser(this.formatPayload())
        .then((response) => {
          if (response.message == "User created") {
            this.$root.$bvToast.toast("User was created", {
              title: "Operation done",
              variant: "success",
            });
          } else {
            this.$root.$bvToast.toast(response[2], {
              title: "User was not created",
              variant: "danger",
            });
          }
        })
        .catch((response) => {
          this.$root.$bvToast.toast("User was not created", {
            title: "There was an error",
            variant: "danger",
          });
        });
    },
    formatPayload() {
      return {
        firstName: this.firstName,
        lastName: this.secondName,
        email: this.email,
        password: this.password,
        rolesId: [4],
      };
    },
  },
};
</script>
