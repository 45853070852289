<template>
  <div class="card">
    <div class="d-flex justify-content-between">
      <template>
        <h1 class="text-primary">{{ $t("search_advanced") }}</h1>
      </template>
      <template>
        <Button
          :label="$t('reset')"
          icon="pi pi-refresh"
          class="p-button-primary"
          @click="resetSearch"
        ></Button>
      </template>
    </div>
    <div class="mt-3">
      <b-row class="mb-2">
        <b-col md="12" sm="12" class="">
          <h5>{{ $t("search_advanced_message") }}</h5>
          <br />{{ search_model }}
        </b-col>
        <b-col md="3" sm="12" class="mt-1">
          <label>{{ $t("condition") }}</label>
          <b-form-select
            size="md"
            :options="conditionOptions"
            :placeholder="$t('condition')"
            type="text"
            v-model="search_condition"
          />
        </b-col>
        <b-col md="3" sm="12" class="mt-1">
          <label>{{ $t("type") }}</label>
          <b-form-select
            size="md"
            :options="typeOptions"
            :placeholder="$t('type')"
            type="text"
            v-model="search_type"
          />
        </b-col>
        <b-col md="3" sm="12" class="mt-1">
          <label>{{ $t("year") }}</label>
          <b-form-select
            size="md"
            :options="yearOptions"
            :placeholder="$t('year')"
            type="text"
            v-model="search_year"
          />
        </b-col>
        <b-col md="3" sm="12" class="mt-1"></b-col>
        <b-col md="3" sm="12" class="mt-1">
          <label>{{ $t("brand") }}</label>
          <b-form-select
            size="md"
            :options="markesOptions"
            :placeholder="$t('brand')"
            type="text"
            v-model="search_brand"
          />
        </b-col>
        <b-col md="3" sm="12" class="mt-1">
          <label>{{ $t("model") }}</label>
          <b-form-input
            size="md"
            :placeholder="$t('model')"
            type="text"
            v-model="search_model"
          />
        </b-col>
        <b-col md="3" sm="12" class="mt-1">
          <label>{{ $t("prix") }}</label>
          <b-input-group>
            <b-input-group-prepend>
              <b-button variant="primary">{{ $t("FROM") }}</b-button>
            </b-input-group-prepend>
            <b-form-input
              size="md"
              :placeholder="$t('prix')"
              type="text"
              v-model="search_price_from"
            />
          </b-input-group>
        </b-col>
        <b-col md="3" sm="12" class="mt-1">
          <label>&nbsp;</label>
          <b-input-group>
            <b-input-group-prepend>
              <b-button variant="primary">{{ $t("TO") }}</b-button>
            </b-input-group-prepend>
            <b-form-input
              size="md"
              :placeholder="$t('prix')"
              type="text"
              v-model="search_price_to"
            />
          </b-input-group>
        </b-col>
        <b-col md="6" sm="12" class="mt-1">
          <label>{{ $t("company") }}</label>
          <b-form-select
            size="md"
            :options="companyOptions"
            :placeholder="$t('company')"
            type="text"
            v-model="search_company"
          />
        </b-col>
        <b-col md="6" sm="12" class="mt-1">
          <label>{{ $t("customer") }}</label>
          <b-form-input
            size="md"
            :placeholder="$t('customer')"
            type="text"
            v-model="search_client"
          />
        </b-col>
      </b-row>
      <b-row class="row mt-4 mb-4">
        <b-col md="12" sm="12" class="text-right">
          <Button class="p-btn btn-primary" @click="searchingDeals">{{
            $t("search")
          }}</Button>
        </b-col>
      </b-row>
      <div v-if="showResults">
        <TableBase
          :items="selectedUsers"
          :fields="fields"
          :hide_recherche="true"
          :busy="loading === 'fetching' ? true : false"
        />

        <Dialog
          :visible.sync="detailsDialog"
          :style="{ width: '380px' }"
          :header="$t('details_deal')"
          :modal="true"
          class="p-fluid"
        >
          <div class="row">
            <div class="col-12 mt-1">
              <strong>{{ $t("company") }}</strong
              ><br />
              {{ deal.company }}
            </div>
            <div class="col-8 mt-1">
              <strong>{{ $t("customer") }}</strong
              ><br />
              {{ deal.customer }}
            </div>
            <div class="col-4 mt-1">
              <strong>{{ $t("condition") }}</strong
              ><br />
              {{ deal.condition }}
            </div>
            <div class="col-4 mt-1">
              <strong>{{ $t("type") }}</strong
              ><br />
              {{ deal.type }}
            </div>
            <div class="col-4 mt-1">
              <strong>{{ $t("brand") }}</strong
              ><br />
              {{ deal.make }}
            </div>
            <div class="col-4 mt-1">
              <strong>{{ $t("model") }}</strong
              ><br />
              {{ deal.model }}
            </div>
            <div class="col-12 mt-1">
              <strong>{{ $t("capitalCost") }}</strong
              ><br />
              {{ deal.capitalCost }}
            </div>
            <div class="col-4 mt-1">
              <strong>{{ $t("term") }}</strong
              ><br />
              {{ deal.terms }}
            </div>
            <div class="col-8 mt-1">
              <strong>{{ $t("monthlyPayment") }}</strong
              ><br />
              {{ deal.monthlyPayment }}
            </div>
          </div>
          <template #footer>
            <div class="mt-2">
              <Button
                :label="$t('close')"
                icon="pi pi-times"
                class="p-button-danger"
                @click="hideDetailsDeal"
              />
            </div>
          </template>
        </Dialog>
        <Dialog
          :visible.sync="userValidationDocumentsDialog"
          :style="{ width: '380px' }"
          :header="$t('validation_documents')"
          :modal="true"
          class="p-fluid"
        >
          <div v-if="countDocumentsRequired == 0">
            {{ $t("message_choose_documents") }}
          </div>
          <div class="p-field">
            <div class="mt-3" v-for="(item, i) in deal.documents" :key="i">
              <div v-if="item.required">
                <label
                  ><a :href="item.url" download
                    ><i class="pi pi-download" target="_blank"></i>
                    {{ item.name }}
                    <span v-if="item.status == 0"
                      >({{ $t("document_pending_short") }})</span
                    >
                    <span v-if="item.status == 1"
                      >({{ $t("document_process_short") }})</span
                    >
                    <span v-if="item.status == 2"
                      >({{ $t("document_accepted_short") }})</span
                    >
                    <span v-if="item.status == 3"
                      >({{ $t("document_rejected_short") }})</span
                    ></a
                  ></label
                >
                <select
                  v-if="item.status == 1"
                  name="LeaveType"
                  @change="onChange($event, item)"
                  class="form-control"
                  v-model="key"
                >
                  <option value="2">{{ $t("document_accepted_short") }}</option>
                  <option value="3">{{ $t("document_rejected_short") }}</option>
                </select>
                <div v-if="item.status == 0">
                  {{ $t("document_pending_short") }}
                </div>
                <div v-if="item.status == 2">{{ $t("document_accepted") }}</div>
                <div v-if="item.status == 3">{{ $t("document_rejected") }}</div>
              </div>
            </div>
          </div>
          <template #footer>
            <div class="mt-2">
              <Button
                :label="$t('cancel')"
                icon="pi pi-times"
                class="p-button-danger"
                @click="hideValidationDocumentsDialog"
              />
              <Button
                :label="$t('save')"
                icon="pi pi-check"
                class="p-button-success"
                @click="validationSubmit"
              />
            </div>
          </template>
        </Dialog>
        <Dialog
          :visible.sync="chooseDocumentsDialog"
          :style="{ width: '380px' }"
          :header="$t('choose_documents')"
          :modal="true"
          class="p-fluid"
        >
          <div>
            <strong>{{ $t("message_select_documents") }}</strong>
          </div>
          <div class="p-field">
            <div class="mt-3" v-for="(item, key) in deal.documents" :key="key">
              <input
                type="checkbox"
                :value="item.key"
                :id="item.key"
                v-model="checkedDocuments"
              />
              {{ item.name }}
            </div>
          </div>
          <template #footer>
            <Button
              :label="$t('cancel')"
              icon="pi pi-times"
              class="p-button-danger"
              @click="hideDocumentsDialog"
            />
            <Button
              :label="$t('save')"
              icon="pi pi-check"
              class="p-button-success"
              @click="saveDocumentsRequiredDialog"
            />
          </template>
        </Dialog>
        <Dialog
          :visible.sync="userCancelDialog"
          :style="{ width: '380px' }"
          :header="$t('cancel_deal')"
          :modal="true"
          class="p-fluid"
        >
          <div class="p-field">
            <div class="mt-3">
              <label>{{ $t("message_cancel_deal") }}</label>
            </div>
          </div>
          <template #footer>
            <Button
              :label="$t('cancel')"
              icon="pi pi-times"
              class="p-button-danger"
              @click="hideCancelDialog"
            />
            <Button
              :label="$t('save')"
              icon="pi pi-check"
              class="p-button-success"
              @click="cancelDealSave"
            />
          </template>
        </Dialog>
        <Dialog
        :visible.sync="userDialog"
        :style="{ width: '380px' }"
        :header="$t('closing_deal')"
        :modal="true"
        class="p-fluid"
      >
        <div class="p-field">
          <div class="row">
            <div class="mt-3 col-6">
              <label>{{ $t("start_date") }}</label
              ><br />
              <input type="date" v-model="startDate" />
            </div>
            <div class="mt-3 col-6">
              <label>{{ $t("end_date") }}</label
              ><br />
              <input type="date" v-model="endDate" />
            </div>
          </div>
        </div>
        <template #footer>
          <Button
            :label="$t('cancel')"
            icon="pi pi-times"
            class="p-button-danger"
            @click="hideDialog"
          />
          <Button
            :label="$t('save')"
            icon="pi pi-check"
            class="p-button-success"
            @click="saveClosedDeal"
          />
        </template>
      </Dialog>
      </div>
    </div>
  </div>
</template>
<script>
import TableBase from "@/modules/shared/table.vue";
import { mapActions, mapState } from "vuex";

export default {
  components: { TableBase },
  data() {
    return {
      users: null,
      deal: {},
      data: {},
      startDate: "",
      endDate: "",
      conditionOptions: [],
      yearOptions: [],
      typeOptions: [],
      modelOptions: [],
      companyOptions: [],
      markesOptions: [],
      search_condition: "",
      search_model: "",
      search_type: "",
      search_brand: "",
      search_year: "",
      search_company: "",
      search_price: 0,
      search_client: "",
      payload: {},
      showResults: false,
      loading: "fetching",
      checkedDocuments: [],
      userDialog: false,
      detailsDialog: false,
      validation_array: [],
      selectedUsers: [],
      filters: {},
      submitted: false,
      search_price_from: 0,
      search_price_to: 0,
      totalRecords: 0,
      payload: {},
      loading: "fetching",
      checkedDocuments: [],
      userDialog: false,
      userCancelDialog: false,
      chooseDocumentsDialog: false,
      detailsDialog: false,
      userValidationDocumentsDialog: false,
      validation_array: [],
      selectedUsers: [],
      filters: {},
      options: {
        assign: false,
        rolFilter: false,
        add: false,
      },
    };
  },
  computed: {
    fields() {
      return [
        {
          key: "id",
          label: this.$t("id"),
          sortable: true,
          thClass: "bg-header text-dark",
        },
        {
          key: "condition",
          label: this.$t("condition"),
          sortable: true,
          thClass: "bg-header text-dark",
        },
        {
          key: "make",
          label: this.$t("brand"),
          sortable: true,
          thClass: "bg-header text-dark",
        },
        {
          key: "model",
          label: this.$t("model"),
          sortable: true,
          thClass: "bg-header text-dark",
        },
        {
          key: "type",
          label: this.$t("type"),
          sortable: true,
          thClass: "bg-header text-dark",
        },
        {
          key: "year",
          label: this.$t("year"),
          sortable: true,
          thClass: "bg-header text-dark",
        },
        {
          key: "capitalCost",
          label: this.$t("prix"),
          sortable: true,
          thClass: "bg-header text-dark",
          formatter: (capitalCost) =>
            this.$options.filters.currency(capitalCost),
        },
        {
          key: "company",
          label: this.$t("company"),
          sortable: true,
          thClass: "bg-header text-dark",
        },
        {
          key: "customer",
          label: this.$t("customer"),
          sortable: true,
          thClass: "bg-header text-dark",
        },
        //{ key: "closedDeal", label: this.$t("closedDeal")},
        {
          key: "actions",
          label: "Actions",
          detailsdeal: true,
          thClass: "bg-header text-dark",
          canceldeal: true,
          validationdocsdeal: true,
          selectdocsdeal: true,
          closeddeal: true,
          downloaddeal: true,
        },
      ];
    },
  },
  created() {},
  mounted() {
    this.loadConditions();
    this.loadCompanies();
    this.loadTypes();
    this.loadMarkes();
    this.loadYears();
  },
  methods: {
    ...mapActions([
      "loadSearchingDeals",
      "generatePDF",
      "loadSAConditions",
      "loadSACompanies",
      "loadSACustumers",
      "loadSATypes",
      "loadSAMarks",
      "loadSAModels",
      "saveCancelDeals",
      "saveDocumentsRequired",
      "validationDocuments",
      "generatePDF",
      "saveCloseDeal",
    ]),
    loadConditions() {
      this.conditionOptions = [];
      this.loadSAConditions()
        .then((response) => {
          this.conditionOptions = response;
        })
        .catch((error) => {
          this.$toast.add({
            summary: this.$t("toast_title_error"),
            detail: this.$t("toast_message_error"),
            severity: "error",
            life: 5000,
          });
        });

      this.search_condition = null;
    },
    loadYears() {
      this.yearOptions = [];
      let years = [{ value: null, text: this.$t("select_year") }];
      const currentYear = new Date().getFullYear();
      const oldYear = currentYear - 10;
      for (let i = currentYear; i >= oldYear; i--) {
        years.push({ value: i, text: i });
      }
      this.yearOptions = years;
      this.search_year = null;
    },
    loadTypes() {
      this.typeOptions = [];
      this.loadSATypes()
        .then((response) => {
          this.typeOptions = response;
        })
        .catch((error) => {});
    },
    loadMarkes() {
      this.markesOptions = [];
      this.loadSAMarks()
        .then((response) => {
          this.markesOptions = response;
        })
        .catch((error) => {});
    },
    loadCompanies() {
      this.companyOptions = [];
      this.loadSACompanies()
        .then((response) => {
          this.companyOptions = response;
        })
        .catch((error) => {});
    },
    resetSearch() {
      this.loadCompanies();
      this.loadMarkes();
      this.loadTypes();
      this.loadYears();
      this.loadConditions();
      this.search_model = "";
      this.search_price_from = 0;
      this.search_price_to = 0;
      this.search_client = "";
    },
    searchingDeals() {
      const chars =
        Number(this.search_condition) +
        Number(this.search_type) +
        Number(this.search_brand) +
        Number(this.search_model);
      if (chars != 0) {
        const payload = {
          condition: this.search_condition,
          type: this.search_type,
          mark: this.search_brand,
          model: this.search_model,
          year: this.search_year,
          price_from: this.search_price_from,
          price_to: this.search_price_to,
          company: this.search_company,
          customer: this.search_client,
        };

        this.loadSearchingDeals(payload)
          .then((response) => {
            this.showResults = true;
            this.selectedUsers = response;
            this.loading = "";
          })
          .catch((error) => {
            this.$toast.add({
              severity: "error",
              summary: error,
            });
            this.loading = "";
          });
      } else {
        this.$toast.add({
          severity: "info",
          summary: this.$t("completed_fields"),
        });
      }
    },
    editValidationDocuments(deal) {
      this.deal = { ...deal };
      this.userValidationDocumentsDialog = true;
    },
    closeDeal(deal) {
      this.deal = { ...deal };
      this.userDialog = true;
    },
    openNew() {
      this.deal = {};
      this.submitted = false;
      this.userDialog = true;
      this.userCancelDialog = true;
    },
    cancelDealSave() {
      this.payload = {
        deal: this.deal.id,
      };

      this.saveCancelDeals(this.payload)
        .then((response) => {
          this.$toast.add({
            severity: "success",
            summary: this.$t("message_success_title"),
            detail: this.$t("message_success"),
            life: 4000,
          });
          this.hideCancelDialog();
          this.searchingDeals();
        })
        .catch((error) => {
          this.$toast.add({
            severity: "error",
            summary: error,
          });
          this.hideCancelDialog();
          this.searchingDeals();
        });
      this.deal = {};
    },
    hideDialog() {
      this.userDialog = false;
      this.submitted = false;
      this.endDate = null;
      this.startDate = null;
    },
    hideValidationDocumentsDialog() {
      this.userValidationDocumentsDialog = false;
      this.submitted = false;
      this.deal = {};
      this.validation_array = [];
    },
    cancelDeal(deal) {
      this.deal = { ...deal };
      this.userCancelDialog = true;
    },
    hideCancelDialog() {
      this.userCancelDialog = false;
      this.submitted = false;
    },
    detailsDeal(deal) {
      this.deal = { ...deal };
      this.detailsDialog = true;
    },
    hideDetailsDeal() {
      this.detailsDialog = false;
      this.submitted = false;
    },
    chooseDocumentsDeal(deal) {
      this.deal = { ...deal };
      this.checkedDocuments = [];
      this.chooseDocumentsDialog = true;

      this.deal.documents.forEach((item) => {
        if (item.required) {
          this.checkedDocuments.push(item.key);
        }
      });
    },
    hideDocumentsDialog() {
      this.chooseDocumentsDialog = false;
      this.submitted = false;
      this.checkedDocuments = [];
    },
    onChange(event, item) {
      this.validation_array.push({ key: item.key, status: event.target.value });
    },
    saveDocumentsRequiredDialog() {
      this.payload = {
        documents_required: this.checkedDocuments,
        deal: this.deal.id,
      };

      this.saveDocumentsRequired(this.payload)
        .then((response) => {
          this.$toast.add({
            severity: "success",
            summary: this.$t("update"),
            detail: this.$t("message_documents_required"),
            life: 4000,
          });
          this.hideDocumentsDialog();
          this.searchingDeals();
        })
        .catch((error) => {
          this.$toast.add({
            severity: "error",
            summary: error,
          });
          this.hideDocumentsDialog();
          this.searchingDeals();
        });
    },
    validationSubmit() {
      let payload = {
        ...{
          deal: this.deal.id,
          validations: this.validation_array,
        },
      };
      this.validationDocuments(payload)
        .then((response) => {
          this.$toast.add({
            severity: "success",
            summary: this.$t("validation_message_summary"),
            detail: this.$t("validation_message_detail"),
            life: 4000,
          });
          this.hideValidationDocumentsDialog();
          this.searchingDeals();
        })
        .catch((error) => {
          this.$toast.add({
            severity: "error",
            summary: error,
          });
          this.hideValidationDocumentsDialog();
          this.searchingDeals();
        });
    },
    saveClosedDeal() {
      let payload = {
        ...{
          deal: this.deal.id,
          endDate: this.endDate,
          startDate: this.startDate,
        },
      };
      this.saveCloseDeal(payload)
        .then((response) => {
          this.$toast.add({
            severity: "success",
            summary: this.$t("closed_deal"),
            detail: this.$t("message_success"),
            life: 4000,
          });
          this.hideDialog();
          this.searchingDeals();
        })
        .catch((error) => {
          this.$toast.add({
            severity: "error",
            summary: error,
          });
          this.hideDialog();
          this.searchingDeals();
        });
    },
    exportDealPDF(deal) {
      this.generatePDF(deal.id)
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response]));
          const link = document.createElement("a");
          link.href = url;
          const dateToday = new Date();
          const day = dateToday.getDate();
          const month = dateToday.getMonth() + 1;
          const year = dateToday.getFullYear();
          const stringToday = day+""+month+""+year;
          const name = "Offer-"+ deal.type + "-term("+deal.terms+")-"+stringToday
          +".pdf";
          link.setAttribute("download", name);
          document.body.appendChild(link);
          link.click();
        })
        .catch((error) => {
          this.$toast.add({
            severity: "error",
            summary: error,
          });
        });
    },
  },
};
</script>