/** @format */

function getDefaultState() {
  return {
    url: '/'
  }
}

const mutations = {
  setURLName: (state, item) => {
    state.url = item
  }
}

export default {
  state: getDefaultState(),
  mutations
}
